import './App.css';
import { Navigate, Route, Routes, useParams, Outlet, useSearchParams } from 'react-router-dom';
import { Fragment, useState, useEffect, useContext } from 'react';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { Logout, RedirectLogin } from './authentication/index';

import UserContext from './UserContext';
import { useNavigateWithParams } from './hooks/useNavigateWithParams';

import HomePage from './pages/HomePage';
import ReasonCodePage from './pages/ReasonCodePage';
import ProgramTypePage from './pages/ProgramTypePage';
import SubscriptionPlanPage from './pages/SubscriptionPlanPage';
import ConnectorPage from './pages/ConnectorPage';
import SubscriptionActionPage from './pages/SubscriptionActionPage';
import UserPage from './pages/UserPage';
import SubscriptionAutomationPage from './pages/SubscriptionAutomationPage';
import PaymentsPage from './pages/PaymentsPage';
import MerchantsPage from './pages/MerchantsPage';

import PageStructure from './ui/organisms/PageStructure';
import Login from "./ui/organisms/Login";
import { fakeData as harpEnvironmentFakeData } from './pages/fakeData/harpEnvironmentsFakeData';

import { listEnvironment } from './api/environmentService';
import GoCardlessRedirect from './authentication/GoCardlessRedirect';
const isSiteOffline = process.env.REACT_APP_SITE_OFFLINE === "true" ?? false;

function EnvironmentUrlWatcher() {
    let { environmentId } = useParams();
    let userContext = useContext(UserContext);
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (environmentId && !userContext.activeEnvironment && userContext.environments && userContext.environments.length > 0) {
            console.log('Setting environment : ' + environmentId);
            let newEnvironment = userContext.environments.find((e) => e.id === environmentId);
            if (newEnvironment) {
                userContext.setActiveEnvironment(newEnvironment.id, false);
                userContext.setIsSandbox(newEnvironment.extensionData.IsSandBox === "True");
            }
                
            else {
                let defaultEnvironment = userContext.environments.find((e) => e.extensionData && e.extensionData.IsSandBox === "True");
                if (defaultEnvironment) {
                    userContext.setActiveEnvironment(defaultEnvironment.id, true);
                    userContext.setIsSandbox(true);
                }     
            }

        }
    }, [environmentId, userContext.activeEnvironment, userContext.environments, isAuthenticated]);

    return (<Outlet />);
}

function DefaultEnvironmentSearch() {
    let userContext = useContext(UserContext);

    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated && userContext.environments && userContext.environments.length > 0) {         
            let defaultEnvironment = userContext.environments.find((e) => e.extensionData && e.extensionData.IsSandBox === "True");
            if (defaultEnvironment) {
                userContext.setActiveEnvironment(defaultEnvironment.id, true);
                userContext.setIsSandbox(true);
            }                
        }
    }, [isAuthenticated, userContext.environments]);

    return (<Fragment />);
}

function DefaultEnvironmentFromExternalPage() {
    let userContext = useContext(UserContext);
    let [searchParams, setSearchParams] = useSearchParams();

    const isLayoutEmbedded = () => {
        return searchParams.get("layout") === "embedded";
    };

    const searchesForSandboxEnvironment = () => {
        return searchParams.get("isSandbox") === "true" || searchParams.get("isSandbox") === "True";
    };
    const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        if (isAuthenticated && userContext.environments && userContext.environments.length > 0) {
            let isSandboxEnvironment = searchesForSandboxEnvironment() ? "True" : "False";
            let defaultEnvironment = userContext.environments.find((e) => e.extensionData && e.extensionData.IsSandBox === isSandboxEnvironment);
            if (defaultEnvironment) {
                userContext.setActiveEnvironment(defaultEnvironment.id, true, "/connectors/new");
                userContext.setIsSandbox(searchesForSandboxEnvironment());
            }
        }
    }, [isAuthenticated, userContext.environments]);

    return (<Fragment />);
}

function App() {
    const [activeEnvironment, setActiveEnvironment] = useState(null);
    const [environments, setEnvironments] = useState([]);
    const [tenant, setTenant] = useState(null);

    const [redirect, setRedirect] = useState({ redirect: true, redirectTo: null});
    const [isSandbox, setIsSandbox] = useState(true);
    const isAuthenticated = useIsAuthenticated();
    let [searchParams, setSearchParams] = useSearchParams();


    const navigate = useNavigateWithParams();
    useEffect(() => {
        if (isAuthenticated && activeEnvironment && environments && environments.length > 0 && redirect && redirect.redirect) {
            console.log('Redirecting to environment');
            if (redirect.redirectTo == null)
                navigate(activeEnvironment);
            else {
                navigate(activeEnvironment + redirect.redirectTo);
            }
        }
    }, [activeEnvironment, environments, redirect, isAuthenticated]);

    useEffect(() => {
        if (isAuthenticated) {
            let tenantId = searchParams.get("tenantId") || null;

            console.log('Loading environments');
            if (process.env.REACT_APP_TEST_DATA === "true") {
                new Promise(resolve => setTimeout(resolve(harpEnvironmentFakeData), 1000))
                    .then((environmentsData) => {
                        if (environmentsData && environmentsData.length > 0) {
                            setEnvironments(environmentsData);
                            if (tenantId)
                                setTenant(tenantId)
                            else if (environmentsData[0].extensionData && environmentsData[0].extensionData.TenantHARPId)
                                setTenant(environmentsData[0].extensionData.TenantHARPId)
                        }
                    });    
            }
            else {
                listEnvironment(null, null, tenantId, null)
                    .then((environmentsData) => {
                        if (environmentsData && environmentsData.length > 0) {
                            setEnvironments(environmentsData);
                            if (tenantId)
                                setTenant(tenantId)
                            else if (environmentsData[0].extensionData && environmentsData[0].extensionData.TenantHARPId)
                                setTenant(environmentsData[0].extensionData.TenantHARPId)
                        }
                    });        
            }           
        }       
    }, [isAuthenticated]);

    const ChangeDefaultEnvironment = (newEnvironmentId, redirect = false, redirectTo = null) => {
        setRedirect({redirect, redirectTo});
        setActiveEnvironment(newEnvironmentId)
    }

    return (
        <UserContext.Provider value={{ setEnvironments, activeEnvironment, environments, setActiveEnvironment: ChangeDefaultEnvironment, setTenant, tenant, isSandbox, setIsSandbox }}>
               
                {isSiteOffline === false ? 
                (<PageStructure>
                    <Routes>
                        <Route path="/" element={isAuthenticated === true ? (activeEnvironment == null && <DefaultEnvironmentSearch />) : <Login />} />
                        <Route path="/external-connectors" element={isAuthenticated === true ? (activeEnvironment == null && <DefaultEnvironmentFromExternalPage />) : <RedirectLogin hideRedirectText={true} />} />
                        <Route path=":environmentId/*" element={<EnvironmentUrlWatcher /> } >
                            <Route index element={<RequireAuth redirectTo="/"><HomePage /></RequireAuth>} />
                            <Route path="connectors/*" element={<RequireAuthWithRedirect redirectTo="/"><ConnectorPage /></RequireAuthWithRedirect>} />
                            <Route path="users/*" element={<RequireAuth redirectTo="/"><UserPage /></RequireAuth>} />
                            <Route path="subscription-automation" >
                                <Route index path="cockpit/*" element={<RequireAuth redirectTo="/"><SubscriptionAutomationPage /></RequireAuth>} />
                                <Route path="reason-codes/*" element={<RequireAuth redirectTo="/"><ReasonCodePage /></RequireAuth>} />
                                <Route path="program-types/*" element={<RequireAuth redirectTo="/"><ProgramTypePage /></RequireAuth>} />
                                <Route path="subscription-plans/*" element={<RequireAuth redirectTo="/"><SubscriptionPlanPage /></RequireAuth>} />
                                <Route path="subscription-actions/*" element={<RequireAuth redirectTo="/"><SubscriptionActionPage /></RequireAuth>} />
                            </Route>   
                            <Route path="enterprise-automation" >
                                <Route index path="merchants/*" element={<RequireAuth redirectTo="/"><MerchantsPage /></RequireAuth>} />
                                {/*<Route path="mandates/*" element={<RequireAuth redirectTo="/"><blank /></RequireAuth>} />*/}
                                <Route path="payments/*" element={<RequireAuth redirectTo="/"><PaymentsPage /></RequireAuth>} />
                                {/*<Route path="customers/*" element={<RequireAuth redirectTo="/"><blank /></RequireAuth>} />*/}
                                {/*<Route path="refunds/*" element={<RequireAuth redirectTo="/"><blank /></RequireAuth>} />*/}
                            </Route>   
                        </Route>                                  
                        <Route path="/logout" element={<RequireAuth redirectTo="/"> <Logout /> </RequireAuth>} />
                        <Route path="/login/sso" element={<RedirectLogin />} />
                        <Route path="/gocardless/redirect" element={<GoCardlessRedirect />} />
                        <Route path="/*" element={<RequireAuth redirectTo="/"></RequireAuth>} />
                    </Routes>
                </PageStructure>)
                : (<Routes>
                    <Route path="/login/sso" element={<RedirectLogin />} />
                </Routes>)}                                        
                
            
        </UserContext.Provider>
    )
}

function RequireAuth({ children, redirectTo }) {
    return (<Fragment>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        <UnauthenticatedTemplate><Navigate to={redirectTo} /></UnauthenticatedTemplate>
    </Fragment>);
}

function RequireAuthWithRedirect({ children, redirectTo }) {
    let [searchParams, setSearchParams] = useSearchParams();

    const isLayoutEmbedded = () => {
        return searchParams.get("layout") === "embedded";
    };

    return (<Fragment>
        <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
        <UnauthenticatedTemplate>{isLayoutEmbedded() ? (<RedirectLogin hideRedirectText={true} />) : (<Navigate to={redirectTo} />)}</UnauthenticatedTemplate>
    </Fragment>);
}
export default App;
