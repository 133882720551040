import { useState, useContext, useEffect, Fragment, forwardRef } from "react";
import {
  Button,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  InputAdornment,
  OutlinedInput,
  ListItem,
  List,
  Switch,
  FormGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { pink } from "@mui/material/colors";
import UserContext from "../../UserContext";

import ImportExportIcon from "@mui/icons-material/ImportExport";
import SearchIcon from "@mui/icons-material/Search";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";

import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HandymanIcon from "@mui/icons-material/Handyman";

// const GridParent = styled(Grid)(({ theme }) => ({}));

// const GridItem = styled(Grid)(({ theme }) => ({
//   padding: 16,
//   paddingLeft: 30,
// }));

// const Transition = forwardRef(function Transition(props, ref) {
//   return <Slide direction="left" ref={ref} {...props} />;
// });

// const StyledListItem = styled(ListItem)({
//   fontSize: "16px",
//   fontSize: "1rem",
//   fontFamily: "Paralucent,Mulish,Muli,sans-serif",
//   borderRadius: 1,
//   borderRight: 0,

//   "&:hover": {
//     transition: "all .1s ease-in",
//     backgroundColor: "rgba(245, 245, 245, 0.5)",
//     borderColor: "#1950d2",
//     cursor: "pointer",
//   },
// });

const EnvironmentSwitch = styled(Switch)(({ theme }) => ({
   width: 58,
   height: 30,
   padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#034485",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb": {
        backgroundColor: "#034485",
      },
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium" xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.19 6.35c-2.04 2.29-3.44 5.58-3.57 5.89L2 10.69l4.05-4.05c.47-.47 1.15-.68 1.81-.55l1.33.26zM11.17 17s3.74-1.55 5.89-3.7c5.4-5.4 4.5-9.62 4.21-10.57-.95-.3-5.17-1.19-10.57 4.21C8.55 9.09 7 12.83 7 12.83L11.17 17zm6.48-2.19c-2.29 2.04-5.58 3.44-5.89 3.57L13.31 22l4.05-4.05c.47-.47.68-1.15.55-1.81l-.26-1.33zM9 18c0 .83-.34 1.58-.88 2.12C6.94 21.3 2 22 2 22s.7-4.94 1.88-6.12C4.42 15.34 5.17 15 6 15c1.66 0 3 1.34 3 3zm4-9c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: alpha("#02305E", 0.9),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#fff",
    width: 28,
    height: 28,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#465E80"
      )}" d="m22.7 19-9.1-9.1c.9-2.3.4-5-1.5-6.9-2-2-5-2.4-7.4-1.3L9 6 6 9 1.6 4.7C.4 7.1.9 10.1 2.9 12.1c1.9 1.9 4.6 2.4 6.9 1.5l9.1 9.1c.4.4 1 .4 1.4 0l2.3-2.3c.5-.4.5-1.1.1-1.4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: alpha("#B0BDCF", 0.9),
  },
}));

export default function EnvironmentSelect() {
  const [selectedValue, setSelectedValue] = useState("");
  const [checked, setChecked] = useState(false);

  let userContext = useContext(UserContext);

  const handleChangeValue = (event) => {
    let isChecked = event.target.checked;

    if (!isChecked) {
      userContext.environments.forEach((e) => {
        if (e.name.toLowerCase() === "sandbox") {
          setSelectedValue(e.id);
          userContext.setIsSandbox(true);
          setChecked(false);
        }
      });
    } else {
      userContext.environments.forEach((e) => {
        if (e.name.toLowerCase() === "production") {
          setSelectedValue(e.id);
          setChecked(true);
          userContext.setIsSandbox(false);
        }
      });
    }
  };

  useEffect(() => {
    if (userContext.activeEnvironment && selectedValue === "") {
      setSelectedValue(userContext.activeEnvironment);
      userContext.environments.forEach((e) => {
        if (e.id === userContext.activeEnvironment) {
          userContext.setIsSandbox(true);
          setChecked(e.name.toLowerCase() === "production");
        }
      });
    }
  }, [userContext.activeEnvironment]);

  useEffect(() => {
    if (selectedValue && selectedValue !== userContext.activeEnvironment) {
      userContext.setActiveEnvironment(selectedValue, true);
    }
  }, [selectedValue]);

  return (
    <FormControl>
      <FormGroup>
        <FormControlLabel
          sx={{ mr: "20px" }}
          control={
            <EnvironmentSwitch checked={checked} onChange={handleChangeValue} />
          }
          label={
            <div
              style={{
                display: "block",
                textAlign: "left",
                mr: "20px",
                minWidth: "90px"
              }}
            >
              <span
                style={{
                  fontSize: "10px",
                  display: "block",
                }}
              >
                Environment
              </span>
              {userContext.environments.map((v) => {
                if (v.id === userContext.activeEnvironment)
                  return (
                    <span
                      key={v.id}
                      style={{ fontSize: "18px", lineHeight: "5px" }}
                    >
                      {v.name}
                    </span>
                  );
              })}
            </div>
          }
        />
      </FormGroup>
    </FormControl>
  );
}
