import React from 'react';

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string } from 'yup';
import SearchSidePanel from '../../ui/organisms/SearchSidePanel';
import LookupPaper from '../../ui/organisms/LookupPaper';
import LookupField from '../../ui/organisms/LookupField';
import MicrosoftLoginButton from "../../ui/molecules/MicrosoftLoginButton";
import { DialogActions } from '@mui/material/';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';
import PromiseRetry from 'promise-retry';
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams';
import Link from '@mui/material/Link';
import UserContext from "../../UserContext";

const defaultValues = {
    company: "",
    environment: ""
};

let entityReferenceSchema = object({
    id: string().required(),
    name: string().required(),
    connector: string(),
    secondaryKey: string(),
    extensionData: object().typeError("Wrong Data format from Environment")
});

let entityReferenceEnvironmentSchema = object({
    id: string().required(),
    name: string().required(),
    connector: string(),
    secondaryKey: string(),
    extensionData: object({
        aadTenantId: string().required("Tenant missing on environment"),
        webServiceUrl: string().required("WebService missing on environment")
    }).typeError("Wrong Data format from Environment")
});

let connectorSchema = object({
    company: entityReferenceSchema,
    environment: entityReferenceEnvironmentSchema
});

const consentUrl = process.env.REACT_APP_CONSENT_URL ?? "missing-consent-url";

export default function EditConnectorForm({
    handleEditRecord,
    handleAddRemainingDetails,
    handleInitialRecord,
    handleClose,
    isLoading,
    isLoadingSearchSidePanel,
    handleListRecords,
    searchSidePanelData,
    recordData,
    handleFetchRecord }) {

    const [formValues, setFormValues] = React.useState(defaultValues);
    const [initialConnectorId, setInitialConnectorId] = React.useState(null);

    const [isAccountEnabled, setIsAccountEnabled] = React.useState(false);

    const [isSignInEnabled, setIsSignInEnabled] = React.useState(true);
    const [loadingAuthentication, setLoadingAuthentication] = React.useState(false);

    const [isFormEnabled, setIsFormEnabled] = React.useState(false);

    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});
    const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
    const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
    const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
    const [columns, setColumns] = React.useState([]);
    const [searchSidePanelType, setSearchSidePanelType] = React.useState("");

    const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] = React.useState({});
    const [selectedLookupId, setSelectedLookupId] = React.useState(null);
    const timer = React.useRef();
    const opener = React.useRef();
    const userContext = React.useContext(UserContext);

    const navigate = useNavigateWithParams();

    const onSubmit = data => {       
    }

    React.useEffect(() => {
        if (!setLoadingAuthentication)
            return () => clearInterval(timer.current);

    }, [setLoadingAuthentication]);

    React.useEffect(() => {
        return () => {
            clearInterval(timer.current);
        };
    }, []);


    const openAuthenticateWindow = () => {
        setLoadingAuthentication(true);
        var connectorId = recordData.id;
        let fullURL = `${consentUrl}?id=${connectorId}&envId=${userContext.activeEnvironment}&tId=${userContext.tenant}`

        opener.current = window.open(fullURL, "_blank", "popup=1,width=500,height=500");

        timer.current = setInterval(function () {
            if (opener && opener.current && opener.current.closed) {                
                setLoadingAuthentication(false);
                clearInterval(timer.current);
                handleClose();
            }
        }, 1000);
    }

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="column">
                <Grid item>
                    <TextField
                        id="usernameid-input"
                        name="username"
                        label="Username"
                        type="text"
                        size="small"
                        sx={{ width: 350 }}
                        required      
                        disabled={true}
                        value={recordData.username}
                    />
                    <Button
                        onClick={openAuthenticateWindow}
                       
                        sx={{ marginLeft: "5px", marginTop: "6px" }}
                    >
                        (Change)
                    </Button>                    
                </Grid>
                <Grid item>
                    <LookupField                        
                        required
                        disabled={isLoading || !isFormEnabled}
                        initialValue={{ id: recordData.environment, name: recordData.environment }}
                        id="environmentlookup"
                        type="environment"
                        name="environment"
                        label="Environment"
                    />
                </Grid>
                <Grid item>
                    <LookupField                        
                        disabled={true}
                        initialValue={recordData.company}
                        id="companylookup"
                        type="company"
                        name="company"
                        label="Company"
                    />
                </Grid>

                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Close</Typography>
                    </Button>
                </DialogActions>
            </Grid>
            <SearchSidePanel open={searchSidePanelOpen}
                handleClose={() => setSearchSidePanelOpen(false)}
                isLoading={isLoadingSearchSidePanel}
                title={searchSidePanelTitle}>
                <LookupPaper
                    handleClose={() => setSearchSidePanelOpen(false)}
                    selectedLookupId={selectedLookupId}
                    handleChangeLookupValue={setSearchSidePanelSelectedLookup}
                    isLoading={isLoadingSearchSidePanel}
                    query={searchSidePanelQuery}
                    data={searchSidePanelData}
                    columns={columns}
                    searchSidePanelType={searchSidePanelType}
                    handleListRecords={handleListRecords} />
            </SearchSidePanel>
        </form>
    );
}