import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { BrowserRouter, useLocation } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import {
  AppInsightsErrorBoundary,
  AppInsightsContext,
} from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { pca } from './authentication/publicClientApplication';

const DebugRouter = ({ children }) => {
  const location = useLocation();
  if (process.env.NODE_ENV === "development") {
    console.log(
      `Route: ${location.pathname}${location.search}, State: ${JSON.stringify(
        location.state
      )}`
    );
  }

  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppInsightsContext.Provider value={reactPlugin}>
        <AppInsightsErrorBoundary appInsights={reactPlugin}>
          <MsalProvider instance={pca}>
            <ThemeProvider theme={theme}>
              <DebugRouter>
                <App />
              </DebugRouter>
            </ThemeProvider>
          </MsalProvider>
        </AppInsightsErrorBoundary>
      </AppInsightsContext.Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
