import * as React from "react";
import { Box, Grid, Typography, Tooltip, Chip } from "@mui/material/";
import DataGrid from "../organisms/DataGrid";
import { styled } from "@mui/material/styles";
import NewRibbonButton from "../molecules/NewRibbonButton";
import RefreshRibbonButton from "../molecules/RefreshRibbonButton";

import TooltipStatusChip from "../molecules/TooltipStatusChip";
import ConfirmDialog from "../molecules/ConfirmDialog";
import SidePanel from "../organisms/SidePanel";

import NotificationSnackBar from "../organisms/NotificationSnackBar";

import NewConnectorForm from "../../forms/connector/NewConnectorForm";
import EditConnectorForm from "../../forms/connector/EditConnectorForm";

import {
  Route,
  Routes,
  Outlet,
  useParams,
  useSearchParams,
} from "react-router-dom";

import { format } from "date-fns";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";
import ProfileCard from "../organisms/ProfileCard";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import DeactivateRibbonButton from "../molecules/DeactivateRibbonButton";
import ActivateRibbonButton from "../molecules/ActivateRibbonButton";
import DeleteRibbonButton from "../molecules/DeleteRibbonButton";
import TestRibbonButton from "../molecules/TestRibbonButton";
import EditRibbonButton from "../molecules/EditRibbonButton";
import LicenseAgreementRibbonButton from "../molecules/LicenseAgreementRibbonButton";

const GridParent = styled(Grid)(({ theme }) => ({}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
  padding: 3,
  paddingLeft: 30,
  display: "inline-flex",
}));

const GridItem = styled(Grid)(({ theme }) => ({
  padding: 16,
  paddingLeft: 30,
}));

const RenderActive = ({ value }) => {
  return value ? (
    <CheckIcon sx={{ width: "100%" }} />
  ) : (
    <CloseIcon sx={{ width: "100%" }} />
  );
};

const SetStatusColor = ({ value }) => {
  switch (value) {
    case "New":
      return "default";
    case "Authenticated":
      return "secondary";
    case "Validating":
      return "warning";
    case "Validated":
      return "success";
    default:
      return "error";
  }
};

const SetStatusIcon = ({ value }) => {
  switch (value) {
    case "New":
      return <DriveFileRenameOutlineIcon />;
    case "Authenticated":
      return <AccountCircleIcon />;
    case "Validating":
      return <HourglassEmptyIcon />;
    case "Validated":
      return <CheckCircleOutlineIcon />;
    default:
      return <ErrorOutlineIcon />;
  }
};

const columns = [
  {
    field: "active",
    headerName: "Active",
    flex: 1,
    maxWidth: 90,
    type: "singleSelect",
    valueOptions: [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ],
    renderCell: RenderActive,
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    maxWidth: 190,
    type: "singleSelect",
    valueOptions: [
      { label: "New", value: "New" },
      { label: "Authenticated", value: "Authenticated" },
      { label: "Validating", value: "Validating" },
      { label: "Validated", value: "Validated" },
      { label: "Failed", value: "Failed" },
    ],
    renderCell: (params) => {
      if (
        params &&
        params.row &&
        params.row.status === "Failed" &&
        params.row.statusErrorMessage
      ) {
        return (
          <TooltipStatusChip
            title={params.row.statusErrorMessage}
            label={params.row.status}
            variant="outlined"
            color={SetStatusColor(params)}
            icon={SetStatusIcon(params)}
          />
        );
      } else {
        return (
          <TooltipStatusChip
            title={params.row.status}
            label={params.formattedValue}
            variant="outlined"
            color={SetStatusColor(params)}
            icon={SetStatusIcon(params)}
          />
        );
      }
    },
  },
  { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
  { field: "environment", headerName: "Environment", flex: 1, minWidth: 100 },
  {
    field: "company",
    headerName: "Company",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (!params.value || params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "connectorTenantId",
    headerName: "Tenant Id",
    flex: 2,
    minWidth: 100,
  },
  {
    field: "createdOn",
    headerName: "Created On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
];
function ConnectorIndex({
  isLoading,
  data,
  onRefresh,
  setDisplayNotification,
  displayNotification,
  handleActivateRecord,
  handleDeactivateRecord,
  handleDeleteRecord,
  handleTestRecord,
}) {
  const [confirmDialog, setConfirmDialog] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState(null);

  const [displayActivateRibbon, setDisplayActivateRibbon] =
    React.useState(false);
  const [displayDeactivateRibbon, setDisplayDeactivateRibbon] =
    React.useState(false);
  const [displayDeleteRibbon, setDisplayDeleteRibbon] = React.useState(false);
  const [displayTestRibbon, setDisplayTestRibbon] = React.useState(false);
  const [displayEditRibbon, setDisplayEditRibbon] = React.useState(false);
  const [displayLicenseAgreementRibbon, setDisplayLicenseAgreementRibbon] =
    React.useState(false);

  let navigate = useNavigateWithParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const isLayoutEmbedded = () => {
    return searchParams.get("layout") === "embedded";
  };

  const onClickRow = (params) => {
    setSelectedRow(params.row);
    if (params.row.active === false && params.row.status === "Validated")
      setDisplayActivateRibbon(true);
    else setDisplayActivateRibbon(false);

    if (params.row.active === true) {
      setDisplayDeactivateRibbon(true);
      setDisplayEditRibbon(false);
    } else {
      setDisplayDeactivateRibbon(false);
      setDisplayEditRibbon(true);
    }

    if (params.row.active === false && params.row.status !== "Validating")
      setDisplayDeleteRibbon(true);
    else setDisplayDeleteRibbon(false);

    if (params.row.status === "Failed") {
      setDisplayTestRibbon(true);
    } else {
      setDisplayTestRibbon(false);
    }

    if (
      params.row.licenseAgreementSigned === false &&
      params.row.status === "Validating"
    )
      setDisplayLicenseAgreementRibbon(true);
    else setDisplayLicenseAgreementRibbon(false);
  };

  const onDoubleClickRow = (params) => {
    editRecord();
  };

  const editRecord = () => {
    if (selectedRow && selectedRow.active === false) {
      navigate(`${selectedRow.id}/edit`);
    }
  };

  const createRecord = () => {
    navigate("new");
  };

  const deleteRecord = () => {
    setConfirmDialog({
      title: "Delete Connector",
      description: `Are you sure you want to Delete ${selectedRow.name}?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleDeleteRecord(selectedRow.id)
          .then((data) => {
            setDisplayNotification({
              message: data.message,
              type: data.type,
            });
            refreshGrid();
          })
          .catch((e) => {
            setDisplayNotification({
              message:
                e.response.status === 403
                  ? "Unauthorized"
                  : e.response.data.Message,
              type: "error",
            });
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const activateRecord = () => {
    setConfirmDialog({
      title: "Activate Connector",
      description: `Are you sure you want to Activate ${selectedRow.name}?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleActivateRecord(selectedRow.id)
          .then(() => {
            setDisplayNotification({
              message: "Connector Activated successfully",
              type: "success",
            });
            refreshGrid();
          })
          .catch((e) => {
            setDisplayNotification({
              message:
                e.response.status === 403
                  ? "Unauthorized"
                  : e.response.data.Message,
              type: "error",
            });
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const deactivateRecord = () => {
    setConfirmDialog({
      title: "Deactivate Connector",
      description: `Are you sure you want to Deactivate ${selectedRow.name}?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleDeactivateRecord(selectedRow.id)
          .then(() => {
            setDisplayNotification({
              message: "Connector Deactivated successfully",
              type: "success",
            });
            refreshGrid();
          })
          .catch((e) => {
            setDisplayNotification({
              message:
                e.response.status === 403
                  ? "Unauthorized"
                  : e.response.data.Message,
              type: "error",
            });
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const testRecord = () => {
    setConfirmDialog({
      title: "Test Connector",
      description: `Are you sure you want to test ${selectedRow.name}?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleTestRecord(selectedRow.id)
          .then(() => {
            setDisplayNotification({
              message: "Validating Connector",
              type: "success",
            });
            refreshGrid();
          })
          .catch((e) => {
            setDisplayNotification({
              message:
                e.response.status === 403
                  ? "Unauthorized"
                  : e.response.data.Message,
              type: "error",
            });
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const openLicenseAgreement = () => {
    let baseUrl = selectedRow.webApplicationUrl;
    let companyName = selectedRow.company.name;
    let environmentName = selectedRow.environment;

    let url = `${baseUrl}/${environmentName}?page=71082591&company=${companyName}`;
    window.open(url, "_blank").focus();
  };

  const refreshGrid = () => {
    setSelectedRow(null);
    setDisplayDeleteRibbon(false);
    setDisplayDeactivateRibbon(false);
    setDisplayActivateRibbon(false);
    setDisplayTestRibbon(false);
    setDisplayEditRibbon(false);
    setDisplayLicenseAgreementRibbon(false);
    onRefresh();
  };

  return (
    <React.Fragment>
      <GridParent
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <ConfirmDialog
          confirmDialogInfo={confirmDialog}
          onConfirm={confirmDialog.actionConfirm}
          onCancel={confirmDialog.actionCancel}
        />
        <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
          <NewRibbonButton
            onClick={createRecord}
            display={true}
            title="New Connector"
          />
          <EditRibbonButton
            onClick={editRecord}
            display={displayEditRibbon}
            title="Edit"
          />
          <RefreshRibbonButton
            onClick={refreshGrid}
            display={true}
            title="Refresh"
          />
          <ActivateRibbonButton
            onClick={activateRecord}
            display={displayActivateRibbon}
            title="Activate"
          />
          <DeactivateRibbonButton
            onClick={deactivateRecord}
            display={displayDeactivateRibbon}
            title="Deactivate"
          />
          <DeleteRibbonButton
            onClick={deleteRecord}
            display={displayDeleteRibbon}
            title="Delete"
          />
          <TestRibbonButton
            onClick={testRecord}
            display={displayTestRibbon}
            title="Validate"
          />
          <LicenseAgreementRibbonButton
            onClick={openLicenseAgreement}
            display={displayLicenseAgreementRibbon}
            title="Accept License Agreement"
          />

          {isLayoutEmbedded() && <ProfileCard embeddedMode={true} />}
        </RibbonGrid>
        <GridItem item xs={12} md={12} lg={12}>
          <Typography>Connectors</Typography>
        </GridItem>
        <GridItem item xs={12} md={12} lg={12}>
          <DataGrid
            loading={isLoading}
            onRowClick={onClickRow}
            onRowDoubleClick={onDoubleClickRow}
            columns={columns}
            rows={data}
          />
        </GridItem>
      </GridParent>
      {displayNotification !== null && (
        <NotificationSnackBar
          displayNotification={displayNotification}
          onClose={() => {
            setDisplayNotification(null);
          }}
        />
      )}
      <Outlet />
    </React.Fragment>
  );
}

function ConnectorNew({
  sidePanelOpen,
  isLoadingSidePanel,
  handleInitialRecord,
  handleCreateRecord,
  setDisplayNotification,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  handleFetchRecord,
}) {
  let navigate = useNavigateWithParams();

  return (
    <SidePanel
      open={sidePanelOpen}
      handleClose={() => navigate("../")}
      isLoading={isLoadingSidePanel}
      title="New Connector"
    >
      <NewConnectorForm
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../")}
        handleInitialRecord={handleInitialRecord}
        handleFetchRecord={handleFetchRecord}
        handleCreateRecord={(id, record) => {
          return handleCreateRecord(id, record)
            .then(() => {
              setDisplayNotification({
                message: "Connector created successfully",
                type: "success",
              });
              return navigate("../");
            })
            .catch((e) => {
              //TODO error
              if (e.response && e.response.data && e.response.data.Message) {
                setDisplayNotification({
                  message: e.response.data.Message,
                  type: "error",
                });
              } else
                setDisplayNotification({
                  message:
                    e.response.status === 403
                      ? "Unauthorized"
                      : "Error occurred",
                  type: "error",
                });
            });
        }}
        handleAddRemainingDetails={(id, record) => {
          return handleCreateRecord(id, record)
            .then(() => {
              return true;
            })
            .catch((e) => {
              //TODO error
              setDisplayNotification({
                message:
                  e.response.status === 403 ? "Unauthorized" : "Error occurred",
                type: "error",
              });
              throw e;
            });
        }}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        handleListRecords={handleListRecords}
        searchSidePanelData={searchSidePanelData}
      />
    </SidePanel>
  );
}

function ConnectorEdit({
  sidePanelOpen,
  isLoadingSidePanel,
  handleInitialRecord,
  setDisplayNotification,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  handleCreateRecord,
  handleFetchRecord,
}) {
  const [recordData, setRecordData] = React.useState(null);

  let navigate = useNavigateWithParams();

  let { connectorId } = useParams();

  React.useEffect(() => {
    handleFetchRecord(connectorId).then((data) => {
      setRecordData(data);
    });
  }, []);

  return (
    <SidePanel
      open={sidePanelOpen}
      handleClose={() => navigate("../")}
      isLoading={isLoadingSidePanel}
      title="Edit Connector"
    >
      {!isLoadingSidePanel && recordData && (
        <EditConnectorForm
          isLoading={isLoadingSidePanel}
          handleClose={() => navigate("../")}
          handleInitialRecord={handleInitialRecord}
          recordData={recordData}
          handleEditRecord={(id, record) => {
            //TODO
            return handleCreateRecord(id, record)
              .then(() => {
                setDisplayNotification({
                  message: "Connector created successfully",
                  type: "success",
                });
                return navigate("../");
              })
              .catch((e) => {
                //TODO error
                if (e.response && e.response.data && e.response.data.Message) {
                  setDisplayNotification({
                    message: e.response.data.Message,
                    type: "error",
                  });
                } else
                  setDisplayNotification({
                    message:
                      e.response.status === 403
                        ? "Unauthorized"
                        : "Error occurred",
                    type: "error",
                  });
              });
          }}
          isLoadingSearchSidePanel={isLoadingSearchSidePanel}
          handleListRecords={handleListRecords}
          searchSidePanelData={searchSidePanelData}
        />
      )}
    </SidePanel>
  );
}

function ConnectorTemplate(props) {
  const {
    data,
    isLoading,
    isLoadingSidePanel,
    isLoadingSearchSidePanel,
    onRefresh,
    handleListRecords,
    handleCreateRecord,
    searchSidePanelData,
    handleInitialRecord,
    handleDeactivateRecord,
    handleActivateRecord,
    handleDeleteRecord,
    handleTestRecord,
    handleFetchRecord,
  } = props;

  const [displayNotification, setDisplayNotification] = React.useState(null);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ConnectorIndex
            isLoading={isLoading}
            displayNotification={displayNotification}
            onRefresh={onRefresh}
            setDisplayNotification={setDisplayNotification}
            handleDeactivateRecord={handleDeactivateRecord}
            handleActivateRecord={handleActivateRecord}
            handleDeleteRecord={handleDeleteRecord}
            handleTestRecord={handleTestRecord}
            data={data}
          />
        }
      >
        <Route
          path="new"
          element={
            <ConnectorNew
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              handleListRecords={handleListRecords}
              handleInitialRecord={handleInitialRecord}
              handleCreateRecord={handleCreateRecord}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              displayNotification={displayNotification}
              handleFetchRecord={handleFetchRecord}
              sidePanelOpen={true}
            />
          }
        />

        <Route
          path=":connectorId/edit"
          element={
            <ConnectorEdit
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              handleListRecords={handleListRecords}
              handleInitialRecord={handleInitialRecord}
              handleCreateRecord={handleCreateRecord}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              displayNotification={displayNotification}
              handleFetchRecord={handleFetchRecord}
              sidePanelOpen={true}
            />
          }
        />
      </Route>
    </Routes>
  );
}

export default ConnectorTemplate;
