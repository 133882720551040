import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string, number } from 'yup';
import MenuItem from "@mui/material/MenuItem";

import { DialogActions } from '@mui/material/';

let programTypeSchema = object({
    name: string().required(),
    description: string().required()
});

export default function ViewProgramTypeForm({ handleClose, isLoading, recordData }) {
    const [formValues, setFormValues] = React.useState(recordData);
    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});
    const [isDirty, setIsDirty] = React.useState({});

    return (
        <form>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="column">
                <Grid item>
                    <TextField
                        id="name-input"
                        name="name"
                        label="Name"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.name}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        select
                        id="periodType-input"
                        name="periodType"
                        label="Period Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.periodType}
                    >
                        <MenuItem value="Calendar">Calendar</MenuItem>
                        <MenuItem value="Rolling">Rolling</MenuItem>
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        select
                        name="subscriptionType"
                        label="Subscription Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.subscriptionType}
                    >
                        <MenuItem value="PerpetualEnhancementPlan">Perpetual Enhancement Plan</MenuItem>
                        <MenuItem value="PerUnitSubscription">Per Unit Subscription</MenuItem>
                        <MenuItem value="Consumption">Consumption</MenuItem>
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        id="createdOn-input"
                        name="createdOn"
                        label="Created On"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.createdOn}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="modifiedOn-input"
                        name="modifiedOn"
                        label="Modified On"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.modifiedOn}
                    />
                </Grid>                
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>                    
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Close</Typography>
                    </Button>
                </DialogActions>
            </Grid>
        </form>
    );
}