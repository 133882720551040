import * as React from 'react';
import RibbonButton from './RibbonButton';
import { Typography } from '@mui/material/';
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteRibbonButton(props) {
    return (
        <RibbonButton {...props} startIcon={<DeleteIcon sx={{ color: "black" }} />}>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonButton>
    );
}