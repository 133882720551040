import * as React from "react";

import { styled } from "@mui/material/styles";
import {
  InputAdornment,
  CircularProgress,
  Box,
  Button,
  ListItem,
  List,
  FormControl,
  OutlinedInput,
} from "@mui/material/";

import ImportExportIcon from "@mui/icons-material/ImportExport";
import SearchIcon from "@mui/icons-material/Search";

import { useSearchParams } from "react-router-dom";

const StyledListItem = styled(ListItem)({
  fontSize: "16px",
  fontSize: "1rem",
  fontFamily: "Paralucent,Mulish,Muli,sans-serif",
  borderRadius: 1,
  borderRight: 0,

  "&:hover": {
    transition: "all .1s ease-in",
    backgroundColor: "rgba(245, 245, 245, 0.5)",
    borderColor: "#1950d2",
    cursor: "pointer",
  },
});

const SidebarListContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "isEmbedded",
})(({ theme, isEmbedded }) => ({
  height: `calc(100% - ${theme.toolbar.height + 100}px)`,
  display: "flex",

  ...(isEmbedded && {
    height: `calc(100% - 100px)`,
  }),
}));

// Cockpit Customer Sidebar List
const CustomerListSidebar = ({
  customers,
  activeCustomer,
  setActiveCustomer,
  handleSearchCustomers,
  disabled,
  isLoading,
}) => {
  const [searchValue, setSearchValue] = React.useState("");
  const [searchCustomer, setSearchCustomer] = React.useState(null);

  let [searchParams, setSearchParams] = useSearchParams();

  const isLayoutEmbedded = () => {
    return searchParams.get("layout") === "embedded";
  };

  const handleListItemClick = (event, index) => {
    if (activeCustomer && customers[index].id === activeCustomer.id) {
      setActiveCustomer(null);
    } else {
      setActiveCustomer(customers[index]);
    }
  };

  const handleChange = async (event) => {
    setSearchValue(event.target.value);
  };

  React.useEffect(() => {
    const timeOutId = setTimeout(() => setSearchCustomer(searchValue), 800);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  React.useEffect(() => {
    if (searchCustomer != null) {
      handleSearchCustomers(searchCustomer);
    }
  }, [searchCustomer]);

  React.useEffect(() => {
    
  }, [customers]);

  return (
    <React.Fragment>
      <FormControl
        sx={{
          position: "sticky",
          m: 1,
          display: "flex",
          height: "70",
        }}
      >
        <OutlinedInput
          id="outlined-adornment-search"
          placeholder="Search"
          sx={{ height: 40, marginTop: "5px" }}
          value={searchValue}
          onChange={handleChange}
          disabled={disabled}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />
        <Button
          variant="string"
          sx={{
            width: "80px",
            height: "30px",
            marginTop: "5px",
            textTransform: "none",
          }}
          endIcon={<ImportExportIcon />}
        >
          Name
        </Button>
      </FormControl>
      <span
        style={{
          display: "flex",
          width: "100%",
          height: "1px",
          left: 0,
          right: 0,
          boxShadow: "0 4px 4px #bbb",
          backgroundColor: "#bbb",
        }}
      />
      <SidebarListContainer isEmbedded={isLayoutEmbedded()}>
        <List
          // overscanCount={5} // Was throwing an error
          style={{
            scrollbarWidth: "thin",

            margin: 0,
            padding: 0,

            listStyle: "none",

            overflowY: "auto",
            overflowX: "none",

            width: "100%",
          }}
        >
          {isLoading && (
            <Box sx={{ textAlign: "center" }}>
              <CircularProgress />
            </Box>
          )}
          {customers &&
            customers.map((customers, index) => (
              <StyledListItem
                key={customers.id}
                selected={
                  activeCustomer != null && customers.id === activeCustomer.id
                }
                onClick={(event) => handleListItemClick(event, index)}
                sx={{
                  listStyle: "none",
                  display: "flex",
                  width: "100%",
                  height: "50px",
                  textAlign: "left",

                  borderLeft:
                    activeCustomer != null && customers.id === activeCustomer.id
                      ? 13
                      : 0,
                  borderColor: "#1976d2",
                  borderBottom: 1,
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderBottomColor: "black",
                }}
              >
                {customers && customers.name}
              </StyledListItem>
            ))}
        </List>
      </SidebarListContainer>
    </React.Fragment>
  );
};

export default CustomerListSidebar;
