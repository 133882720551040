/*
 *  This class aims to imitate the Nivo Rock Chart Tip but more customizable
 */

import * as React from "react";
import PropTypes from "prop-types";
import { Paper } from "@mui/material/";
import { styled } from "@mui/material/styles";

const LineTip = styled(Paper)({
  backgroundColor: "white",

  borderRadius: "5px",

  paddingTop: "7px",
  paddingBottom: "7px",
  paddingLeft: "10px",
  paddingRight: "10px",

  width: "auto",
  height: "auto",

  boxShadow: "0px 1px 1px",
});

const SvgWrapper = styled("svg")({
  width: "12px",
  height: "12px",

  marginRight: "10px",
  marginLeft: "5px",
});

const LineChartTip = ({ slice }) => {
  return (
    <LineTip>
      {slice.points.length > 0 &&
        slice.points.map((point) => (
          <div key={point.id}>
            <SvgWrapper>
              <circle cx="10" cy="10" r="40" fill={point.serieColor}></circle>
            </SvgWrapper>
            <strong>
              {point.data.xFormatted}
              {"-"}
              {point.serieId}
              {": "}
              {point.data.yFormatted}
            </strong>
          </div>
        ))}
    </LineTip>
  );
};

LineChartTip.propTypes = {
  slice: PropTypes.object,
};

LineChartTip.defaultProps = {
  slice: {
    id: 0,
    serieId: 0,
    height: 0,
    width: 0,
    x: 0,
    x0: 0,
    y: 0,
    y0: 0,
    points: [],
  },
};

/*
    Slice object structure from Nivo Rocks 
    (This is not documented, as far as I am aware)
    --------------------------------------

    slice: {
        id:         number,
        serieId:    number,
        height:     number,
        width:      number,
        x:          number,
        x0:         number,
        y:          number,
        y0:         number,

        points:     (array of objects) [
                        {
                            id:             number,
                            index:          number,
                            borderColor:    string,
                            color:          string,
                            serieColor:     string,
                            xFormatted:     number,
                            yFormatted:     number,
                            x:              number,
                            y:              number,
                            data: {
                            xFormatted:   string,
                            x:            string,
                            yFormatted:   number,
                            y:            number,
                            },
                        },
                    ],
    }
*/

export default LineChartTip;
