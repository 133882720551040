import * as React from 'react';
import RibbonButton from './RibbonButton';
import { Typography } from '@mui/material/';
import SpeedIcon from '@mui/icons-material/Speed';

export default function TestRibbonButton(props) {
    return (
        <RibbonButton {...props} startIcon={<SpeedIcon sx={{ color: "black" }} />}>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonButton>
    );
}