import * as React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Paper,
  Typography,
  FormControlLabel,
  Switch,
  TextField,
  Stack,
  Box,
} from "@mui/material/";
import DataGrid from "../organisms/DataGrid";
import { getGridStringOperators } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Subtitle from "../atoms/Subtitle";

import FileUploadIcon from "@mui/icons-material/FileUpload";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import NewRibbonButton from "../molecules/NewRibbonButton";
import EditRibbonButton from "../molecules/EditRibbonButton";
import RefreshRibbonButton from "../molecules/RefreshRibbonButton";
import DeleteRibbonButton from "../molecules/DeleteRibbonButton";
import UnpublishRibbonButton from "../molecules/UnpublishRibbonButton";
import PublishRibbonButton from "../molecules/PublishRibbonButton";
import MasterProcessRibbonButton from "../molecules/MasterProcessRibbonButton";
import ContractDefinitionRibbonButton from "../molecules/ContractDefinitionRibbonButton";

import ConfirmDialog from "../molecules/ConfirmDialog";
import SidePanel from "../organisms/SidePanel";

import NotificationSnackBar from "../organisms/NotificationSnackBar";

import NewSubscriptionPlanForm from "../../forms/subscriptionplan/NewSubscriptionPlanForm";
import EditSubscriptionPlanForm from "../../forms/subscriptionplan/EditSubscriptionPlanForm";
import CancelSubscriptionPlanForm from "../../forms/subscriptionplan/CancelSubscriptionPlanForm";
import ContractDefinitionForm from "../../forms/subscriptionplan/ContractDefinitionForm";

import {
  Route,
  Routes,
  Outlet,
  useParams,
  useSearchParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { format } from "date-fns";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";
import { useFirstRender } from "../../hooks/useFirstRender";

import ProfileCard from "../organisms/ProfileCard";
import StatusChip from "../atoms/StatusChip";

const GridParent = styled(Grid)(({ theme }) => ({}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
  padding: 3,
  paddingLeft: 30,
  display: "inline-flex",
}));

const GridItem = styled(Grid)(({ theme }) => ({
  padding: 16,
  paddingLeft: 30,
}));

const SetStatusColor = (value) => {
  switch (value) {
    case "Draft":
      return "default";
    case "Published":
      return "success";
    default:
      return "error";
  }
};

const SetStatusIcon = (value) => {
  switch (value) {
    case "Draft":
      return <DriveFileRenameOutlineIcon />;
    case "Published":
      return <FileUploadIcon />;
    default:
      return <RemoveCircleOutlineIcon />;
  }
};

const RenderStatus = ({ value }) => {
  return (
    <StatusChip
      sx={{ width: "100%" }}
      variant="outlined"
      label={value}
      color={SetStatusColor(value)}
      icon={SetStatusIcon(value)}
    />
  );
};

const ConnectorInputValue = (props) => {
  const { item, applyValue, focusElementRef } = props;

  const connectorRef = React.useRef(null);
  React.useImperativeHandle(focusElementRef, () => ({
    focus: () => {
      connectorRef.current
        .querySelector(`input[value="${item.value || ""}"]`)
        .focus();
    },
  }));

  const handleFilterChange = (event) => {
    applyValue({
      ...item,
      value: (event.target.value === undefined) & "" || event.target.value,
    });
  };

  return (
    <TextField
      id="standard-basic"
      variant="standard"
      label="Value"
      name="connector-filter"
      placeholder="Filter value"
      onChange={handleFilterChange}
      value={item.value}
      ref={connectorRef}
    />
  );
};

const connectorOperator = {
  label: "by id",
  value: "by-id",
  getApplyFilterFn: (filterItem, column) => {
    if (
      !filterItem.columnField ||
      !filterItem.value ||
      !filterItem.operatorValue
    ) {
      return null;
    }
    return (params) => {
      return params.row?.connector?.company.id === filterItem.value;
    };
  },
  InputComponent: ConnectorInputValue,
  InputComponentProps: { type: "string" },
};

const columns = [
  {
    field: "status",
    headerName: "Status",
    flex: 2,
    minWidth: 120,
    type: "singleSelect",
    valueOptions: [
      { label: "Draft", value: "Draft" },
      { label: "Published", value: "Published" },
      { label: "Cancelled", value: "Cancelled" },
    ],
    renderCell: RenderStatus,
  },
  {
    field: "customer",
    headerName: "Customer",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (!params.value || params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "connector",
    headerName: "Company",
    flex: 2,
    minWidth: 100,
    filterOperators: [...getGridStringOperators(), connectorOperator],
    //.append(connectorOperator),
    valueGetter: (params) => {
      if (
        !params.value ||
        !params.value.company ||
        params.value.company.name === undefined
      )
        return "";

      return params.value.company.name;
    },
  },
  {
    field: "reasonCode",
    headerName: "Reason Code",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "programType",
    headerName: "Program Type",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (!params.value || params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "billingPeriodUnit",
    headerName: "Billing Period Unit",
    flex: 2,
    minWidth: 100,
    type: "singleSelect",
    valueOptions: [
      { label: "Days", value: "Days" },
      { label: "Months", value: "Months" },
    ],
  },
  {
    field: "monthBillingPeriodType",
    headerName: "Billing Period Type",
    flex: 2,
    minWidth: 100,
    type: "singleSelect",
    valueOptions: [
      { label: "Monthly", value: "Monthly" },
      { label: "Quarterly", value: "Quarterly" },

      { label: "Half-Yearly", value: "HalfYearly" },

      { label: "Yearly", value: "Yearly" },

      { label: "Other", value: "Other" },
    ],
    valueFormatter: (params) => {
      if (params.value === "HalfYearly") return "Half-Yearly";

      return params.value;
    },
  },
  {
    field: "billingPeriodValue",
    headerName: "Billing Period Value",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "billingType",
    headerName: "Billing Type",
    flex: 1,
    minWidth: 100,
    type: "singleSelect",
    valueOptions: [{ label: "Upfront", value: "Upfront" }],
    value: "Upfront",
    valueFormatter: (params) => {
      return params.value;
    },
  },
  {
    field: "periodType",
    headerName: "Period Type",
    flex: 1,
    minWidth: 100,
    type: "singleSelect",
    valueOptions: [
      { label: "Calendar", value: "Calendar" },
      { label: "Rolling", value: "Rolling" },
    ],
    valueFormatter: (params) => {
      return params.value;
    },
  },
  {
    field: "subscriptionType",
    headerName: "Subscription Type",
    flex: 1,
    minWidth: 100,
    type: "singleSelect",
    valueOptions: [
      { label: "Per Unit Subscription", value: "PerUnitSubscription" },
    ],
    valueFormatter: (params) => {
      if (params.value === "PerUnitSubscription") return "Per Unit Subscription";
      if (params.value === "PerpetualEnhancementPlan")
        return "Perpetual Enhancement Plan";

      return params.value;
    },
  },
  {
    field: "createdOn",
    headerName: "Created On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
];
function SubscriptionPlanIndex({
  mode,
  isLoading,
  data,
  onRefresh,
  handlePublish,
  handleMasterProcess,
  handleUnpublish,
  handleCancel,
  setDisplayNotification,
  displayNotification,
  activeCustomer,
  setActiveSubscriptionPlan,
}) {
  const [display, setDisplay] = React.useState({
    edit: false,
    cancel: false,
    publish: false,
    unpublish: false,
    masterProcess: false,
    contractDefinition: false,
  });

  // const [displayEdit, setDisplayEdit] = React.useState(false);
  // const [displayCancel, setDisplayCancel] = React.useState(false);
  // const [displayPublish, setDisplayPublish] = React.useState(false);
  // const [displayUnpublish, setDisplayUnpublish] = React.useState(false);
  // const [displayMasterProcess, setDisplayMasterProcess] = React.useState(false);
  // const [displayContractDefinition, setDisplayContractDefinition] = React.useState(false);

  const [displayTestDate, setDisplayTestDate] = React.useState(false);

  const [testDate, setTestDate] = React.useState("");
  const [allowTestDate, setAllowTestDate] = React.useState(false);

  const [confirmDialog, setConfirmDialog] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState(null);

  const [selectionModel, setSelectionModel] = React.useState([]);

  let navigate = useNavigateWithParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const getCompanyIdFilter = () => {
    return searchParams.get("company-id");
  };

  const isFirstRender = useFirstRender();

  const isLayoutEmbedded = () => {
    return searchParams.get("layout") === "embedded";
  };

  const isCockpitMode = () => {
    return mode === "cockpit";
  };

  const isDefaultMode = () => {
    return mode === "default";
  };

  const selectRow = (params) => {
    setSelectionModel([params.id]);
    setSelectedRow(params.row);
    setActiveSubscriptionPlan(params.row);

    if (params.row.status === "Draft") {
      setDisplay({
        edit: true,
        cancel: true,
        publish: true,
        unpublish: false,
        masterProcess: false,
        contractDefinition: true,
      });
    }
    if (params.row.status === "Published") {
      setDisplay({
        edit: false,
        cancel: false,
        publish: false,
        unpublish: true,
        masterProcess: true,
        contractDefinition: true,
      });
    }
    if (params.row.status === "Cancelled") {
      setDisplay({
        ...display,
        edit: false,
        cancel: false,
        publish: false,
        masterProcess: false,
      });
    }
  };

  const onClickRow = (params, e) => {
    if (selectedRow !== params.row) {
      selectRow(params);
    } else {
      setSelectionModel([]);
      setDisplay({
        edit: false,
        cancel: false,
        publish: false,
        masterProcess: false,
        contractDefinition: false,
      });
    }
  };

  const onDoubleClickRow = (params) => {
    selectRow(params);
    editOrViewRecord();
  };

  const contractDefinition = () => {
    navigate(
      (isCockpitMode() ? "plans/" : "") +
        `${selectedRow.id}/contract-definition`
    );
  };

  const cancelRecord = () => {
    setDisplayTestDate(false);
    setConfirmDialog({
      title: "Cancel Subscription Plan ?",
      description: `Are you sure you want to cancel ?`,
      actionConfirm: () => {
        setConfirmDialog({});
        navigate(
          (isCockpitMode() ? "plans/" : "") + `${selectedRow.id}/cancel`
        );
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const createRecord = () => {
    navigate((isCockpitMode() ? "plans/" : "") + "new");
  };

  const publishRecord = () => {
    setDisplayTestDate(false);
    setConfirmDialog({
      title: "Publish Subscription Plan ?",
      description: `Are you sure you want to publish ?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handlePublish(selectedRow.id, activeCustomer)
          .then(() => {
            refreshGrid(null, activeCustomer);
            setDisplayNotification({
              message: "Subscription Plan Published!",
              type: "success",
            });
          })
          .catch((e) => {
            if (e.response.data.Message)
              setDisplayNotification({
                message: e.response.data.Message,
                type: "error",
              });
            else {
              setDisplayNotification({
                message: "Unknown Error",
                type: "error",
              });
            }
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const unpublishRecord = () => {
    setDisplayTestDate(false);
    setConfirmDialog({
      title: "Unpublish Subscription Plan ?",
      description: `Are you sure you want to unpublish ?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleUnpublish(selectedRow.id, activeCustomer)
          .then(() => {
            refreshGrid(null, activeCustomer);
            setDisplayNotification({
              message: "Subscription Plan Unpublished!",
              type: "success",
            });
          })
          .catch((e) => {
            if (e.response.data.Message)
              setDisplayNotification({
                message: e.response.data.Message,
                type: "error",
              });
            else {
              setDisplayNotification({
                message: "Unknown Error",
                type: "error",
              });
            }
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const runMasterProcess = () => {
    setDisplayTestDate(true);
    setConfirmDialog({
      title: "Run HARP Connect?",
      description: `Run HARP Connect for this Subscription Plan ?`,
      actionConfirm: (date) => {
        const body = {
          SubscriptionPlanId: selectedRow.id,
        };

        if (date) {
          body.TestDateTime = new Date(date).toISOString();
        }
        setConfirmDialog({});

        handleMasterProcess(selectedRow.id, body)
          .then(() => {
            setTestDate("");
            setAllowTestDate(false);

            setDisplayNotification({
              message: "HARP Connect ran sucessfully!",
              type: "success",
            });
          })
          .catch((e) => {
            if (e.response.data.Message)
              setDisplayNotification({
                message: e.response.data.Message,
                type: "error",
              });
            else {
              setDisplayNotification({
                message: "Unknown Error",
                type: "error",
              });
            }
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const editOrViewRecord = () => {
    if (selectedRow) {
      if (selectedRow.status === "Draft")
        navigate(`${isCockpitMode() ? "plans/" : ""}${selectedRow.id}/edit`);
      else navigate(`${isCockpitMode() ? "plans/" : ""}${selectedRow.id}/view`);
    }
  };

  const refreshGrid = (event, activeCustomer) => {
    setSelectedRow(null);
    setActiveSubscriptionPlan(null);
    setSelectionModel([]);

    setDisplay({
      edit: false,
      cancel: false,
      publish: false,
      unpublish: false,
      masterProcess: false,
      contractDefinition: false,
    });

    // setDisplayEdit(false);
    // setDisplayCancel(false);
    // setDisplayPublish(false);
    // setDisplayUnpublish(false);
    // setDisplayMasterProcess(false);
    // setDisplayContractDefinition(false);
    onRefresh(activeCustomer);
  };

  const displaySubscriptionPlanRibbons = () => {
    return (
      <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
        <NewRibbonButton
          onClick={createRecord}
          display={true}
          title="New Subscription Plan"
        />
        <EditRibbonButton
          onClick={editOrViewRecord}
          display={display.edit}
          title="Edit"
        />
        <RefreshRibbonButton
          onClick={(evt) => {
            refreshGrid(evt, activeCustomer);
          }}
          display={true}
          title="Refresh"
        />
        <DeleteRibbonButton
          onClick={cancelRecord}
          display={display.cancel}
          title="Cancel"
        />
        <PublishRibbonButton
          onClick={publishRecord}
          display={display.publish}
          title="Publish"
        />
        <UnpublishRibbonButton
          onClick={unpublishRecord}
          display={display.unpublish}
          title="Unpublish"
        />
        <MasterProcessRibbonButton
          onClick={runMasterProcess}
          display={display.masterProcess}
          title="HARP Connect"
        />
        <ContractDefinitionRibbonButton
          onClick={contractDefinition}
          display={display.contractDefinition}
          title="Contract Definition"
        />

        {isLayoutEmbedded() && isDefaultMode() && (
          <ProfileCard embeddedMode={true} />
        )}
      </RibbonGrid>
    );
  };

  React.useEffect(() => {
    if (!isFirstRender) {
      refreshGrid(null, activeCustomer);
    }
  }, [activeCustomer]);

  return (
    <React.Fragment>
      <GridParent
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <ConfirmDialog
          confirmDialogInfo={confirmDialog}
          onConfirm={confirmDialog.actionConfirm}
          onCancel={confirmDialog.actionCancel}
          testDate={testDate}
        >
          {displayTestDate && (
            <Stack direction="column">
              <FormControlLabel
                sx={{ mt: 1 }}
                control={
                  <Switch
                    checked={allowTestDate}
                    onChange={(e) => {
                      const value = e.target.checked;
                      if (!value) setTestDate("");

                      setAllowTestDate(e.target.checked);
                    }}
                  />
                }
                label="Enable Test Mode"
              />
              {allowTestDate && (
                <TextField
                  id="TestDate-input"
                  name="TestDate"
                  label="Test Date"
                  type="date"
                  size="small"
                  sx={{ width: 200, marginTop: "10px" }}
                  required
                  onChange={(e) => {
                    setTestDate(e.target.value);
                  }}
                  value={testDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            </Stack>
          )}
        </ConfirmDialog>
        {isDefaultMode() && displaySubscriptionPlanRibbons()}
        <GridItem item xs={12} md={12} lg={12}>
          <Subtitle title="Subscription Plans" style={{ textAlign: "left" }} />
        </GridItem>
        {isCockpitMode() && displaySubscriptionPlanRibbons()}
        <GridItem item xs={12} md={12} lg={12}>
          <DataGrid
            customSize={isCockpitMode() ? "small" : "normal"}
            loading={isLoading}
            onRowClick={onClickRow}
            onRowDoubleClick={onDoubleClickRow}
            selectionModel={selectionModel}
            columns={columns}
            rows={data}
            disableMultipleSelection={true}
            // Apply filter for company id
            initialState={{
              filter: {
                filterModel: {
                  items:
                    getCompanyIdFilter() != null
                      ? [
                          {
                            id: 1,
                            columnField: "connector",
                            value: getCompanyIdFilter(),
                            operatorValue: "by-id",
                          },
                        ]
                      : [],
                },
              },
            }}
          />
        </GridItem>
      </GridParent>
      {displayNotification !== null && (
        <NotificationSnackBar
          displayNotification={displayNotification}
          onClose={() => {
            setDisplayNotification(null);
          }}
        />
      )}
      <Outlet context={{ refreshGrid }} />
    </React.Fragment>
  );
}

function SubscriptionPlanNew({
  activeCustomer,
  sidePanelOpen,
  isLoadingSidePanel,
  handleCreateRecord,
  setDisplayNotification,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  handleListConnectors,
}) {
  let navigate = useNavigateWithParams();

  return (
    <SidePanel
      open={sidePanelOpen}
      handleClose={() => navigate("../")}
      isLoading={isLoadingSidePanel}
      title="New Subscription Plan"
    >
      <NewSubscriptionPlanForm
        isLoading={isLoadingSidePanel}
        activeCustomer={activeCustomer}
        handleClose={() => navigate("../")}
        handleCreateRecord={(record) => {
          return handleCreateRecord(record)
            .then(() => {
              setDisplayNotification({
                message: "Subscription Plan created successfully",
                type: "success",
              });
              return navigate("../");
            })
            .catch((e) => {
              //TODO error
              setDisplayNotification({
                message: "Error occurred! Please check that the information is valid and not empty",
                type: "error",
              });
            });
        }}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        handleListRecords={handleListRecords}
        searchSidePanelData={searchSidePanelData}
        handleListConnectors={handleListConnectors}
      />
    </SidePanel>
  );
}

function SubscriptionPlanEdit({
  isLoadingSidePanel,
  sidePanelOpen,
  handleEditRecord,
  handleFetchRecord,
  recordData,
  handleListConnectors,
  readyOnlyForm,
  handleListRecords,
  searchSidePanelData,
  isLoadingSearchSidePanel,
}) {
  let navigate = useNavigateWithParams();
  const { refreshGrid } = useOutletContext();

  let { subscriptionPlanId } = useParams();

  React.useEffect(() => {
    handleFetchRecord(subscriptionPlanId);
  }, []);

  return (
    <React.Fragment>
      <SidePanel
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../")}
        //title={!isLoadingSidePanel && recordData && `${recordData.name}`}
        open={sidePanelOpen}
      >
        {!isLoadingSidePanel && (
          <EditSubscriptionPlanForm
            isLoading={isLoadingSidePanel}
            handleClose={() => navigate("../")}
            recordData={recordData}
            handleEditRecord={(subscriptionPlanId, data) => {
              return handleEditRecord(subscriptionPlanId, data).then(() => {
                navigate("../");
                return refreshGrid();
              });
            }}
            handleListConnectors={handleListConnectors}
            readyOnlyForm={readyOnlyForm}
            handleListRecords={handleListRecords}
            searchSidePanelData={searchSidePanelData}
            isLoadingSearchSidePanel={isLoadingSearchSidePanel}
          />
        )}
      </SidePanel>
    </React.Fragment>
  );
}

function SubscriptionPlanCancel({
  isLoadingSidePanel,
  sidePanelOpen,
  handleCancel,
  handleListRecords,
  isLoadingSearchSidePanel,
  searchSidePanelData,
}) {
  let navigate = useNavigateWithParams();

  const { refreshGrid } = useOutletContext();
  let { subscriptionPlanId } = useParams();

  return (
    <React.Fragment>
      <SidePanel
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../")}
        title={!isLoadingSidePanel && `Cancel Subscription Plan`}
        open={sidePanelOpen}
      >
        {!isLoadingSidePanel && (
          <CancelSubscriptionPlanForm
            isLoading={isLoadingSidePanel}
            handleClose={() => {
              navigate("../");
            }}
            handleCancel={(reasonCode) => {
              return handleCancel(subscriptionPlanId, reasonCode).then(() => {
                navigate("../");
                return refreshGrid();
              });
            }}
            isLoadingSearchSidePanel={isLoadingSearchSidePanel}
            handleListRecords={handleListRecords}
            searchSidePanelData={searchSidePanelData}
          />
        )}
      </SidePanel>
    </React.Fragment>
  );
}

function SubscriptionPlanContractDefinition({
  isLoadingSidePanel,
  sidePanelOpen,
  handleContractDefinition,
  recordData,
  handleFetchRecord,
}) {
  let navigate = useNavigateWithParams();

  const { refreshGrid } = useOutletContext();
  let { subscriptionPlanId } = useParams();

  React.useEffect(() => {
    handleFetchRecord(subscriptionPlanId);
  }, []);

  return (
    <React.Fragment>
      <SidePanel
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../../")}
        title={!isLoadingSidePanel && `Contract Definition`}
        open={sidePanelOpen}
      >
        {
          <ContractDefinitionForm
            isLoading={isLoadingSidePanel}
            recordData={recordData}
            handleClose={() => {
              navigate("../");
            }}
            handleContractDefinition={(data) => {
              return handleContractDefinition(subscriptionPlanId, data).then(
                () => {
                  navigate("../");
                  return refreshGrid();
                }
              );
            }}
          />
        }
      </SidePanel>
    </React.Fragment>
  );
}

function SubscriptionPlanTemplate(props) {
  const {
    mode,
    data,
    isLoading,
    isLoadingSidePanel,
    isLoadingSearchSidePanel,
    onRefresh,
    handleFetchRecord,
    handleListRecords,
    handleEditRecord,
    handleCreateRecord,
    recordData,
    searchSidePanelData,
    handlePublish,
    handleCancel,
    handleUnpublish,
    handleMasterProcess,
    activeCustomer,
    activeConnnector,
    setActiveSubscriptionPlan,
    handleListConnectors,
    handleContractDefinition,
    readyOnlyForm,
  } = props;

  const [displayNotification, setDisplayNotification] = React.useState(null);

  let navigate = useNavigateWithParams();

  const isCockpitMode = () => {
    return mode === "cockpit";
  };

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SubscriptionPlanIndex
            mode={mode}
            isLoading={isLoading}
            displayNotification={displayNotification}
            onRefresh={onRefresh}
            handlePublish={handlePublish}
            handleCancel={handleCancel}
            handleUnpublish={handleUnpublish}
            setDisplayNotification={setDisplayNotification}
            handleMasterProcess={handleMasterProcess}
            activeCustomer={activeCustomer}
            setActiveSubscriptionPlan={setActiveSubscriptionPlan}
            data={data}
          />
        }
      >
        <Route
          path={(isCockpitMode() ? "plans/" : "") + ":subscriptionPlanId/edit"}
          element={
            <SubscriptionPlanEdit
              isLoadingSidePanel={isLoadingSidePanel}
              recordData={recordData}
              handleEditRecord={(subscriptionPlanId, updateRecord) => {
                return handleEditRecord(subscriptionPlanId, updateRecord)
                  .then(() => {
                    setDisplayNotification({
                      message: "Subscription Plan updated successfully",
                      type: "success",
                    });
                  })
                  .catch((e) => {
                    if (
                      e &&
                      e.response &&
                      e.response.data &&
                      e.response.data.Message
                    )
                      setDisplayNotification({
                        message: e.response.data.Message,
                        type: "error",
                      });
                    else
                      setDisplayNotification({
                        message: "Error updating Subscription Plan",
                        type: "error",
                      });
                  });
              }}
              handleFetchRecord={handleFetchRecord}
              sidePanelOpen={true}
              readyOnlyForm={false}
              handleListConnectors={handleListConnectors}
              handleListRecords={handleListRecords}
              searchSidePanelData={searchSidePanelData}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
            />
          }
        />
        <Route
          path={(isCockpitMode() ? "plans/" : "") + ":subscriptionPlanId/view"}
          element={
            <SubscriptionPlanEdit
              isLoadingSidePanel={isLoadingSidePanel}
              recordData={recordData}
              handleEditRecord={(subscriptionPlanId, updateRecord) => {
                return handleEditRecord(subscriptionPlanId, updateRecord)
                  .then(() => {
                    setDisplayNotification({
                      message: "Subscription Plan updated successfully",
                      type: "success",
                    });
                  })
                  .catch((e) => {
                    if (
                      e &&
                      e.response &&
                      e.response.data &&
                      e.response.data.Message
                    )
                      setDisplayNotification({
                        message: e.response.data.Message,
                        type: "error",
                      });
                    else
                      setDisplayNotification({
                        message: "Error updating Subscription Plan",
                        type: "error",
                      });
                  });
              }}
              handleFetchRecord={handleFetchRecord}
              sidePanelOpen={true}
              readyOnlyForm={true}
              handleListConnectors={handleListConnectors}
              handleListRecords={handleListRecords}
              searchSidePanelData={searchSidePanelData}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
            />
          }
        />
        <Route
          path={(isCockpitMode() ? "plans/" : "") + "new"}
          element={
            <SubscriptionPlanNew
              activeCustomer={activeCustomer}
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              handleListRecords={handleListRecords}
              handleCreateRecord={handleCreateRecord}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              displayNotification={displayNotification}
              handleListConnectors={handleListConnectors}
              sidePanelOpen={true}
            />
          }
        />

        <Route
          path={
            (isCockpitMode() ? "plans/" : "") + ":subscriptionPlanId/cancel"
          }
          element={
            <SubscriptionPlanCancel
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              handleListRecords={handleListRecords}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              displayNotification={displayNotification}
              handleListConnectors={handleListConnectors}
              sidePanelOpen={true}
              handleCancel={(id, reasonCode) => {
                return handleCancel(id, reasonCode)
                  .then(() => {
                    setDisplayNotification({
                      message: "Subscription Plan Cancelled",
                      type: "success",
                    });
                  })
                  .catch((e) => {
                    if (e.response.data.Message)
                      setDisplayNotification({
                        message: e.response.data.Message,
                        type: "error",
                      });
                    else {
                      setDisplayNotification({
                        message: "Unknown Error",
                        type: "error",
                      });
                    }
                  });
              }}
            />
          }
        />

        <Route
          path={
            (isCockpitMode() ? "plans/" : "") +
            ":subscriptionPlanId/contract-definition"
          }
          element={
            <SubscriptionPlanContractDefinition
              isLoadingSidePanel={isLoadingSidePanel}
              sidePanelOpen={true}
              recordData={recordData}
              handleFetchRecord={handleFetchRecord}
              handleContractDefinition={(id, data) => {
                return handleContractDefinition(id, data)
                  .then(() => {
                    setDisplayNotification({
                      message: "Contract definition updated!",
                      type: "success",
                    });
                  })
                  .catch((e) => {
                    if (e.response.data.Message)
                      setDisplayNotification({
                        message: e.response.data.Message,
                        type: "error",
                      });
                    else {
                      setDisplayNotification({
                        message: "Unknown Error",
                        type: "error",
                      });
                    }
                    throw e;
                  });
              }}
            />
          }
        />
      </Route>
    </Routes>
  );
}

SubscriptionPlanTemplate.propTypes = {
  mode: PropTypes.oneOf(["default", "cockpit"]),
};
SubscriptionPlanTemplate.defaultProps = {
  mode: "default",
  setActiveSubscriptionPlan: () => {},
};

export default SubscriptionPlanTemplate;
