import ReasonCodeTemplate from '../ui/templates/ReasonCodeTemplate';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { useNavigateWithParams } from '../hooks/useNavigateWithParams'
import { v4 as uuidv4 } from 'uuid';
import { fakeData } from './fakeData/reasonCodeFakeData'
import { getReasonCodes, createReasonCode, updateReasonCode, getReasonCode, deleteReasonCode } from '../api/reasonCodesService';
import UserContext from "../UserContext";


function ReasonCodesPage() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "Reason Code Page" });

    const [datagridData, setDatagridData] = React.useState([]);
    const datagridRef = React.useRef(fakeData);

    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);

    const timerRef = React.useRef(null);
    const userContext = React.useContext(UserContext);

    const onRefresh = () => {
        setDatagridData([]);
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData(fakeData);
                    datagridRef.current = fakeData;
                    setIsLoading(false);
                    resolve();
                }, 1500);
            });
        }
        else {
            return getReasonCodes({} , userContext.activeEnvironment, userContext.tenant).then((data) => {
                setDatagridData(data);
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleCreateRecord = (record) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData([{ ...record, id: uuidv4() }, ...datagridData]);
                    datagridRef.current = [{ ...record, id: uuidv4() }, ...datagridData];
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    resolve();
                }, 1550);
            });           
        }
        else {
            return createReasonCode(record, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return onRefresh();
            })
            .catch((e) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                throw e;
            })
        }  
    }

    const handleFetchRecord = (id) => {
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    var recordIndex = datagridRef.current.findIndex((x => x.id === id));
                    if (recordIndex > -1) {
                        setIsLoadingSidePanel(false);
                        return resolve(datagridRef.current[recordIndex]);
                    }

                    return resolve(null);
                }, 1550);
            })
        }
        else {
            return getReasonCode(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoadingSidePanel(false);
                return data;
            })
                .catch((e) => {
                    setIsLoadingSidePanel(false);
                    throw e;
                });
        }
            
    }

    const handleEditRecord = (id, record) => {  
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    var recordIndex = datagridRef.current.findIndex((x => x.id === id));

                    const newDataGridData = datagridRef.current.map(element => {
                        return element.id === id ? { ...element, ...record } : element
                    });

                    setDatagridData(newDataGridData);
                    datagridRef.current = newDataGridData;
                    setIsLoadingSidePanel(false);
                    resolve(newDataGridData[recordIndex]);
                }, 1550);
            });
        }
        else {
            return updateReasonCode(id, record, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoadingSidePanel(false);
                return onRefresh();                
            })
                .catch((e) => {
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }


    const handleDeleteRecord = (record) => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                setDatagridData(datagridData.filter((x) => x.id !== record.id));
                datagridRef.current = datagridData.filter((x) => x.id !== record.id);
                setIsLoading(false);
            }, 1550);
        }
        else {
            return deleteReasonCode(record.id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
      
    }

    React.useEffect(() => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            const timer = setTimeout(() => {
                setDatagridData(fakeData);
                datagridRef.current = fakeData;
                setIsLoading(false);
            }, 1500);
            return () => clearTimeout(timer);
        }
        else {
            return getReasonCodes({}, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setDatagridData(data);
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
       
    }, [userContext.activeEnvironment]);

    React.useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);


    return (<ReasonCodeTemplate data={datagridData}
        isLoading={isLoading}
        isLoadingSidePanel={isLoadingSidePanel}
        handleCreateRecord={handleCreateRecord}
        handleFetchRecord={handleFetchRecord}
        handleDeleteRecord={handleDeleteRecord}
        handleEditRecord={handleEditRecord}
        onRefresh={onRefresh} />);
}

export default ReasonCodesPage;