import * as React from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import RibbonLoadingButton from './RibbonLoadingButton';
import { Typography } from '@mui/material/';


export default function RefreshRibbonButton(props) {
    return (
        <RibbonLoadingButton {...props} startIcon={<RefreshIcon sx={{ color: "black" }} />}>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonLoadingButton>
    );
}