import * as React from 'react';
import { Grid, Typography } from '@mui/material/';
import DataGrid from '../organisms/DataGrid';
import { styled } from '@mui/material/styles';
import RefreshRibbonButton from '../molecules/RefreshRibbonButton';
import ConfirmDialog from '../molecules/ConfirmDialog';
import SidePanel from '../organisms/SidePanel';
import { Route, Routes, Outlet, useParams, useSearchParams, useOutletContext } from 'react-router-dom';
import { format } from 'date-fns'
import ProfileCard from '../organisms/ProfileCard'
import NotificationSnackBar from '../organisms/NotificationSnackBar';
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams';
import PostRibbonButton from "../molecules/PostRibbonButton";
import CancelRibbonButton from '../molecules/CancelRibbonButton';
import RefundRibbonButton from '../molecules/RefundRibbonButton';
import NotificationRibbonButton from '../molecules/NotificationRibbonButton';
import RetryRibbonButton from '../molecules/RetryRibbonButton';
import StatusChip from "../atoms/StatusChip";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ViewPaymentForm from "../../forms/payment/ViewPaymentForm";
import CancelPaymentForm from '../../forms/payment/CancelPaymentForm';
import TooltipStatusChip from "../molecules/TooltipStatusChip";

const GridParent = styled(Grid)(({ theme }) => ({

}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
    padding: 3,
    paddingLeft: 30,
    display: 'inline-flex'
}));

const GridItem = styled(Grid)(({ theme }) => ({
    padding: 16,
    paddingLeft: 30
}));

const SetChipExternalStatusColor = (value) => {
    switch (value) {
        case "PendingCustomerApproval":
        case "PendingSubmission":
            return "default";
        case "Submitted":
        case "Confirmed":
        case "PaidOut":
            return "success";
        default:
            return "error";
    }
};

const SetChipStatusColor = (value) => {
    switch (value) {
        case "New":
        case "Active":
            return "default";
        case "Completed":       
            return "success";
        default:
            return "error";
    }
};
const SetChipExternalStatusIcon = (value) => {
    switch (value) {
        case "PendingCustomerApproval":
        case "PendingSubmission":
            return <DriveFileRenameOutlineIcon />;
        case "Submitted":
        case "Confirmed":
        case "PaidOut":
            return <FileUploadIcon />;
        default:
            return <RemoveCircleOutlineIcon />;
    }
};

const SetChipStatusIcon = (value) => {
    switch (value) {
        case "New":
        case "Active":
            return <CreditCardOutlinedIcon />;
        case "Completed":
            return <CreditScoreOutlinedIcon />;            
        default:
            return <RemoveCircleOutlineIcon />;
    }
};

const ExternalStatusValueFormatter = (value) => {
    if (value === "PendingCustomerApproval") return "Pending Customer Approval";
    if (value === "PendingSubmission") return "Pending Submission";
    if (value === "Submitted") return "Submitted";
    if (value === "Confirmed") return "Confirmed";
    if (value === "PaidOut") return "Paid Out";
    if (value === "Cancelled") return "Cancelled";
    if (value === "CustomerApprovalDenied") return "Customer Approval Denied";
    if (value === "Failed") return "Failed";
    if (value === "ChargedBack") return "Charged Back";

    return value;
};

const RenderExternalStatus = ({ value }) => {
    return (
        <StatusChip
            sx={{ width: "100%" }}
            variant="outlined"
            label={ExternalStatusValueFormatter(value)}
            color={SetChipExternalStatusColor(value)}
            icon={SetChipExternalStatusIcon(value)}
        />
    );
};

const columns = [
   {
       field: 'status', headerName: 'Status', flex: 1, minWidth: 50,maxWidth:200, type: 'singleSelect',
       valueOptions: [
           { label: "New", value: "New" },
           { label: "Active", value: "Active" },
           { label: "Completed", value: "Completed" },           
        ],
        renderCell: (value) => {
        
            if (value && value.row && value.row.errorMessage != null) {
                return (
                    <TooltipStatusChip
                        title={value.row.errorMessage} //Pop Up Message
                        label={value.formattedValue} //Field value
                        variant="outlined"
                        sx={{ width: "100%" }}
                        color={SetChipStatusColor("error")}
                        icon={SetChipStatusIcon(RemoveCircleOutlineIcon)}               
                    />
                );
            } else {
                return (       
                    <TooltipStatusChip
                        title={value.row.status} //Pop Up Message
                        label={value.formattedValue} //Field value
                        variant="outlined"
                        color={SetChipStatusColor(value.formattedValue)}
                        icon={SetChipStatusIcon(value.formattedValue)}
                        sx={{ width: "100%" }}
                    />
                );
            }
        },    
   },
    {
        field: 'externalPaymentStatus', headerName: 'External Payment Status', flex: 1, minWidth: 50, type: 'singleSelect',
        valueOptions: [
            { label: "Pending Customer Approval", value: "PendingCustomerApproval" },
            { label: "Pending Submission", value: "PendingSubmission" },
            { label: "Submitted", value: "Submitted" },
            { label: "Confirmed", value: "Confirmed" },
            { label: "Paid Out", value: "PaidOut" },
            { label: "Cancelled", value: "Cancelled" },
            { label: "Customer Approval Denied", value: "CustomerApprovalDenied" },
            { label: "Failed", value: "Failed" },
            { label: "Charged Back", value: "ChargedBack" },
        ],
        renderCell: RenderExternalStatus        
        
    },
    {
        field: "merchant",
        headerName: "Merchant",
        flex: 1,
        minWidth: 100,
        maxWidth: 200,
        valueGetter: (params) => {
            if (!params.value || params.value.organisationId === undefined) return "";

            return params.value.organisationId;
        },
    },
    {
        field: "customer",
        headerName: "Customer",
        flex: 1,
        minWidth: 100,
        valueGetter: (params) => {
            if (!params.value || params.value.name === undefined) return "";

            return params.value.name;
        },            
    },
    {
        field: "invoice",
        headerName: "Invoice",
        flex: 1,
        minWidth: 100,
        maxWidth: 200,
        valueGetter: (params) => {
            if (!params.value || params.value.name === undefined) return "";

            return params.value.name;
        },
    },
    {
        field: "currency",
        headerName: "Currency",
        flex: 1,
        minWidth: 100,
        maxWidth: 200,
        valueGetter: (params) => {
            if (!params.value || params.value.name === undefined) return "";

            return params.value.name;
        },
    },
    {
        field: 'grossAmount', headerName: 'Total Amount', flex: 1, minWidth: 50,
        valueGetter: (params) => {
            const options = params.row && params.row.currency && params.row.currency.extensionData && params.row.currency.extensionData.Code ? {
                style: 'currency',
                currency: params.row.currency.extensionData.Code,
            } : {};

            const formatter = new Intl.NumberFormat(undefined, options);

            return formatter.format(params.value);
        },
    },
    {
        field: 'netAmount', headerName: 'Net Amount', flex: 1, minWidth: 50,
        valueGetter: (params) => {
            const options = params.row && params.row.currency && params.row.currency.extensionData && params.row.currency.extensionData.Code ? {
                style: 'currency',
                currency: params.row.currency.extensionData.Code,
            } : {};

            const formatter = new Intl.NumberFormat(undefined, options);

            return formatter.format(params.value);
        }
    },
    {
        field: 'taxAmount', headerName: 'Taxes Amount', flex: 1, minWidth: 50,
        valueGetter: (params) => {
            const options = params.row && params.row.currency && params.row.currency.extensionData && params.row.currency.extensionData.Code ? {
                style: 'currency',
                currency: params.row.currency.extensionData.Code,
            } : {};

            const formatter = new Intl.NumberFormat(undefined, options);

            return formatter.format(params.value);
        },
    }, 
    {
        field: 'billingRequestUrl', headerName: 'Billing Request Url', flex: 1, minWidth: 50,
        renderCell: ({ value }) => {
            return (
                <StatusChip
                    sx={{ width: "100%" }}
                    variant="outlined"
                    label="Copy to clipboard"
                    color="default"
                    icon={<ContentCopyOutlinedIcon />}
                />
            );

        }
    }, 

   {
       field: 'chargedOn', headerName: 'Charge On', flex: 1, minWidth: 100, type: "dateTime",
       valueFormatter: (params) => { return params.value ? format(new Date(params.value), "dd/MM/yyyy") : params.value; }
   },

];

function PaymentsIndex({ mode, isLoading, data, onRefresh, displayNotification, setDisplayNotification, handleFetchRecord, handlePost, handleCancel, setDisplayTestDate }) {

    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState({});
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [checkboxSelection, setCheckboxSelection] = React.useState([]);

    const [displayPost, setDisplayPost] = React.useState(false);
    const [displayRetry, setDisplayRetry] = React.useState(false);
    const [displayRefund, setDisplayRefund] = React.useState(false);
    const [displayCancel, setDisplayCancel] = React.useState(false);
    const [displayMandate, setDisplayMandate] = React.useState(false);
    const [displaySendNotification, setDisplaySendNotification] = React.useState(false);

    let navigate = useNavigateWithParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const isLayoutEmbedded = () => {
       return searchParams.get("layout") === "embedded";
    };

    const isCockpitMode = () => {
        return mode === "cockpit";
    };

    const handleOnCellClick = (param) => {
        if (param.field === "billingRequestUrl") {
            navigator.clipboard.writeText(param.value);
            setDisplayNotification({
                message: `Billing request URL copied to clipboard !`,
                type: "success",
            })
        }

    }

    const cancelRecord = () => {
        setConfirmDialog({
            title: "Cancel Payment ?",
            description: `Are you sure you want to cancel ?`,
            actionConfirm: () => {
                setConfirmDialog({});
                navigate(
                    (isCockpitMode() ? "payments/" : "") + `${selectedRow.id}/cancel`
                );
            },
            actionCancel: () => {
                setConfirmDialog({});
            },
        });
    };

    const onClickRow = (params) => {
        setSelectedRow(params.row);

        ////Refund Only
        //if (params.row.externalPaymentStatus === "Confirmed" || params.row.externalPaymentStatus === "PaidOut") {
        //    setDisplayRetry(false);
        //    setDisplayRefund(true);
        //    setDisplayCancel(false);
        //}
        ////Cancel Only
        //if (params.row.externalPaymentStatus === "PendingSubmission") {
        //    setDisplayRetry(false);
        //    setDisplayRefund(false);
        //    setDisplayCancel(true);

        //}
        ////Notification Only
        //if (params.row.externalPaymentStatus === "PendingCustomerApproval") {
        //    setDisplayRetry(false);
        //    setDisplayRefund(false);
        //    setDisplayCancel(false);
        //}
        ////Retry Only
        //if (params.row.externalPaymentStatus === "Failed") {
        //    setDisplayRetry(true);
        //    setDisplayRefund(false);
        //    setDisplayCancel(false);
        //}

        //if (params.row.mandateId === null) {
        //    setDisplayMandate(false);
        //} else {
        //    setDisplayMandate(true);
        //}


        //if (params.row.status === "New") {
        //    setDisplayPost(true)
        //}
        //else {
        //    setDisplayPost(false)
        //}

    };

    const onDoubleClickRow = (params) => {

        openRecord();

    };

    const openRecord = () => {
        navigate(`${selectedRow.id}`);
    };

/*    const refreshGrid = () => {
        setSelectedRow(null);
        setDisplayEdit(false);
        setDisplayRetry(false);
        setDisplayRefund(false);
        setDisplayCancel(false);
        onRefresh();
    }*/

    const refreshGrid = (event, activeCustomer) => {
        setSelectedRow(null);
        setDisplayCancel(false);
        onRefresh(activeCustomer);
    }

    const refundPayment = () => {
        setSelectedRow(null);
        setDisplayEdit(false);
        onRefresh();
    }

    const postRecord = () => {
        let title = "Post Payment Action ?";
        let params = [];

        if (selectedRows.length > 0) {
            params = selectedRows.filter((row) => row && row.status === "New" && row.subscriptionActions !== null && row.subscriptionActions.length > 0);
            title = `Post ${params.length} Payment Actions ?`;
        }

        setConfirmDialog({
            title: title,
            description: `Are you sure you want to post the payment ?`,
            actionConfirm: async () => {
                setConfirmDialog({});
                try {
                    await handlePost(params.map(p => p.subscriptionActions.filter(f => f.actionStatus === "Firmed" && f.actionType === "DirectDebitPayment").map((s) => s.id)))
                    refreshGrid();
                    setDisplayNotification({
                        message: "Posting Payment Action.",
                        type: "success",
                    });
                } catch (e) {
                    if (e.response && e.response.data.Message) {
                        setDisplayNotification({
                            message: e.response.data.Message,
                            type: "error",
                        });
                    } else {
                        setDisplayNotification({
                            message: "Unknown Error",
                            type: "error",
                        });
                    }
                }
            },
            actionCancel: () => {
                setConfirmDialog({});
            },
        });
    };

    const ProcessSumOfRibbons = (rows) => {

        let displayPost = false;
        let displayRetry = false;
        let displayRefund = false;
        let displayCancel = false;
        let displayNotification = false;
        let displayMandate = false;

        for (var i = 0; i < rows.length; i++) {
            const row = rows[i];

            if (!row || !row.status) continue;

            if (row.externalPaymentStatus === "Confirmed" || row.externalPaymentStatus === "PaidOut") {
                displayRefund = true;
            }

            if (row.externalPaymentStatus === "PendingSubmission") {
                displayCancel = true;
            }

            if (row.externalPaymentStatus === "PendingCustomerApproval") {
                displayNotification = true;
            }

            if (row.status === "New" && !displayPost) {
                displayPost = true;
            }

            if (row.externalPaymentStatus === "Failed") {
                displayRetry = true;
            }      


            if (displayPost && displayRefund && displayCancel && displayNotification && displayRetry) break;
        }
        //Do not display ribbon if over 2 rows are selected
        if (rows.length > 1) {
            displayMandate = false;
        }

        setDisplayRefund(displayRefund);
        setDisplayCancel(displayCancel);
        setDisplayRetry(displayRetry);
        setDisplayPost(displayPost);
        
    };

   
    return (<React.Fragment>
        <GridParent
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch">
            {confirmDialog && confirmDialog.title && <ConfirmDialog confirmDialogInfo={confirmDialog} onConfirm={confirmDialog.actionConfirm} onCancel={confirmDialog.actionCancel} />}
            <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>

                <RefundRibbonButton
                    onClick={refreshGrid}
                    display={displayRefund}
                    title="Refund Payment"
                />

                <CancelRibbonButton
                    onClick={cancelRecord}
                    display={displayCancel}
                    title="Cancel payment"
                /> 

                <NotificationRibbonButton
                    onClick={refreshGrid}
                    display={displaySendNotification}
                    title="Send Notification"
                />

                <RetryRibbonButton
                    onClick={refreshGrid}
                    display={displayRetry}
                    title="Retry Payment"
                />

                <RefreshRibbonButton
                    onClick={refreshGrid}
                    display={true}
                    title="Refresh"
                />

                <PostRibbonButton
                    onClick={postRecord}
                    display={displayPost}
                    title="Post Payment Action"
                />

                {isLayoutEmbedded() && (<ProfileCard embeddedMode={true} />)}
            </RibbonGrid>
            <GridItem item xs={12} md={12} lg={12}>
                <Typography>Payments</Typography>
            </GridItem>
            <GridItem item xs={12} md={12} lg={12}>
                <DataGrid loading={isLoading} onRowClick={onClickRow} onRowDoubleClick={onDoubleClickRow}
                    onCellClick={handleOnCellClick} columns={columns} rows={data} checkboxSelection

                    onSelectionModelChange={(Selection) => {
                        if (Selection && Selection.length === 0) {
                            //setDisplayCheckBoxSelection(false);
                            setDisplayRetry(false);
                            setDisplayRefund(false);
                            setDisplayCancel(false);
                            setDisplaySendNotification(false);
                            setDisplayPost(false);
                            setDisplayMandate(false);
                            setSelectedRows([]);                          
                        }

                        if (Selection && Selection.length > 0) {
                            const selectedIDs = new Set(Selection);

                            const selectedRowData = data.filter((row) =>
                                selectedIDs.has(row.id));

                            
                            setSelectedRows(selectedRowData);
                            ProcessSumOfRibbons(selectedRowData);
                        }
                
                    }}/>
            </GridItem>

        </GridParent>
        {displayNotification !== null && (<NotificationSnackBar
            displayNotification={displayNotification}
            onClose={() => setDisplayNotification(null)}
        />)}
        <Outlet context={{ refreshGrid }} />
    </React.Fragment>);
}


function PaymentsView({ isLoadingSidePanel, sidePanelOpen, handleFetchRecord, setDisplayNotification, recordData }) {
    let navigate = useNavigateWithParams();

    let { paymentsId } = useParams();

    React.useEffect(() => {
        console.log(recordData);
        console.log('RecordData updated');
    }, [recordData]);

    React.useEffect(() => {
        console.log('paymentId updated');
        handleFetchRecord(paymentsId)
            .catch((e) => {
                //TODO error handle 404
                setDisplayNotification({ message: "Error" })
            })
    }, [paymentsId]);

    return (
        <React.Fragment>
            <SidePanel
                isLoading={isLoadingSidePanel}
                handleClose={() => navigate("../")}
                title={!isLoadingSidePanel && recordData && `Payment - ${recordData.externalPaymentStatus}`}
                open={sidePanelOpen}>
                {!isLoadingSidePanel && recordData && (
                        <ViewPaymentForm
                        isLoading={isLoadingSidePanel}
                        handleClose={() => navigate("../")}
                        recordData={recordData}                        
                        readyOnlyForm={true}                                   
                    />)}          
            </SidePanel>
        </React.Fragment>);
}

function PaymentCancel({
    isLoadingSidePanel,
    sidePanelOpen,
    handleCancel,
    handleListRecords,
    isLoadingSearchSidePanel,
    searchSidePanelData,
}) {

    let navigate = useNavigateWithParams();
    const { refreshGrid } = useOutletContext();
    let { paymentId } = useParams();

    return (
        <React.Fragment>
            <SidePanel
                isLoading={isLoadingSidePanel}
                handleClose={() => navigate("../")}
                title={!isLoadingSidePanel && `Cancel Payment ?`}
                open={sidePanelOpen}
            >
                {!isLoadingSidePanel && (
                    <CancelPaymentForm
                        isLoading={isLoadingSidePanel}
                        handleClose={() => {
                            navigate("../");
                        }}
                        handleCancel={(reasonCode) => {
                            return handleCancel(paymentId, reasonCode).then(() => {
                                navigate("../");
                                return refreshGrid();
                            });
                        }}
                        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                        handleListRecords={handleListRecords}
                        searchSidePanelData={searchSidePanelData}
                    />
                )}
            </SidePanel>
        </React.Fragment>
    );
}

function PaymentsTemplate({ mode, data, isLoading, isLoadingSidePanel, isLoadingSearchSidePanel, onRefresh,
    handleFetchRecord, recordData, handleListRecords, handlePost, handleCancel, handleListConnectors, searchSidePanelData, refreshGrid }) {

    const [displayNotification, setDisplayNotification] = React.useState(null);


    const isCockpitMode = () => {
        return mode === "cockpit";
    };

    return (
        <Routes>
            <Route path="/" element={<PaymentsIndex
                isLoading={isLoading}
                displayNotification={displayNotification}
                setDisplayNotification={setDisplayNotification}
                onRefresh={onRefresh}
                handleCancel={handleCancel}
                handleListRecords={handleListRecords}
                handleFetchRecord={handleFetchRecord}
                handlePost={handlePost}
                data={data} />}
            > 
                <Route path=":paymentsId" element={<PaymentsView
                    isLoadingSidePanel={isLoadingSidePanel}
                    recordData={recordData}
                    handleFetchRecord={handleFetchRecord}
                    setDisplayNotification={setDisplayNotification}
                    sidePanelOpen={true} />} />
                <Route
                    path={
                        (isCockpitMode() ? "payments/" : "") + ":paymentId/cancel"
                    }
                    element={
                        <PaymentCancel
                            isLoadingSidePanel={isLoadingSidePanel}
                            isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                            handleListRecords={handleListRecords}
                            searchSidePanelData={searchSidePanelData}
                            setDisplayNotification={setDisplayNotification}
                            displayNotification={displayNotification}
                            handleListConnectors={handleListConnectors}
                            sidePanelOpen={true}
                            handleCancel={(id, reasonCode) => {
                                return handleCancel(id, reasonCode)
                                    .then(() => {
                                        setDisplayNotification({
                                            message: "Payment Cancelled",
                                            type: "success",
                                        });
                                    })
                                    .catch((e) => {
                                        if (e.response.data.Message)
                                            setDisplayNotification({
                                                message: e.response.data.Message,
                                                type: "error",
                                            });
                                        else {
                                            setDisplayNotification({
                                                message: "Unknown Error",
                                                type: "error",
                                            });
                                        }
                                    });
                            }}
                        />
                    }
                />
            </Route>
        </Routes>
    );
}

export default PaymentsTemplate;