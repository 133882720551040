import React from 'react';

import {
    FormControlLabel,
    Switch,
    DialogActions,
    Button,
    Grid,
    Typography,
    TextField
} from "@mui/material/";

import { object, string} from 'yup';

import { useNavigateWithParams } from '../../hooks/useNavigateWithParams'
import MenuItem from "@mui/material/MenuItem";

const defaultValues = {
    role: "",
    id: "",
};

let updateRoleSchema = object({
    role: string().required().oneOf(["Administrator", "User"]),
    id: string().required(),
});

export default function UpdateRoleForm({
    handleClose, isLoading, handleUpdateRole, recordData }) {

    const [formValues, setFormValues] = React.useState(defaultValues);

    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});    
    const [isDisabled, setIsDisabled] = React.useState(false);    

    let navigate = useNavigateWithParams();
    const onSubmit = data => {
        data.preventDefault();
        debugger;
        updateRoleSchema
            .validate(formValues, { stripUnknown: true })
            .then((parsedFormValues) => {
                return parsedFormValues;                
            })
            .then((updateRecord) => {
                return handleUpdateRole(updateRecord.id, updateRecord);
            });
    }

    React.useEffect(() => {
        if (recordData) {
            updateRoleSchema
                .validate(recordData, { stripUnknown: true })
                .then((userRecord) => {                    
                    setFormValues(userRecord);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [recordData]);

    React.useEffect(() => {
        var userParsed = updateRoleSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                debugger;
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = value.message;
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    const handleInputChange = (e) => {
        debugger;

        let { name, value } = e.target; 

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="row">     
                <Grid item xs={6}>
                    <TextField
                        select
                        id="role-input"
                        name="role"
                        label="Role"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading}
                        value={formValues.role || ""}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Administrator">Administrator</MenuItem>
                        <MenuItem value="User">User</MenuItem>
                    </TextField>
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    {!isDisabled && <Button type="submit" disabled={!isValid || isLoading || isDisabled} variant="outlined" >
                        <Typography color="black">Update</Typography>
                    </Button>}
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Back</Typography>
                    </Button>
                </DialogActions>

            </Grid>            
        </form>
    );
}