import SubscriptionAutomationTemplate from '../ui/templates/SubscriptionAutomationTemplate';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigateWithParams } from '../hooks/useNavigateWithParams'
import { fakeData as programTypeFakeData } from './fakeData/programTypeFakeData'
import { fakeData as reasonCodeFakeData } from './fakeData/reasonCodeFakeData'
import { fakeData as companyFakeData } from './fakeData/companyFakeData'
import { fakeData as customerFakeData } from './fakeData/customerFakeData'
import { fakeData as subscriptionPlanFakeData } from './fakeData/subscriptionPlanFakeData'
import { fakeData as productFakeData } from './fakeData/entityReferenceFakeData'
import { fakeData as currencyFakeData } from './fakeData/currencyFakeData'

import {
     getSubscriptionAction, listSubscriptionActions, postSubscriptionAction, firmSubscriptionAction,
     cancelSubscriptionAction, firmSubscriptionActions, postSubscriptionActions, cancelSubscriptionActions
       } from '../api/subscriptionActionsService';

import {
    cancelSubscriptionPlan, updateSubscriptionPlan, masterProcess, createSubscriptionPlan,
    draftSubscriptionPlan, getSubscriptionPlan, getSubscriptionPlans, publishSubscriptionPlan, updateContractDefinition
       } from '../api/subscriptionPlansService';

import {
    getSubscriptionPlanLines, listSubscriptionPlanLines, EnableSubscriptionPlanLine, DisableSubscriptionPlanLine,
    createSubscriptionPlanLine, DeleteSubscriptionPlanLine, updateSubscriptionPlanLine, AddCreditNote
       } from '../api/subscriptionPlanLinesService';

import { getReasonCodes } from '../api/reasonCodesService';
import { getProgramTypes } from '../api/programTypeService';
import { listCustomers, listCustomersByConnectorId } from '../api/customersService';
import { getConnectors, getConnector } from '../api/connectorsService';
import { listCompany } from '../api/companyService';
import { listProducts } from '../api/productsService';
import { listCurrencies } from '../api/currenciesService';
import { listUnits } from '../api/unitsService';

import UserContext from "../UserContext";
import { useSearchParams } from "react-router-dom";


export default function SubscriptionAutomationPage() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "Subscription Automation Page" });

    const [subscriptionPlanDatagridData, setSubscriptionPlanDatagridData] = React.useState([]);
    const [subscriptionActionDatagridData, setSubscriptionActionDatagridData] = React.useState([]);
    const [subscriptionPlanLineDatagridData, setSubscriptionPlanLineDatagridData] = React.useState([]);
    const [customersDatagridData, setCustomersDatagridData] = React.useState([]);

    const [subscriptionPlanData, setSubscriptionPlanData] = React.useState(null);
    const [subscriptionActionData, setSubscriptionActionData] = React.useState(null);
    const [subscriptionPlanLineData, setSubscriptionPlanLineData] = React.useState(null);

    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSubscriptionPlan, setIsLoadingSubscriptionPlan] = React.useState(true);
    const [isLoadingSubscriptionPlanLine, setIsLoadingSubscriptionPlanLine] = React.useState(false);
    const [isLoadingSubscriptionAction, setIsLoadingSubscriptionAction] = React.useState(true);

    const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);
    const [isLoadingSearchSidePanel, setIsLoadingSearchSidePanel] = React.useState(false);

    const [searchSidePanelData, setSearchSidePanelData] = React.useState([]);

    const timerRef = React.useRef(null);

    const userContext = React.useContext(UserContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const getConnectorIdFilter = () => {
        return searchParams.get("connector-id");
    };

    const handleListCustomers = (query) => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            
        }
        else {
            return listCustomersByConnectorId(getConnectorIdFilter(), userContext.activeEnvironment, userContext.tenant)
            //return listCustomers(null, userContext.activeEnvironment, userContext.tenant)
            //TODO Add query filtering 
                .then((data) => {
                    setIsLoading(false);
                    setCustomersDatagridData(data);
                    return data;
                })
                .catch((e) => {
                    setIsLoading(false);
                });
        }        
    }

    const handleSearchCustomers = (customerName) => {
        //TODO
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
               
            });
        }
        else {
            return new Promise((resolve, reject) => {
                if (customerName) {
                    const filteredData = customersDatagridData.filter((x) => x.name && x.name.toLowerCase().includes(customerName.toLowerCase()));
                    setIsLoading(false);
                    return resolve(filteredData);
                } else {
                    setIsLoading(false);
                    resolve(customersDatagridData);
                }
            });

            
            /*
            return createSubscriptionPlan(customerName).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })

            */
        }
    }

    const handleListConnectors = () => {
        /*if (getConnectorIdFilter() != null) {
            return getConnector(getConnectorIdFilter().toLowerCase(), userContext.activeEnvironment, userContext.tenant);
        } else {*/
            return getConnectors(userContext.activeEnvironment, userContext.tenant);
        //}
    }

    const handleListRecords = (recordType, query) => {
        setIsLoadingSearchSidePanel(true);
        setSearchSidePanelData([]);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                if (recordType === "reasoncode") {
                    if (query && query.name) {
                        setSearchSidePanelData(reasonCodeFakeData.filter((x) => x.name && x.name.includes(query.name)));
                    }
                    else
                        setSearchSidePanelData(reasonCodeFakeData);
                }
                else if (recordType === "programtype") {
                    if (query && query.name) {
                        const filteredData = programTypeFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(programTypeFakeData);
                }
                else if (recordType === "company") {
                    if (query && query.name) {
                        const filteredData = companyFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(companyFakeData);
                }
                else if (recordType === "customer") {
                    if (query && query.name) {
                        const filteredData = customerFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(customerFakeData);
                }
                else if (recordType === "product") {
                    
                    if (query && query.name) {
                        const filteredData = productFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(productFakeData);
                }
                setIsLoadingSearchSidePanel(false);
            }, 1500);
        } else {

            if (recordType === "reasoncode") {
                return getReasonCodes(query, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }
                        setIsLoadingSearchSidePanel(false);

                        return data;
                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
            else if (recordType === "programtype") {
                return getProgramTypes(userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));

                            setSearchSidePanelData(filteredData);
                            setIsLoadingSearchSidePanel(false)

                            return filteredData;
                        }

                        setSearchSidePanelData(data);
                        setIsLoadingSearchSidePanel(false)

                        return data;
                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
            else if (recordType === "company") {

                return listCompany(query.connectorId, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }

                        setIsLoadingSearchSidePanel(false);

                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });

            }
            else if (recordType === "customer") {
                return listCustomers(query.connectorId, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }

                        setIsLoadingSearchSidePanel(false);
                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
            else if (recordType === "product") {
                return listProducts(query.connectorId, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }

                        setIsLoadingSearchSidePanel(false);
                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
            else if (recordType === "currency") {
                return listCurrencies(query.connectorId, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }

                        setIsLoadingSearchSidePanel(false);

                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
            else if (recordType === "unit") {
                return listUnits(query.connectorId, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }
                        setIsLoadingSearchSidePanel(false);
                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
            else if (recordType === "connector") {

                return getConnectors(userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            if (getConnectorIdFilter() != null) {
                                const filtereByConnectorId = Array(data.find((x) => x.id = getConnectorIdFilter().toLowerCase()));
                                setSearchSidePanelData(filtereByConnectorId);
                            }
                            else {
                                setSearchSidePanelData(data);
                            }
                            
                        }
                        setIsLoadingSearchSidePanel(false)
                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });

            }
        }        
    }

    const subscriptionPlans = {
        data: subscriptionPlanDatagridData,
        recordData: subscriptionPlanData,
        isLoading: isLoadingSubscriptionPlan,
        onRefresh: (activeCustomer) => {
            setSubscriptionPlanDatagridData([]);
            setIsLoadingSubscriptionPlan(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    setSubscriptionPlanDatagridData(subscriptionPlanFakeData);
                    setIsLoadingSubscriptionPlan(false);
                }, 1500);
            } else {
                getSubscriptionPlans(activeCustomer,getConnectorIdFilter(), userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSubscriptionPlan(false);
                    setSubscriptionPlanDatagridData(data);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        setSubscriptionPlanDatagridData([]);
                    })
            }
        },
        handleCreateRecord: (record) => {
            setIsLoadingSubscriptionPlan(true);
            setIsLoadingSidePanel(true);
            if (process.env.REACT_APP_TEST_DATA === "true") {
                return new Promise((resolve) => {
                    timerRef.current = setTimeout(() => {
                        setSubscriptionPlanDatagridData([{ ...record, id: uuidv4() }, ...subscriptionPlanDatagridData]);
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);
                    }, 1500);
                });
            }
            else {
                return createSubscriptionPlan(record, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSubscriptionPlan(false);
                    setIsLoadingSidePanel(false);
                    return subscriptionPlans.onRefresh();
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);
                        throw e;
                    })
            }
        },
        handleFetchRecord: (id) => {
            setIsLoadingSidePanel(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    var recordIndex = subscriptionPlanDatagridData.findIndex((x => x.id === id));
                    setSubscriptionPlanData(subscriptionPlanDatagridData[recordIndex])
                    setIsLoadingSidePanel(false);
                }, 1500);
            }
            else {
                getSubscriptionPlan(id, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        setSubscriptionPlanData(data)
                        setIsLoadingSidePanel(false);
                    })
                    .catch((e) => {
                        setIsLoadingSidePanel(false);
                    })
            }
        },
        handleEditRecord: (subscriptionPlanId, record) => {            
            setIsLoadingSubscriptionPlan(true);
            setIsLoadingSidePanel(true);
            if (process.env.REACT_APP_TEST_DATA === "true") {
                return new Promise((resolve) => {
                    //TODO
                });
            }
            else {
                return updateSubscriptionPlan(subscriptionPlanId, record, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);
                        return data;
                    })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);
                        throw e;
                    })
            }            
        },
        handlePublish: (id, activeCustomerId) => {
            setIsLoadingSubscriptionPlan(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return publishSubscriptionPlan(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    subscriptionPlans.onRefresh(activeCustomerId);
                    setIsLoadingSubscriptionPlan(false);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        throw e;
                    })
            }
        },
        handleUnpublish: (id, activeCustomerId) => {
            setIsLoadingSubscriptionPlan(true);
            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return draftSubscriptionPlan(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    subscriptionPlans.onRefresh(activeCustomerId);
                    setIsLoadingSubscriptionPlan(false);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        throw e;
                    })
            }
        },
        handleCancel: (id, body) => {
            setIsLoadingSubscriptionPlan(true);
            setIsLoadingSidePanel(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return cancelSubscriptionPlan(id, body, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSubscriptionPlan(false);
                    setIsLoadingSidePanel(false);

                    return subscriptionPlans.onRefresh();
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);

                        throw e;
                    })
            }
        },
        handleMasterProcess: (id, data) => {
            setIsLoadingSubscriptionPlan(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return masterProcess(id, data, userContext.activeEnvironment, userContext.tenant).then((response) => {
                    setIsLoadingSubscriptionPlan(false);

                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);

                        throw e;
                    })
            }
        },
        handleContractDefinition: (id, body) => {
            setIsLoadingSubscriptionPlan(true);
            setIsLoadingSidePanel(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return updateContractDefinition(id, body, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSubscriptionPlan(false);
                    setIsLoadingSidePanel(false);

                    return subscriptionPlans.onRefresh();
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);

                        throw e;
                    })
            }
        }
    }

    const subscriptionPlanLines = {
        data: subscriptionPlanLineDatagridData,
        recordData: subscriptionPlanLineData,
        isLoading: isLoadingSubscriptionPlanLine,
        onRefresh: (subscriptionPlanId) => {
            setSubscriptionPlanLineDatagridData([]);
            setIsLoadingSubscriptionPlanLine(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    //TODO
                }, 1500);
            } else {
                if (!subscriptionPlanId) {
                    setIsLoadingSubscriptionPlanLine(false);
                    setSubscriptionPlanLineDatagridData([]);
                    return;
                }
                listSubscriptionPlanLines(subscriptionPlanId, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSubscriptionPlanLine(false);
                    setSubscriptionPlanLineDatagridData(data);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlanLine(false);
                        setSubscriptionPlanLineDatagridData([]);
                    })
            }
        },
        handleCreateRecord: (subscriptionPlanId, record) => {
            setIsLoadingSubscriptionPlanLine(true);
            setIsLoadingSidePanel(true);
            if (process.env.REACT_APP_TEST_DATA === "true") {
                return new Promise((resolve) => {
                    timerRef.current = setTimeout(() => {
                        
                    }, 1500);
                });
            }
            else {
                return createSubscriptionPlanLine(subscriptionPlanId, record, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    if (typeof(data) !== "string") {
                        throw data.errMsg;
                    }
                    else {
                        setIsLoadingSubscriptionPlanLine(false);
                        setIsLoadingSidePanel(false);
                        return subscriptionPlanLines.onRefresh(subscriptionPlanId);
                    }
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlanLine(false);
                        setIsLoadingSidePanel(false);
                        throw e;
                    })
            }
        },
        handleFetchRecord: (subscriptionPlanId, id) => {
            setIsLoadingSidePanel(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    
                }, 1500);
            }
            else {
                if (subscriptionPlanId && !id || (subscriptionPlanData == null && !id)) {
                    return getSubscriptionPlan(subscriptionPlanId, userContext.activeEnvironment, userContext.tenant)
                        .then((data) => {
                            setSubscriptionPlanData(data)
                            setIsLoadingSidePanel(false);
                            return data;
                        })
                        .catch((e) => {
                            setIsLoadingSidePanel(false);
                        })
                }

                return getSubscriptionPlanLines(subscriptionPlanId, id, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        setSubscriptionPlanLineData(data)
                        setIsLoadingSidePanel(false);
                    })
                    .catch((e) => {
                        setIsLoadingSidePanel(false);
                    })
            }
        },
        handleEditRecord: (subscriptionPlanId, subscriptionPlanLineId, recordToUpdate) => {
            setIsLoadingSubscriptionPlan(true);
            setIsLoadingSidePanel(true);
            if (process.env.REACT_APP_TEST_DATA === "true") {
                return new Promise((resolve) => {
                    timerRef.current = setTimeout(() => {
                        setSubscriptionPlanDatagridData([{ ...recordToUpdate, id: uuidv4() }, ...subscriptionPlanDatagridData]);
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);
                    }, 1500);
                });
            }
            else {
                return updateSubscriptionPlanLine(subscriptionPlanId, subscriptionPlanLineId, recordToUpdate, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);
                        return subscriptionPlanLines.onRefresh(subscriptionPlanId);
                    })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlan(false);
                        setIsLoadingSidePanel(false);
                        throw e;
                    })
                }            
        },
        handleEnable: (subscriptionPlanId, id) => {
            setIsLoadingSubscriptionPlanLine(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return EnableSubscriptionPlanLine(subscriptionPlanId, id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    subscriptionPlanLines.onRefresh(subscriptionPlanId);
                    setIsLoadingSubscriptionPlanLine(false);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlanLine(false);
                        throw e;
                    })
            }
        },
        handleDisable: (subscriptionPlanId, id) => {
            setIsLoadingSubscriptionPlanLine(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return DisableSubscriptionPlanLine(subscriptionPlanId, id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    subscriptionPlanLines.onRefresh(subscriptionPlanId);
                    setIsLoadingSubscriptionPlanLine(false);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlanLine(false);
                        throw e;
                    })
            }
        },
        handleDelete: (subscriptionPlanId, id) => {
            setIsLoadingSubscriptionPlanLine(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return DeleteSubscriptionPlanLine(subscriptionPlanId, id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    subscriptionPlanLines.onRefresh(subscriptionPlanId);
                    setIsLoadingSubscriptionPlanLine(false);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlanLine(false);
                        throw e;
                    })
            }
        },
        handleAddCreditNote: (subscriptionPlanId, id, body) => {
            setIsLoadingSubscriptionPlanLine(true);
            setIsLoadingSidePanel(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                return AddCreditNote(subscriptionPlanId, id, body, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        setIsLoadingSubscriptionPlanLine(false);
                        setIsLoadingSidePanel(false);

                        return subscriptionPlanLines.onRefresh(subscriptionPlanId);
                })
                    .catch((e) => {
                        setIsLoadingSubscriptionPlanLine(false);
                        setIsLoadingSidePanel(false);

                        throw e;
                    })
            }
        }
    }

    const subscriptionActions = {
        data: subscriptionActionDatagridData,
        recordData: subscriptionActionData,
        isLoading: isLoadingSubscriptionAction,
        onRefresh: (activeSubscriptionPlan, activeSubscriptionPlanLine, activeCustomer) => {
            setSubscriptionActionDatagridData([]);
            setIsLoadingSubscriptionAction(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    //TODO fake data
                    setSubscriptionActionDatagridData([]);
                    setIsLoadingSubscriptionAction(false);
                }, 1500);
            } else {
                let query = {};

                if (activeSubscriptionPlan)
                    query.subscriptionPlanId = activeSubscriptionPlan.id;

                if (activeSubscriptionPlanLine)
                    query.subscriptionPlanLineId = activeSubscriptionPlanLine.id;

                if (activeCustomer)
                    query.customerId = activeCustomer.id;

                if (getConnectorIdFilter() != null) {
                    query.connectorId = getConnectorIdFilter();
                }

                listSubscriptionActions(query, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSubscriptionAction(false);
                    setSubscriptionActionDatagridData(data);
                });
            }
        },        
        handleFetchRecord: (id) => {
            setIsLoadingSidePanel(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    //todo
                }, 1500);
            } else {
                return getSubscriptionAction(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSidePanel(false);
                    setSubscriptionActionData(data);
                })
                    .catch((e) => {
                        setIsLoadingSidePanel(false);
                        throw e;
                    })
            }
        },
        handlePost: (ids) => {
            setIsLoadingSubscriptionAction(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                let promise = ids != null && Array.isArray(ids) ? postSubscriptionActions(ids.map((v) => v.id), userContext.activeEnvironment, userContext.tenant) : postSubscriptionAction(ids, userContext.activeEnvironment, userContext.tenant);

                return promise
                    .catch((e) => {
                        setIsLoadingSubscriptionAction(false);
                        throw e;
                    })
            }
        },
        handleFirm: (ids) => {
            setIsLoadingSubscriptionAction(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                let promise = ids != null && Array.isArray(ids) ? firmSubscriptionActions(ids.map((v) => v.id), userContext.activeEnvironment, userContext.tenant) : firmSubscriptionAction(ids, userContext.activeEnvironment, userContext.tenant);

                return promise
                    .catch((e) => {
                        setIsLoadingSubscriptionAction(false);
                        throw e;
                    })
            }
        },
        handleCancel: (ids, body) => {
            setIsLoadingSubscriptionAction(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                let promise = ids != null && Array.isArray(ids) ? cancelSubscriptionActions(ids, body, userContext.activeEnvironment, userContext.tenant) : cancelSubscriptionAction(ids, body, userContext.activeEnvironment, userContext.tenant);

                return promise
                    .catch((e) => {
                        setIsLoadingSubscriptionAction(false);
                        throw e;
                    })
            }
        },
        handleEditRecord: () => {
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        },                
    }

    React.useEffect(() => {
        //TODO memory leak cleanup in all methods
        setIsLoadingSubscriptionPlan(true);
        let isMounted = true;
        if (process.env.REACT_APP_TEST_DATA === "true") {
            const timer = setTimeout(() => {
                setSubscriptionPlanDatagridData(subscriptionPlanFakeData);
                setIsLoadingSubscriptionPlan(false);
            }, 1500);
            return () => clearTimeout(timer);
        }
        else {
            getSubscriptionPlans(null,null, userContext.activeEnvironment, userContext.tenant).then((data) => {
                if (isMounted) {
                    setIsLoadingSubscriptionPlan(false);
                    setSubscriptionPlanDatagridData(data);
                }
            });
        }

        return () => {
            isMounted = false;
        };
    }, [userContext.activeEnvironment]);

    React.useEffect(() => {
        setIsLoadingSubscriptionAction(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            const timer = setTimeout(() => {
                
            }, 1500);
            return () => clearTimeout(timer);
        }
        else {
            listSubscriptionActions({ connectorId: getConnectorIdFilter() }, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoadingSubscriptionAction(false);
                setSubscriptionActionDatagridData(data);
            });
        }
    }, [userContext.activeEnvironment]);
    React.useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);

    return (<SubscriptionAutomationTemplate
        subscriptionPlans={subscriptionPlans}
        subscriptionPlanLines={subscriptionPlanLines}
        subscriptionActions={subscriptionActions}
        handleListCustomers={handleListCustomers}
        handleSearchCustomers={handleSearchCustomers}
        handleListRecords={handleListRecords}
        isLoading={isLoading}
        isLoadingSidePanel={isLoadingSidePanel}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        searchSidePanelData={searchSidePanelData}
        handleListConnectors={handleListConnectors }
    />);
}