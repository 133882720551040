import * as React from "react";
import { Paper, CircularProgress } from "@mui/material/";
import { styled } from "@mui/material/styles";

import LinkIcon from "@mui/icons-material/Link";

const StyledPaper = styled(Paper)({
  background: "rgb(0,104,229)",
  background:
    "linear-gradient(45deg , rgba(127,4,137,1) 0%, rgba(0,104,229,1) 100%)",
  height: "125px",
  marginLeft: "5px",
  marginRight: "5px",

  "@media (max-width:900px)": {
    maxWidth: "100%",
  },
});

const PaperContentWrapper = styled("div")({
  display: "flex",
  flexDirection: "column",
  padding: "10px",
  color: "white",
  height: "105px",
  position: "relative",
});

const ContentTitle = styled("span")({
  fontWeight: "lighter",
  fontSize: "20px",
  display: "flex",
  alignItems: "flex-start",
  alignContent: "flex-start",
  marginTop: "10px",
  fontFamily: "sans-serif",
  width: "80%",
  userSelect: "none",
});

const ContentValue = styled("span")({
  fontWeight: "bold",
  fontSize: "30px",
  display: "flex",
  marginTop: "auto",
  fontFamily: "sans-serif",
  // userSelect: "none",
});

const LinkToIcon = styled("button")({
  cursor: "pointer",
  outline: "none",
  border: "none",
  overflow: "hidden",
  backgroundColor: "transparent",
  color: "white",
  position: "absolute",
  top: "10px",
  right: "10px",
});

const BriefCard = ({ title, id, value, currency, linkTo }) => {
  const ScrollTo = () => {
    if (linkTo) {
      const forwardedRef = document.getElementById(linkTo);

      if (forwardedRef) {
        window.scrollTo(0, forwardedRef.offsetTop - 80);
      }
    }
  };

  return (
    <StyledPaper elevation={3} id={id}>
      <PaperContentWrapper>
        {value != null ? (
          <React.Fragment>
            <ContentTitle>{title}</ContentTitle>
            <ContentValue flip="true">
              {id === "totalRevenue"
                ? value.length === 0
                  ? "N/A"
                  : new Intl.NumberFormat("en-UK", {
                      style: "currency",
                      currency: value[currency].code,
                    }).format(Number(value[currency].value))
                : value.toLocaleString("en-UK")}
            </ContentValue>
            <LinkToIcon onClick={ScrollTo}>
              <LinkIcon></LinkIcon>
            </LinkToIcon>
          </React.Fragment>
        ) : (
          <CircularProgress
            sx={{ position: "relative", transform: "Translate(50%, 50%)" }}
            color="inherit"
          />
        )}
      </PaperContentWrapper>
    </StyledPaper>
  );
};

BriefCard.defaultProps = {
  title: "",
  currency: null,
  value: null,
};
export default BriefCard;
