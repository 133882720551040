import SidebarListItem from '../molecules/SidebarListItem';
import HomeIcon from '@mui/icons-material/Home';
import SettingsInputCompositeIcon from '@mui/icons-material/SettingsInputComposite';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import GroupIcon from '@mui/icons-material/Group';

import ViewTimelineIcon from '@mui/icons-material/ViewTimeline';

import StorefrontIcon from '@mui/icons-material/Storefront';
import ArticleIcon from '@mui/icons-material/Article';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import GroupsIcon from '@mui/icons-material/Groups';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

import { useContext } from 'react';
import UserContext from '../../UserContext';

// Navigation Panel
function SidebarList({ open }) {

    const activeEnvironmentId = useContext(UserContext).activeEnvironment;
    //TODO: Find a way to apply the sidebarActive to each component without having to add it in the tags

    return (<div>
        <SidebarListItem to={`/${activeEnvironmentId}`} icon={<HomeIcon fontSize="small" sx={{ alignContent: "center" }} />} title="Home" />
        <SidebarListItem to={`/${activeEnvironmentId}/connectors`} icon={<SettingsInputCompositeIcon fontSize="small"/>} title="Connectors" />
        {/*<SidebarListItem icon={<PendingActionsIcon fontSize="small" />} title="Actions" sidebarActive={open} ><SidebarListItem depthLevel={1} title="Unfirmed actions" /><SidebarListItem depthLevel={1} title="Firmed actions" /><SidebarListItem depthLevel={1} title="Posted actions" /></SidebarListItem>*/}
        <SidebarListItem icon={<ViewModuleIcon fontSize="small" />} title="Subscription Automation" sidebarActive={open} >
            <SidebarListItem to={`/${activeEnvironmentId }/subscription-automation/cockpit`} depthLevel={1} title="Cockpit"/>
            <SidebarListItem to={`/${activeEnvironmentId }/subscription-automation/subscription-actions`} depthLevel={1} title="Subscription Actions" />
            <SidebarListItem to={`/${activeEnvironmentId}/subscription-automation/program-types`} depthLevel={1} title="Program Types" />
            <SidebarListItem to={`/${activeEnvironmentId }/subscription-automation/reason-codes`} depthLevel={1} title="Reason Codes" />
            <SidebarListItem to={`/${activeEnvironmentId}/subscription-automation/subscription-plans`} depthLevel={1} title="Subscription Plans" />
        </SidebarListItem>

        <SidebarListItem icon={<ViewTimelineIcon fontSize="small" />} title="Enterprise Automation" sidebarActive={open} >
            <SidebarListItem to={`/${activeEnvironmentId}/enterprise-automation/merchants`} icon={<StorefrontIcon fontSize="small" />} depthLevel={1} title="Merchants" />
            {/*<SidebarListItem to={''} icon={<ArticleIcon fontSize="small" />} depthLevel={1} title="Mandates" />*/}

            <SidebarListItem to={`/${activeEnvironmentId}/enterprise-automation/payments`} icon={<CreditCardIcon fontSize="small" />} depthLevel={1} title="Payments" />
            {/*<SidebarListItem to={''} icon={<GroupsIcon fontSize="small" />} depthLevel={1} title="Customers" />*/}
            {/*<SidebarListItem to={''} icon={<CurrencyExchangeIcon fontSize="small" />} depthLevel={1} title="Refunds" />*/}
        </SidebarListItem>

        <SidebarListItem to={`${activeEnvironmentId}/users`} icon={<GroupIcon fontSize="small" />} title="Users" />
        </div>)
}

export default SidebarList;