import * as React from 'react';
import RibbonButton from './RibbonButton';
import { Typography } from '@mui/material/';
import UnpublishedIcon from '@mui/icons-material/Unpublished';

export default function UnpublishRibbonButton(props) {
    return (
        <RibbonButton {...props} startIcon={<UnpublishedIcon sx={{ color: "black" }} />}>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonButton>
    );
}