import MerchantsTemplate from '../ui/templates/MerchantsTemplate';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { fakeData as companyFakeData } from './fakeData/companyFakeData'
import { fakeData as paymentsFakeData } from './fakeData/paymentsFakeData'
import { fakeData as merchantFakeData } from './fakeData/merchantFakeData'
import UserContext from "../UserContext";
import { listMerchants, getMerchant, addMerchant, testMerchantConnection, deleteMerchant, updateMerchant } from '../api/MerchantsService';

import { getConnectors, getFilteredConnectors } from "../api/connectorsService";
import { useSearchParams } from "react-router-dom";


function MerchantsPage() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "Merchant Page" });

    const [datagridData, setDatagridData] = React.useState([]);
    const [searchSidePanelData, setSearchSidePanelData] = React.useState([]);
    const datagridRef = React.useRef(paymentsFakeData);

    const [recordData, setRecordData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);
    const [isLoadingSearchSidePanel, setIsLoadingSearchSidePanel] = React.useState(false);

    const timerRef = React.useRef(null);
    const userContext = React.useContext(UserContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const getConnectorIdFilter = () => {
        return (searchParams.get("connector-id") != null ? searchParams.get("connector-id").toLowerCase() : null);
    };

    const onRefresh = () => {
        setDatagridData([]);
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData(merchantFakeData);
                    datagridRef.current = merchantFakeData;
                    setIsLoading(false);
                    resolve();
                }, 1500);
            });
        }

        else {
            return listMerchants({ connectorId: getConnectorIdFilter() }, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setDatagridData(data);
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }

    }

    const handleFetchRecord = (id) => {
        setIsLoadingSidePanel(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                //todo
            }, 1500);
        } else {
            return getMerchant(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoadingSidePanel(false);
                setRecordData(data);
            })
                .catch((e) => {
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    };

    const handleCreateRecord = (record) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    /*
                    setDatagridData([{ ...record, id: uuidv4() }, ...datagridData]);
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);*/
                }, 1500);
            });
        } else {
            return addMerchant(
                record,
                userContext.activeEnvironment,
                userContext.tenant
            )
                .then((data) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    return data;
                })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                });
        }
    };

    const handleEditRecord = (merchantId, record) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                //TODO
            });
        } else {
            return updateMerchant(
                merchantId,
                record,
                userContext.activeEnvironment,
                userContext.tenant
            )
                .then((data) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    return data;
                })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                });
        }
    };

    const handleListRecords = (recordType, query) => {
        setIsLoadingSearchSidePanel(true);
        setSearchSidePanelData([]);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {

                setIsLoadingSearchSidePanel(false);
            }, 1500);
        } else {
            if (recordType === "connector") {
                return getFilteredConnectors("merchants", userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter(
                                (x) =>
                                    x.name &&
                                    x.name.toLowerCase().includes(query.name.toLowerCase())
                            );
                            setSearchSidePanelData(filteredData);
                        } else {
                            if (getConnectorIdFilter() != null) {
                                const filteredData = Array(data.find((x) => x.company.connector = getConnectorIdFilter()));
                                setSearchSidePanelData(filteredData);
                            } else {
                                setSearchSidePanelData(data);
                            }

                        }

                        setIsLoadingSearchSidePanel(false);
                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
        }
    };

    const handleTestMerchantConnection = (id) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                //todo
                resolve();
            });
        } else {
            return testMerchantConnection(
                id,
                userContext.activeEnvironment,
                userContext.tenant
            ).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return data; // Return the data received from the API
            }).catch((e) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                throw e;
            });
        }
    };


    const handleDeleteRecord = (id) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                //todo
                resolve();
            });
        }
        else {
            return deleteMerchant(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                onRefresh();
                return data;
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }
    React.useEffect(() => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            const timer = setTimeout(() => {
                setDatagridData(merchantFakeData);
                datagridRef.current = merchantFakeData;
                setIsLoading(false);

            }, 1500);
            return () => clearTimeout(timer);
        }

        else {
            return listMerchants({ connectorId: getConnectorIdFilter() }, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setDatagridData(data);
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }

    }, [userContext.activeEnvironment]);

    React.useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);


    return (<MerchantsTemplate
        data={datagridData}
        isLoading={isLoading}
        isLoadingSidePanel={isLoadingSidePanel}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        searchSidePanelData={searchSidePanelData}
        recordData={recordData}
        handleListRecords={handleListRecords}
        handleFetchRecord={handleFetchRecord}
        handleCreateRecord={handleCreateRecord}
        handleDeleteRecord={handleDeleteRecord}
        handleEditRecord={(merchantId, data) => {
            return handleEditRecord(merchantId, data).then(() =>
                onRefresh()
            );
        }}
        handleTestMerchantConnection={handleTestMerchantConnection}
        onRefresh={onRefresh} />);
}

export default MerchantsPage;