import { Snackbar, Alert } from '@mui/material/';


export default function NotificationSnackBar({ displayNotification, onClose  }) {

    return (
        displayNotification !== null && (<Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={displayNotification !== null}
        autoHideDuration={5000}
        onClose={onClose}>

            {displayNotification !== null && (
            <Alert
                onClose={onClose}
                    severity={displayNotification !== null && displayNotification.type !== null ? displayNotification.type : "success"}
                sx={{ width: '100%' }}>
                    {displayNotification !== null && displayNotification.message}
            </Alert>)}
        </Snackbar>));

}