import SubscriptionActionTemplate from '../ui/templates/SubscriptionActionTemplate';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { fakeData as programTypeFakeData } from './fakeData/programTypeFakeData'
import { fakeData as reasonCodeFakeData } from './fakeData/reasonCodeFakeData'
import { fakeData as companyFakeData } from './fakeData/companyFakeData'
import { fakeData as customerFakeData } from './fakeData/customerFakeData'

import { getSubscriptionAction, listSubscriptionActions, postSubscriptionAction, firmSubscriptionAction, cancelSubscriptionAction, cancelSubscriptionActions, postSubscriptionActions, firmSubscriptionActions } from '../api/subscriptionActionsService';
import { getReasonCodes } from '../api/reasonCodesService';
import { getProgramTypes } from '../api/programTypeService';

import { fakeData } from './fakeData/subscriptionPlanFakeData'
import UserContext from "../UserContext";
import { useSearchParams } from "react-router-dom";

export default function SubscriptionActionPage() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "Subscription Actions Page" });

    const [datagridData, setDatagridData] = React.useState([]);
    const [searchSidePanelData, setSearchSidePanelData] = React.useState([]);
    const [recordData, setRecordData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);
    const [isLoadingSearchSidePanel, setIsLoadingSearchSidePanel] = React.useState(false);
    const userContext = React.useContext(UserContext);
    let [searchParams, setSearchParams] = useSearchParams();
    const getConnectorIdFilter = () => {
        return (searchParams.get("connector-id") != null ? searchParams.get("connector-id").toLowerCase() : null);
    };

    const timerRef = React.useRef(null);

    const onRefresh = () => {
        setDatagridData([]);
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                setDatagridData(fakeData);
                setIsLoading(false);
            }, 1500);
        } else {
            listSubscriptionActions({ connectorId: getConnectorIdFilter() }, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setDatagridData(data);
            });
        }
    }   

    const handleFetchRecord = (id) => {
        setIsLoadingSidePanel(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                var recordIndex = datagridData.findIndex((x => x.id === id));
                setRecordData(datagridData[recordIndex])
                setIsLoadingSidePanel(false);
            }, 1500);
        } else {
            return getSubscriptionAction(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoadingSidePanel(false);
                setRecordData(data);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handlePost = (ids) => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            let promise = ids != null && Array.isArray(ids) ? postSubscriptionActions(ids.map((v) => v.id), userContext.activeEnvironment, userContext.tenant) : postSubscriptionAction(ids, userContext.activeEnvironment, userContext.tenant);

            return promise
                .then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleFirm = (ids) => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            let promise = ids != null && Array.isArray(ids) ? firmSubscriptionActions(ids.map((v) => v.id), userContext.activeEnvironment, userContext.tenant) : firmSubscriptionAction(ids, userContext.activeEnvironment, userContext.tenant);

            return promise
                .then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleCancel = (ids, body) => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            let promise = ids != null && Array.isArray(ids) ? cancelSubscriptionActions(ids, body, userContext.activeEnvironment, userContext.tenant) : cancelSubscriptionAction(ids, body, userContext.activeEnvironment, userContext.tenant);
            return promise
                .then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }
    const handleListRecords = (recordType, query) => {
        setIsLoadingSearchSidePanel(true);
        setSearchSidePanelData([]);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                if (recordType === "reasoncode") {
                    if (query && query.name) {
                        setSearchSidePanelData(reasonCodeFakeData.filter((x) => x.name && x.name.includes(query.name)));
                    }
                    else
                        setSearchSidePanelData(reasonCodeFakeData);
                }
                else if (recordType === "programtype") {
                    if (query && query.name) {
                        const filteredData = programTypeFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(programTypeFakeData);
                }
                else if (recordType === "company") {
                    if (query && query.name) {
                        const filteredData = companyFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(companyFakeData);
                }
                else if (recordType === "customer") {
                    if (query && query.name) {
                        const filteredData = customerFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(customerFakeData);
                }
                setIsLoadingSearchSidePanel(false);
            }, 1500);
        } else {
            if (recordType === "reasoncode") {
                return getReasonCodes(query, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }

                        setIsLoadingSearchSidePanel(false)
                        return data;
                    });
            }
            else if (recordType === "programtype") {
                return getProgramTypes(userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }

                        setIsLoadingSearchSidePanel(false)
                    });
            }
            else if (recordType === "company") {
                if (query && query.name) {
                    const filteredData = companyFakeData.filter((x) => x.name && x.name.includes(query.name));
                    setSearchSidePanelData(filteredData);
                }
                else
                    setSearchSidePanelData(companyFakeData);
            }
            else if (recordType === "customer") {
                if (query && query.name) {
                    const filteredData = customerFakeData.filter((x) => x.name && x.name.includes(query.name));
                    setSearchSidePanelData(filteredData);
                }
                else
                    setSearchSidePanelData(customerFakeData);
            }
        }        
    }

    React.useEffect(() => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            const timer = setTimeout(() => {
                setDatagridData(fakeData);
                setIsLoading(false);
            }, 1500);
            return () => clearTimeout(timer);
        }
        else {
            listSubscriptionActions({ connectorId: getConnectorIdFilter() }, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setDatagridData(data);
            });
        }       
    }, [userContext.activeEnvironment]);

    React.useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);


    return (<SubscriptionActionTemplate data={datagridData}
        isLoading={isLoading}
        isLoadingSidePanel={isLoadingSidePanel}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        handleFetchRecord={handleFetchRecord}
        handleListRecords={handleListRecords}
        handleFirm={handleFirm}
        handlePost={handlePost}
        handleCancel={handleCancel}
        recordData={recordData}
        searchSidePanelData={searchSidePanelData}
        onRefresh={onRefresh}
    />);
}