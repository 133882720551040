import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string, bool, array } from "yup";

import { DialogActions } from '@mui/material/';
import AutocompleteField from "../ui/organisms/AutocompleteField";

let typeSchema = object({
    name: string(),
    isDefault: bool()
});

let reasonCodeSchema = object({
    name: string().required(),
    description: string().required(),
    types: array().of(typeSchema)
});

export default function EditReasonCodeForm({ handleEditRecord, handleClose, isLoading, recordData }) {
    const [formValues, setFormValues] = React.useState(recordData);
    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});
    const [initialValues, setInitialValues] = React.useState({});

    
    const onSubmit = data => {
        data.preventDefault();
        if (formValues) {
            reasonCodeSchema
                .validate(formValues, { stripUnknown: true })
                .then((parsedReasonCode) => {
                    let updateRecord = {};                    
                    for (const key in parsedReasonCode) {
                        if (parsedReasonCode[key] !== initialValues[key] && (typeof parsedReasonCode[key] !== 'object')) {
                            updateRecord[key] = parsedReasonCode[key];
                        }
                        else if (typeof parsedReasonCode[key] === 'object') {
                            if (Array.isArray(parsedReasonCode[key]) && Array.isArray(initialValues[key])) {
                                let arrParsedSorted = parsedReasonCode[key].sort((a, b) => (a.name > b.name) - (a.name < b.name));
                                let arrInitialSorted = initialValues[key].sort((a, b) => (a.name > b.name) - (a.name < b.name));

                                if (JSON.stringify(arrParsedSorted) !== JSON.stringify(arrInitialSorted))
                                    updateRecord[key] = parsedReasonCode[key];
                            }                                                                        
                        }
                    }
                    return updateRecord;
                })
                .then((reasoncode) => {
                    if (Object.keys(reasoncode).length > 0)
                        return handleEditRecord(recordData.id, reasoncode)

                    return true;
                })
                .catch(e => {
                    console.log(e);
                });
        }

    }

    React.useEffect(() => {
        if (recordData) {
            reasonCodeSchema
                .validate(recordData, { stripUnknown: true })
                .then((reasonCode) => {
                    setFormValues(reasonCode);
                    setInitialValues(reasonCode);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [recordData]);

    React.useEffect(() => {
        console.log(formValues.types);
    }, [formValues.types]);

    React.useEffect(() => {
        var reasonCodeParsed = reasonCodeSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = value.message;
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    const handleInputChange = (e) => {        
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });        
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="column">
                <Grid item>
                    <TextField
                        id="name-input"
                        name="name"
                        label="Name"
                        type="text"
                        size="small"
                        disabled={true}
                        value={formValues.name}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="description-input"
                        name="description"
                        label="Description"
                        type="text"
                        size="small"
                        required
                        disabled={isLoading}
                        value={formValues.description}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <AutocompleteField
                        label="Types"
                        disabled={isLoading}
                        defaultValues={formValues.types ? formValues.types : []}
                        handleChange={(id, values) => {
                            handleInputChange({ target: { name: id, value: values } });
                        }}
                        id="types"
                    />
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Button type="submit" disabled={!isValid || isLoading} variant="outlined" >
                        <Typography color="black">Update</Typography>
                    </Button>
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Cancel</Typography>
                    </Button>
                </DialogActions>
            </Grid>
        </form>
    );
}