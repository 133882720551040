import * as React from 'react';
import RibbonLoadingButton from './RibbonLoadingButton';
import { Typography } from '@mui/material/';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

export default function AddRoleRibbonButton(props) {
    return (
        <RibbonLoadingButton {...props}
            startIcon={
                <GroupAddIcon sx={{ color: "black" }} />
            }>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonLoadingButton>
    );
}