import React from 'react';

import {
    FormControlLabel,
    Switch,
    DialogActions,
    Button,
    Grid,
    Typography,
    TextField
} from "@mui/material/";
import LookupPaper from "../../ui/organisms/LookupPaper";
import LookupField from "../../ui/organisms/LookupField";
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams'
import SearchSidePanel from "../../ui/organisms/SearchSidePanel";

import { parse, format, isDate, parseJSON, isValid } from 'date-fns'
import { object, string, number, bool, date, mixed } from "yup";


const defaultValues = {
    startOn: "",
    amountOfBillingPeriodsToCredit: 0,
    creditAllCyclesUntilNow: true,
    disableLine: false,
    reasonCode: ""
};

let entityReferenceSchema = object({
    id: string().required(),
    name: string().required(),
    connector: string(),
    secondaryKey: string().nullable(),
    extensionData: mixed(),
    url: string().nullable()
}).nullable();

let AddCreditNoteSchema = object({
    startOn: date().transform(parseDateString).required(),
    amountOfBillingPeriodsToCredit: number().transform((value) => (isNaN(value) ? undefined : value)).default(0).required(),
    creditAllCyclesUntilNow: bool().required(),
    disableLine: bool().required(),
    reasonCode: entityReferenceSchema.required()
});

function parseDateString(value, originalValue) {
    if (originalValue == null)
        return null;

    if (originalValue === "")
        return null;

    let parsedDate = isDate(originalValue)
        ? new Date(Date.UTC(originalValue.getUTCFullYear(), originalValue.getUTCMonth(), originalValue.getUTCDate(), originalValue.getUTCHours(), originalValue.getUTCMinutes(), originalValue.getUTCSeconds()))
        : parseJSON(originalValue, "yyyy-MM-dd", new Date());

    if (!isValid(parsedDate)) {
        parsedDate = parse(originalValue, "yyyy-MM-dd", new Date());

    }

    return parsedDate;
}

export default function AddCreditNoteForm({
    handleClose, isLoading, handleAddCreditNote,

    isLoadingSearchSidePanel,
    handleListRecords,
    searchSidePanelData,
    readyOnlyForm

}) {

    const [formValues, setFormValues] = React.useState(defaultValues);

    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});    
    const [isDisabled, setIsDisabled] = React.useState(false);

    const [searchSidePanelType, setSearchSidePanelType] = React.useState("");
    const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] = React.useState({});
    const [selectedLookupId, setSelectedLookupId] = React.useState(null);

    const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
    const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
    const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
    const [columns, setColumns] = React.useState([]);

    const [connectors, setConnectors] = React.useState([]);
    const [defaultConnector, setDefaultConnector] = React.useState(null);
    const [displayConnectorSelect, setDisplayConnectorSelect] = React.useState(true);

    const showAmountOfBillingPeriodsToCredit = () => {
        if (formValues.creditAllCyclesUntilNow === false) {
            return true;
        }

        return false;
    };

    let navigate = useNavigateWithParams();
    const onSubmit = data => {
        data.preventDefault();

        AddCreditNoteSchema
            .validate(formValues, { stripUnknown: true })
            .then((parsedFormValues) => {
                return parsedFormValues;                
            })
            .then((data) => {
                return handleAddCreditNote(data);
            });
    }

    React.useEffect(() => {
        var contractDefinitionParsed = AddCreditNoteSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = {
                        message: value.message,
                        type: value.type
                    }
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    React.useEffect(() => {
        if (formValues.creditAllCyclesUntilNow === true) {
            setFormValues({
                ...formValues,
                "amountOfBillingPeriodsToCredit": 0
            });
        }

    }, [formValues.creditAllCyclesUntilNow]);

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "startOn" && value !== "") {
            
            value = parse(value, "yyyy-MM-dd", new Date());
            value.setHours(new Date().getHours())
            value.setMinutes(new Date().getMinutes())
        }

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }        

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="row">     
                <Grid item xs={6}>
                    <TextField
                        id="StartOn-input"
                        name="startOn"
                        label="Start On"
                        type="date"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={isDisabled || isLoading}
                        onChange={handleInputChange}
                        required
                        value={formValues.startOn ? format(formValues.startOn, "yyyy-MM-dd") : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>     
                <Grid item xs={12}>
                    <LookupField
                        handleSearchSidePanel={(type, query) => {
                            setDisplayConnectorSelect(false);

                            handleListRecords(type, { ...query, type: "new" });
                            setSearchSidePanelOpen(true);
                            setSelectedLookupId("reasonCode");
                            setSearchSidePanelTitle("Search Reason Codes");
                            setSearchSidePanelQuery({ ...query, type: "new" });
                            setSearchSidePanelType("reasoncode");
                            setColumns([
                                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
                            ]);
                        }}
                        required
                        disabled={isLoading || readyOnlyForm === true}
                        searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
                        onChange={handleInputChange}

                        id="reasonCode"
                        type="reasoncode"
                        name="reasonCode"
                        label="Reason Code"
                        initialValue={formValues && formValues.reasonCode}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ width: 200 }}
                        control={
                            <Switch
                                id="creditAllCyclesUntilNow-input"
                                name="creditAllCyclesUntilNow"
                                label="Credit all cycles until now?"
                                size="medium"
                                disabled={isDisabled || isLoading}
                                checked={formValues.creditAllCyclesUntilNow}
                                onChange={handleInputChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Credit all cycles until now?"
                    />
                </Grid> 
                <Grid item xs={12}>
                    {showAmountOfBillingPeriodsToCredit() && <TextField
                        id="AmountOfBillingPeriodsToCredit-input"
                        name="amountOfBillingPeriodsToCredit"
                        label="Amount of billing periods to credit"
                        type="number"
                        size="small"
                        disabled={isDisabled || isLoading}
                        sx={{ width: 200 }}
                        value={formValues && formValues.amountOfBillingPeriodsToCredit}
                        onChange={handleInputChange}
                    />}
                </Grid> 
                <Grid item xs={12}>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ width: 200 }}
                        control={
                            <Switch
                                id="DisableLine-input"
                                name="disableLine"
                                label="Disable line?"
                                size="medium"
                                disabled={isDisabled || isLoading}
                                checked={formValues.disableLine}
                                onChange={handleInputChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Disable line?"
                    />
                </Grid>               
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    {!isDisabled && <Button type="submit" disabled={!isValid || isLoading || isDisabled} variant="outlined" >
                        <Typography color="black">Create</Typography>
                    </Button>}
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Back</Typography>
                    </Button>
                </DialogActions>
                <SearchSidePanel
                    open={searchSidePanelOpen}
                    handleClose={() => setSearchSidePanelOpen(false)}
                    isLoading={isLoadingSearchSidePanel}
                    title={searchSidePanelTitle}
                >
                    <LookupPaper
                        handleClose={() => setSearchSidePanelOpen(false)}
                        selectedLookupId={selectedLookupId}
                        handleChangeLookupValue={setSearchSidePanelSelectedLookup}
                        isLoading={isLoadingSearchSidePanel}
                        query={searchSidePanelQuery}
                        data={searchSidePanelData}
                        columns={columns}
                        searchSidePanelType={searchSidePanelType}
                        connectors={connectors}
                        defaultConnector={defaultConnector}
                        displayConnectorSelect={displayConnectorSelect}
                        handleListRecords={handleListRecords}
                    />
                </SearchSidePanel>
            </Grid>            
        </form>
    );
}