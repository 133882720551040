import * as React from "react";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import StarsIcon from "@mui/icons-material/Stars";

import { useFirstRender } from "../../hooks/useFirstRender";

export default function AutocompleteField({
  id,
  label,
  options,
  defaultValues,
  handleChange,
  disabled,
}) {
  const [values, setValues] = React.useState(
    defaultValues ? defaultValues : []
  );

  const isFirstRender = useFirstRender();

  React.useEffect(() => {
    handleChange(id, values);
  }, [values]);

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      options={options}
      value={values}
      getOptionLabel={(option) => option.name}
      onChange={(evt, value) => {
        setValues(value);
      }}
      isOptionEqualToValue={(option, value) => {
        return option && value && option.name === value.name;
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) =>
          option ? (
            <Tooltip
              key={option.name}
              title={
                option.isDefault
                  ? `This reason code is the default option for ${option.name}`
                  : `Click to set this reason code as default for ${option.name}`
              }
              placement="left"
            >
              <Chip
                key={option.name}
                variant="outlined"
                label={option.name}
                disabled={disabled}
                color={option.isDefault ? "success" : "default"}
                icon={
                  option.isDefault ? (
                    <StarsIcon sx={{ opacity: "0.75" }} />
                  ) : null
                }
                onClick={(evt) => {
                  setValues((prevState) => {
                    return prevState.map((v) => {
                      if (v && v.name === option.name)
                        return { ...v, isDefault: !v.isDefault };

                      return v;
                    });
                  });
                }}
                {...getTagProps({ index })}
              />
            </Tooltip>
          ) : null
        )
      }
      renderInput={(params) => (
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ width: "220px" }}>
            <TextField
              {...params}
              disabled={disabled}
              variant="outlined"
              size="small"
              label={label}
              id={id}
            />
          </FormControl>
        </Box>
      )}
    />
  );
}

AutocompleteField.defaultProps = {
  options: [
    { key: "New", name: "New", isDefault: false },
    { key: "Cancel", name: "Cancel", isDefault: false },
    { key: "Upgrade", name: "Upgrade", isDefault: false },
    { key: "Downgrade", name: "Downgrade", isDefault: false },
    { key: "Renewal", name: "Renewal", isDefault: false },
  ],
  defaultValues: [],
  handleChange: () => {},
};
