import * as React from "react";

import {
  Typography,
  Paper,
  Popover,
  MenuList,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@mui/material/";

import { styled } from "@mui/material/styles";

import LineChart from "../organisms/LineChart";
import BarChart from "../organisms/BarChart";
import ChartFilter from "../organisms/ChartFilter";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import UserContext from "../../UserContext";

import _ from "lodash/";

const StyledTitle = styled(Typography)({
  color: "#7A7A7A",
  fontWeight: "bold",
  fontSize: "25px",
  fontFamily: "sans-serif",
});

/*
  DataCard header
*/
const HeaderContainer = styled("div")({
  marginLeft: "10px",
  marginRight: "10px",
  display: "flex",
  flexDirection: "column",
  position: "relative",
  height: "25px",
});

const Header = ({
  title,
  isHidden,
  toggleHidden,
  chartData,
  getChartData,
  toggleFiltering,
}) => {
  return (
    <HeaderContainer>
      <StyledTitle>{title}</StyledTitle>
      <Options
        isHidden={isHidden}
        toggleHidden={toggleHidden}
        chartData={chartData}
        getChartData={getChartData}
        toggleFiltering={toggleFiltering}
      />
    </HeaderContainer>
  );
};

Header.defaultProps = {
  title: "",
  isHidden: false,
  chart: null,
  toggleHidden: () => {},
  togleFiltering: () => {},
};

/*
  Options menu
*/

const OptionSelect = styled("button")({
  color: "#7A7A7A",
  backgroundColor: "transparent",
  cursor: "pointer",
  outline: "none",
  border: "none",
  overflow: "hidden",
  position: "absolute",
  top: 0,
  right: 0,
});

const Options = ({ isHidden, chartData, toggleHidden, toggleFiltering }) => {
  const [buttonRef, setButtonRef] = React.useState(null);
  const open = Boolean(buttonRef);
  const id = open ? "simple-popover" : undefined;

  const toggleOptions = (event) => {
    setButtonRef(buttonRef ? null : event.currentTarget);
  };

  const hideHandler = () => {
    setButtonRef(null);
    toggleHidden();
  };

  const filterHandler = () => {
    setButtonRef(null);
    toggleFiltering();
  };

  return (
    <React.Fragment>
      <OptionSelect onClick={toggleOptions}>
        <MoreHorizIcon />
      </OptionSelect>
      <Popover
        id={id}
        open={open}
        onClose={toggleOptions}
        anchorEl={buttonRef}
        placement="bottom-start"
      >
        <MenuList>
          {/* Hide DataCard */}
          <MenuItem onClick={hideHandler}>
            <ListItemIcon>
              {isHidden ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </ListItemIcon>
            <ListItemText primary={!isHidden ? "Hide" : "Show"} />
          </MenuItem>
          {/* Chart Options */}
          {chartData && (
            <MenuItem onClick={filterHandler}>
              <ListItemIcon>
                <FilterAltIcon />
              </ListItemIcon>
              <ListItemText primary="Filter" />
            </MenuItem>
          )}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};

Options.defaultProps = {
  isHidden: false,
  chartData: null,
  toggleHidden: () => {},
  toggleFiltering: () => {},
};

/*
  Chart Wrapper
*/
const Chart = ({
  id,

  title,
  height,
  //
  type,
  format,
  //
  data,
  //
  currency,
  changeCurrency,
  //
  toggleFiltering,
  //
  startDate,
  finishDate,
}) => {
  if (type === "LineChart")
    return (
      <LineChart
        id={id}
        title={title}
        height={height}
        data={data}
        format={format}
        currency={currency}
        changeCurrency={changeCurrency}
        toggleFiltering={toggleFiltering}
        startDate={startDate}
        finishDate={finishDate}
      />
    );
  if (type === "BarChart")
    return (
      <BarChart
        id={id}
        title={title}
        height={height}
        data={data}
        format={format}
        currency={currency}
        changeCurrency={changeCurrency}
        toggleFiltering={toggleFiltering}
        startDate={startDate}
        finishDate={finishDate}
      />
    );

  return <span style={{ display: "flex" }}>Chart format invalid</span>;
};

/*
  Loading for awaiting data
*/
const Loading = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        margin: "auto",
      }}
    >
      <CircularProgress />
    </div>
  );
};

const Container = styled(Paper)({
  p: 2,
  display: "flex",
  flexDirection: "column",
  height: "auto",
  paddingTop: "15px",
  paddingBottom: "15px",
  marginBottom: "15px",
});

const BodyContainer = styled("div")({
  color: "white",
  display: "flex",
  flexDirection: "column",
  margin: "10px",
  position: "relative",
});

// TODO: Refactor
const ExtendedCard = ({
  sx,
  children,
  startHidden,
  id,
  title,
  chart,
  handleFetchData,
}) => {
  const [isFiltering, setFiltering] = React.useState(false);
  const [isHidden, setHidden] = React.useState(startHidden);

  const [chartData, setChartData] = React.useState(null);
  const [format, setFormat] = React.useState(chart.format);
  const [currency, setCurrency] = React.useState(0);
    const userContext = React.useContext(UserContext);

  const [startBy, setStartBy] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [finishBy, setFinishBy] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 11, 59, 59)
  );

  const setStartDate = (start) => {
    setStartBy(start);
  };

  const setFinishDate = (finish) => {
    setFinishBy(finish);
  };

  const fetchData = ({ startBy, finishBy, format, type }) => {
    return handleFetchData({
      id: id,
      startBy: startBy,
      finishBy: finishBy,
      format: format ? format : chart.format,
      type: type ? type : chart.type,
    })
      .then((data) => {
        setChartData(data);
        setFormat(format);

        return data;
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const toggleHidden = () => {
    setHidden(!isHidden);
  };

  const toggleFiltering = () => {
    setFiltering(!isFiltering);
  };

  const changeCurrency = (changeTo) => {
    setCurrency(changeTo);
  };

  React.useEffect(() => {
    fetchData({
      startBy: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      ).toISOString(),
      finishBy: new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        0,
        11,
        59,
        59
      ).toISOString(),
      format: format,
      type: chart.type,
    });
  }, [userContext.activeEnvironment]);

  return (
    <React.Fragment>
      {chartData && (
        <ChartFilter
          id={chart.title.toLowerCase()}
          //
          isFiltering={isFiltering}
          toggleFilter={toggleFiltering}
          //
          chartData={chartData}
          fetchData={fetchData}
          //
          currency={currency}
          updateCurrency={changeCurrency}
          //
          startDate={startBy}
          finishDate={finishBy}
          updateStart={setStartDate}
          updateFinish={setFinishDate}
        />
      )}
      <Container id={id} sx={{ ...sx }}>
        {/* TODO: Make it display title of chart if hidden*/}
        <Header
          title={!chart ? title : isHidden ? chart.title : ""}
          isHidden={isHidden}
          toggleHidden={toggleHidden}
          chartData={chart && chartData}
          toggleFiltering={chartData && toggleFiltering}
        />
        {!isHidden && (
          <BodyContainer>
            {chart && (
              <React.Fragment>
                {chartData ? (
                  <Chart
                    title={chart.title}
                    height={chart.height}
                    type={chart.type}
                    format={format}
                    data={chartData}
                    currency={currency}
                    changeCurrency={changeCurrency}
                    startDate={startBy}
                    finishDate={finishBy}
                    toggleFiltering={toggleFiltering}
                  />
                ) : (
                  <Loading />
                )}
              </React.Fragment>
            )}
            {children}
          </BodyContainer>
        )}
      </Container>
    </React.Fragment>
  );
};

ExtendedCard.defaultProps = {
  id: undefined,
  startHidden: false,
  chart: null,
  handleFetchData: () => {},
};
export default ExtendedCard;
