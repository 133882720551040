import * as React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import Legend from "./Legend";

const LegendContainer = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
});

const BarChartLegends = ({ color, data, legends, filterData }) => {
  return (
    <LegendContainer container spacing={1}>
      {data && data.keys &&
        data.keys.map((key, index) => {
          return (
            <Legend
              id={key}
              index={index}
              key={key}
              value={index}
              color={color[key % color.length]}
              visible={legends[index]}
              filterData={filterData}
            />
          );
        })}
    </LegendContainer>
  );
};

BarChartLegends.propTypes = {
  
}

BarChartLegends.defaultProps = {
  color: ["#000437", "#191d4b", "#32365e", "#4c4f73", "#666887", "#7f819b"],
}

export default BarChartLegends;
