import * as React from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
export default function RibbonLoadingButton(props) {

    return (
        props.display ? <LoadingButton onClick={props.onClick} startIcon={props.startIcon} variant="text" size="small" sx={{ textTransform: "none" }}>
            {props.children}
        </LoadingButton> : null);
}

RibbonLoadingButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func  
};