import * as React from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  List,
  ListItem,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";

import YearMonthRangeSelector from "../molecules/YearMonthRangeSelector";

const BriefCardFilter = ({
  isFiltering,
  toggleFilter,

  data,
  fetchData,

  currency,
  updateCurrency,

  startDate,
  finishDate,

  updateStart,
  updateFinish,
}) => {
  const changeCurrency = (event) => {
    updateCurrency(event.target ? event.target.value : 0);
  };

  const submitFiltering = () => {
    toggleFilter();

    fetchData({
      startBy: startDate,
      finishBy: finishDate,
      currency: currency,
    });
  };

  return (
    <Dialog
      sx={{ color: "#fff" }}
      open={isFiltering}
      onClose={toggleFilter}
      disableEscapeKeyDown
    >
      <DialogTitle>Filter</DialogTitle>
      <List>
        <ListItem>
          <YearMonthRangeSelector
            startDate={startDate}
            finishDate={finishDate}
            onStartDateChanged={updateStart}
            onFinishDateChanged={updateFinish}
          />
        </ListItem>
        <ListItem>
          <FormControl sx={{ width: "250px" }}>
            <InputLabel id="currency-label">Currency</InputLabel>
            <Select
              value={currency}
              label="Currency"
              labelId="currency-label"
              id="currency-select"
              onChange={changeCurrency}
            >
              {data.totalRevenue.map((currency, index) => (
                <MenuItem key={index} value={index}>
                  {currency.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        <ListItem>
          <Button
            onClick={submitFiltering}
            variant="outlined"
            color="info"
            size="large"
          >
            Update Filter
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
};

export default BriefCardFilter;
