import * as React from "react";
import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiContainer from "@mui/material/Container";
import { EventType } from "@azure/msal-browser";
import {
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";

import { useSearchParams, Routes, Route, Navigate } from "react-router-dom";

import AppBarStyled from "../organisms/AppBarStyled";
import DrawerStyled from "../organisms/DrawerStyled";
import NotLoggedIn from "../organisms/NotLoggedIn";

import Login from "../organisms/Login";

const StyledContainer = styled(MuiContainer, {
  shouldForwardProp: (prop) => prop !== "open",
  shouldForwardProp: (prop) => prop !== "isEmbedded",
})(({ theme, open, isEmbedded }) => ({
  width: "100%", //`calc(100% - ${theme.sidepanel.width.open}px)`,
  marginLeft: "auto",

  ...(isEmbedded && { marginLeft: theme.sidepanel.width.open }),

  ...(!open &&
    isEmbedded && {
      width: `calc(100% - ${theme.sidepanel.width.closed}px)`,
      marginLeft: theme.sidepanel.width.closed,
    }),
}));

export default function PageStructure({ children }) {
  const [open, setOpen] = React.useState(false);
  const { instance } = useMsal();
  let [searchParams, setSearchParams] = useSearchParams();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const isLayoutEmbedded = () => {
    return searchParams.get("layout") === "embedded";
  };

  React.useEffect(() => {
    // This will be run on component mount
    const callbackId = instance.addEventCallback((message) => {
      // This will be run every time an event is emitted after registering this callback
      if (message.eventType === EventType.LOGOUT_END && message.error == null) {
        setOpen(false);
      }
    });

    return () => {
      // This will be run on component unmount
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
    //eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ height: "100vh", display: "flex" }}>
      {isLayoutEmbedded() ? null : (
        <AppBarStyled open={open} onClickIcon={setOpen} />
      )}
      <AuthenticatedTemplate>
        {isLayoutEmbedded() ? null : (
          <DrawerStyled open={open} onClickIcon={toggleDrawer} />
        )}
        <StyledContainer
          disableGutters
          maxWidth="100hv"
          open={open}
          isEmbedded={!isLayoutEmbedded()}
          sx={{
            marginTop: isLayoutEmbedded() ? 0 : 8,
          }}
        >
          {children}
        </StyledContainer>
          </AuthenticatedTemplate>       
          <UnauthenticatedTemplate>
              {children}
          </UnauthenticatedTemplate>
    </Box>
  );
}
