import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Switch from '@mui/material/Switch';
import Typography from "@mui/material/Typography";
import { object, string, number, mixed, bool } from 'yup';
import MenuItem from "@mui/material/MenuItem";
import SearchSidePanel from '../../ui/organisms/SearchSidePanel';
import LookupPaper from '../../ui/organisms/LookupPaper';
import LookupField from '../../ui/organisms/LookupField';
import FormControlLabel from '@mui/material/FormControlLabel';

import { DialogActions } from '@mui/material/';
import { useParams } from 'react-router-dom';


const defaultValues = {
    reasonCode: "",
    programType: "",
    customer: "",
    connector: "",
    periodType: "",
    subscriptionType: "",
    billingPeriodValue: 0,
    billingPeriodUnit: "",
    monthBillingPeriodType: "",
    billingType: "",
    isGoCardlessIntegration: false
};

let entityReferenceSchema = object({
    id: string().required(),
    name: string().required(),
    connector: string(),
    secondaryKey: string().nullable(),
    extensionData: mixed(),
    url: string().nullable()
}).nullable();

let subscriptionPlanSchema = object({
    customer: entityReferenceSchema,
    billingPeriodUnit: string().oneOf(["Days", "Months"]),
    monthBillingPeriodType: string().oneOf(["None", "Monthly", "Quarterly", "HalfYearly", "Yearly", "Other"]),
    billingPeriodValue: number().required(),
    billingType: string().oneOf(["Upfront"]),
    programType: entityReferenceSchema,
    reasonCode: entityReferenceSchema,
    salesPerson: string().nullable(),
    connector: entityReferenceSchema,
    periodType: string().required().oneOf(["Calendar", "Rolling"]),
    subscriptionType: string().required().oneOf(["PerpetualEnhancementPlan", "PerUnitSubscription", "Consumption"]),
    isGoCardlessIntegration: bool()
});


export default function EditSubscriptionPlanForm({ handleClose, isLoading, isLoadingSearchSidePanel, recordData, readyOnlyForm,
    handleEditRecord, handleListConnectors, handleListRecords, searchSidePanelData }) {

    const [formValues, setFormValues] = React.useState(defaultValues);

    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});
    const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
    const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
    const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
    const [columns, setColumns] = React.useState([]);
    const [searchSidePanelType, setSearchSidePanelType] = React.useState("");
    const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] = React.useState({});
    const [selectedLookupId, setSelectedLookupId] = React.useState(null);

    const [connectors, setConnectors] = React.useState([]);
    const [defaultConnector, setDefaultConnector] = React.useState(null);
    const [displayConnectorSelect, setDisplayConnectorSelect] = React.useState(true);
    const [isDisabled, setIsDisabled] = React.useState(false);

    const [initialValues, setInitialValues] = React.useState({});
    let { subscriptionPlanId } = useParams();

    //TODO on submit
    const onSubmit = (data) => {
        data.preventDefault();

        subscriptionPlanSchema
            .validate(formValues, { stripUnknown: true })
            .then((parsedFormValues) => {                
                let updateRecord = {};
                debugger;
                for (const key in parsedFormValues) {
                    if (parsedFormValues[key] !== initialValues[key] && (typeof initialValues[key] !== 'object')) {
                        updateRecord[key] = parsedFormValues[key];
                    }
                    else if (typeof parsedFormValues[key] === 'object') {
                        if (parsedFormValues[key] === null && initialValues[key] !== null)
                            updateRecord[key] = parsedFormValues[key];
                        else if (parsedFormValues[key] !== null) {
                            if (parsedFormValues[key].id && initialValues[key].id && parsedFormValues[key].id !== initialValues[key].id)
                                updateRecord[key] = parsedFormValues[key];
                        }
                    }
                }

                return updateRecord;
            })
            .then((updateRecord) => {
                return handleEditRecord(subscriptionPlanId, updateRecord);
            });              
    };

    React.useEffect(() => {
        if (recordData) {            
            handleListConnectors()
                .then((connectors) => {
                    setConnectors(connectors);
                    if (!recordData || !recordData.connector || !recordData.connector.id) {
                        setDefaultConnector(null);
                    }
                    else {
                        let foundConnector = connectors.find((connector) => {
                            if (connector && connector.company && connector.company.id)
                                return connector.id === recordData.connector.id;

                            return false;
                        });

                        if (foundConnector)
                            setDefaultConnector(foundConnector);
                        else
                            setDefaultConnector(null);
                    }
                })
                .catch((e) => setConnectors([]));
        }
       
    }, [recordData]);

    React.useEffect(() => {
        if (recordData) {
            subscriptionPlanSchema
                .validate(recordData, { stripUnknown: true })
                .then((subscriptionPlan) => {                    
                    setFormValues(subscriptionPlan);
                    setInitialValues(subscriptionPlan);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [recordData]);

    React.useEffect(() => {
        var subscriptionPlanParsed = subscriptionPlanSchema
            .validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = {
                        message: value.message,
                        type: value.type,
                    };
                    return acc;
                }, {});
                setError(errors);

                setIsValid(false);
            });
    }, [formValues]);

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (e.target.type == "checkbox") {
            value = e.target.checked;
        }  

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const showMonthBillingPeriodType = () => {
        if (formValues.billingPeriodUnit === 'Months') {
            return false;
        }
        formValues.monthBillingPeriodType = "Other"
        return true;
    };

    const showBillingPeriodValue = () => {
        if (formValues.billingPeriodUnit === 'Months') {

            switch (formValues.monthBillingPeriodType) {
                case "Monthly":
                    formValues.billingPeriodValue = "1"
                    break;

                case "Quarterly":
                    formValues.billingPeriodValue = "3"
                    break;

                case "HalfYearly":
                    formValues.billingPeriodValue = "6"
                    break;

                case "Yearly":
                    formValues.billingPeriodValue = "12"
                    break;

                default:
                    break;
            }

            if (formValues.monthBillingPeriodType === "Other") {
                return false;
            }

            return true;
        }
 
        return false;
    };

    const allowEdit = () => {
        if (recordData.status == 'Draft') {
            return false;
        }
        return true;
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="row">

                <Grid item xs={6}>
                    <LookupField
                        handleSearchSidePanel={(type, query) => {
                            setDisplayConnectorSelect(false);
                            handleListRecords(type, query);
                            setSearchSidePanelOpen(true);
                            setSelectedLookupId("connectorlookup");
                            setSearchSidePanelTitle("Search Connectors");
                            setSearchSidePanelQuery(query);
                            setSearchSidePanelType("connector");
                            setColumns(
                                [{
                                    field: 'name', headerName: 'Name', flex: 2, minWidth: 100
                                },
                                {
                                    field: 'company', headerName: 'Company', flex: 2, minWidth: 100,
                                    valueGetter: function (params) {
                                        if (params && params.row && params.row.company && params.row.company.name) {
                                            try {
                                                return params.row.company.name;
                                            }
                                            catch (e) {

                                            }
                                            return "";
                                        }
                                    }
                                }
                                ]
                            );
                        }}
                        required
                        disabled={isLoading || allowEdit() || readyOnlyForm === true}
                        searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
                        onChange={(e) => {
                            if (e && e.target && e.target.name && e.target.value && e.target.value.id)
                                handleInputChange({
                                    target: {
                                        name: e.target.name,
                                        value: e.target.value.id
                                    }
                                })
                        }}
                        id="connectorlookup"
                        type="connector"
                        name="connector"
                        label="Connector"
                        initialValue={formValues && formValues.connector}
                    />
                </Grid>

                <Grid item xs={6}>
                    <LookupField
                        handleSearchSidePanel={(type, query) => {
                            setDisplayConnectorSelect(true);

                            if (defaultConnector)
                                query = { ...query, connectorId: defaultConnector.id }

                            handleListRecords(type, query);

                            setSearchSidePanelQuery(query);
                            setSearchSidePanelOpen(true);
                            setSelectedLookupId("customerlookup");
                            setSearchSidePanelTitle("Search Customers");
                            setSearchSidePanelType("customer");
                            setColumns([{ field: 'name', headerName: 'Name', flex: 2, minWidth: 100 }]);
                        }}
                        required
                        disabled={isLoading || !defaultConnector || allowEdit() || readyOnlyForm === true}
                        searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
                        onChange={handleInputChange}
                        id="customerlookup"
                        type="customer"
                        name="customer"
                        label="Customer"
                        initialValue={formValues && formValues.customer}
                    />
                </Grid>

                <Grid item xs={6}>
                    <LookupField
                        handleSearchSidePanel={(type, query) => {
                            setDisplayConnectorSelect(false);
                            handleListRecords(type, query);
                            setSearchSidePanelOpen(true);
                            setSelectedLookupId("programtypelookup");
                            setSearchSidePanelTitle("Search Program Types");
                            setSearchSidePanelQuery(query);
                            setSearchSidePanelType("programtype");
                            setColumns(
                                [{ field: 'name', headerName: 'Name', flex: 2, minWidth: 100 }]
                            );
                        }}
                        required
                        disabled={isLoading || allowEdit() || readyOnlyForm === true}
                        searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
                        onChange={handleInputChange}
                        id="programtypelookup"
                        type="programtype"
                        name="programType"
                        label="Program Type"
                        initialValue={formValues && formValues.programType}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        name="billingPeriodUnit"
                        label="Billing Period Unit"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading || allowEdit() || readyOnlyForm === true}
                        value={formValues.billingPeriodUnit}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Days">Days</MenuItem>
                        <MenuItem value="Months">Months</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        name="monthBillingPeriodType"
                        label="Billing Period Type"
                        size="small"
                        sx={{ width: 200 }}
                        required={true}
                        disabled={isLoading || showMonthBillingPeriodType() || allowEdit()}
                        value={formValues.monthBillingPeriodType}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Monthly">Monthly</MenuItem>
                        <MenuItem value="Quarterly">Quarterly</MenuItem>
                        <MenuItem value="HalfYearly">Half-Yearly</MenuItem>
                        <MenuItem value="Yearly">Yearly</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="billingPeriodValue"
                        label="Billing Period Value"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        
                        disabled={isLoading || showBillingPeriodValue() || allowEdit()}
                        value={formValues.billingPeriodValue}
                        onChange={handleInputChange}
                        inputProps={{
                            step: 1,
                            min: 1,
                            max: 99999,
                            type: 'number',
                        }} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        name="billingType"
                        label="Billing Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading || allowEdit() || readyOnlyForm === true}
                        value={formValues.billingType}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Upfront">Up front</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        id="periodType-input"
                        name="periodType"
                        label="Period Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading || allowEdit() || readyOnlyForm === true}
                        value={formValues.periodType}
                        onChange={handleInputChange}
                    >
                        {/*<MenuItem value="Calendar">Calendar</MenuItem>*/}
                        <MenuItem value="Rolling">Rolling</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        name="subscriptionType"
                        label="Subscription Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading || allowEdit() || readyOnlyForm === true}
                        value={formValues.subscriptionType}
                        onChange={handleInputChange}
                    >
                        {/*<MenuItem value="PerpetualEnhancementPlan">Perpetual Enhancement Plan</MenuItem>*/}
                        {/*<MenuItem value="Consumption">Consumption</MenuItem>*/}
                        <MenuItem value="PerUnitSubscription">Per Unit Subscription</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <LookupField
                        handleSearchSidePanel={(type, query) => {
                            setDisplayConnectorSelect(false);
                            handleListRecords(type, { ...query, type: "new" });
                            setSearchSidePanelQuery({ ...query, type: "new" });
                            setSearchSidePanelOpen(true);
                            setSelectedLookupId("reasoncodelookup");
                            setSearchSidePanelTitle("Search Reason Codes");
                            setSearchSidePanelType("reasoncode");
                            setColumns([{ field: 'name', headerName: 'Name', flex: 2, minWidth: 100 }]);
                        }}
                        required
                        disabled={isLoading || allowEdit() || readyOnlyForm === true}
                        searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
                        onChange={handleInputChange}
                        id="reasoncodelookup"
                        type="reasoncode"
                        name="reasonCode"
                        label="Reason Code"
                        initialValue={formValues && formValues.reasonCode}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ width: 200 }}                  
                        control={
                            <Switch
                                id="IsGoCardlessIntegration-input"
                                name="isGoCardlessIntegration"
                                label="Enable for GoCardless Direct Debit"
                                size="medium"
                                disabled={isDisabled || allowEdit() || isLoading}
                                checked={formValues.isGoCardlessIntegration}                              
                                onChange={handleInputChange}            
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Enable for GoCardless Direct Debit"
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="salesPerson-input"
                        name="salesPerson"
                        label="Sales Person"
                        type="text"
                        size="small"
                        disabled={isDisabled || allowEdit() || isLoading}
                        value={formValues.salesPerson}
                        onChange={handleInputChange}
                        sx={{ width: 200 }}
                    />
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    {readyOnlyForm === false && <Button type="submit" disabled={!isValid || isLoading} variant="outlined" >
                        <Typography color="black">Update</Typography>
                    </Button>}
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        {readyOnlyForm === false ? <Typography color="black">Cancel</Typography> : <Typography color="black">Close</Typography>}
                    </Button>
                </DialogActions>

            </Grid>
            <SearchSidePanel open={searchSidePanelOpen}
                handleClose={() => setSearchSidePanelOpen(false)}
                isLoading={isLoadingSearchSidePanel}
                title={searchSidePanelTitle}>
                <LookupPaper
                    handleClose={() => setSearchSidePanelOpen(false)}
                    selectedLookupId={selectedLookupId}
                    handleChangeLookupValue={setSearchSidePanelSelectedLookup}
                    isLoading={isLoadingSearchSidePanel}
                    query={searchSidePanelQuery}
                    data={searchSidePanelData}
                    columns={columns}
                    searchSidePanelType={searchSidePanelType}
                    connectors={connectors}
                    defaultConnector={defaultConnector}
                    displayConnectorSelect={displayConnectorSelect}
                    handleListRecords={handleListRecords} />
            </SearchSidePanel>
        </form>
    );
}