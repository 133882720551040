import { styled, useTheme } from "@mui/material/styles";
import { AuthenticatedTemplate } from "@azure/msal-react";
import ProfileCard from "../organisms/ProfileCard";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import { Toolbar, Typography, IconButton } from "@mui/material";
import EnvironmentSelect from '../organisms/EnvironmentSelect'
import HARPLogo from "../../images/HARP-Logo-White.svg";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  // transition: theme.transitions.create(["width", "margin"], {
  //   easing: theme.transitions.easing.sharp,
  //   duration: theme.transitions.duration.leavingScreen,
  // }),
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "8px",
  },
  ...(open && {
    marginLeft: theme.sidepanel.width.open,
    width: `calc(100% - ${theme.sidepanel.width.open}px)`,
    // transition: theme.transitions.create(["width", "margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
  }),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  height: theme.toolbar.height,
  //pr: '24px', // keep right padding when drawer closed
}))

export default function AppBarStyled({ open, onClickIcon, title }) {
  //TODO resize problem on smaller screens, height is slightly different for some reason
  return (
    <AppBar
      open={open}
      sx={{ position: "-webkit-fixed", position: "fixed", top: "0" }}
    >
      <StyledToolbar>
        <AuthenticatedTemplate>
          <IconButton
            size="medium"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onClickIcon}
            sx={{
              marginLeft: "-25px",
              marginRight: "20px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        </AuthenticatedTemplate>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{ flexGrow: 1, alignContent: "center" }}
        >
          <img
            style={{
              margin: 0,
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            height="45px"
            src={HARPLogo}
            alt="Harp Logo"
          />
              </Typography>
            <AuthenticatedTemplate>
                  <EnvironmentSelect />
            </AuthenticatedTemplate>
        <ProfileCard />
      </StyledToolbar>
    </AppBar>
  );
}

AppBarStyled.defaultProps = {};