import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog({ confirmDialogInfo, onConfirm, onCancel, cancelOption, confirmOption,testDate, children }) {

    return (
        <Dialog
            open={confirmDialogInfo && confirmDialogInfo.title ? true : false}
                TransitionComponent={Transition}
            onClose={onCancel}
                aria-describedby="alert-dialog-slide-description"
            >
            <DialogTitle>{confirmDialogInfo && confirmDialogInfo.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    {confirmDialogInfo && confirmDialogInfo.description}
                </DialogContentText>
                {children}
                </DialogContent>
                <DialogActions>
                <Button onClick={onCancel}>{cancelOption}</Button>
                <Button onClick={(e) => { onConfirm(testDate)}}>{confirmOption}</Button>
                </DialogActions>
            </Dialog>
    );
}

ConfirmDialog.defaultProps = {
    confirmOption: "Confirm",
    cancelOption: "Cancel"
}
