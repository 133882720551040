/*
 *  This class aims to imitate the Nivo Rock Chart Tip but more customizable
 */

import * as React from "react";
import { Paper } from "@mui/material/";
import { styled } from "@mui/material/styles";

const Tip = styled(Paper)({
    backgroundColor: "white",

    borderRadius: "5px",

    paddingTop: "7px",
    paddingBottom: "7px",
    paddingLeft: "10px",
    paddingRight: "10px",

    width: "auto",
    height: "auto",

    boxShadow: "0px 1px 1px"
});

const StyledTip = styled("svg")({
    width: "12px",
    height: "12px",

    marginRight: "10px",
    marginLeft: "5px"
})

const BarChartTip = (value) => {    
    return (
        <Tip>
            <div key={value.id}>
                <StyledTip>
                    <circle cx="10" cy="10" r="40" fill={value.color} />
                </StyledTip>
                <strong>{value.indexValue}{"-"}{value.id}{": "}{value.formattedValue}</strong>
            </div>
        </Tip>
    )
}

export default BarChartTip;