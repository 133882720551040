import ConnectorTemplate from '../ui/templates/ConnectorTemplate';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { fakeData as companyFakeData } from './fakeData/companyFakeData';
import { fakeData as environmentFakeData } from './fakeData/environmentFakeData';

import {
    getConnector, createConnector, createInitialConnector, getConnectors, deactivateConnector,
    activateConnector, deleteConnector, testConnector
} from '../api/connectorsService';

import {
    listCompany
} from '../api/companyService';

import {
    listEnvironment
} from '../api/environmentService';

import UserContext from "../UserContext";


export default function ConnectorPage() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "Connector Page" });

    const [datagridData, setDatagridData] = React.useState([]);
    const [searchSidePanelData, setSearchSidePanelData] = React.useState([]);
    const [recordData, setRecordData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);
    const [isLoadingSearchSidePanel, setIsLoadingSearchSidePanel] = React.useState(false);

    const timerRef = React.useRef(null);

    const userContext = React.useContext(UserContext);

    const onRefresh = () => {
        setDatagridData([]);
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                //TODO fake data
                setDatagridData([]);
                setIsLoading(false);
            }, 1500);
        } else {
            getConnectors(userContext.activeEnvironment, userContext.tenant)
                .then((data) => {
                setIsLoading(false);
                setDatagridData(data);
            });
        }
    }

    const handleInitialRecord = (record) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData([{ ...record, id: uuidv4() }, ...datagridData]);
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                }, 1500);
            });
        }
        else {
            return createInitialConnector(record, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return data;
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }

    const handleCreateRecord = (id, record) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData([{ ...record, id: id }, ...datagridData]);
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                }, 1500);
            });
        }
        else {
            return createConnector(id, record, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }        
    }

    const handleFetchRecord = (id) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                var recordIndex = datagridData.findIndex((x => x.id === id));
                setRecordData(datagridData[recordIndex])
                setIsLoading(false);
                setIsLoadingSidePanel(false);
            }, 1500);
        }
        else {
            return getConnector(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return data;
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }

    const handleTestRecord = (id) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                //todo
                resolve();
            });
        }
        else {
            return testConnector(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }

    const handleDeactivateRecord = (id) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                //todo
                resolve();
            });
        }
        else {
            return deactivateConnector(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }

    const handleActivateRecord = (id) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                //todo
                resolve();
            });
        }
        else {
            return activateConnector(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }

    const handleDeleteRecord = (id) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                //todo
                resolve();
            });
        }
        else {
            return deleteConnector(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                onRefresh();
                return data;
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    }
    const handleListRecords = (recordType, query) => {
        setIsLoadingSearchSidePanel(true);
        setSearchSidePanelData([]);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {
                if (recordType === "company") {
                    if (query && query.name) {
                        const filteredData = companyFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(companyFakeData);
                }
                setIsLoadingSearchSidePanel(false);
            }, 1500);
        } else {
           if (recordType === "company") {
               return listCompany(query.connectorId, userContext.activeEnvironment, userContext.tenant)
                   .then((data) => {
                       if (data && query && query.name) {
                           const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                           setSearchSidePanelData(filteredData);
                       } else {
                           setSearchSidePanelData(data);
                       }
                       setIsLoadingSearchSidePanel(false);

                   })
                   .catch((e) => {
                       setIsLoadingSearchSidePanel(false);
                   });          
            }
            
            if (recordType === "environment") {     
                let envIsSandbox = true;
                let currentEnvs = userContext.environments;
                for (let i = 0; i < currentEnvs.length; i++) {
                    if (currentEnvs[i].id === userContext.activeEnvironment) {
                        envIsSandbox = currentEnvs[i].extensionData.IsSandBox;
                    }
                }
                return listEnvironment(query.connectorId, userContext.activeEnvironment, userContext.tenant, envIsSandbox)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }
                        setIsLoadingSearchSidePanel(false);

                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });   
            }
        }
        
    }

    React.useEffect(() => {
        if (userContext.activeEnvironment) {
            setIsLoading(true);
            if (process.env.REACT_APP_TEST_DATA === "true") {
                const timer = setTimeout(() => {
                    setDatagridData([]);
                    setIsLoading(false);
                }, 1500);
                return () => clearTimeout(timer);
            }
            else {
                getConnectors(userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        setIsLoading(false);
                        setDatagridData(data);
                    });
            }       
        }
      
    }, [userContext.activeEnvironment]);

    React.useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);


    return (<ConnectorTemplate
        data={datagridData}
        isLoading={isLoading}
        isLoadingSidePanel={isLoadingSidePanel}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        handleCreateRecord={handleCreateRecord}
        handleInitialRecord={handleInitialRecord}
        handleListRecords={handleListRecords}
        handleDeleteRecord={handleDeleteRecord}
        handleActivateRecord={handleActivateRecord}
        handleDeactivateRecord={handleDeactivateRecord}
        searchSidePanelData={searchSidePanelData}
        handleTestRecord={handleTestRecord}
        handleFetchRecord={handleFetchRecord}
        onRefresh={onRefresh}        
    />);
}