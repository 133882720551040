import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string } from 'yup';
import { DialogActions } from '@mui/material/';

const defaultValues = {
    name: "",
    email: "",
    role: "",
    timezone: "",
};

let userSchema = object({
    name: string().required(),
    email: string().required(),
    role: string().required().oneOf(["Administrator","User"]),
    
});

export default function NewUserForm({ handleCreateRecord,
    handleClose, isLoading
    }) {
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});

    const onSubmit = data => {
        data.preventDefault();
        handleCreateRecord(formValues);
    }

    React.useEffect(() => {
        var userParsed = userSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = value.message;
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <form onSubmit={onSubmit}>

            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="column">

                <Grid item xs={6}>
                    <TextField
                        id="name-input"
                        name="name"
                        label="Name"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading}
                        value={formValues.name}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="email-input"
                        name="email"
                        label="Email"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading}
                        value={formValues.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        id="role-input"
                        name="role"
                        label="Role"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading}
                        value={formValues.role || ""}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Administrator">Administrator</MenuItem>
                        <MenuItem value="User">User</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        id="timezone-input"
                        name="timezone"
                        label="Timezone"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading}
                        value={formValues.timezone || ""}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Etc/GMT+12">(GMT-12:00) International Date Line West</MenuItem>
                        <MenuItem value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</MenuItem>
                        <MenuItem value="Pacific/Honolulu">(GMT-10:00) Hawaii</MenuItem>
                        <MenuItem value="US/Alaska">(GMT-09:00) Alaska</MenuItem>
                        <MenuItem value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</MenuItem>
                        <MenuItem value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</MenuItem>
                        <MenuItem value="US/Arizona">(GMT-07:00) Arizona</MenuItem>
                        <MenuItem value="America/Chihuahua">(GMT-07:00) Chihuahua, La Paz, Mazatlan</MenuItem>
                        <MenuItem value="US/Mountain">(GMT-07:00) Mountain Time (US & Canada)</MenuItem>
                        <MenuItem value="America/Managua">(GMT-06:00) Central America</MenuItem>
                        <MenuItem value="US/Central">(GMT-06:00) Central Time (US & Canada)</MenuItem>
                        <MenuItem value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</MenuItem>
                        <MenuItem value="Canada/Saskatchewan">(GMT-06:00) Saskatchewan</MenuItem>
                        <MenuItem value="America/Bogota">(GMT-05:00) Bogota, Lima, Quito, Rio Branco</MenuItem>
                        <MenuItem value="US/Eastern">(GMT-05:00) Eastern Time (US & Canada)</MenuItem>
                        <MenuItem value="US/East-Indiana">(GMT-05:00) Indiana (East)</MenuItem>
                        <MenuItem value="Canada/Atlantic">(GMT-04:00) Atlantic Time (Canada)</MenuItem>
                        <MenuItem value="America/Caracas">(GMT-04:00) Caracas, La Paz</MenuItem>
                        <MenuItem value="America/Manaus">(GMT-04:00) Manaus</MenuItem>
                        <MenuItem value="America/Santiago">(GMT-04:00) Santiago</MenuItem>
                        <MenuItem value="Canada/Newfoundland">(GMT-03:30) Newfoundland</MenuItem>
                        <MenuItem value="America/Sao_Paulo">(GMT-03:00) Brasilia</MenuItem>
                        <MenuItem value="America/Argentina/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</MenuItem>
                        <MenuItem value="America/Godthab">(GMT-03:00) Greenland</MenuItem>
                        <MenuItem value="America/Montevideo">(GMT-03:00) Montevideo</MenuItem>
                        <MenuItem value="America/Noronha">(GMT-02:00) Mid-Atlantic</MenuItem>
                        <MenuItem value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is</MenuItem>
                        <MenuItem value="Atlantic/Azores">(GMT-01:00) Azores</MenuItem>
                        <MenuItem value="Africa/Casablanca">(GMT+00:00) Casablanca, Monrovia, Reykjavik</MenuItem>
                        <MenuItem value="Etc/Greenwich">(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London</MenuItem>
                        <MenuItem value="Europe/Amsterdam">(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna</MenuItem>
                        <MenuItem value="Europe/Belgrade">(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague</MenuItem>
                        <MenuItem value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen, Madrid, Paris</MenuItem>
                        <MenuItem value="Europe/Sarajevo">(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb</MenuItem>
                        <MenuItem value="Africa/Lagos">(GMT+01:00) West Central Africa</MenuItem>
                        <MenuItem value="Asia/Amman">(GMT+02:00) Amman</MenuItem>
                        <MenuItem value="Europe/Athens">(GMT+02:00) Athens, Bucharest, Istanbul</MenuItem>
                        <MenuItem value="Asia/Beirut">(GMT+02:00) Beirut</MenuItem>
                        <MenuItem value="Africa/Cairo">(GMT+02:00) Cairo</MenuItem>
                        <MenuItem value="Africa/Harare">(GMT+02:00) Harare, Pretoria</MenuItem>
                        <MenuItem value="Europe/Helsinki">(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius</MenuItem>
                        <MenuItem value="Asia/Jerusalem">(GMT+02:00) Jerusalem</MenuItem>
                        <MenuItem value="Europe/Minsk">(GMT+02:00) Minsk</MenuItem>
                        <MenuItem value="Africa/Windhoek">(GMT+02:00) Windhoek</MenuItem>
                        <MenuItem value="Asia/Kuwait">(GMT+03:00) Kuwait, Riyadh, Baghdad</MenuItem>
                        <MenuItem value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg, Volgograd</MenuItem>
                        <MenuItem value="Africa/Nairobi">(GMT+03:00) Nairobi</MenuItem>
                        <MenuItem value="Asia/Tbilisi">(GMT+03:00) Tbilisi</MenuItem>
                        <MenuItem value="Asia/Tehran">(GMT+03:30) Tehran</MenuItem>
                        <MenuItem value="Asia/Muscat">(GMT+04:00) Abu Dhabi, Muscat</MenuItem>
                        <MenuItem value="Asia/Baku">(GMT+04:00) Baku</MenuItem>
                        <MenuItem value="Asia/Yerevan">(GMT+04:00) Yerevan</MenuItem>
                        <MenuItem value="Asia/Kabul">(GMT+04:30) Kabul</MenuItem>
                        <MenuItem value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</MenuItem>
                        <MenuItem value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi, Tashkent</MenuItem>
                        <MenuItem value="Asia/Calcutta">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</MenuItem>
                        <MenuItem value="Asia/Calcutta">(GMT+05:30) Sri Jayawardenapura</MenuItem>
                        <MenuItem value="Asia/Katmandu">(GMT+05:45) Kathmandu</MenuItem>
                        <MenuItem value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</MenuItem>
                        <MenuItem value="Asia/Dhaka">(GMT+06:00) Astana, Dhaka</MenuItem>
                        <MenuItem value="Asia/Rangoon">(GMT+06:30) Yangon (Rangoon)</MenuItem>
                        <MenuItem value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</MenuItem>
                        <MenuItem value="Asia/Krasnoyarsk">(GMT+07:00) Krasnoyarsk</MenuItem>
                        <MenuItem value="Asia/Hong_Kong">(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi</MenuItem>
                        <MenuItem value="Asia/Kuala_Lumpur">(GMT+08:00) Kuala Lumpur, Singapore</MenuItem>
                        <MenuItem value="Asia/Irkutsk">(GMT+08:00) Irkutsk, Ulaan Bataar</MenuItem>
                        <MenuItem value="Australia/Perth">(GMT+08:00) Perth</MenuItem>
                        <MenuItem value="Asia/Taipei">(GMT+08:00) Taipei</MenuItem>
                        <MenuItem value="Asia/Tokyo">(GMT+09:00) Osaka, Sapporo, Tokyo</MenuItem>
                        <MenuItem value="Asia/Seoul">(GMT+09:00) Seoul</MenuItem>
                        <MenuItem value="Asia/Yakutsk">(GMT+09:00) Yakutsk</MenuItem>
                        <MenuItem value="Australia/Adelaide">(GMT+09:30) Adelaide</MenuItem>
                        <MenuItem value="Australia/Darwin">(GMT+09:30) Darwin</MenuItem>
                        <MenuItem value="Australia/Brisbane">(GMT+10:00) Brisbane</MenuItem>
                        <MenuItem value="Australia/Canberra">(GMT+10:00) Canberra, Melbourne, Sydney</MenuItem>
                        <MenuItem value="Australia/Hobart">(GMT+10:00) Hobart</MenuItem>
                        <MenuItem value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</MenuItem>
                        <MenuItem value="Asia/Vladivostok">(GMT+10:00) Vladivostok</MenuItem>
                        <MenuItem value="Asia/Magadan">(GMT+11:00) Magadan, Solomon Is., New Caledonia</MenuItem>
                        <MenuItem value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</MenuItem>
                        <MenuItem value="Pacific/Fiji">(GMT+12:00) Fiji, Kamchatka, Marshall Is.</MenuItem>
                        <MenuItem value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</MenuItem>
                    </TextField>
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Button type="submit" disabled={!isValid || isLoading} variant="outlined" >
                        <Typography color="black">Create</Typography>
                    </Button>
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Cancel</Typography>
                    </Button>
                    </DialogActions>

            </Grid>           
        </form>
    );
}