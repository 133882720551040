import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import LookupField from "../../ui/organisms/LookupField";
import { object, string, number, mixed } from 'yup';
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";

import { DialogActions } from "@mui/material/";
import { useParams } from 'react-router-dom';


const defaultValues = {

    customerOnboardingRedirectUrl: "",
    customerOnboardingExitUrl: "",
};

let merchantSchema = object({

    customerOnboardingRedirectUrl: string().nullable(),
    customerOnboardingExitUrl: string().nullable(),

});

export default function EditMerchantForm({
    handleClose,
    isLoading,
    recordData,
    handleEditRecord,
}) {
    const [formValues, setFormValues] = React.useState(recordData);
    const [initialValues, setInitialValues] = React.useState({});
    const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] =
        React.useState({});

    let navigate = useNavigateWithParams();
    let { merchantId } = useParams();

    const onSubmit = (data) => {
        data.preventDefault();
        debugger;
        merchantSchema
            .validate(formValues, { stripUnknown: true })
            .then((parsedFormValues) => {
                let updateRecord = {};
                for (const key in parsedFormValues) {
                    if (parsedFormValues[key] !== initialValues[key] && (typeof initialValues[key] !== 'object')) {
                        updateRecord[key] = parsedFormValues[key];
                    }
                    else if (typeof parsedFormValues[key] === 'object') {
                        if (parsedFormValues[key] === null && initialValues[key] !== null)
                            updateRecord[key] = parsedFormValues[key];
                        else if (parsedFormValues[key] !== null) {
                            if (parsedFormValues[key].id && initialValues[key].id && parsedFormValues[key].id !== initialValues[key].id)
                                updateRecord[key] = parsedFormValues[key];
                        }
                    }
                }

                return updateRecord;
            })
            .then((updateRecord) => {
                return handleEditRecord(merchantId, updateRecord);
            });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    React.useEffect(() => {
        if (recordData) {
            merchantSchema
                .validate(recordData, { stripUnknown: true })
                .then((merchant) => {
                    setFormValues(merchant);
                    setInitialValues(merchant);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [recordData]);


    return (
        <form onSubmit={onSubmit}>
            <Grid
                marginTop={2}
                spacing={2}
                container
                alignItems="left"
                justify="center"
                direction="row"
            >
                <Grid item xs={6}>
                    <LookupField                        
                        required
                        disabled={true}
                        searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}                        
                        id="connectorlookup"
                        type="connector"
                        name="connectorId"
                        label="Connector"
                        initialValue={formValues && formValues.connector}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        name="type"
                        label="Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.type}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Sandbox">Sandbox</MenuItem>
                        <MenuItem value="Live">Live</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        required
                        id="organisationId-input"
                        name="email"
                        label="Organisation id"
                        type="text"
                        size="small"
                        disabled={true}
                        value={formValues.organisationId}
                        onChange={handleInputChange}
                        sx={{ width: 200 }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        name="status"
                        label="Status"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.status}
                        onChange={handleInputChange}
                    >
                        <MenuItem value="Successful">Successful</MenuItem>
                        <MenuItem value="InReview">In Review</MenuItem>
                        <MenuItem value="ActionRequired">Action Required</MenuItem>

                    </TextField>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="email-input"
                        name="email"
                        label="Merchant email"
                        type="text"
                        size="small"
                        disabled={true}
                        value={formValues.email}
                        onChange={handleInputChange}
                        sx={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="onboardingUrl-input"
                        name="onboardingUrl"
                        label="Merchant onboarding Url"
                        type="text"
                        size="small"
                        disabled={true}
                        value={formValues.onboardingUrl}
                        onChange={handleInputChange}
                        sx={{ width: "100%" }}

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="customerOnboardingRedirectUrl-input"
                        name="customerOnboardingRedirectUrl"
                        label="Customer onboarding redirect Url"
                        type="text"
                        size="small"
                        disabled={false}
                        value={formValues.customerOnboardingRedirectUrl}
                        onChange={handleInputChange}
                        sx={{ width: "100%" }}

                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="customerOnboardingExitUrl-input"
                        name="customerOnboardingExitUrl"
                        label="Customer onboarding redirect exit Url"
                        type="text"
                        size="small"
                        disabled={false}
                        value={formValues.customerOnboardingExitUrl}
                        onChange={handleInputChange}
                        sx={{ width: "100%" }}
                    />
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Button type="submit" disabled={isLoading} variant="outlined" >
                        <Typography color="black">Update</Typography>
                    </Button>
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined">
                        <Typography color="black">Close</Typography>
                    </Button>
                </DialogActions>
            </Grid>           
        </form>
    );
}
