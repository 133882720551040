import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { List, Divider, Toolbar, IconButton } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import SidebarList from "../organisms/SidebarList";

// Left panel on page
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    scrollbarWidth: "thin",
    position: "relative",
    whiteSpace: "nowrap",
    width: theme.sidepanel.width.open,
    // transition: theme.transitions.create("width", {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.enteringScreen,
    // }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      // transition: theme.transitions.create("width", {
      //   easing: theme.transitions.easing.sharp,
      //   duration: theme.transitions.duration.leavingScreen,
      // }),
      width: theme.sidepanel.width.closed,
      [theme.breakpoints.down("sm")]: {
        width: theme.sidepanel.width.closed,
      },
    }),
  },
}));

export default function DrawerStyled({ open, onClickIcon }) {
  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        overflow: "visible",
        zIndex: 2,
        position: "-webkit-fixed",
        position: "fixed",
        top: "0",
        height: "100%",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      >
        <IconButton onClick={onClickIcon}>
          <ChevronLeft />
        </IconButton>
      </Toolbar>
      <Divider />
      <List style={{ overflow: "visible" }}>
        <SidebarList open={open} />
      </List>
    </Drawer>
  );
}
