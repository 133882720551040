import React from "react";

import {
  InputAdornment,
  FormControlLabel,
  Switch,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material/";

import { object, string, number, bool, date, mixed } from "yup";
import SearchSidePanel from "../../ui/organisms/SearchSidePanel";
import LookupPaper from "../../ui/organisms/LookupPaper";
import LookupField from "../../ui/organisms/LookupField";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";
import {
  parse,
  format,
  addDays,
  addMonths,
  isDate,
  parseJSON,
  isValid as isValidDate,
  startOfDay,
  differenceInDays,
  compareAsc,
} from "date-fns";

import { useParams } from "react-router-dom";

const defaultValues = {
  enabled: false,
  isAddon: false,
  isOneTimeFee: false,
  product: "",
  quantity: "",
  currentCycleStartDate: "",
  currentCycleEndDate: "",
  currentSalesCycle: "",
  billingPeriodSalesAmount: 0,
  billingPeriodSalesAmountAfterDiscount: 0,
  salesCurrency: "",
  salesUnit: "",
  reasonCode: "",
  salesPrice: "",
  discountAmount: 0,
  discountPercent: 0,
};

let entityReferenceSchema = object({
  id: string().required(),
  name: string().required(),
  connector: string(),
  secondaryKey: string().nullable(),
    extensionData: mixed(),
    url: string().nullable()
}).nullable();

function parseDateString(value, originalValue) {
  let parsedDate = isDate(originalValue)
    ? new Date(
        Date.UTC(
          originalValue.getUTCFullYear(),
          originalValue.getUTCMonth(),
          originalValue.getUTCDate(),
          originalValue.getUTCHours(),
          originalValue.getUTCMinutes(),
          originalValue.getUTCSeconds()
        )
      )
    : parseJSON(originalValue, "yyyy-MM-dd", new Date());

  if (!isValidDate(parsedDate)) {
    parsedDate = parse(originalValue, "yyyy-MM-dd", new Date());
  }

  return parsedDate;
}

const addBillingPeriodValueToDateTime = (
  currentDate,
  billingPeriodUnit,
  billingPeriodValue
) => {
  if (billingPeriodUnit === "Days")
    return addDays(currentDate, billingPeriodValue);
  if (billingPeriodUnit === "Months")
    return addMonths(currentDate, billingPeriodValue);

  return currentDate;
};

const calculateDiffInDays = (startDate, endDate) => {
  return differenceInDays(startOfDay(startDate), startOfDay(endDate)) + 1;
};

const calculateDaysInCycle = (
  contractDefinition,
  currentCycleStartDate,
  billingPeriodValue,
  billingPeriodUnit
) => {
  let endDate = calculateCurrentCycleEndDate(
    contractDefinition,
    currentCycleStartDate,
    billingPeriodValue,
    billingPeriodUnit
  );

  return calculateDiffInDays(
    endDate,
    parseDateString(null, currentCycleStartDate)
  );
};
const calculateTotalDaysInCycle = (
  contractDefinition,
  currentCycleStartDate,
  billingPeriodValue,
  billingPeriodUnit
) => {
  if (
    contractDefinition &&
    contractDefinition.globalStartDate &&
    contractDefinition.globalEndDate &&
    contractDefinition.fixedCycles
  ) {
    let cycleStartDate = parseDateString(
      null,
      contractDefinition.globalStartDate
    );
    let cycleEndDate = addDays(
      addBillingPeriodValueToDateTime(
        cycleStartDate,
        billingPeriodUnit,
        billingPeriodValue
      ),
      -1
    );

    for (var i = 0; i < contractDefinition.fixedCycles; i++) {
      if (
        startOfDay(parseDateString(currentCycleStartDate)) >=
          startOfDay(cycleStartDate) &&
        startOfDay(parseDateString(null, currentCycleStartDate)) <=
          startOfDay(cycleEndDate)
      ) {
        return calculateDiffInDays(cycleEndDate, cycleStartDate);
      }

      cycleStartDate = addDays(cycleEndDate, 1);
      cycleEndDate = addBillingPeriodValueToDateTime(
        cycleEndDate,
        billingPeriodUnit,
        billingPeriodValue
      );
    }

    return calculateDiffInDays(
      addDays(
        addBillingPeriodValueToDateTime(
          parseDateString(null, currentCycleStartDate),
          billingPeriodUnit,
          billingPeriodValue
        ),
        -1
      ),
      parseDateString(null, currentCycleStartDate)
    );
  }

  return 0;
};

const calculateCurrentCycleEndDate = (
  contractDefinition,
  currentCycleStartDate,
  billingPeriodValue,
  billingPeriodUnit
) => {
  if (
    contractDefinition &&
    contractDefinition.globalStartDate &&
    contractDefinition.globalEndDate &&
    contractDefinition.fixedCycles
  ) {
    let cycleStartDate = parseDateString(
      null,
      contractDefinition.globalStartDate
    );
    let cycleEndDate = addDays(
      addBillingPeriodValueToDateTime(
        cycleStartDate,
        billingPeriodUnit,
        billingPeriodValue
      ),
      -1
    );

    for (var i = 0; i < contractDefinition.fixedCycles; i++) {
      if (
        startOfDay(parseDateString(null, currentCycleStartDate)) >=
          startOfDay(cycleStartDate) &&
        startOfDay(parseDateString(null, currentCycleStartDate)) <=
          startOfDay(cycleEndDate)
      ) {
        return cycleEndDate;
      }

      cycleStartDate = addDays(cycleEndDate, 1);
      cycleEndDate = addBillingPeriodValueToDateTime(
        cycleEndDate,
        billingPeriodUnit,
        billingPeriodValue
      );
    }

    return addBillingPeriodValueToDateTime(
      parseDateString(null, currentCycleStartDate),
      billingPeriodUnit,
      billingPeriodValue
    );
  } else {
    return addDays(
      addBillingPeriodValueToDateTime(
        parseDateString(null, currentCycleStartDate),
        billingPeriodUnit,
        billingPeriodValue
      ),
      -1
    );
  }
};

const calculateBillingPeriodSalesAmount = (
  quantity,
  salesPrice,
  billingPeriodValue
) => {
  if (quantity && salesPrice && billingPeriodValue) {
    return quantity * salesPrice * billingPeriodValue;
  }

  return 0;
};

const calculateBillingPeriodSalesAmountAfterDiscount = (
  quantity,
  salesPrice,
  billingPeriodValue,
  discountAmount,
  discountPercent
) => {
  if (salesPrice && quantity && billingPeriodValue) {
    if (Number(discountAmount) > 0 && Number(discountPercent) > 0)
      return (
        (quantity * salesPrice - quantity * discountAmount) *
          billingPeriodValue -
        (Number(discountPercent) / 100) *
          ((quantity * salesPrice - quantity * Number(discountAmount)) *
            billingPeriodValue)
      );
    if (Number(discountPercent) > 0) {
      return (
        quantity * salesPrice * billingPeriodValue -
        (Number(discountPercent) / 100) *
          (quantity * salesPrice * billingPeriodValue)
      );
    }

    if (Number(discountAmount) > 0)
      return (
        quantity * salesPrice -
        quantity * Number(discountAmount) * billingPeriodValue
      );

    return calculateBillingPeriodSalesAmount(
      quantity,
      salesPrice,
      billingPeriodValue
    );
  }

  return 0;
};

const RenderCheckboxForAddon = ({ value }) => {
  return <Checkbox sx={{ width: "100%" }} disabled checked={value} />;
};

const RenderCheckboxForOneTimeFee = ({ value }) => {
  return <Checkbox sx={{ width: "100%" }} disabled checked={value} />;
};

let subscriptionPlanLineSchema = object({
  enabled: bool(),
  isAddon: bool(),
  isOneTimeFee: bool(),
  product: entityReferenceSchema,
  quantity: string(),
  currentCycleStartDate: date().transform(parseDateString),
  currentCycleEndDate: date().transform(parseDateString),
  salesCurrency: entityReferenceSchema,
  salesUnit: entityReferenceSchema,
  reasonCode: entityReferenceSchema,
    salesPrice: string()
        .matches(/^[0-9]*(\.[0-9]{0,2})?$/,'Sales Price should have only two decimal places.')
        .required("Sales Price is required."),
  discountAmount: number(),
  discountPercent: number(),
  billingPeriodSalesAmount: number(),
  billingPeriodSalesAmountAfterDiscount: number(),
});

export default function EditSubscriptionPlanLineForm({
  handleClose,
  isLoading,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  handleListConnectors,
  activeSubscriptionPlan,
  recordData,
  handleEditRecord,
  readyOnlyForm,
}) {
  const [formValues, setFormValues] = React.useState(defaultValues);  

  const [isValid, setIsValid] = React.useState(false);
  const [errors, setErrors] = React.useState({});
  const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
  const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
  const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
  const [columns, setColumns] = React.useState([]);
  const [searchSidePanelType, setSearchSidePanelType] = React.useState("");
  const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] =
    React.useState({});
  const [selectedLookupId, setSelectedLookupId] = React.useState(null);

  const [connectors, setConnectors] = React.useState([]);
  const [defaultConnector, setDefaultConnector] = React.useState(null);
  const [displayConnectorSelect, setDisplayConnectorSelect] =
    React.useState(true);

  const [initialValues, setInitialValues] = React.useState({});
  let { subscriptionPlanId, subscriptionPlanLineId } = useParams();

    let navigate = useNavigateWithParams();

  const onSubmit = (data) => {
    data.preventDefault();

    subscriptionPlanLineSchema
      .validate(formValues, { stripUnknown: true })
      .then((parsedFormValues) => {
        let updateRecord = {};

        for (const key in parsedFormValues) {
          if (
            parsedFormValues[key] !== initialValues[key] &&
            typeof initialValues[key] !== "object"
          ) {
            updateRecord[key] = parsedFormValues[key];
          } else if (typeof parsedFormValues[key] === "object") {
            if (parsedFormValues[key] === null && initialValues[key] !== null)
              updateRecord[key] = parsedFormValues[key];
            else if (parsedFormValues[key] !== null) {
              if (isDate(parsedFormValues[key]) && isDate(initialValues[key])) {
                //0 means same dates
                if (
                  compareAsc(
                    new Date(parsedFormValues[key].getTime()).setHours(
                      0,
                      0,
                      0,
                      0
                    ),
                    new Date(initialValues[key].getTime()).setHours(0, 0, 0, 0)
                  ) !== 0
                ) {
                  updateRecord[key] = parsedFormValues[key].toISOString();
                }
              } else if (
                parsedFormValues[key].id &&
                initialValues[key].id &&
                parsedFormValues[key].id !== initialValues[key].id
              )
                updateRecord[key] = parsedFormValues[key];
            }
          }
        }

        return updateRecord;
      })
      .then((updateRecord) => {
        return handleEditRecord(
          subscriptionPlanId,
          subscriptionPlanLineId,
          updateRecord
        );
      });
  };

  React.useEffect(() => {
    handleListConnectors()
      .then((connectors) => {
        setConnectors(connectors);
        if (
          !activeSubscriptionPlan ||
          !activeSubscriptionPlan.connector ||
          !activeSubscriptionPlan.connector.id
        ) {
          setDefaultConnector(null);
        } else {
          let foundConnector = connectors.find((connector) => {
            if (connector && connector.company && connector.company.id)
              return connector.id === activeSubscriptionPlan.connector.id;

            return false;
          });

          if (foundConnector) setDefaultConnector(foundConnector);
          else setDefaultConnector(null);
        }
      })
      .catch((e) => setConnectors([]));
  }, []);
    React.useEffect(() => {
    if (recordData) {
      subscriptionPlanLineSchema
        .validate(recordData, { stripUnknown: true })
        .then((subscriptionPlanLine) => {
          setFormValues(subscriptionPlanLine);
          setInitialValues(subscriptionPlanLine);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [recordData]);

  React.useEffect(() => {
      var subscriptionPlanLineParsed = subscriptionPlanLineSchema
          .validate(formValues, { abortEarly: false })
          .then(() => setIsValid(true))
      .catch((e) => {
        const currentError = e.inner.reduce((acc, value) => {
          acc[value.path] = {
            message: value.message,
            type: value.type,
          };
          return acc;
        }, {});
          setErrors(value => ({ ...value, ...currentError }));

        setIsValid(false);
      });
  }, [formValues]);

  React.useEffect(() => {
    if (activeSubscriptionPlan && activeSubscriptionPlan.billingPeriodValue) {
      let totalDaysInCycle;
      let countDaysInCycle;
      let currentEndDate = formValues.currentCycleEndDate;
      const contractDefinition = activeSubscriptionPlan.contractDefinition;

      if (formValues.currentCycleStartDate) {
        currentEndDate = calculateCurrentCycleEndDate(
          activeSubscriptionPlan.contractDefinition,
          formValues.currentCycleStartDate,
          activeSubscriptionPlan.billingPeriodValue,
          activeSubscriptionPlan.billingPeriodUnit
        );

        totalDaysInCycle = calculateTotalDaysInCycle(
          activeSubscriptionPlan.contractDefinition,
          formValues.currentCycleStartDate,
          activeSubscriptionPlan.billingPeriodValue,
          activeSubscriptionPlan.billingPeriodUnit
        );
        countDaysInCycle = calculateDaysInCycle(
          activeSubscriptionPlan.contractDefinition,
          formValues.currentCycleStartDate,
          activeSubscriptionPlan.billingPeriodValue,
          activeSubscriptionPlan.billingPeriodUnit
        );
      }

      let billingPeriodSalesAmount = calculateBillingPeriodSalesAmount(
        formValues.quantity,
        formValues.salesPrice,
        activeSubscriptionPlan.billingPeriodValue
      );
      let billingPeriodSalesAmountAfterDiscount =
        calculateBillingPeriodSalesAmountAfterDiscount(
          formValues.quantity,
          formValues.salesPrice,
          activeSubscriptionPlan.billingPeriodValue,
          formValues.discountAmount,
          formValues.discountPercent
        );

      if (
        contractDefinition &&
        contractDefinition.globalStartDate &&
        contractDefinition.globalEndDate &&
        contractDefinition.fixedCycles &&
        formValues.currentCycleStartDate &&
        totalDaysInCycle !== countDaysInCycle
      ) {
        billingPeriodSalesAmount =
          billingPeriodSalesAmount * (countDaysInCycle / totalDaysInCycle);
        billingPeriodSalesAmountAfterDiscount =
          billingPeriodSalesAmountAfterDiscount *
          (countDaysInCycle / totalDaysInCycle);
      }

      let newFormValues = {
        ...formValues,
        billingPeriodSalesAmount: billingPeriodSalesAmount,
        billingPeriodSalesAmountAfterDiscount:
          billingPeriodSalesAmountAfterDiscount,
        CurrentCycleEndDate:
          currentEndDate && isValidDate(currentEndDate) ? currentEndDate : "",
      };

      setFormValues(newFormValues);
    }
  }, [
    activeSubscriptionPlan,
    formValues.quantity,
    formValues.salesPrice,
    formValues.discountAmount,
    formValues.discountPercent,
    formValues.currentCycleStartDate,
  ]);

  const handleInputChange = (e) => {
      let { name, value } = e.target;
    
    if (name === "currentCycleStartDate" && value !== "") {
      value = parse(value, "yyyy-MM-dd", new Date());
      value.setHours(new Date().getHours());
      value.setMinutes(new Date().getMinutes());
    }

    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }
    let newFormValues = {
      ...formValues,
      [name]: value,
      };

    if (name === "Product") {
      if (value && value.extensionData) {
        //if (value.extensionData.IsAddon !== undefined) {
        //  newFormValues["IsAddon"] =
        //    value.extensionData.IsAddon.toLowerCase() === "true" ? true : false;
        //}
        if (value.extensionData.IsOneTimeFee !== undefined) {
          newFormValues["IsOneTimeFee"] =
            value.extensionData.IsOneTimeFee.toLowerCase() === "true"
              ? true
              : false;
        }
        if (
          activeSubscriptionPlan &&
          activeSubscriptionPlan.billingPeriodUnit
        ) {
          if (activeSubscriptionPlan.billingPeriodUnit === "Days") {
            if (value.extensionData.PricePerDay !== undefined) {
              newFormValues["SalesPrice"] = value.extensionData.PricePerDay;
            }
          }
          if (activeSubscriptionPlan.billingPeriodUnit === "Months") {
            if (value.extensionData.PricePerMonth !== undefined) {
              newFormValues["SalesPrice"] = value.extensionData.PricePerMonth;
            }
          }
        }
      }
    }

      setFormValues(newFormValues);
    };

  return (
      <form onSubmit={onSubmit}>
      <Grid
        marginTop={2}
        spacing={2}
        container
        alignItems="left"
        justify="center"
        direction="row"
      >
        <Grid item xs={6}>
          <FormControlLabel
            labelPlacement="start"
            sx={{ width: 200 }}
            control={
              <Switch
                id="enabled-input"
                name="enabled"
                label="Enabled?"
                size="big"
                checked={formValues.enabled}
                onChange={handleInputChange}
                disabled={isLoading || readyOnlyForm === true}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Enabled?"
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(true);
              if (defaultConnector)
                query = { ...query, connectorId: defaultConnector.id };

              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("productlookup");
              setSearchSidePanelTitle("Search Products");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("product");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
                //{
                //  field: "IsAddon",
                //  headerName: "Is addon?",
                //  flex: 2,
                //  minWidth: 100,
                //  renderCell: RenderCheckboxForAddon,
                //  valueGetter: function (params) {
                //    if (params && params.row && params.row.extensionData) {
                //      try {
                //        return String(
                //          params.row.extensionData.IsAddon
                //        ).toLowerCase() === "true"
                //          ? true
                //          : false;
                //      } catch (e) {}
                //      return "";
                //    }
                //  },
                //},
                {
                  field: "IsOneTimeFee",
                  headerName: "One time fee?",
                  flex: 2,
                  minWidth: 100,
                  renderCell: RenderCheckboxForOneTimeFee,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return String(
                          params.row.extensionData.IsOneTimeFee
                        ).toLowerCase() === "true"
                          ? true
                          : false;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
              ]);
            }}
            required
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="productlookup"
            type="product"
            name="product"
            label="Product"
            disabled={isLoading || readyOnlyForm === true}
            initialValue={formValues && formValues.product}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            sx={{ width: 200 }}
            labelPlacement="start"
            control={
              <Switch
                id="isaddon-input"
                name="isAddon"
                label="Is addon?"
                size="big"
                checked={formValues.isAddon}
                onChange={handleInputChange}
                disabled={isLoading || readyOnlyForm === true}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Is addon?"
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            id="quantity-input"
            name="quantity"
            label="Quantity"
            type="number"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={isLoading || readyOnlyForm === true}
            onChange={handleInputChange}
            value={formValues && formValues.quantity}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            labelPlacement="start"
            sx={{ width: 200 }}
            control={
              <Switch
                id="isOneTimeFee-input"
                name="isOneTimeFee"
                label="Is one time fee?"
                size="big"
                disabled={isLoading || readyOnlyForm === true}
                checked={formValues.isOneTimeFee}
                onChange={handleInputChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Is one time fee?"
          />
        </Grid>
        <Grid item xs={6}>
           <TextField                      
               id="salesPrice-input"
               name="salesPrice"
               label="Sales Price"
               type="number"
               size="small"
               sx={{ width: 200 }}
               required
               disabled={isLoading || readyOnlyForm === true}
               value={formValues && formValues.salesPrice}
               onChange={handleInputChange}
               InputProps={{
                    startAdornment: (
                    <InputAdornment position="start">&#8364;</InputAdornment>
                   ),
               }}
               error={!isValid && errors.hasOwnProperty("salesPrice") && errors["salesPrice"]}
               helperText={!isValid && errors["salesPrice"]?.message}
           />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="DiscountAmount-input"
            name="discountAmount"
            label="Discount Amount"
            type="number"
            size="small"
            sx={{ width: 200 }}
            disabled={isLoading || readyOnlyForm === true}
            value={formValues && formValues.discountAmount}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&#8364;</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="DiscountPercent-input"
            name="discountPercent"
            label="Discount Percent"
            type="number"
            size="small"
            disabled={isLoading || readyOnlyForm === true}
            sx={{ width: 200 }}
            value={formValues && formValues.discountPercent}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="billingPeriodSalesAmount-input"
            name="billingPeriodSalesAmount"
            label="Billing Period Sales Amount"
            type="number"
            size="small"
            sx={{ width: 200 }}
            disabled={true}
            value={formValues && formValues.billingPeriodSalesAmount}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&#8364;</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="billingPeriodSalesAmountAfterDiscount-input"
            name="billingPeriodSalesAmountAfterDiscount"
            label="Billing Period Sales Amount After Discount"
            type="number"
            size="small"
            sx={{ width: 200 }}
            disabled={true}
            value={
              formValues && formValues.billingPeriodSalesAmountAfterDiscount
            }
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">&#8364;</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="CurrentCycleStartDate-input"
            name="currentCycleStartDate"
            label="Current Cycle Start Date"
            type="date"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={isLoading || readyOnlyForm === true}
            onChange={handleInputChange}
            value={
              formValues.currentCycleStartDate
                ? format(formValues.currentCycleStartDate, "yyyy-MM-dd")
                : ""
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="CurrentCycleEndDate-input"
            name="currentCycleEndDate"
            label="Current Cycle End Date"
            type="date"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={true}
            onChange={handleInputChange}
            value={
              formValues.currentCycleEndDate
                ? format(formValues.currentCycleEndDate, "yyyy-MM-dd")
                : ""
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(true);
              if (defaultConnector)
                query = { ...query, connectorId: defaultConnector.id };

              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("currencylookup");
              setSearchSidePanelTitle("Search Currencies");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("currency");
              setColumns([
                {
                  field: "Symbol",
                  headerName: "Symbol",
                  flex: 1,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return params.row.extensionData.Symbol;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
                {
                  field: "Code",
                  headerName: "Code",
                  flex: 1,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return params.row.extensionData.Code;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading || readyOnlyForm === true}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="currencylookup"
            type="currency"
            name="salesCurrency"
            label="Sales Currency"
            initialValue={formValues && formValues.salesCurrency}
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(true);
              if (defaultConnector)
                query = { ...query, connectorId: defaultConnector.id };

              handleListRecords(type, query);

              setSearchSidePanelOpen(true);
              setSelectedLookupId("unitlookup");
              setSearchSidePanelTitle("Search Units");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("unit");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading || readyOnlyForm === true}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="unitlookup"
            type="unit"
            name="salesUnit"
            label="Sales Unit"
            initialValue={formValues && formValues.salesUnit}
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(false);

              handleListRecords(type, { ...query, type: "new" });
              setSearchSidePanelOpen(true);
              setSelectedLookupId("reasoncodelookup");
              setSearchSidePanelTitle("Search Reason Codes");
              setSearchSidePanelQuery({ ...query, type: "new" });
              setSearchSidePanelType("reasoncode");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading || readyOnlyForm === true}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="reasoncodelookup"
            type="reasoncode"
            name="reasonCode"
            label="Reason Code"
            initialValue={formValues && formValues.reasonCode}
                  />
        </Grid>
              <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
          {readyOnlyForm === false && (
            <Button
              type="submit"
              disabled={!isValid || isLoading}
              variant="outlined"
            >
            <Typography color="black">Update</Typography>
            </Button>
          )}
          <Button onClick={handleClose} disabled={isLoading} variant="outlined">
            {readyOnlyForm === false ? (
              <Typography color="black">Cancel</Typography>
            ) : (
              <Typography color="black">Close</Typography>
            )}
          </Button>
        </DialogActions>
      </Grid>
      <SearchSidePanel
        open={searchSidePanelOpen}
        handleClose={() => setSearchSidePanelOpen(false)}
        isLoading={isLoadingSearchSidePanel}
        title={searchSidePanelTitle}
      >
        <LookupPaper
          handleClose={() => setSearchSidePanelOpen(false)}
          selectedLookupId={selectedLookupId}
          handleChangeLookupValue={setSearchSidePanelSelectedLookup}
          isLoading={isLoadingSearchSidePanel}
          query={searchSidePanelQuery}
          data={searchSidePanelData}
          columns={columns}
          searchSidePanelType={searchSidePanelType}
          connectors={connectors}
          defaultConnector={defaultConnector}
          displayConnectorSelect={displayConnectorSelect}
          handleListRecords={handleListRecords}
        />
      </SearchSidePanel>
    </form>
  );
}
