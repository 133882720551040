import React from "react";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string } from "yup";
import SearchSidePanel from "../../ui/organisms/SearchSidePanel";
import LookupPaper from "../../ui/organisms/LookupPaper";
import LookupField from "../../ui/organisms/LookupField";
import MicrosoftLoginButton from "../../ui/molecules/MicrosoftLoginButton";
import { DialogActions } from "@mui/material/";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "@mui/material/CircularProgress";
import PromiseRetry from "promise-retry";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";

import UserContext from "../../UserContext";

const StyledTypography = styled(Typography)({
    //wordBreak: "break-word",
    textAlign: "right",
    color: "white",
    fontWeight: "800",
    fontFamily: "Paralucent,Mulish,Muli,sans-serif",
});
  
const defaultValues = {
  company: "",
  environment: "",
  emailNotificationEndpointUrl: "",
};

let entityReferenceSchema = object({
  id: string().required(),
  name: string().required(),
  connector: string(),
  secondaryKey: string(),
  extensionData: object().typeError("Wrong Data format from Environment"),
});

let entityReferenceEnvironmentSchema = object({
  id: string().required(),
  name: string().required(),
  connector: string(),
  secondaryKey: string(),
  extensionData: object({
    aadTenantId: string().required("Tenant missing on environment"),
    webServiceUrl: string().required("WebService missing on environment"),
    applicationUrl: string().required("Application Url missing on environment"),
  }).typeError("Wrong Data format from Environment"),
});

let connectorSchema = object({
  company: entityReferenceSchema,
  environment: entityReferenceEnvironmentSchema,
});

const consentUrl = process.env.REACT_APP_CONSENT_URL ?? "missing-consent-url";

export default function NewConnectorForm({
  handleCreateRecord,
  handleAddRemainingDetails,
  handleInitialRecord,
  handleClose,
  isLoading,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  handleFetchRecord,
}) {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [initialConnectorId, setInitialConnectorId] = React.useState(null);

  const [isAccountEnabled, setIsAccountEnabled] = React.useState(false);

  const [isSignInEnabled, setIsSignInEnabled] = React.useState(true);
  const [loadingAuthentication, setLoadingAuthentication] =
    React.useState(false);

  const [isFormEnabled, setIsFormEnabled] = React.useState(false);

  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState({});
  const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
  const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
  const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
  const [columns, setColumns] = React.useState([]);
  const [searchSidePanelType, setSearchSidePanelType] = React.useState("");

  const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] =
    React.useState({});
  const [selectedLookupId, setSelectedLookupId] = React.useState(null);
  const timer = React.useRef();
  const opener = React.useRef();

  const userContext = React.useContext(UserContext);

  const navigate = useNavigateWithParams();

  const onSubmit = (data) => {
    data.preventDefault();
    const parsedEnvironmentExtensionData = formValues.environment.extensionData;

    const connectorRecord = {
      WebServiceUrl: parsedEnvironmentExtensionData.webServiceUrl,
      ApplicationUrl: parsedEnvironmentExtensionData.applicationUrl,
      ConnectorTenantId: parsedEnvironmentExtensionData.aadTenantId,
      Environment: formValues.environment.name,
      Company: formValues.company,
      EmailNotificationEndpointUrl: formValues.emailNotificationEndpointUrl,
    };

    handleCreateRecord(initialConnectorId, connectorRecord);
  };

  React.useEffect(() => {
    var connectorParsed = connectorSchema
      .validate(formValues, { abortEarly: false })
      .then(() => setIsValid(true))
      .catch((e) => {
        const errors = e.inner.reduce((acc, value) => {
          acc[value.path] = {
            message: value.message,
            type: value.type,
          };
          return acc;
        }, {});
        setError(errors);
        setIsValid(false);
      });
  }, [formValues]);

  React.useEffect(() => {
    if (initialConnectorId !== null) {
      handleInitialRecord(initialConnectorId)
        .then((data) => {
          opener.current = null;
          setLoadingAuthentication(true);
          //TODO signalR to send this response back instead of us pooling
          PromiseRetry(
            function (retry) {
              return handleFetchRecord(initialConnectorId)
                .then((data) => {
                  if (data.status === "Authenticated") return true;

                  throw new Error("Not authenticated yet");
                })
                .catch(retry);
            },
            { maxTimeout: 3000, retries: 30 }
          )
            .then(() => {
              setLoadingAuthentication(false);
              setIsFormEnabled(true);
            })
            .catch(() => {
              //TODO cleanup promise in case navigates to another page
            });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [initialConnectorId]);

  React.useEffect(() => {
    if (
      initialConnectorId !== null &&
      formValues.environment &&
      formValues.environment.extensionData
    ) {
      const parsedEnvironmentExtensionData =
        formValues.environment.extensionData;

      const connectorRecord = {
        WebServiceUrl: parsedEnvironmentExtensionData.webServiceUrl,
        ApplicationUrl: parsedEnvironmentExtensionData.applicationUrl,
        ConnectorTenantId: parsedEnvironmentExtensionData.aadTenantId,
        Environment: formValues.environment.name,
      };

      handleAddRemainingDetails(initialConnectorId, connectorRecord)
        .then(() => {
          setIsAccountEnabled(true);
        })
        .catch(() => {
          setIsAccountEnabled(false);
        });
    }
  }, [formValues.environment]);

  React.useEffect(() => {
    return () => {
      clearInterval(timer.current);
    };
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const openAuthenticateWindow = () => {
    setIsSignInEnabled(false);

    var connectorId = initialConnectorId || uuidv4();
    let fullURL = `${consentUrl}?id=${connectorId}&envId=${userContext.activeEnvironment}&tId=${userContext.tenant}`;
    opener.current = window.open(
      fullURL,
      "_blank",
      "popup=1,width=500,height=500"
    );

    timer.current = setInterval(function () {
      if (opener && opener.current && opener.current.closed) {
        clearInterval(timer);
        setInitialConnectorId(connectorId);
      }
    }, 1000);
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid
        marginTop={2}
        spacing={2}
        container
        alignItems="left"
        justify="center"
        direction="column"
      >
        <Grid item>
          <Box sx={{ position: "relative" }}>
            {!isFormEnabled && (
              <MicrosoftLoginButton
                buttonTheme="light"
                enabled={isSignInEnabled}
                onClick={openAuthenticateWindow}
              />
            )}
            {loadingAuthentication && (
              <CircularProgress
                sx={{ position: "absolute", top: 0, left: "15%" }}
              />
            )}
          </Box>
        </Grid>
        <Grid item>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              query.connectorId = initialConnectorId;
              handleListRecords(type, {
                ...query,
                isSandbox: userContext.isSandbox,
              });
              setSearchSidePanelOpen(true);
              setSelectedLookupId("environmentlookup");
              setSearchSidePanelTitle("Search Environments");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("environment");
              setColumns([
                {
                  field: "name",
                  headerName: "Environment",
                  flex: 2,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.name) {
                      return params.row.name;

                      return "";
                    }
                  },
                },
                {
                  field: "aadTenantId",
                  headerName: "Tenant",
                  flex: 2,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return params.row.extensionData.aadTenantId;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
                {
                  field: "webServiceUrl",
                  headerName: "WebServiceUrl",
                  flex: 2,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return params.row.extensionData.webServiceUrl;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
              ]);
            }}
            required
            disabled={isLoading || !isFormEnabled}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="environmentlookup"
            type="environment"
            name="environment"
            label="Environment"
          />
        </Grid>
        <Grid item>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              query.connectorId = initialConnectorId;
              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("companylookup");
              setSearchSidePanelTitle("Search Companies");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("company");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading || !isFormEnabled || !isAccountEnabled}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="companylookup"
            type="company"
            name="company"
            label="Company"
          />
        </Grid>
        <Grid item>
          <TextField
            sx={{ width: "200px" }}
            id="emailNotificationEndpointUrl-input"
            name="emailNotificationEndpointUrl"
            label="Email Notification Endpoint URL"
            type="text"
            size="small"
            disabled={isLoading}
            value={formValues.emailNotificationEndpointUrl}
            onChange={handleInputChange}
          />
        </Grid>
        <DialogActions sx={{ position: "absolute", bottom: 60, right: 0 }}>
          <Button
            type="submit"
            disabled={!isValid || isLoading}
            variant="outlined"
          >
            <Typography color="black">Create</Typography>
          </Button>
          <Button onClick={handleClose} disabled={isLoading} variant="outlined">
            <Typography color="black">Cancel</Typography>
          </Button>
        </DialogActions>
        <Box
          sx={{
            backgroundColor: "#1976d2",
            width: "100%",
            paddingTop: "10px",
            paddingBottom: "10px",
            textAlign: "right",
            position: "absolute",
            bottom: 0,
            right: 0,
          }}
        >
          <StyledTypography
            sx={{ color: "white", marginRight: "22px" }}
            variant="span"
          >
            Using HARP with Dynamics 365 Business Central? <br />
          </StyledTypography>
          <StyledTypography
            sx={{ color: "white", marginRight: "22px" }}
            variant="span"
          >
            Please{" "}
            <a
              style={{ color: "white", textAlign: "right" }}
              href="https://bluefort-academy.thinkific.com/courses/take/lisa-business-subscription-automation-for-microsoft-dynamics-365-business-central"
            >
              Click Here
            </a>{" "}
            for the documentation.
          </StyledTypography>
        </Box>
      </Grid>
      <SearchSidePanel
        open={searchSidePanelOpen}
        handleClose={() => setSearchSidePanelOpen(false)}
        isLoading={isLoadingSearchSidePanel}
        title={searchSidePanelTitle}
      >
        <LookupPaper
          handleClose={() => setSearchSidePanelOpen(false)}
          selectedLookupId={selectedLookupId}
          handleChangeLookupValue={setSearchSidePanelSelectedLookup}
          isLoading={isLoadingSearchSidePanel}
          query={searchSidePanelQuery}
          data={searchSidePanelData}
          columns={columns}
          searchSidePanelType={searchSidePanelType}
          handleListRecords={handleListRecords}
        />
      </SearchSidePanel>
    </form>
  );
}
