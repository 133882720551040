import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import AvatarIcon from '../molecules/AvatarIcon';
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { msalProviderPopupConfig } from "../../authentication/authConfig";

export default function ProfileCard({ embeddedMode}) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);



    const handleClick = (event) => {
        
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const { instance, accounts } = useMsal();

    //TODO test for header, add after an interceptor on axios
    //useEffect(() => {
    //    if (useIsAuthenticated()) {
    //        const headers = new Headers();
    //        const bearer = `Bearer ${accessToken}`;

    //        headers.append("Authorization", bearer);

    //        const options = {
    //            method: "GET",
    //            headers: headers
    //        };

    //        Axios("https://localhost:5001/api/User", options)
    //    }
    //}, [useIsAuthenticated()])
    
    const username = accounts && accounts.length > 0 ? accounts[0].name : "";
    //TODO: error handle on ClientAuthError
    //TODO: transform menu items into molecules
    return (
        <React.Fragment>
            <Box sx={embeddedMode ? { marginLeft: 'auto' } : { display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <UnauthenticatedTemplate>
                    <Button color="inherit" onClick={() => instance.loginPopup(msalProviderPopupConfig.loginRequestConfig)}>Sign In</Button>
                </UnauthenticatedTemplate>
                <AuthenticatedTemplate>
                    <AvatarIcon  tooltipTitle="Account settings" handleClick={handleClick} menuId="account-menu" anchorEl={anchorEl}
                        username={username}
                    />
                </AuthenticatedTemplate>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem>
                    <Avatar /> <div style={{ display: "flex", flexDirection: 'column' }}>
                        {accounts && accounts.length > 0 && accounts[0].name}
                        <Typography variant="caption">
                        ({accounts && accounts.length > 0 && accounts[0].username})
                    </Typography></div>                    
                </MenuItem>               
                <Divider />
                <MenuItem onClick={() => instance.loginPopup({ prompt: 'select_account'})}>
                    <ListItemIcon>
                        <PersonAdd fontSize="small" />
                    </ListItemIcon>
                    Sign in with a different account
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={() => instance.logoutPopup()}>
                    <ListItemIcon >
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}