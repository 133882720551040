import * as React from 'react';
import RibbonButton from './RibbonButton';
import { Typography } from '@mui/material/';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
export default function DeactivateUserRibbonButton(props) {
    return (
        <RibbonButton {...props} startIcon={<PersonRemoveAlt1Icon sx={{ color: "black" }} />}>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonButton>
    );
}