import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SelectField from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string } from 'yup';
import { DialogActions } from '@mui/material/';
import LookupPaper from '../../ui/organisms/LookupPaper';
import SearchSidePanel from '../../ui/organisms/SearchSidePanel';

const defaultValues = {
    name: "",
    periodType: "Rolling",
    subscriptionType: "PerUnitSubscription"
};

let programTypeSchema = object({
    name: string().required(),
    periodType: string().required().oneOf(["Calendar","Rolling"]),
    subscriptionType: string().required().oneOf(["PerpetualEnhancementPlan", "PerUnitSubscription","Consumption"])
});

export default function NewProgramTypeForm({ handleCreateRecord, handleClose, isLoading, handleListRecords, isLoadingSearchSidePanel, searchSidePanelData }) {
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});

    const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
    const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
    const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
    const [columns, setColumns] = React.useState([]);
    const [searchSidePanelType, setSearchSidePanelType] = React.useState("");
    const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] = React.useState({});
    const [selectedLookupId, setSelectedLookupId] = React.useState(null);

    const onSubmit = data => {
        data.preventDefault();
        handleCreateRecord(formValues);
    }

    React.useEffect(() => {
        var programTypeParsed = programTypeSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = value.message;
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <form onSubmit={onSubmit}>

            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="column">

                <Grid item>
                    <TextField
                        id="name-input"
                        name="name"
                        label="Name"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}

                        required
                        disabled={isLoading}
                        value={formValues.name}
                        onChange={handleInputChange}
                    />
                </Grid>               
                <Grid item>
                    <TextField
                        select
                        id="periodType-input"
                        name="periodType"
                        label="Period Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading}
                        value={formValues.periodType || ""}
                        onChange={handleInputChange}
                    >
                        {/*<MenuItem value="Calendar">Calendar</MenuItem>*/}
                        <MenuItem value="Rolling">Rolling</MenuItem>
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField
                        select
                        name="subscriptionType"
                        label="Subscription Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={isLoading}
                        value={formValues.subscriptionType || ""}
                        onChange={handleInputChange}
                    >                        
                        {/*<MenuItem value="PerpetualEnhancementPlan">Perpetual Enhancement Plan</MenuItem>*/}
                        {/*<MenuItem value="Consumption">Consumption</MenuItem>*/}
                        <MenuItem value="PerUnitSubscription">Per Unit Subscription</MenuItem>
                    </TextField>
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Button type="submit" disabled={!isValid || isLoading} variant="outlined" >
                        <Typography color="black">Create</Typography>
                    </Button>
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Cancel</Typography>
                    </Button>
                    </DialogActions>

            </Grid>
            <SearchSidePanel open={searchSidePanelOpen}
                handleClose={() => setSearchSidePanelOpen(false)}
                isLoading={isLoadingSearchSidePanel}
                title={searchSidePanelTitle}>
                <LookupPaper
                    handleClose={() => setSearchSidePanelOpen(false)}
                    selectedLookupId={selectedLookupId}
                    handleChangeLookupValue={setSearchSidePanelSelectedLookup}
                    isLoading={isLoadingSearchSidePanel}
                    query={searchSidePanelQuery}
                    data={searchSidePanelData}
                    columns={columns}
                    searchSidePanelType={searchSidePanelType}
                    handleListRecords={handleListRecords} />
            </SearchSidePanel>
        </form>
    );
}