import * as React from 'react';
import RibbonLoadingButton from './RibbonLoadingButton';
import { Typography } from '@mui/material/';
import RequestPageIcon from '@mui/icons-material/RequestPage';

export default function CreditNoteRibbonButton(props) {
    return (
        <RibbonLoadingButton {...props}
            startIcon={
                <RequestPageIcon sx={{ color: "black" }} />
            }>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonLoadingButton>
    );
}