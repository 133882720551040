import * as React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function avatarNameSplit(username) {
  // The below check is a bit extra, since we already are
  // defining the username to be a string in the propTypes
  //   if (typeof username !== "string" || ! username instanceof String)
  //     return '';

  if (username.length > 0)
    return `${username.split(" ")[0][0]}${username.split(" ")[1][0]}`;
}

export default function AvatarIcon({
  tooltipTitle,
  username,
  handleClick,
  anchorEl,
  menuId,
}) {
  const open = Boolean(anchorEl);

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        onClick={handleClick}
        size="small"
        aria-controls={open ? menuId : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          sx={{ width: 40, height: 40, bgcolor: stringToColor(username) }}
        >
          {avatarNameSplit(username)}
        </Avatar>
      </IconButton>
    </Tooltip>
  );
}

AvatarIcon.propTypes = {
  tooltipTitle: PropTypes.string,
  username: PropTypes.string,
  handleClick: PropTypes.func,
  anchorEl: PropTypes.object,
  menuId: PropTypes.string,
};
AvatarIcon.defaultProps = {
  tooltipTitle: "",
  username: "",
  menuId: undefined,
  anchorEl: undefined,
  handleClick: () => {},
};
