import React from "react";

import {
  InputAdornment,
  FormControlLabel,
  Switch,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
  Checkbox,
} from "@mui/material/";

import { object, string, number, bool, date, mixed } from "yup";
import SearchSidePanel from "../../ui/organisms/SearchSidePanel";
import LookupPaper from "../../ui/organisms/LookupPaper";
import LookupField from "../../ui/organisms/LookupField";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";
import {
  parse,
  format,
  addDays,
  addMonths,
  isDate,
  parseJSON,
  isValid as isValidDate,
  startOfDay,
  differenceInDays,
} from "date-fns";

const RenderCheckboxForAddon = ({ value }) => {
  return <Checkbox sx={{ width: "100%" }} disabled checked={value} />;
};

const RenderCheckboxForOneTimeFee = ({ value }) => {
  return <Checkbox sx={{ width: "100%" }} disabled checked={value} />;
};

const defaultValues = {
  Enabled: true,
  IsAddon: false,
  IsOneTimeFee: false,
  Product: "",
  Quantity: "",
  CurrentCycleStartDate: "",
  CurrentCycleEndDate: "",
  billingPeriodSalesAmount: 0,
  billingPeriodSalesAmountAfterDiscount: 0,
  SalesCurrency: "",
  SalesUnit: "",
  ReasonCode: "",
  SalesPrice: "",
  DiscountAmount: 0,
  DiscountPercent: 0,
};

let entityReferenceSchema = object({
  id: string().required(),
  name: string().required(),
  connector: string(),
  secondaryKey: string(),
  extensionData: mixed(),
  url: string().nullable(),
});

let subscriptionPlanLineSchema = object({
  Enabled: bool().required(),
  IsAddon: bool().required(),
  IsOneTimeFee: bool().required(),
  Product: entityReferenceSchema,
  Quantity: string().required(),
  CurrentCycleStartDate: date().required(),
  CurrentCycleEndDate: date(),
  SalesCurrency: entityReferenceSchema,
  SalesUnit: entityReferenceSchema,
  ReasonCode: entityReferenceSchema,
  SalesPrice: string().required(),
  DiscountAmount: number().required(),
  DiscountPercent: number().required(),
});

const parseDateString = (originalValue) => {
  let parsedDate = isDate(originalValue)
    ? new Date(
        Date.UTC(
          originalValue.getUTCFullYear(),
          originalValue.getUTCMonth(),
          originalValue.getUTCDate(),
          originalValue.getUTCHours(),
          originalValue.getUTCMinutes(),
          originalValue.getUTCSeconds()
        )
      )
    : parseJSON(originalValue, "yyyy-MM-dd", new Date());

  if (!isValidDate(parsedDate)) {
    parsedDate = parse(originalValue, "yyyy-MM-dd", new Date());
  }

  return parsedDate;
};

const addBillingPeriodValueToDateTime = (
  currentDate,
  billingPeriodUnit,
  billingPeriodValue
) => {
  if (billingPeriodUnit === "Days")
    return addDays(currentDate, billingPeriodValue);
  if (billingPeriodUnit === "Months")
    return addMonths(currentDate, billingPeriodValue);

  return currentDate;
};

const calculateDiffInDays = (startDate, endDate) => {
  return differenceInDays(startOfDay(startDate), startOfDay(endDate)) + 1;
};

const calculateDaysInCycle = (
  contractDefinition,
  currentCycleStartDate,
  billingPeriodValue,
  billingPeriodUnit
) => {
  let endDate = calculateCurrentCycleEndDate(
    contractDefinition,
    currentCycleStartDate,
    billingPeriodValue,
    billingPeriodUnit
  );

  return calculateDiffInDays(endDate, parseDateString(currentCycleStartDate));
};
const calculateTotalDaysInCycle = (
  contractDefinition,
  currentCycleStartDate,
  billingPeriodValue,
  billingPeriodUnit
) => {
  if (
    contractDefinition &&
    contractDefinition.globalStartDate &&
    contractDefinition.globalEndDate &&
    contractDefinition.fixedCycles
  ) {
    let cycleStartDate = parseDateString(contractDefinition.globalStartDate);
    let cycleEndDate = addDays(
      addBillingPeriodValueToDateTime(
        cycleStartDate,
        billingPeriodUnit,
        billingPeriodValue
      ),
      -1
    );

    for (var i = 0; i < contractDefinition.fixedCycles; i++) {
      if (
        startOfDay(parseDateString(currentCycleStartDate)) >=
          startOfDay(cycleStartDate) &&
        startOfDay(parseDateString(currentCycleStartDate)) <=
          startOfDay(cycleEndDate)
      ) {
        return calculateDiffInDays(cycleEndDate, cycleStartDate);
      }

      cycleStartDate = addDays(cycleEndDate, 1);
      cycleEndDate = addBillingPeriodValueToDateTime(
        cycleEndDate,
        billingPeriodUnit,
        billingPeriodValue
      );
    }

    return calculateDiffInDays(
      addDays(
        addBillingPeriodValueToDateTime(
          parseDateString(currentCycleStartDate),
          billingPeriodUnit,
          billingPeriodValue
        ),
        -1
      ),
      parseDateString(currentCycleStartDate)
    );
  }

  return 0;
};

const calculateCurrentCycleEndDate = (
  contractDefinition,
  currentCycleStartDate,
  billingPeriodValue,
  billingPeriodUnit
) => {
  if (
    contractDefinition &&
    contractDefinition.globalStartDate &&
    contractDefinition.globalEndDate &&
    contractDefinition.fixedCycles
  ) {
    let cycleStartDate = parseDateString(contractDefinition.globalStartDate);
    let cycleEndDate = addDays(
      addBillingPeriodValueToDateTime(
        cycleStartDate,
        billingPeriodUnit,
        billingPeriodValue
      ),
      -1
    );

    for (var i = 0; i < contractDefinition.fixedCycles; i++) {
      if (
        startOfDay(parseDateString(currentCycleStartDate)) >=
          startOfDay(cycleStartDate) &&
        startOfDay(parseDateString(currentCycleStartDate)) <=
          startOfDay(cycleEndDate)
      ) {
        return cycleEndDate;
      }

      cycleStartDate = addDays(cycleEndDate, 1);
      cycleEndDate = addBillingPeriodValueToDateTime(
        cycleEndDate,
        billingPeriodUnit,
        billingPeriodValue
      );
    }

    return addBillingPeriodValueToDateTime(
      parseDateString(currentCycleStartDate),
      billingPeriodUnit,
      billingPeriodValue
    );
  } else {
    return addDays(
      addBillingPeriodValueToDateTime(
        parseDateString(currentCycleStartDate),
        billingPeriodUnit,
        billingPeriodValue
      ),
      -1
    );
  }
};

const calculateBillingPeriodSalesAmount = (
  quantity,
  salesPrice,
  billingPeriodValue
) => {
  if (quantity && salesPrice && billingPeriodValue) {
    return quantity * salesPrice * billingPeriodValue;
  }

  return 0;
};

const calculateBillingPeriodSalesAmountAfterDiscount = (
  quantity,
  salesPrice,
  billingPeriodValue,
  discountAmount,
  discountPercent
) => {
  if (salesPrice && quantity && billingPeriodValue) {
    if (Number(discountAmount) > 0 && Number(discountPercent) > 0)
      return (
        (quantity * salesPrice - quantity * discountAmount) *
          billingPeriodValue -
        (Number(discountPercent) / 100) *
          ((quantity * salesPrice - quantity * Number(discountAmount)) *
            billingPeriodValue)
      );
    if (Number(discountPercent) > 0) {
      return (
        quantity * salesPrice * billingPeriodValue -
        (Number(discountPercent) / 100) *
          (quantity * salesPrice * billingPeriodValue)
      );
    }

    if (Number(discountAmount) > 0)
      return (
        (quantity * salesPrice - quantity * Number(discountAmount)) *
        billingPeriodValue
      );

    return calculateBillingPeriodSalesAmount(
      quantity,
      salesPrice,
      billingPeriodValue
    );
  }

  return 0;
};

export default function NewSubscriptionPlanLineForm({
  handleCreateRecord,
  handleClose,
  isLoading,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  handleListConnectors,
  activeSubscriptionPlan,
}) {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState({});
  const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
  const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
  const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
  const [columns, setColumns] = React.useState([]);
  const [searchSidePanelType, setSearchSidePanelType] = React.useState("");

  const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] =
    React.useState({});
  const [selectedLookupId, setSelectedLookupId] = React.useState(null);

  const [connectors, setConnectors] = React.useState([]);
  const [defaultConnector, setDefaultConnector] = React.useState(null);
  const [displayConnectorSelect, setDisplayConnectorSelect] =
    React.useState(true);

  const [salesCurrency, setSalesCurrency] = React.useState(
    defaultValues.SalesCurrency
  );

  let navigate = useNavigateWithParams();

  const onSubmit = (data) => {
    data.preventDefault();
    let parsed = new Date(
      `${formValues.CurrentCycleStartDate}T${String(
        new Date().getHours()
      ).padStart(2, "0")}:${String(new Date().getMinutes()).padStart(2, "0")}`
    );
    handleCreateRecord({
      ...formValues,
      ["CurrentCycleStartDate"]: parsed.toISOString(),
    });
  };

  React.useEffect(() => {
    handleListConnectors()
      .then((connectors) => {
        setConnectors(connectors);
        if (
          !activeSubscriptionPlan ||
          !activeSubscriptionPlan.connector ||
          !activeSubscriptionPlan.connector.id
        ) {
          setDefaultConnector(null);
        } else {
          let foundConnector = connectors.find((connector) => {
            if (connector && connector.company && connector.company.id)
              return connector.id === activeSubscriptionPlan.connector.id;

            return false;
          });

          if (foundConnector) setDefaultConnector(foundConnector);
          else setDefaultConnector(null);
        }
      })
      .catch((e) => setConnectors([]));
  }, []);

  React.useEffect(() => {
    if (formValues.CurrentCycleStartDate === "") {
      let newDate = format(new Date(), "yyyy-MM-dd");
      setFormValues((state) => ({
        ...state,
        CurrentCycleStartDate: newDate,
      }));
    }
  }, []);

  React.useEffect(() => {
    handleListRecords("reasoncode", { type: "new", isDefault: true })
      .then((defaultReasonCode) => {
        if (defaultReasonCode && defaultReasonCode.length > 0) {
          return defaultReasonCode[0];
        }

        throw new Error("No default reason code found");
      })
      .then((reasoncode) => {
        return entityReferenceSchema.validate(reasoncode, {
          stripUnknown: true,
        });
      })
      .then((parsedReasonCode) => {
        setFormValues((state) => ({
          ...state,
          ReasonCode: parsedReasonCode,
        }));
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  React.useEffect(() => {
    var subscriptionPlanLineParsed = subscriptionPlanLineSchema
      .validate(formValues, { abortEarly: false })
      .then(() => setIsValid(true))
      .catch((e) => {
        const errors = e.inner.reduce((acc, value) => {
          acc[value.path] = {
            message: value.message,
            type: value.type,
          };
          return acc;
        }, {});
        setError(errors);
        setIsValid(false);
      });
  }, [formValues]);

  React.useEffect(() => {
    if (
      formValues.CurrentCycleStartDate &&
      activeSubscriptionPlan &&
      formValues.CurrentCycleStartDate.length <= 10
    ) {
      let currentEndDate = calculateCurrentCycleEndDate(
        activeSubscriptionPlan.contractDefinition,
        parseDateString(formValues.CurrentCycleStartDate),
        activeSubscriptionPlan.billingPeriodValue,
        activeSubscriptionPlan.billingPeriodUnit
      );
      let parsedEndDate = format(currentEndDate, "yyyy-MM-dd");

      if (isValidDate(currentEndDate)) {
        setFormValues((state) => ({
          ...state,
          CurrentCycleEndDate: parsedEndDate,
        }));
      }
    }
  }, [formValues.CurrentCycleStartDate, activeSubscriptionPlan]);

  React.useEffect(() => {
    if (activeSubscriptionPlan && activeSubscriptionPlan.billingPeriodValue) {
      let totalDaysInCycle;
      let countDaysInCycle;
      let currentEndDate = formValues.CurrentCycleEndDate;
      const contractDefinition = activeSubscriptionPlan.contractDefinition;

      if (formValues.CurrentCycleStartDate) {
        currentEndDate = calculateCurrentCycleEndDate(
          activeSubscriptionPlan.contractDefinition,
          parseDateString(formValues.CurrentCycleStartDate),
          activeSubscriptionPlan.billingPeriodValue,
          activeSubscriptionPlan.billingPeriodUnit
        );

        totalDaysInCycle = calculateTotalDaysInCycle(
          activeSubscriptionPlan.contractDefinition,
          formValues.CurrentCycleStartDate,
          activeSubscriptionPlan.billingPeriodValue,
          activeSubscriptionPlan.billingPeriodUnit
        );
        countDaysInCycle = calculateDaysInCycle(
          activeSubscriptionPlan.contractDefinition,
          formValues.CurrentCycleStartDate,
          activeSubscriptionPlan.billingPeriodValue,
          activeSubscriptionPlan.billingPeriodUnit
        );
      }

      let billingPeriodSalesAmount = calculateBillingPeriodSalesAmount(
        formValues.Quantity,
        formValues.SalesPrice,
        activeSubscriptionPlan.billingPeriodValue
      );
      let billingPeriodSalesAmountAfterDiscount =
        calculateBillingPeriodSalesAmountAfterDiscount(
          formValues.Quantity,
          formValues.SalesPrice,
          activeSubscriptionPlan.billingPeriodValue,
          formValues.DiscountAmount,
          formValues.DiscountPercent
        );

      if (
        contractDefinition &&
        contractDefinition.globalStartDate &&
        contractDefinition.globalEndDate &&
        contractDefinition.fixedCycles &&
        formValues.CurrentCycleStartDate &&
        totalDaysInCycle !== countDaysInCycle
      ) {
        billingPeriodSalesAmount =
          billingPeriodSalesAmount * (countDaysInCycle / totalDaysInCycle);
        billingPeriodSalesAmountAfterDiscount =
          billingPeriodSalesAmountAfterDiscount *
          (countDaysInCycle / totalDaysInCycle);
      }

      setFormValues((state) => ({
        ...state,
        billingPeriodSalesAmount: billingPeriodSalesAmount,
        billingPeriodSalesAmountAfterDiscount:
          billingPeriodSalesAmountAfterDiscount,
        CurrentCycleEndDate:
          currentEndDate && isValidDate(currentEndDate) ? currentEndDate : "",
      }));
    }
  }, [
    activeSubscriptionPlan,
    formValues.Quantity,
    formValues.SalesPrice,
    formValues.DiscountAmount,
    formValues.DiscountPercent,
    formValues.CurrentCycleStartDate,
  ]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    }

    let newFormValues = {
      ...formValues,
      [name]: value,
    };

    if (name === "Product") {
      if (value && value.extensionData) {
        //if (value.extensionData.IsAddon !== undefined) {
        //  newFormValues["IsAddon"] =
        //    value.extensionData.IsAddon.toLowerCase() === "true" ? true : false;
        //}
        if (value.extensionData.IsOneTimeFee !== undefined) {
          newFormValues["IsOneTimeFee"] =
            value.extensionData.IsOneTimeFee.toLowerCase() === "true"
              ? true
              : false;
        }
        if (
          activeSubscriptionPlan &&
          activeSubscriptionPlan.billingPeriodUnit
        ) {
          if (activeSubscriptionPlan.billingPeriodUnit === "Days") {
            if (value.extensionData.PricePerDay !== undefined) {
              newFormValues["SalesPrice"] = value.extensionData.PricePerDay;
            }
          }
          if (activeSubscriptionPlan.billingPeriodUnit === "Months") {
            if (value.extensionData.PricePerMonth !== undefined) {
              newFormValues["SalesPrice"] = value.extensionData.PricePerMonth;
            }
          }
        }
      }
    } else if (name === "SalesCurrency") {
      if (value) {
        if (value.extensionData)
          setSalesCurrency(
            value.extensionData.Symbol
              ? value.extensionData.Symbol
              : value.extensionData.Code
          );
      }
    }

    setFormValues(newFormValues);
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid
        marginTop={2}
        spacing={2}
        container
        alignItems="left"
        justify="center"
        direction="row"
      >
        <Grid item xs={6}>
          <FormControlLabel
            labelPlacement="start"
            sx={{ width: 200 }}
            control={
              <Switch
                id="enabled-input"
                name="Enabled"
                label="Enabled?"
                size="big"
                checked={formValues.Enabled}
                onChange={handleInputChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Enabled?"
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(true);
              if (defaultConnector)
                query = { ...query, connectorId: defaultConnector.id };

              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("productlookup");
              setSearchSidePanelTitle("Search Products");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("product");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
                //{
                //  field: "IsAddon",
                //  headerName: "Is addon?",
                //  flex: 2,
                //  minWidth: 100,
                //  renderCell: RenderCheckboxForAddon,
                //  valueGetter: function (params) {
                //    if (params && params.row && params.row.extensionData) {
                //      try {
                //        return String(
                //          params.row.extensionData.IsAddon
                //        ).toLowerCase() === "true"
                //          ? true
                //          : false;
                //      } catch (e) {}
                //      return "";
                //    }
                //  },
                //},
                {
                  field: "IsOneTimeFee",
                  headerName: "One time fee?",
                  flex: 2,
                  minWidth: 100,
                  renderCell: RenderCheckboxForOneTimeFee,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return String(
                          params.row.extensionData.IsOneTimeFee
                        ).toLowerCase() === "true"
                          ? true
                          : false;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="productlookup"
            type="product"
            name="Product"
            label="Product"
          />
        </Grid>
        {/*<Grid item xs={6}>*/}
        {/*  <FormControlLabel*/}
        {/*    labelPlacement="start"*/}
        {/*    sx={{ width: 200 }}*/}
        {/*    control={*/}
        {/*      <Switch*/}
        {/*        id="isaddon-input"*/}
        {/*        name="IsAddon"*/}
        {/*        label="Is addon?"*/}
        {/*        size="big"*/}
        {/*        checked={formValues.IsAddon}*/}
        {/*        onChange={handleInputChange}*/}
        {/*        inputProps={{ "aria-label": "controlled" }}*/}
        {/*      />*/}
        {/*    }*/}
        {/*    label="Is addon?"*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item xs={5}>
          <TextField
            id="quantity-input"
            name="Quantity"
            label="Quantity"
            type="number"
            size="small"
            sx={{ width: 200 }}
            required
            onChange={handleInputChange}
            value={formValues && formValues.quantity}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            labelPlacement="start"
            sx={{ width: 200 }}
            control={
              <Switch
                id="isOneTimeFee-input"
                name="IsOneTimeFee"
                label="Is one time fee?"
                size="big"
                checked={formValues.IsOneTimeFee}
                onChange={handleInputChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Is one time fee?"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="salesPrice-input"
            name="SalesPrice"
            label="Sales Price"
            type="number"
            size="small"
            sx={{ width: 200 }}
            required
            value={formValues && formValues.SalesPrice}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {salesCurrency}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="DiscountAmount-input"
            name="DiscountAmount"
            label="Discount Amount"
            type="text"
            size="small"
            sx={{ width: 200 }}           
            value={formValues && formValues.DiscountAmount}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {salesCurrency}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="DiscountPercent-input"
            name="DiscountPercent"
            label="Discount Percent"
            type="number"
            size="small"
            sx={{ width: 200 }}           
            value={formValues && formValues.DiscountPercent}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="billingPeriodSalesAmount-input"
            name="billingPeriodSalesAmount"
            label="Billing Period Sales Amount"
            type="number"
            size="small"
            sx={{ width: 200 }}
            disabled={true}
            value={formValues && formValues.billingPeriodSalesAmount}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {salesCurrency}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="billingPeriodSalesAmountAfterDiscount-input"
            name="billingPeriodSalesAmountAfterDiscount"
            label="Billing Period Sales Amount After Discount"
            type="number"
            size="small"
            sx={{ width: 200 }}
            disabled={true}
            value={
              formValues && formValues.billingPeriodSalesAmountAfterDiscount
            }
            onChange={handleInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {salesCurrency}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="CurrentCycleStartDate-input"
            name="CurrentCycleStartDate"
            label="Current Cycle Start Date"
            type="date"
            size="small"
            sx={{ width: 200 }}
            required
            onChange={handleInputChange}
            value={formValues.CurrentCycleStartDate}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="CurrentCycleEndDate-input"
            name="CurrentCycleEndDate"
            label="Current Cycle End Date"
            type="date"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={true}
            onChange={handleInputChange}
            value={
              formValues.CurrentCycleEndDate
                ? format(formValues.CurrentCycleEndDate, "yyyy-MM-dd")
                : ""
            }
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(true);
              if (defaultConnector)
                query = { ...query, connectorId: defaultConnector.id };

              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("currencylookup");
              setSearchSidePanelTitle("Search Currencies");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("currency");
              setColumns([
                {
                  field: "Symbol",
                  headerName: "Symbol",
                  flex: 1,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return params.row.extensionData.Symbol;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
                {
                  field: "Code",
                  headerName: "Code",
                  flex: 1,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (params && params.row && params.row.extensionData) {
                      try {
                        return params.row.extensionData.Code;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="currencylookup"
            type="currency"
            name="SalesCurrency"
            label="Sales Currency"
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(true);
              if (defaultConnector)
                query = { ...query, connectorId: defaultConnector.id };

              handleListRecords(type, query);

              setSearchSidePanelOpen(true);
              setSelectedLookupId("unitlookup");
              setSearchSidePanelTitle("Search Units");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("unit");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="unitlookup"
            type="unit"
            name="SalesUnit"
            label="Sales Unit"
          />
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(false);

              handleListRecords(type, { ...query, type: "new" });
              setSearchSidePanelOpen(true);
              setSelectedLookupId("reasoncodelookup");
              setSearchSidePanelTitle("Search Reason Codes");
              setSearchSidePanelQuery({ ...query, type: "new" });
              setSearchSidePanelType("reasoncode");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="reasoncodelookup"
            type="reasoncode"
            name="ReasonCode"
            label="Reason Code"
            initialValue={formValues && formValues.ReasonCode}
          />
        </Grid>
        <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
          <Button
            type="submit"
            disabled={!isValid || isLoading}
            variant="outlined"
          >
            <Typography color="black">Create</Typography>
          </Button>
          <Button onClick={handleClose} disabled={isLoading} variant="outlined">
            <Typography color="black">Cancel</Typography>
          </Button>
        </DialogActions>
      </Grid>
      <SearchSidePanel
        open={searchSidePanelOpen}
        handleClose={() => setSearchSidePanelOpen(false)}
        isLoading={isLoadingSearchSidePanel}
        title={searchSidePanelTitle}
      >
        <LookupPaper
          handleClose={() => setSearchSidePanelOpen(false)}
          selectedLookupId={selectedLookupId}
          handleChangeLookupValue={setSearchSidePanelSelectedLookup}
          isLoading={isLoadingSearchSidePanel}
          query={searchSidePanelQuery}
          data={searchSidePanelData}
          columns={columns}
          searchSidePanelType={searchSidePanelType}
          connectors={connectors}
          defaultConnector={defaultConnector}
          displayConnectorSelect={displayConnectorSelect}
          handleListRecords={handleListRecords}
        />
      </SearchSidePanel>
    </form>
  );
}
