import * as React from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  List,
  ListItem,
} from "@mui/material";
import YearMonthRangeSelector from "../molecules/YearMonthRangeSelector";
import YearRangeSelector from "../molecules/YearRangeSelector";

const ChartFilter = ({
  id,

  isFiltering,
  toggleFilter,

  chartData,
  fetchData,

  currency,
  updateCurrency,

  startDate,
  finishDate,

  updateStart,
  updateFinish,
}) => {
  const [format, setFormat] = React.useState(chartData.periodFormat);
  const [curToChange, setCurToChange] = React.useState(currency);

  const changeCurrency = (event) => {
    setCurToChange(event.target ? event.target.value : 0);
  };

  const updateFilter = (event) => {
    setFormat(event.target ? event.target.value : "Monthly");

    switch (event.target.value) {
      case "Quarterly":
        updateStart(new Date(startDate.getFullYear(), 0, 1));
        updateFinish(new Date(finishDate.getFullYear(), 12, 0, 11, 59, 59));
        break;
      case "Yearly":
        updateStart(new Date(startDate.getFullYear(), 0, 1));
        updateFinish(new Date(finishDate.getFullYear(), 12, 0, 11, 59, 59));
        break;
    }
  };

  const submitFiltering = () => {
    toggleFilter();

    fetchData({
      startBy: startDate,
      finishBy: finishDate,
      format: format,
      currency: currency,
    });

    updateCurrency(curToChange);
  };

  return (
    <Dialog
      sx={{ color: "#fff" }}
      open={isFiltering}
      onClose={toggleFilter}
      disableEscapeKeyDown
    >
      <DialogTitle>Filter</DialogTitle>
      <List>
        <ListItem>
          <FormControl>
            <InputLabel id="filter-by">Filter by</InputLabel>
            <Select
              native
              value={format}
              label="Filter by"
              labelId="filter-by"
              id="filter-by"
              onChange={updateFilter}
            >
              <option value="Yearly">Yearly</option>
              <option value="Quarterly">Quarterly</option>
              <option value="Monthly">Monthly</option>
              {/* <option value="week">Weekly</option> */}
            </Select>
          </FormControl>

          {id === "revenue" && chartData.data.currencies && (
            <FormControl sx={{ width: "250px", ml: "25px" }}>
              <InputLabel id="currency-label">Currency</InputLabel>
              <Select
                value={curToChange}
                label="Currency"
                labelId="currency-label"
                id="currency-select"
                onChange={changeCurrency}
              >
                {chartData.data.currencies.map((currency, index) => (
                  <MenuItem key={index} value={index}>
                    {currency}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </ListItem>

        {(format === "Yearly" || format === "Quarterly") && (
          <ListItem>
            <YearRangeSelector
              startDate={startDate}
              finishDate={finishDate}
              onStartDateChanged={updateStart}
              onFinishDateChanged={updateFinish}
            />
          </ListItem>
        )}
        {(format === "Monthly" || format === "Weekly") && (
          <ListItem>
            <YearMonthRangeSelector
              startDate={startDate}
              finishDate={finishDate}
              onStartDateChanged={updateStart}
              onFinishDateChanged={updateFinish}
            />
          </ListItem>
        )}
        <ListItem>
          <Button
            onClick={submitFiltering}
            variant="outlined"
            color="info"
            size="large"
          >
            Update Filter
          </Button>
        </ListItem>
      </List>
    </Dialog>
  );
};

ChartFilter.defaultProps = {};
export default ChartFilter;
