import Pagination from '@mui/material/Pagination';

import {
    DataGrid as MUIDataGrid,
    gridPageCountSelector,
    gridPageSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';

const StyledDataGrid = styled(MUIDataGrid)(({ theme }) => ({
    padding: 16,
    paddingLeft: 30,
    '& .MuiDataGrid-footerContainer': {
        justifyContent: 'center',
    }
}));

function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
        <Pagination
            color="primary"
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
    );
}

export default function DataGrid(props) {
    const heightSize = props.customSize && props.customSize === "small" ? 400 : 550;
    //TODO add onclick away listener https://mui.com/components/click-away-listener/ validating when user don't click on the ribbon
    return (
        <div style={{ height: heightSize, width: '100%' }}>
            <StyledDataGrid
                hideFooterSelectedRowCount
                autoPageSize                
                components={{
                    Pagination: CustomPagination,
                }}
                {...props}
            />
        </div>
    );
}