import * as React from "react";
import PropTypes from "prop-types";
import { Checkbox, Grid, Paper, Typography } from "@mui/material/";
import DataGrid from "../organisms/DataGrid";
import { styled } from "@mui/material/styles";
import NewRibbonButton from "../molecules/NewRibbonButton";
import RefreshRibbonButton from "../molecules/RefreshRibbonButton";
import DeleteRibbonButton from "../molecules/DeleteRibbonButton";
import DeactivateRibbonButton from "../molecules/DeactivateRibbonButton";
import ActivateRibbonButton from "../molecules/ActivateRibbonButton";
import CreditNoteRibbonButton from "../molecules/CreditNoteRibbonButton";

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import Subtitle from "../atoms/Subtitle";

import ConfirmDialog from "../molecules/ConfirmDialog";
import SidePanel from "../organisms/SidePanel";

import NotificationSnackBar from "../organisms/NotificationSnackBar";

import NewSubscriptionPlanLineForm from "../../forms/subscriptionplanline/NewSubscriptionPlanLineForm";
import EditSubscriptionPlanLineForm from "../../forms/subscriptionplanline/EditSubscriptionPlanLineForm";
import AddCreditNoteForm from "../../forms/subscriptionplanline/AddCreditNoteForm";
import {
  Route,
  Routes,
  Outlet,
  useParams,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";
import { useFirstRender } from "../../hooks/useFirstRender";

import { format, isDate, parse, parseJSON, isValid, addMonths, addDays } from 'date-fns'

import ProfileCard from "../organisms/ProfileCard";
const GridParent = styled(Grid)(({ theme }) => ({}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
  padding: 3,
  paddingLeft: 30,
  display: "inline-flex",
}));

const GridItem = styled(Grid)(({ theme }) => ({
  padding: 16,
  paddingLeft: 30,
}));

const RenderCheckboxForAddon = ({ value }) => {
  return <Checkbox sx={{ width: "100%" }} disabled checked={value} />;
};

const RenderCheckboxForOneTimeFee = ({ value }) => {
  return (
    <Checkbox sx={{ width: "100%" }} disabled checked={value} />
  );
};

const RenderEnabled = ({ value }) => {
  return value ? <CheckIcon sx={{ width: "100%" }} /> : <CloseIcon sx={{ width: "100%" }} />;
};

const columns = [
  { field: "lineNumber", headerName: "Line Number", flex: 1, minWidth: 100 },
  {
    field: "enabled",
    headerName: "Enabled",
    flex: 1,
    minWidth: 75,
    renderCell: RenderEnabled,
  },
  {
    field: "product",
    headerName: "Product",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (!params.value || params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "isOneTimeFee",
    headerName: "One Time Fee?",
    flex: 1,
    minWidth: 100,
    renderCell: RenderCheckboxForOneTimeFee,
  },
  {
    field: "isAddon",
    headerName: "Is Addon",
    flex: 1,
    minWidth: 100,
    renderCell: RenderCheckboxForAddon,
  },
  { field: "quantity", headerName: "Quantity", flex: 2, minWidth: 100 },
  { field: "salesPrice", headerName: "Sales Price", flex: 2, minWidth: 100 },
  {
    field: "discountAmount",
    headerName: "Discount Amount",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "discountPercent",
    headerName: "Discount Percent",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "billingPeriodSalesAmount",
    headerName: "Billing Period Sales Amount",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "billingPeriodSalesAmountAfterDiscount",
    headerName: "Billing Period Sales Amount After Discount",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "salesCurrency",
    headerName: "Sales Currency",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (!params.value || params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "salesUnit",
    headerName: "Sales Unit",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (!params.value || params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "lastSalesAmountInvoiced",
    headerName: "Last Sales Amount Invoiced",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "currentSalesCycle",
    headerName: "Current Sales Cycle",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "originalStartDate",
    headerName: "Original Start Date",
    flex: 3,
    minWidth: 100,
    type: "date",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "originalEndDate",
    headerName: "Original End Date",
    flex: 3,
    minWidth: 100,
    type: "date",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "currentCycleStartDate",
    headerName: "Current Cycle Start Date",
    flex: 3,
    minWidth: 100,
    type: "date",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "currentCycleEndDate",
    headerName: "Current Cycle End Date",
    flex: 3,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "nextBillingCycleStartDate",
    headerName: "Next Cycle Start Date",
    flex: 3,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "nextBillingCycleEndDate",
    headerName: "Next Cycle End Date",
    flex: 3,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "reasonCode",
    headerName: "Reason Code",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
      if (!params.value || params.value.name === undefined) return "";

      return params.value.name;
    },
  },
  {
    field: "createdOn",
    headerName: "Created On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
];
function SubscriptionPlanLineIndex({
  mode,
  data,
  activeSubscriptionPlan,
  isLoading,
  displayNotification,
  onRefresh,
  handleEnable,
  handleDisable,
  handleDelete,
  setDisplayNotification,
  setActiveSubscriptionPlanLine,
}) {
  const [displayCreateRibbon, setDisplayCreateRibbon] = React.useState(false);
  const [displayDelete, setDisplayDelete] = React.useState(false);
  const [displayEnable, setDisplayEnable] = React.useState(false);
  const [displayDisable, setDisplayDisable] = React.useState(false);
  const [displayCreditNoteRibbon, setDisplayCreditNoteRibbon] =
    React.useState(false);

  const [confirmDialog, setConfirmDialog] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState(null);

  let navigate = useNavigateWithParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const isFirstRender = useFirstRender();

  const isLayoutEmbedded = () => {
    return searchParams.get("layout") === "embedded";
  };

  const isCockpitMode = () => {
    return mode === "cockpit";
  };

  const isDefaultMode = () => {
    return mode === "default";
  };

  const onClickRow = (params) => {
    if (params.row.enabled === true) {
      setDisplayDisable(true);
      setDisplayEnable(false);
      setDisplayDelete(false);
    }
    if (params.row.enabled === false) {
      setDisplayDisable(false);
      setDisplayEnable(true);
      setDisplayDelete(true);
    }
    if (
      activeSubscriptionPlan != null &&
      activeSubscriptionPlan.id &&
      activeSubscriptionPlan.status === "Draft"
    ) {
      setDisplayCreateRibbon(true);
      if (
        params.row.hasOwnProperty("lastSalesAmountInvoiced") &&
        params.row.lastSalesAmountInvoiced > 0
      ) {
        setDisplayCreditNoteRibbon(true);
      } else setDisplayCreditNoteRibbon(false);
    } else {
      setDisplayCreateRibbon(false);
      setDisplayCreditNoteRibbon(false);
    }

    setSelectedRow(params.row);
    if (isCockpitMode()) {
      setActiveSubscriptionPlanLine(params.row);
    }
  };

  const onDoubleClickRow = (params) => {
    viewOrEditRecord();
  };

  const createRecord = () => {
    navigate(
      (isCockpitMode() ? `lines/${activeSubscriptionPlan.id}/` : "") + "new"
    );
  };

  const viewOrEditRecord = () => {
    if (activeSubscriptionPlan && activeSubscriptionPlan.status === "Draft")
      navigate(
        `${isCockpitMode() ? `lines/${activeSubscriptionPlan.id}/` : ""}${
          selectedRow.id
        }/edit`
      );
    else
      navigate(
        `${isCockpitMode() ? `lines/${activeSubscriptionPlan.id}/` : ""}${
          selectedRow.id
        }/view`
      );
  };

  const refreshGrid = (event, activeSubscriptionPlan) => {
    setSelectedRow(null);
    setDisplayDisable(false);
    setDisplayEnable(false);
    setDisplayDelete(false);
    setDisplayCreditNoteRibbon(false);
    if (activeSubscriptionPlan) {
      onRefresh(activeSubscriptionPlan.id);
      if (activeSubscriptionPlan.status === "Draft")
        setDisplayCreateRibbon(true);
      else setDisplayCreateRibbon(false);
    } else {
      onRefresh();
      setDisplayCreateRibbon(false);
    }
  };

  const addCreditNote = () => {
    if (activeSubscriptionPlan)
      navigate(
        `${isCockpitMode() ? `lines/${activeSubscriptionPlan.id}/` : ""}${
          selectedRow.id
        }/AddCreditNote`
      );
  };

  const enableRecord = () => {
    setConfirmDialog({
      title: "Enable Subscription Plan Line",
      description: `Are you sure you want to Enable Plan Line ${selectedRow.lineNumber}?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleEnable(activeSubscriptionPlan.id, selectedRow.id)
          .then(() => {
            setDisplayNotification({
              message: "Plan Line Enabled successfully",
              type: "success",
            });
            refreshGrid(null, activeSubscriptionPlan);
          })
          .catch((e) => {
            setDisplayNotification({
              message: e.response.data.Message,
              type: "error",
            });
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const disableRecord = () => {
    setConfirmDialog({
      title: "Disable Subscription Plan Line",
      description: `Are you sure you want to Disable Plan Line : ${selectedRow.lineNumber}?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleDisable(activeSubscriptionPlan.id, selectedRow.id)
          .then(() => {
            setDisplayNotification({
              message: "Plan Line Disabled successfully",
              type: "success",
            });
            refreshGrid(null, activeSubscriptionPlan);
          })
          .catch((e) => {
            setDisplayNotification({
              message: e.response.data.Message,
              type: "error",
            });
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const deleteRecord = () => {
    setConfirmDialog({
      title: "Delete Subscription Plan Line?",
      description: `Are you sure you want to Delte Plan Line : ${selectedRow.lineNumber}?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleDelete(activeSubscriptionPlan.id, selectedRow.id)
          .then(() => {
            setDisplayNotification({
              message: "Plan Line Delete successfully",
              type: "success",
            });
            refreshGrid(null, activeSubscriptionPlan);
          })
          .catch((e) => {
            setDisplayNotification({
              message: e.response.data.Message,
              type: "error",
            });
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const displaySubscriptionPlanLineRibbons = () => {
    return (
      <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
        <NewRibbonButton
          onClick={createRecord}
          display={displayCreateRibbon}
          title="New Subscription Plan Line"
        />
        <RefreshRibbonButton
          onClick={(evt) => {
            refreshGrid(evt, activeSubscriptionPlan);
          }}
          display={true}
          title="Refresh"
        />
        <ActivateRibbonButton
          onClick={enableRecord}
          display={displayEnable}
          title="Enable"
        />
        <CreditNoteRibbonButton
          onClick={addCreditNote}
          display={displayCreditNoteRibbon}
          title="Credit Note"
        />
        <DeactivateRibbonButton
          onClick={disableRecord}
          display={displayDisable}
          title="Disable"
        />
        <DeleteRibbonButton
          onClick={deleteRecord}
          display={displayDelete}
          title="Delete"
        />

        {isLayoutEmbedded() && isDefaultMode() && (
          <ProfileCard embeddedMode={true} />
        )}
      </RibbonGrid>
    );
  };

  React.useEffect(() => {
    if (!isFirstRender) refreshGrid(null, activeSubscriptionPlan);
  }, [activeSubscriptionPlan]);

  return (
    <React.Fragment>
      <GridParent
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <ConfirmDialog
          confirmDialogInfo={confirmDialog}
          onConfirm={confirmDialog.actionConfirm}
          onCancel={confirmDialog.actionCancel}
        />
        {isDefaultMode() && displaySubscriptionPlanLineRibbons()}
        <GridItem item xs={12} md={12} lg={12}>
          <Subtitle
            title="Subscription Plan Lines"
            style={{ textAlign: "left" }}
          />
        </GridItem>
        {isCockpitMode() && displaySubscriptionPlanLineRibbons()}
        <GridItem item xs={12} md={12} lg={12}>
          <DataGrid
            customSize={isCockpitMode() ? "small" : "normal"}
            loading={isLoading}
            onRowClick={onClickRow}
            onRowDoubleClick={onDoubleClickRow}
            columns={columns}
            rows={data}
          />
        </GridItem>
      </GridParent>
      {displayNotification !== null && (
        <NotificationSnackBar
          displayNotification={displayNotification}
          onClose={() => {
            setDisplayNotification(null);
          }}
        />
      )}
      <Outlet />
    </React.Fragment>
  );
}

function SubscriptionPlanLineNew({
  sidePanelOpen,
  isLoadingSidePanel,
  handleCreateRecord,
  setDisplayNotification,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  activeSubscriptionPlan,
  handleListConnectors,
  handleFetchRecord,
}) {
  let navigate = useNavigateWithParams();

  let { subscriptionPlanId } = useParams();

  React.useEffect(() => {
    if (activeSubscriptionPlan == null)
      handleFetchRecord(subscriptionPlanId, null);
  }, []);

  return (
    <SidePanel
      open={sidePanelOpen}
      handleClose={() => navigate("../")}
      isLoading={isLoadingSidePanel}
      title="New Subscription Plan Line"
    >
      <NewSubscriptionPlanLineForm
        isLoading={isLoadingSidePanel}
        activeSubscriptionPlan={activeSubscriptionPlan}
        handleClose={() => navigate("../")}
        handleCreateRecord={(record) => {
          return handleCreateRecord(activeSubscriptionPlan.id, record)
            .then(() => {
              setDisplayNotification({
                message: "Subscription Plan Line created successfully",
                type: "success",
              });
              return navigate("../");
            })
            .catch((e) => {
              //TODO error
              if (e && e.response && e.response.data && e.response.data.Message)
                setDisplayNotification({
                  message: e.response.data.Message,
                  type: "error",
                });
              else
                  setDisplayNotification({
                  message: typeof(e) === "string"? e:"Error updating subscription plan line",
                  type: "error",
                });
            });
        }}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        handleListRecords={handleListRecords}
        searchSidePanelData={searchSidePanelData}
        handleListConnectors={handleListConnectors}
      />
    </SidePanel>
  );
}

function SubscriptionPlanLineEdit({
  isLoadingSidePanel,
  sidePanelOpen,
  handleEditRecord,
  handleFetchRecord,
  recordData,
  setDisplayNotification,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  activeSubscriptionPlan,
  handleListConnectors,
  readyOnlyForm,
}) {
  let navigate = useNavigateWithParams();

  let { subscriptionPlanId, subscriptionPlanLineId } = useParams();

  React.useEffect(() => {
    handleFetchRecord(subscriptionPlanId, subscriptionPlanLineId);
  }, []);

  return (
    <React.Fragment>
      <SidePanel
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../../")}
        title={
          "" && !isLoadingSidePanel && recordData && `${recordData.lineNumber}`
        }
        open={sidePanelOpen}
      >
        {!isLoadingSidePanel && recordData && (
          <EditSubscriptionPlanLineForm
            handleClose={() => navigate("../../")}
            isLoading={isLoadingSidePanel}
            isLoadingSearchSidePanel={isLoadingSearchSidePanel}
            handleListRecords={handleListRecords}
            searchSidePanelData={searchSidePanelData}
            handleListConnectors={handleListConnectors}
            activeSubscriptionPlan={activeSubscriptionPlan}
            recordData={recordData}
            readyOnlyForm={readyOnlyForm}
            handleEditRecord={(
              subscriptionPlanId,
              subscriptionPlanLineId,
              updateRecord
            ) => {
              handleEditRecord(
                subscriptionPlanId,
                subscriptionPlanLineId,
                updateRecord
              )
                .then(() => {
                  navigate("../../");
                  setDisplayNotification({
                    message: "Plan Line Updated successfully",
                    type: "success",
                  });
                })
                .catch((e) => {
                  if (
                    e &&
                    e.response &&
                    e.response.data &&
                    e.response.data.Message
                  )
                    setDisplayNotification({
                      message: e.response.data.Message,
                      type: "error",
                    });
                  else
                    setDisplayNotification({
                      message: "Error updating subscription plan line",
                      type: "error",
                    });
                });
            }}
          />
        )}
      </SidePanel>
    </React.Fragment>
  );
}

function SubscriptionPlanLineAddCreditNote({
  isLoadingSidePanel,
  sidePanelOpen,
  handleFetchRecord,
  recordData,
  setDisplayNotification,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  activeSubscriptionPlan,
  handleListConnectors,
  readyOnlyForm,
  handleAddCreditNote,
}) {
  let navigate = useNavigateWithParams();

  let { subscriptionPlanId, subscriptionPlanLineId } = useParams();

  React.useEffect(() => {
    handleFetchRecord(subscriptionPlanId, subscriptionPlanLineId);
  }, []);

  return (
    <React.Fragment>
      <SidePanel
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../../")}
        title={
          !isLoadingSidePanel &&
          recordData &&
          `Create credit note to line ${recordData.lineNumber}`
        }
        open={sidePanelOpen}
      >
        {!isLoadingSidePanel && recordData && (
          <AddCreditNoteForm
            handleClose={() => navigate("../../")}
            isLoading={isLoadingSidePanel}
            isLoadingSearchSidePanel={isLoadingSearchSidePanel}
            handleListRecords={handleListRecords}
            searchSidePanelData={searchSidePanelData}
            handleListConnectors={handleListConnectors}
            activeSubscriptionPlan={activeSubscriptionPlan}
            recordData={recordData}
            readyOnlyForm={readyOnlyForm}
            handleAddCreditNote={(data) => {
              return handleAddCreditNote(
                subscriptionPlanId,
                subscriptionPlanLineId,
                data
              )
                .then(() => {
                  navigate("../../");
                  setDisplayNotification({
                    message: "Credit note created successfully",
                    type: "success",
                  });
                })
                .catch((e) => {
                  if (
                    e &&
                    e.response &&
                    e.response.data &&
                    e.response.data.Message
                  )
                    setDisplayNotification({
                      message: e.response.data.Message,
                      type: "error",
                    });
                  else
                    setDisplayNotification({
                      message: "Error creating credit note",
                      type: "error",
                    });
                });
            }}
          />
        )}
      </SidePanel>
    </React.Fragment>
  );
}

function SubscriptionPlanLineTemplate(props) {
  const {
    mode,
    data,
    activeSubscriptionPlan,
    recordData,
    isLoading,
    onRefresh,
    handleCreateRecord,
    handleFetchRecord,
    handleListRecords,
    handleEnable,
    handleDisable,
    handleEditRecord,
    handleDelete,
    searchSidePanelData,
    isLoadingSidePanel,
    isLoadingSearchSidePanel,
    handleListConnectors,
    setActiveSubscriptionPlanLine,
    setActiveSubscriptionPlan,
    handleAddCreditNote,
  } = props;

  const [displayNotification, setDisplayNotification] = React.useState(null);

  const isCockpitMode = () => {
    return mode === "cockpit";
  };

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SubscriptionPlanLineIndex
            mode={mode}
            data={data}
            activeSubscriptionPlan={activeSubscriptionPlan}
            isLoading={isLoading}
            displayNotification={displayNotification}
            onRefresh={onRefresh}
            handleEnable={handleEnable}
            handleDisable={handleDisable}
            handleDelete={handleDelete}
            setDisplayNotification={setDisplayNotification}
            setActiveSubscriptionPlanLine={setActiveSubscriptionPlanLine}
          />
        }
      >
        <Route
          path={
            (isCockpitMode() ? "lines/" : "") +
            ":subscriptionPlanId/:subscriptionPlanLineId/view"
          }
          element={
            <SubscriptionPlanLineEdit
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              activeSubscriptionPlan={activeSubscriptionPlan}
              handleListRecords={handleListRecords}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              displayNotification={displayNotification}
              handleListConnectors={handleListConnectors}
              recordData={recordData}
              handleFetchRecord={handleFetchRecord}
              handleEditRecord={handleEditRecord}
              sidePanelOpen={true}
              readyOnlyForm={true}
            />
          }
        />
        <Route
          path={
            (isCockpitMode() ? "lines/" : "") +
            ":subscriptionPlanId/:subscriptionPlanLineId/edit"
          }
          element={
            <SubscriptionPlanLineEdit
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              activeSubscriptionPlan={activeSubscriptionPlan}
              handleListRecords={handleListRecords}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              displayNotification={displayNotification}
              handleListConnectors={handleListConnectors}
              recordData={recordData}
              handleFetchRecord={handleFetchRecord}
              handleEditRecord={handleEditRecord}
              sidePanelOpen={true}
              readyOnlyForm={false}
            />
          }
        />
        <Route
          path={
            (isCockpitMode() ? "lines/" : "") +
            ":subscriptionPlanId/:subscriptionPlanLineId/AddCreditNote"
          }
          element={
            <SubscriptionPlanLineAddCreditNote
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              activeSubscriptionPlan={activeSubscriptionPlan}
              handleListRecords={handleListRecords}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              displayNotification={displayNotification}
              handleListConnectors={handleListConnectors}
              recordData={recordData}
              handleFetchRecord={handleFetchRecord}
              handleAddCreditNote={handleAddCreditNote}
              sidePanelOpen={true}
              readyOnlyForm={false}
              /*
                        isLoadingSidePanel={isLoadingSidePanel}
                        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                        activeSubscriptionPlan={activeSubscriptionPlan}
                        handleListRecords={handleListRecords}
                        searchSidePanelData={searchSidePanelData}
                        setDisplayNotification={setDisplayNotification}
                        displayNotification={displayNotification}
                        handleListConnectors={handleListConnectors}
                        recordData={recordData}
                        handleFetchRecord={handleFetchRecord}
                        handleEditRecord={handleEditRecord}
                        sidePanelOpen={true}
                        readyOnlyForm={false}
                        */
            />
          }
        />
        <Route
          path={(isCockpitMode() ? "lines/" : "") + ":subscriptionPlanId/new"}
          element={
            <SubscriptionPlanLineNew
              isLoadingSidePanel={isLoadingSidePanel}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              activeSubscriptionPlan={activeSubscriptionPlan}
              handleListRecords={handleListRecords}
              handleCreateRecord={handleCreateRecord}
              searchSidePanelData={searchSidePanelData}
              setDisplayNotification={setDisplayNotification}
              // handleFetchRecord={handleFetchRecord}
              displayNotification={displayNotification}
              handleListConnectors={handleListConnectors}
              handleFetchRecord={(subscriptionPlan) => {
                return handleFetchRecord(subscriptionPlan).then((data) => {
                  if (setActiveSubscriptionPlan)
                    setActiveSubscriptionPlan(data);
                });
              }}
              sidePanelOpen={true}
            />
          }
        />
      </Route>
    </Routes>
  );
}

SubscriptionPlanLineTemplate.propTypes = {
  mode: PropTypes.oneOf(["default", "cockpit"]),
};
SubscriptionPlanLineTemplate.defaultProps = {
  mode: "default",
};

export default SubscriptionPlanLineTemplate;
