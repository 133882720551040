import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    /* Topbar */
    toolbar: {
        height: 65
    },

    /* Navigation panel */
    sidepanel: {
        width: {
            open: 280,
            closed: 40, // originally theme.spacing(5) ≡ (8 * 5) px, 
                        // ref: https://mui.com/material-ui/customization/spacing/
        }
    },

    /* Customer panel for cockpit */
    customerSearchSidepanel: {
        width: {
            open: 250,
            closed: 0,
        }
    },

    typography: {
        fontFamily: ['Paralucent', 'Mulish', 'Muli', 'sans-serif'].join(',')
    },
    colors: {
        gray: "#f4f4f4",

        bluefort: [
            "#7F0489",
            "#6E1195",
            "#5C1FA2",
            "#4D2BAD",
            "#3E37B8",
            "#2E44C4",
            "#1F50CF",
            "#105CDA",
            "#0068E5"
        ]
    }
})

export default theme;