import * as React from 'react';
import { Grid, Typography } from '@mui/material/';
import DataGrid from '../organisms/DataGrid';
import { styled } from '@mui/material/styles';
import RefreshRibbonButton from '../molecules/RefreshRibbonButton';
import ConfirmDialog from '../molecules/ConfirmDialog';
import SidePanel from '../organisms/SidePanel';
import { Route, Routes, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns'
import ProfileCard from '../organisms/ProfileCard'
import NotificationSnackBar from '../organisms/NotificationSnackBar';
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams';
import StatusChip from "../atoms/StatusChip";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import HandymanIcon from "@mui/icons-material/Handyman";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import NewMerchantForm from "../../forms/Merchant/NewMerchantForm";
import EditMerchantForm from "../../forms/Merchant/EditMerchantForm";
import NewRibbonButton from "../molecules/NewRibbonButton";
import GoCardlessCustomersRibbonButton from "../molecules/GoCardlessCustomersRibbonButton";
import GoCardlessPayoutsRibbonButton from "../molecules/GoCardlessPayoutsRibbonButton";
import TestRibbonButton from '../molecules/TestRibbonButton';
import TooltipStatusChip from "../molecules/TooltipStatusChip";
import ApprovalIcon from "@mui/icons-material/Approval";
import PostAddIcon from "@mui/icons-material/PostAdd";
import BlockIcon from "@mui/icons-material/Block";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DeleteRibbonButton from "../molecules/DeleteRibbonButton";
import EditRibbonButton from '../molecules/EditRibbonButton';

const GridParent = styled(Grid)(({ theme }) => ({

}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
    padding: 3,
    paddingLeft: 30,
    display: 'inline-flex'
}));

const GridItem = styled(Grid)(({ theme }) => ({
    padding: 16,
    paddingLeft: 30
}));

const SetChipExternalStatusColor = (value) => {
    switch (value) {
        case "InReview":
            return "default";
        case "Successful":
            return "success";
        default:
            return "error";
    }
};

const SetChipExternalStatusIcon = (value) => {
    switch (value) {
        case "InReview":
            return <DriveFileRenameOutlineIcon />;
        case "Successful":
            return <FileUploadIcon />;
        default:
            return <RemoveCircleOutlineIcon />;
    }
};


const ExternalStatusValueFormatter = (value) => {
    if (value === "InReview") return "In review";
    if (value === "ActionRequired") return "Action required";

    return value;
};

const RenderExternalStatus = ({ value }) => {
    return (
        <StatusChip
            sx={{ width: "100%" }}
            variant="outlined"
            label={ExternalStatusValueFormatter(value)}
            color={SetChipExternalStatusColor(value)}
            icon={SetChipExternalStatusIcon(value)}
        />
    );
};

const SetNotificationColor = (harpMessage) => {
    switch (harpMessage) {
      
        //Success
        case "Merchant account is correctly linked.": 
            return "success";

        //Initial and InReview
        case "Action Required":
        case "Merchant account is being reviewed.": 
            return "warning"

        //Action Required, AccessToken Null, InActive Token
        default:
            return "error";
    }
};

const columns = [

    {
        field: "harpMessage",
        headerName: "Alerts",
        flex: 0,
        maxWidth: 56,
        type: "singleSelect",
        renderCell: ({ value }) => {
            return (
                <TooltipStatusChip
                    sx={{ width: "87%", paddingLeft: 1.585 }}
                    variant="filled"
                    text-align="center"
                    title={value}
                    color={SetNotificationColor(value)}
                    icon={<NotificationsNoneIcon/>}
                    display="flex"
                />
            );
        }
    },

    {
        field: "connector",
        headerName: "Connector",
        flex: 1,
        minWidth: 100,
        maxWidth: 400,
        valueGetter: (params) => {
            if (!params.value || params.value.name === undefined) return "";

            return params.value.name;
        },
    },
    {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        minWidth: 50,
        maxWidth: 180,
        type: 'singleSelect',
        valueOptions: [
            { label: 'Sandbox', value: 'Sandbox' },
            { label: 'Live', value: 'Live' },
        ],
        renderCell: ({ value }) => {
            const getChipStatusColor = (value) => {
                return value === 'Sandbox' ? 'default' : 'primary';
            };

            const getChipStatusIcon = (value) => {
                return value === 'Sandbox' ? <HandymanIcon /> : <RocketLaunchIcon />;
            };

            return (
                <StatusChip
                    sx={{ width: '100%' }}
                    variant="outlined"
                    label={value}
                    color={getChipStatusColor(value)}
                    icon={getChipStatusIcon(value)}
                />
            );
        }
    },
    {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        minWidth: 50,
        maxWidth: 200,
        type: 'singleSelect',
        valueOptions: [
            { label: 'Successful', value: 'Successful' },
            { label: 'In Review', value: 'InReview' },
            { label: 'Action Required', value: 'ActionRequired' },
        ],
        renderCell: RenderExternalStatus
    },
    {
        field: "email",
        headerName: "E-Mail",
        flex: 1,
        minWidth: 100,
        maxWidth: 200,
    },
    {
        field: "organisationId",
        headerName: "Organisation Id",
        flex: 1,
        minWidth: 100,
        maxWidth: 200,
    },
    {
        field: 'onboardingUrl', headerName: 'Onboarding Url', flex: 1, minWidth: 100, maxWidth: 200,
        renderCell: ({ value }) => {
            return (
                <StatusChip
                    sx={{ width: "90%" }}
                    variant="outlined"
                    label="Copy to clipboard"
                    color="default"
                    icon={<ContentCopyOutlinedIcon />}
                />
            );

        }
    },
    {
        field: "customerOnboardingRedirectUrl",
        headerName: "Customer Onboarding Redirect Url",
        flex: 1,
        minWidth: 100,
        maxWidth: 350,
    },

    {
        field: "customerOnboardingExitUrl",
        headerName: "Customer Onboarding Exit Url",
        flex: 1,
        minWidth: 100,
        maxWidth: 350,
    },

    
];

function MerchantsIndex({ isLoading, data, onRefresh, displayNotification, setDisplayNotification, handleTestMerchantConnection, handleDeleteRecord }) {

    const [confirmDialog, setConfirmDialog] = React.useState({});
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [errorMessage, setErrorMessage] = React.useState({});

    const [displayRibbons, setDisplayRibbons] = React.useState({ "new": true, "payouts": false, "customers": false, "mandates": false, "tests": false, "edit": false });
    const [displaySendNotification, setDisplaySendNotification] = React.useState(false);
    const [displayDeleteRibbon, setDisplayDeleteRibbon] = React.useState(false);

    let navigate = useNavigateWithParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const isLayoutEmbedded = () => {
        return searchParams.get("layout") === "embedded";
    };

    const handleOnCellClick = (param) => {
        if (param.field === "onboardingUrl") {
            navigator.clipboard.writeText(param.value);
            setDisplayNotification({
                message: `Onboarding URL copied to clipboard !`,
                type: "success",
            })
        }

    }

    const onClickRow = (params) => {
        setDisplayRibbons({ ...{ "new": true, "customers": true, "payouts": true, "tests": true, "edit": true } });
        setSelectedRow(params.row);
        if (params.row.status !== 'Successful') {
            setDisplayDeleteRibbon(true);
        } else {
            setDisplayDeleteRibbon(false);
        }
    };

    const onDoubleClickRow = (params) => {

        openRecord();

    };

    const openRecord = () => {
        navigate(`${selectedRow.id}`);
    };

    const createRecord = () => {
        navigate("new");
    };
    const refreshGrid = () => {
        setSelectedRow(null);
        setDisplayRibbons({ ...{ "new": true, "customers": false, "payouts": false, "mandates": false, "tests": false, "edit": false } });

        setDisplaySendNotification(false);
        setDisplayDeleteRibbon(false);
        onRefresh();
    };

    const testMerchantConnection = () => {
        handleTestMerchantConnection(selectedRow.id)
            .then(() => {
               // var ErrorMessage = data.merchant.selectedRow.id // Extract the snackbar message
                setDisplayNotification({
                    message: "Test successful! Please check Alerts!",
                    type: "success",
                });
                refreshGrid();
            })
            .catch((e) => {
                setDisplayNotification({
                    message:
                        e.response.status === 403
                            ? "Unauthorized"
                            : e.response.data.Message,
                    type: "error",
                });
            });
    };

    const deleteRecord = () => {
        setConfirmDialog({
            title: "Delete Merchant",
            description: `Are you sure you want to Delete Merchant for Connector ${selectedRow.connector.name}?`,
            actionConfirm: () => {
                setConfirmDialog({});
                handleDeleteRecord(selectedRow.id)
                    .then((data) => {
                        setDisplayNotification({
                            message: "Merchant Deleted Sucessfully.",
                            type: "success",
                        });
                        refreshGrid();
                    })
                    .catch((e) => {
                        setDisplayNotification({
                            message:
                                e.response.status === 403
                                    ? "Unauthorized"
                                    : e.response.data.Message,
                            type: "error",
                        });
                    });
            },
            actionCancel: () => {
                setConfirmDialog({});
            },
        });
    };
    return (<React.Fragment>
        <GridParent
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch">
            {confirmDialog && confirmDialog.title && <ConfirmDialog confirmDialogInfo={confirmDialog} onConfirm={confirmDialog.actionConfirm} onCancel={confirmDialog.actionCancel} />}
            <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
                <NewRibbonButton
                    onClick={createRecord}
                    display={displayRibbons.new}
                    title="Onboard a new merchant"
                />

                <EditRibbonButton
                    onClick={openRecord}
                    display={displayRibbons.edit}
                    title="Edit"
                />

                <RefreshRibbonButton
                    onClick={refreshGrid}
                    display={true}
                    title="Refresh"
                />
                <DeleteRibbonButton
                    onClick={deleteRecord}
                    display={displayDeleteRibbon}
                    title="Delete"
                />
                <GoCardlessCustomersRibbonButton
                    display={displayRibbons.customers}
                    merchantType={selectedRow && selectedRow.type}
                />

                <GoCardlessPayoutsRibbonButton
                    display={displayRibbons.payouts}
                    merchantType={selectedRow && selectedRow.type}
                />

                <TestRibbonButton
                    onClick={testMerchantConnection}
                    display={displayRibbons.tests}
                    title="Test Merchant Connection"
                />



                {isLayoutEmbedded() && (<ProfileCard embeddedMode={true} />)}
            </RibbonGrid>
            <GridItem item xs={12} md={12} lg={12}>
                <Typography>Merchants</Typography>
            </GridItem>
            <GridItem item xs={12} md={12} lg={12}>
                <DataGrid loading={isLoading} onRowClick={onClickRow} onRowDoubleClick={onDoubleClickRow} onCellClick={handleOnCellClick} columns={columns} rows={data} />
            </GridItem>

        </GridParent>
        {displayNotification !== null && (<NotificationSnackBar
            displayNotification={displayNotification}
            onClose={() => setDisplayNotification(null)}

        />)}
        <Outlet />
    </React.Fragment>);
}

function MerchantsNew({ isLoadingSidePanel, sidePanelOpen, isLoadingSearchSidePanel, searchSidePanelData, setDisplayNotification, handleListRecords, displayNotification, handleCreateRecord }) {
    let navigate = useNavigateWithParams();

    return (
        <React.Fragment>
            <SidePanel
                open={sidePanelOpen}
                handleClose={() => navigate("../")}
                isLoading={isLoadingSidePanel}
                title="New merchant"
            >
                <NewMerchantForm
                    isLoading={isLoadingSidePanel}
                    handleClose={() => navigate("../")}
                    handleCreateRecord={(record) => {
                        return handleCreateRecord(record)
                            .then(() => {
                                setDisplayNotification({
                                    message: "Merchant created!",
                                    type: "success",
                                });
                                return navigate("../");
                            })
                            .catch((e) => {
                                if (e && e.response && e.response.status === 409) {
                                    setDisplayNotification({
                                        message: "This connector already has a merchant",
                                        type: "error",
                                    });
                                }
                                else if (e && e.response && e.response.data && e.response.data.Message)
                                    setDisplayNotification({
                                        message: e.response.data.Message,
                                        type: "error",
                                    });
                                else {
                                    setDisplayNotification({
                                        message: "Unknown Error",
                                        type: "error",
                                    });
                                }
                            });
                    }}
                    isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                    searchSidePanelData={searchSidePanelData}
                    handleListRecords={handleListRecords}
                />
            </SidePanel>
        </React.Fragment>
    );
}

function MerchantsEdit({ isLoadingSidePanel, sidePanelOpen, handleFetchRecord,
                         recordData, setDisplayNotification, handleEditRecord, 
                         handleListRecords, isLoadingSearchSidePanel, searchSidePanelData }) {
    let navigate = useNavigateWithParams();
    let { merchantId } = useParams();
    React.useEffect(() => {
        return handleFetchRecord(merchantId)
            .catch((e) => {
                //TODO error handle 404
                setDisplayNotification({ message: "Error" })
            })
    }, []);

    return (
        <React.Fragment>
            <SidePanel
                isLoading={isLoadingSidePanel}
                handleClose={() => navigate("../")}
                title={!isLoadingSidePanel && recordData && `Merchant - ${recordData.type}`}
                open={sidePanelOpen}
            >
                {!isLoadingSidePanel && recordData && (
                    <EditMerchantForm
                        isLoading={isLoadingSidePanel}
                        handleClose={() => navigate("../")}
                        recordData={recordData}
                        handleEditRecord={(merchantId, data) => {
                            return handleEditRecord(merchantId, data).then(() => {
                                navigate("../");
                            });
                        }}
                        handleListRecords={handleListRecords}
                        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                        searchSidePanelData={searchSidePanelData}
                    />
                )}


            </SidePanel>
        </React.Fragment>
    );
}


function MerchantsTemplate({ data, isLoading, isLoadingSidePanel, isLoadingSearchSidePanel, onRefresh,
    handleFetchRecord, recordData, handleListRecords, handleCreateRecord, searchSidePanelData, handleDeleteRecord, handleTestMerchantConnection, handleEditRecord }) {

    const [displayNotification, setDisplayNotification] = React.useState(null);


    return (
        <Routes>
            <Route path="/" element={<MerchantsIndex
                isLoading={isLoading}
                displayNotification={displayNotification}
                setDisplayNotification={setDisplayNotification}
                onRefresh={onRefresh}
                handleListRecords={handleListRecords}
                handleTestMerchantConnection={handleTestMerchantConnection}
                handleDeleteRecord={handleDeleteRecord}
                data={data} />}
            >
                <Route path="new" element={<MerchantsNew
                    isLoadingSidePanel={isLoadingSidePanel}
                    isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                    searchSidePanelData={searchSidePanelData}
                    setDisplayNotification={setDisplayNotification}
                    handleListRecords={handleListRecords}
                    displayNotification={displayNotification}
                    handleCreateRecord={handleCreateRecord}
                    sidePanelOpen={true} />}
                />

                <Route path=":merchantId" element={<MerchantsEdit
                    isLoadingSidePanel={isLoadingSidePanel}
                    recordData={recordData}
                    handleFetchRecord={handleFetchRecord}
                    setDisplayNotification={setDisplayNotification}
                    handleEditRecord={(merchantId, updateRecord) => {
                        return handleEditRecord(merchantId, updateRecord)
                            .then(() => {
                                setDisplayNotification({
                                    message: "Merchant updated successfully!",
                                    type: "success",
                                });
                            })
                            .catch((e) => {
                                if (
                                    e &&
                                    e.response &&
                                    e.response.data &&
                                    e.response.data.Message
                                )
                                    setDisplayNotification({
                                        message: e.response.data.Message,
                                        type: "error",
                                    });
                                else
                                    setDisplayNotification({
                                        message: "Error updating Merchant!",
                                        type: "error",
                                    });
                            });
                    }}
                    handleTestMerchantConnection={handleTestMerchantConnection}
                    sidePanelOpen={true} />} />



            </Route>
        </Routes>
    );
}

export default MerchantsTemplate;