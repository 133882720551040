import React from 'react';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import DataGrid from './DataGrid';
import SelectConnector from './SelectConnector';
import { DialogActions } from '@mui/material/';

const defaultValues = {
    search: ""
};

export default function LookupPaper({ isLoading, handleClose, handleListRecords, handleChangeLookupValue,
    selectedLookupId, query, columns, data, searchSidePanelType, displayConnectorSelect, defaultConnector, connectors }) {
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [selectedValue, setSelectedValue] = React.useState(null);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    const onClickRow = (e) => {
        setSelectedValue(e.row);
    }

    const onDoubleClick = (e) => {
        onClickRow(e);
        onSubmit();
    }

    const handleChangeConnector = (connectorId) => {
        handleListRecords(searchSidePanelType, { ...query, connectorId: connectorId });
    }

    const onKeyUp = (event) => {
       
        if (event.key === "Enter") {
            return handleListRecords(searchSidePanelType, { ...query, name: formValues.search });
        }
    };
    
    const onSubmit = () => {
        if (selectedValue) {
            handleChangeLookupValue(prevState => {
                return {
                    ...prevState,
                    [selectedLookupId]: {
                        id: selectedValue.id || selectedValue.Id,
                        name: selectedValue.name || selectedValue.Name,
                        url: selectedValue.url || selectedValue.Url,
                        connector: selectedValue.connector || selectedValue.Connector,
                        secondaryKey: selectedValue.secondaryKey || selectedValue.SecondaryKey,
                        extensionData: selectedValue.extensionData || selectedValue.ExtensionData
                    }
                }
            });
        }

        handleClose();
    }

    React.useEffect(() => {
        setFormValues({
            ...formValues,
            search: query.name,
        });
    }, [query]);

    return (
        <Grid marginTop={2} spacing={1} container alignItems="left" justify="center" direction="column">
            {displayConnectorSelect && <Grid item>
                <SelectConnector
                    handleChangeConnector={handleChangeConnector}
                    defaultConnector={defaultConnector}
                    connectors={connectors}
                    disabled={isLoading}
                />
            </Grid>}
            <Grid item>
                <TextField
                    id="search-input"
                    name="search"
                    label="Search"
                    type="text"
                    size="small"
                    sx={{ width: 200 }}
                    disabled={isLoading}
                    value={formValues.search}
                    onChange={handleInputChange}
                    onKeyUp={onKeyUp}
            />
                <Button
                    disabled={isLoading}
                    sx={{ marginLeft: 2 }}
                    onClick={() => {
                        handleListRecords(searchSidePanelType, { ...query, name: formValues.search });
                    }}
                    variant="outlined" >
                <Typography color="black">Search</Typography>
            </Button>
            </Grid>
           
            <Grid item>
                <DataGrid loading={isLoading}
                    onRowClick={onClickRow}
                    onRowDoubleClick={onDoubleClick}
                    columns={columns}
                    rows={data}
                    getRowId={(row) => {
                        return row ? row.Id || row.id : ""
                    }}
                />
            </Grid>
            <Grid item>
                <DialogActions >
                    <Button disabled={isLoading} onClick={onSubmit} variant="outlined" >
                        <Typography color="black">Select</Typography>
                    </Button>
                    <Button onClick={handleClose} variant="outlined" >
                        <Typography color="black">Cancel</Typography>
                    </Button>
                </DialogActions>
                </Grid>
            </Grid>                    
    );
}