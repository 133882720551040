import * as React from "react";
import { Grid, IconButton, Box } from "@mui/material/";
import { styled } from "@mui/material/styles";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import SubtitleFilter from "../molecules/SubtitleFilter";

import BriefCard from "../organisms/BriefCard";

import ExtendedCard from "../organisms/ExtendedCard";
import BriefCardFilter from "../organisms/BriefCardFilter";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import UserContext from "../../UserContext";

const Container = styled("div")({
  p: 2,
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: "auto",
});

const CurvedButton = styled(IconButton)({
  display: "flex",
  maxWidth: "40px",
  maxHeight: "40px",
  backgroundColor: "rgba(255,255,255, .8)",
  borderRadius: "25px",
  boxShadow:
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.4)",
  "&:hover": {
    backgroundColor: "rgba(255,255,255)",
  },
});

// Brief Cards

const BriefContainer = ({ cards, handleFetchData }) => {
  const [briefData, setBriefData] = React.useState(null);
  const [isFiltering, setFiltering] = React.useState(false);

    const userContext = React.useContext(UserContext);


  const [activeIndex, setActiveIndex] = React.useState(0);
  const [currency, setCurrency] = React.useState(0);

  const [startBy, setStartBy] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [finishBy, setFinishBy] = React.useState(
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0, 11, 59, 59)
  );

  const setStartDate = (start) => {
    setStartBy(start);
  };

  const setFinishDate = (finish) => {
    setFinishBy(finish);
  };

  const toggleFiltering = () => {
    setFiltering(!isFiltering);
  };

  const slidePrev = () => {
    if (activeIndex - 1 > 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      setActiveIndex(cards.length);
    }
  };
  const slideNext = () => {
    if (activeIndex < cards.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  };

  const syncActiveIndex = ({ item }) => setActiveIndex(item);

  const fetchData = ({ startBy, finishBy }) => {
    return handleFetchData({
      id: "brief",
      startBy: startBy,
      finishBy: finishBy,
    })
      .then((data) => {
        setBriefData(data);
        // console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
        // TODO: DO THE ERROR NOTIFICATION
      });
  };

  const updateCurrency = (value) => {
    setCurrency(value);
  };

  React.useEffect(() => {
    fetchData({
      startBy: startBy.toISOString(),
      finishBy: finishBy.toISOString(),
    });
  }, [userContext.activeEnvironment]);

  const handleDragStart = (e) => e.preventDefault();

  return (
    <Container style={{ marginBottom: "15px" }}>
      {briefData && (
        <React.Fragment>
          <SubtitleFilter
            title={"Data Cards"}
            filter={{
              start: startBy,
              finish: finishBy,
              format: "Monthly",
              toggleFiltering: toggleFiltering,
            }}
            style={{ textAlign: "center" }}
          />
          <BriefCardFilter
            isFiltering={isFiltering}
            toggleFilter={toggleFiltering}
            data={briefData}
            fetchData={fetchData}
            currency={currency}
            updateCurrency={updateCurrency}
            startDate={startBy}
            finishDate={finishBy}
            updateStart={setStartDate}
            updateFinish={setFinishDate}
          />
        </React.Fragment>
      )}
      <div
        style={{
          width: "100%",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {briefData && (
          <React.Fragment>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: "20px",
                marginRight: "20px",
              }}
            >
              <AliceCarousel
                // autoPlay
                autoPlayInterval={1600}
                animationType="fadeout"
                animationDuration={800}
                infinite
                autoHeight
                disableDotsControls
                disableButtonsControls
                mouseTracking
                activeIndex={activeIndex}
                onSlideChanged={syncActiveIndex}
                responsive={{
                  0: { items: 2 },
                  568: { items: 2 },
                  1105: { items: 3 },
                  1210: { items: 4 },
                  1420: { items: 5 },
                  1820: { items: 6 },
                  // 1920: { items: 7 },
                }}
                items={cards.map((card) => (
                  <BriefCard
                    id={card.id}
                    title={card.title}
                    value={briefData[card.id]}
                    linkTo={card.linkTo}
                    currency={currency}
                    onDragStart={handleDragStart}
                    role="presentation"
                  />
                ))}
              />
            </div>
            <CurvedButton
              style={{
                left: "2px",
                top: "calc(50% - 20px)",
                position: "absolute",
              }}
              onClick={slidePrev}
            >
              <ArrowBackIosIcon />
            </CurvedButton>
            <CurvedButton
              style={{
                right: "2px",
                top: "calc(50% - 20px)",
                position: "absolute",
              }}
              onClick={slideNext}
            >
              <ArrowForwardIosIcon />
            </CurvedButton>
          </React.Fragment>
        )}
      </div>
    </Container>
  );
};
// End Brief Cards

// Extended Cards
const ExtendedContainer = ({ cards, handleFetchData }) => {
  return (
    <Container>
      {/* Layout 1 */}

      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <ExtendedCard
            key={cards[2].id}
            id={cards[2].id}
            chart={{
              height: { xl: 500, lg: 400, md: 300, sm: 200 },
              ...cards[2].chart,
            }}
            handleFetchData={handleFetchData}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <ExtendedCard
            key={cards[0].id}
            id={cards[0].id}
            chart={{
              height: { xl: 400, lg: 350, md: 300, sm: 200 },
              ...cards[0].chart,
            }}
            handleFetchData={handleFetchData}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <ExtendedCard
            key={cards[1].id}
            id={cards[1].id}
            chart={{
              height: { xl: 400, lg: 350, md: 300, sm: 200 },
              ...cards[1].chart,
            }}
            handleFetchData={handleFetchData}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={4}>
          <ExtendedCard
            key={cards[3].id}
            id={cards[3].id}
            chart={{
              height: { xl: 300, lg: 250, md: 225, sm: 200 },
              ...cards[3].chart,
            }}
            handleFetchData={handleFetchData}
          />
        </Grid>
        
        <Grid item xs={12} md={12} lg={4}>
          <ExtendedCard
            key={cards[4].id}
            id={cards[4].id}
            chart={{
              height: { xl: 300, lg: 250, md: 225, sm: 200 },
              ...cards[4].chart,
            }}
            handleFetchData={handleFetchData}
          />
        </Grid>
        
        <Grid item xs={12} md={12} lg={4}>
          <ExtendedCard
            key={cards[5].id}
            id={cards[5].id}
            chart={{
              height: { xl: 300, lg: 250, md: 225, sm: 200 },
              ...cards[5].chart,
            }}
            handleFetchData={handleFetchData}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
// End Extended Cards

// Page
const PageWrapper = styled("div")({
  margin: "10px",
  display: "flex",
  flexFlow: "row wrap",
  height: "auto",
});

function HomeTemplate({ sx, dataBrief, dataExtended, handleFetchData }) {
  return (
    <PageWrapper sx={{ ...sx }}>
      {/* Brief data card container */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <BriefContainer cards={dataBrief} handleFetchData={handleFetchData} />
        </Grid>

        {/* Extended data card container */}
        <Grid item xs={12} md={12} lg={12}>
          <ExtendedContainer
            cards={dataExtended}
            handleFetchData={handleFetchData}
          />
        </Grid>
      </Grid>
    </PageWrapper>
  );
}

HomeTemplate.defaultProps = {
  /*
    dataBrief contains all the charts that are going to be displayed in the home template,
    each object MUST contain an id, 

    chart is optional but requres a title, format and filter.
  */
  dataBrief: [
    { id: "totalCustomers", title: "Total Customers", linkTo: "customers" },
    { id: "totalChurn", title: "Churn Rate", linkTo: "churn-rate" },
    { id: "totalRevenue", title: "Total Revenue", linkTo: "revenue" },
    {
      id: "actionsCreated",
      title: "Actions Created",
      linkTo: "actions-created",
    },
    { id: "actionsFirmed", title: "Actions Firmed", linkTo: "actions-firmed" },
    { id: "actionsPosted", title: "Actions Posted", linkTo: "actions-posted" },
  ],
  dataExtended: [
    {
      id: "customers",
      chart: { title: "Customers", type: "BarChart", format: "Monthly" },
    },
    {
      id: "churn-rate",
      chart: { title: "Churn Rate", type: "BarChart", format: "Monthly" },
    },
    {
      id: "revenue",
      chart: { title: "Revenue", type: "BarChart", format: "Monthly" },
    },
    {
      id: "actions-created",
      chart: { title: "Actions Created", type: "LineChart", format: "Monthly" },
    },
    {
      id: "actions-firmed",
      chart: { title: "Actions Firmed", type: "LineChart", format: "Monthly" },
    },
    {
      id: "actions-posted",
      chart: { title: "Actions Posted", type: "LineChart", format: "Monthly" },
    },
  ],
};
export default HomeTemplate;
