import React from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

export default function SelectConnector({ id, name, label, disabled, connectors, defaultConnector,
    handleChangeConnector }) {
    const [selectValue, setSelectValue] = React.useState("");

    const handleSelectChange = (e) => {
        const { value } = e.target;
        setSelectValue(value);
        handleChangeConnector(value);
    };

    React.useEffect(() => {
        if (defaultConnector) {
            setSelectValue(defaultConnector.id);
        }
    }, []);

    return (<TextField
        select
        id={id}
        name={name }
        label={label }
        size="small"
        sx={{ width: "100%"}}
        disabled={disabled }
        value={selectValue || ""}
        onChange={handleSelectChange}
    >
        {connectors && connectors.length > 0 ? connectors.map((connector) =>
            <MenuItem key={connector.id} value={connector.id}>{connector.name}</MenuItem>)
            :
            <MenuItem key={1} value={null}> No connectors found </MenuItem>}
    </TextField>);
}

SelectConnector.defaultProps = {
    id: "selectconnectorid",
    name: "selectconnectorid",
    label: "Connector",
    disabled: false
}