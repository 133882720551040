import * as React from 'react';
import RibbonLoadingButton from './RibbonLoadingButton';
import { Typography } from '@mui/material/';
import PostAddIcon from '@mui/icons-material/PostAdd';

export default function PostRibbonButton(props) {
    return (
        <RibbonLoadingButton {...props}
            startIcon={
                <PostAddIcon sx={{ color: "black" }} />
            }>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonLoadingButton>
    );
}