import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string, array, bool } from 'yup';

import { DialogActions } from '@mui/material/';
import AutocompleteField from "../ui/organisms/AutocompleteField";

const defaultValues = {
    name: "",
    description: ""
};

let typeSchema = object({
    name: string(),
    isDefault: bool()
});

let reasonCodeSchema = object({
    name: string().required(),
    description: string().required(),
    types: array().of(typeSchema)
});

export default function NewReasonCodeForm({ handleCreateRecord,handleClose, isLoading }) {
    const [formValues, setFormValues] = React.useState(defaultValues);
    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});

    const onSubmit = data => {
        data.preventDefault();

        reasonCodeSchema.validate(formValues, { abortEarly: false })
            .then((data) => handleCreateRecord(data));
    }

    React.useEffect(() => {
        var reasonCodeParsed = reasonCodeSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = value.message;
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="column">
                <Grid item>
                    <TextField
                        id="name-input"
                        name="name"
                        label="Name"
                        type="text"
                        size="small"
                        required
                        disabled={isLoading}
                        value={formValues.name}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <TextField
                        id="description-input"
                        name="description"
                        label="Description"
                        type="text"
                        size="small"
                        required
                        disabled={isLoading}
                        value={formValues.description}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item>
                    <AutocompleteField
                        label="Types"
                        handleChange={(id, values) => {
                            handleInputChange({ target: { name: id, value: values } });
                        }}
                        id="types"
                    />
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Button type="submit" disabled={!isValid || isLoading} variant="outlined" >
                        <Typography color="black">Create</Typography>
                    </Button>
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Cancel</Typography>
                    </Button>
                </DialogActions>
            </Grid>
        </form>
    );
}