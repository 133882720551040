import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";

import { ResponsiveLine } from "@nivo/line";

import SubtitleFilter from "../molecules/SubtitleFilter";
import TemporaryChartPlaceholder from "../molecules/TemporaryChartPlaceholder";
import LineChartTip from "../molecules/LineChartTip";
import LineChartLegends from "../molecules/LineChartLegends";

import _ from "lodash/";
import { renderContinuousColorLegendToCanvas } from "@nivo/legends";

const LineChartCommon = {
  margin: {
    top: 20,
    right: 60,
    bottom: 60,
    left: 60,
  },
  xScale: { type: "point" },
  yScale: { type: "linear", min: "auto", max: "auto" },
  enablePointLabel: true,
  pointSize: 10,
  pointBorderWidth: 2,
  pointColor: { from: "color" },
  pointBorderColor: { from: "color" },
  enableSlices: "x",
  pointLabelYOffset: -12,
  useMesh: true,
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 25,
    legendPosition: "middle",
  },
};

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "height",
})(({ theme, height }) => ({
  width: "auto",
  display: "flex",

  height: height.xl + "px",
  "@media screen and (max-width: 1536px)": {
    height: height.xl + "px",
  },
  "@media screen and (max-width: 1200px)": {
    height: height.lg + "px",
  },
  "@media screen and (max-width:  900px)": {
    height: height.md + "px",
  },
  "@media screen and (max-width: 600px)": {
    height: height.sm + "px",
  },
}));

const LineChart = ({
  id,
  title,
  height,
  data,
  format,
  currency,
  toggleFiltering,
  startDate,
  finishDate,
}) => {
  const BluefortColours = useTheme().colors.bluefort;

  const [chartData, setChartData] = React.useState(null);
  const [chartPoints, setChartPoints] = React.useState(null);
  const [chartFilter, setChartFilter] = React.useState(null);
  const [isEmpty, setEmpty] = React.useState(false);
  const [legends, setLegends] = React.useState(false);


  const setColor = (key) => {
    if (isNaN(key.id)) {
      return BluefortColours[0];
    }

    return BluefortColours[key.id % BluefortColours.length];
  };

  const filterData = (key, index, value) => {
    let t = _.cloneDeep(chartFilter);
    let l = _.cloneDeep(legends);

    if (chartFilter[index].data.length > 0) {
      t[index].data = [];
      setChartFilter(t);
      l[index] = false;
      setLegends(l);
    } else {
      t[index].data = value;
      setChartFilter(t);
      l[index] = true;
      setLegends(l);
    }
  };

  React.useEffect(() => {
    if (!chartFilter) {
      return;
    }

    let array_len = 0;

    chartFilter.map((value) => {
      if (value.data) {
        array_len = array_len + value.data.length;
      }
    });

    setEmpty(array_len <= 0);
  }, [chartFilter]);

  React.useEffect(() => {
    const d = _.cloneDeep(data.data);
    const v = d.currencies ?  _.cloneDeep(d.values[d.currencies[currency]]) : _.cloneDeep(d.values);
    
    setChartData(d);
    setChartFilter(v);
    setChartPoints(v);
    
    let l = _.cloneDeep(v);
    v.map((value, index) => {
      l[index] = true;
    });
    setLegends(l);
  }, [data]);

  return (
    <React.Fragment>
      {chartData && chartFilter && (
        <SubtitleFilter
          title={title}
          filter={{
            toggleFiltering: toggleFiltering,
            format: format,
            start: startDate,
            finish: finishDate,
          }}
        />
      )}
      <Container height={height}>
        <TemporaryChartPlaceholder
          style={{
            height: height.xl - 100 + "px",
            "@media screen and (max-width: 1536px)": {
              height: height.xl - 25 + "px",
            },
            "@media screen and (max-width: 1200px)": {
              height: height.lg - 50 + "px",
            },
            "@media screen and (max-width:  900px)": {
              height: height.md - 75 + "px",
            },
            "@media screen and (max-width: 600px)": {
              height: height.sm - 25 + "px",
            },
          }}
          isEnabled={chartData == null || isEmpty}
        />
        {chartData && chartFilter && !isEmpty && (
          <ResponsiveLine
            {...LineChartCommon}
            data={chartFilter}
            colors={setColor}
            yFormat={value => chartData.currencies ? new Intl.NumberFormat("en-GB", {style: "currency", currency: chartData.currencies[currency]}).format(value) : value}
            axisLeft={{
              orient: "left",
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 25,
              legendPosition: "middle",
              format: value => chartData.currencies ? new Intl.NumberFormat("en-GB", {style: "currency", currency: chartData.currencies[currency]}).format(value) : value,
            }}
            sliceTooltip={LineChartTip}
          />
        )}
      </Container>
      {chartData && chartPoints && chartFilter && (
        <LineChartLegends
          color={BluefortColours}
          data={chartPoints}
          filterData={filterData}
          legends={legends}
        />
      )}
    </React.Fragment>
  );
};

LineChart.defaultProps = {
  height: { xl: 525, lg: 400, md: 300, sm: 200 },
};
export default LineChart;
