const fakeData = [{ "$id": null, "name": "Zontrax", "description": "Alpha", "entityReferences": [], "id": "35c6e2f6-9df6-4d5c-988e-a14dc21cf3cf", "createdBy": "c4646af2-dbd8-4d9e-8960-aaef304b7ae5", "modifiedBy": "92b1aacd-1a28-49e2-adbf-974c30cb12ee", "createdOn": "7/19/2021", "modifiedOn": "7/22/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "It", "description": "Trippledex", "entityReferences": [null, null, null], "id": "d53da77f-304a-46f2-823f-2b47488d2e63", "createdBy": "2842bb1c-2bb1-40a9-a003-b4b975ccbdaa", "modifiedBy": "8ee9b9cb-a398-4c24-8c4e-bf757deb39b2", "createdOn": "2/2/2022", "modifiedOn": "7/27/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Gembucket", "description": "Lotstring", "entityReferences": [null, null, null, null, null], "id": "a5d261fb-395b-4d67-9805-066e7ac4c8c9", "createdBy": "756febc8-829c-44fc-b386-e154794e2db9", "modifiedBy": "845caab0-723d-4a95-8ffb-cca08163c22c", "createdOn": "11/23/2021", "modifiedOn": "6/25/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Bitchip", "description": "Job", "entityReferences": [null], "id": "15e8d865-e9ef-44df-bf40-14f3948259da", "createdBy": "51c7f3ac-c41f-4765-a1a4-71e1ff8ef9a7", "modifiedBy": "dc10ce68-18da-4746-9e9d-ececbd6f01e2", "createdOn": "12/6/2021", "modifiedOn": "7/15/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Span", "description": "Pannier", "entityReferences": [], "id": "378e87ab-58b4-482e-a188-03f84537bbfc", "createdBy": "fc9cb520-37d6-48e2-89d8-bda971593726", "modifiedBy": "d83a11f1-8351-461a-8659-f39331729c71", "createdOn": "8/8/2021", "modifiedOn": "12/20/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Temp", "description": "Bigtax", "entityReferences": [], "id": "add94c62-434c-4b05-b3ec-96d639eea1ad", "createdBy": "dc36e3bd-c8b2-4da1-bd52-f7a9053a3ff4", "modifiedBy": "43efe823-16a2-4f18-8174-cde23bb3e3c8", "createdOn": "7/9/2021", "modifiedOn": "5/19/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Namfix", "description": "Duobam", "entityReferences": [null, null], "id": "9be043e3-9608-437a-9fff-8af25224532a", "createdBy": "d88c1573-ae93-4ce9-9e91-ea049f449f98", "modifiedBy": "82dfc3b4-799a-45b3-9aa9-9be5249c1993", "createdOn": "10/29/2021", "modifiedOn": "10/12/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Flowdesk", "description": "Fixflex", "entityReferences": [null, null, null], "id": "c937aea7-b8a5-46b8-9775-789af3ab0280", "createdBy": "e0c7e1cb-2636-4d4a-8179-a8cb07b4f317", "modifiedBy": "d3c68e3a-6141-4fa9-84c6-5c93d513fe74", "createdOn": "8/15/2021", "modifiedOn": "9/3/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Latlux", "description": "Zamit", "entityReferences": [null, null, null, null, null], "id": "9baba44a-e164-4948-8720-426d46227b9a", "createdBy": "c46db1a2-c92b-4381-a4b6-8ceb04f21f72", "modifiedBy": "852673e5-703e-4b92-b723-36285becb531", "createdOn": "6/8/2021", "modifiedOn": "11/26/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Zamit", "description": "Matsoft", "entityReferences": [], "id": "62c11b8f-25a3-411d-bca0-2b2e13c7b07f", "createdBy": "90b567f9-8801-4a80-acd5-0b37240f1d94", "modifiedBy": "e3ad70aa-e6a3-49e2-9e34-82c65f680b91", "createdOn": "5/13/2021", "modifiedOn": "6/4/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Aerified", "description": "It", "entityReferences": [], "id": "328a6346-ff9d-4e0a-9a10-1c7916dbe7b9", "createdBy": "5e0f666e-db79-4a28-9a19-660260f94668", "modifiedBy": "31b51c9f-db15-481d-9394-db821f7aa01e", "createdOn": "4/10/2021", "modifiedOn": "6/28/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Fix San", "description": "Sub-Ex", "entityReferences": [], "id": "dd6ab688-03a9-415b-80b2-7aa4aea9367a", "createdBy": "0e92d269-1f8e-420b-9988-4ed5429ecb10", "modifiedBy": "2cb32ce0-0b3e-499d-8002-f0c0294136bd", "createdOn": "4/10/2021", "modifiedOn": "4/22/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Tres-Zap", "description": "Trippledex", "entityReferences": [null, null, null, null, null], "id": "b196adc5-6a72-493f-9e9c-3b0e13c6c8ac", "createdBy": "ba1ff38a-e6a4-4a77-9348-88c5018472eb", "modifiedBy": "f151ddf2-abc5-4c2c-944c-6864a4720c0c", "createdOn": "3/22/2021", "modifiedOn": "8/18/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Biodex", "description": "Tres-Zap", "entityReferences": [null, null, null, null, null], "id": "e679cd3b-79d3-4189-8141-6d6d763dae9b", "createdBy": "1d6549cc-31e6-45fd-ab73-e6292cb141f5", "modifiedBy": "7df80672-2ee2-4c94-90e9-2660170e9e59", "createdOn": "3/16/2021", "modifiedOn": "3/10/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Stringtough", "description": "Ronstring", "entityReferences": [], "id": "507ee3e8-1c12-4268-b178-33488d2d93c0", "createdBy": "8d595341-d5fb-4685-9ed3-0d888569afa2", "modifiedBy": "7098bb5a-ede3-4750-99ae-f14648deee6d", "createdOn": "11/1/2021", "modifiedOn": "9/15/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Cardify", "description": "Tresom", "entityReferences": [null, null, null], "id": "b9910b0f-4251-4eb0-a3d0-d1446cfe4865", "createdBy": "2f98e23f-97d6-44db-b23c-acebf8285b2f", "modifiedBy": "3de87d6f-05ad-4583-88c3-7a2cf31d197f", "createdOn": "3/26/2021", "modifiedOn": "1/6/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Ronstring", "description": "Cookley", "entityReferences": [null, null, null, null], "id": "95a1df0a-7b19-4207-98ec-4dc9bcd28202", "createdBy": "6025d206-9c52-43fa-bf9d-e8790251402e", "modifiedBy": "b04e03c4-4f4e-43e3-99fb-03f88c71f09e", "createdOn": "4/12/2021", "modifiedOn": "3/12/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Matsoft", "description": "Alpha", "entityReferences": [null, null, null, null, null], "id": "3a00f894-6f23-4a4e-860d-6862fbbd8ac0", "createdBy": "13753e73-1159-430c-9974-ee53ce5f464e", "modifiedBy": "ba8f1b66-3604-4804-8fd3-eb13799f4c57", "createdOn": "3/21/2021", "modifiedOn": "11/16/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Sub-Ex", "description": "Zamit", "entityReferences": [null, null, null, null, null], "id": "d58104d1-b69b-463f-966a-6bc84a1de5d2", "createdBy": "97745f62-c0ee-4e6c-887a-5833796a5a6e", "modifiedBy": "91df9e2f-4caa-43da-9ceb-9792c01cdab4", "createdOn": "2/25/2022", "modifiedOn": "6/10/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Lotstring", "description": "Trippledex", "entityReferences": [], "id": "9f140d71-698c-43c8-b2af-1da41410bd19", "createdBy": "62f3a69e-1136-429a-8049-22206516b993", "modifiedBy": "c64f130c-020e-446e-b682-8d50730e84d2", "createdOn": "10/22/2021", "modifiedOn": "7/17/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Bitwolf", "description": "Zaam-Dox", "entityReferences": [null, null, null], "id": "0bfaf54f-36f7-4bd5-9466-ce9bc38961ff", "createdBy": "e099fd8b-d2c9-4e9c-adb6-c96e2aa80ab9", "modifiedBy": "60714ab1-d512-404c-9344-0f0af8296931", "createdOn": "12/5/2021", "modifiedOn": "12/5/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Veribet", "description": "Bitwolf", "entityReferences": [null], "id": "f7313306-1a66-4dcd-9f93-369961862eba", "createdBy": "6d0e79ac-c0a4-4c1a-af39-150b8b50c0c6", "modifiedBy": "c58e8db8-6336-4739-a554-f22fe76e25f8", "createdOn": "2/16/2022", "modifiedOn": "5/28/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Temp", "description": "Hatity", "entityReferences": [null, null, null], "id": "d218d1f1-09cb-48f4-bec6-1a26821de421", "createdBy": "b31c727f-8077-4d9e-bca4-9c86f132abb1", "modifiedBy": "d9a5687d-2593-48b8-95eb-509ab91a6d44", "createdOn": "6/13/2021", "modifiedOn": "5/7/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Transcof", "description": "Biodex", "entityReferences": [], "id": "8e13204f-9e24-4786-a110-b1a48d54f7c8", "createdBy": "775fd5cc-8242-4c26-96f4-ca4da68e4867", "modifiedBy": "48f5bf00-4f63-42de-bc7b-6d854242f39d", "createdOn": "5/28/2021", "modifiedOn": "2/5/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Latlux", "description": "Zaam-Dox", "entityReferences": [null, null, null, null, null], "id": "ef2b2892-83fc-496f-a91d-57ab52909035", "createdBy": "16028f13-2956-4947-9091-a4ff1fe58e57", "modifiedBy": "574e9670-3303-4e66-9528-1899d0e6888f", "createdOn": "5/6/2021", "modifiedOn": "11/10/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Daltfresh", "description": "Temp", "entityReferences": [null], "id": "cf3a2023-ab86-4e0c-aaac-c4fb51698172", "createdBy": "aa1d66f2-d7af-475a-8039-a5abd089d691", "modifiedBy": "b1812ff3-acd5-4d4a-b4b3-1729b72f117a", "createdOn": "6/17/2021", "modifiedOn": "11/17/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Flexidy", "description": "Bamity", "entityReferences": [null], "id": "1cb1de19-9288-4d54-838f-17f0be68fb19", "createdBy": "50876247-700c-4fb4-aa2f-a09b34fc97ac", "modifiedBy": "22835abf-d4da-4828-99c9-9ca61c637d49", "createdOn": "10/10/2021", "modifiedOn": "3/1/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Toughjoyfax", "description": "Ventosanzap", "entityReferences": [null], "id": "fdfa52be-280c-4a51-9984-24a6167d9f45", "createdBy": "34d8a30a-e37a-45da-a99a-9d12eca4e0f9", "modifiedBy": "06b93e42-6e42-48fc-b668-8e11f4c6175f", "createdOn": "3/27/2021", "modifiedOn": "3/16/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Hatity", "description": "Greenlam", "entityReferences": [null, null, null], "id": "3f527d31-792e-41c2-b41a-c3a621496513", "createdBy": "0b71b944-e7ba-4c88-ac4c-4b89d7859a24", "modifiedBy": "785acb82-2e3f-4f66-ac46-96e1171b5812", "createdOn": "1/22/2022", "modifiedOn": "9/20/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Temp", "description": "Kanlam", "entityReferences": [null, null], "id": "d423d526-d6b3-496b-b6ea-215288514817", "createdBy": "27ccfdb3-bd81-4340-9625-f04e72a80ff9", "modifiedBy": "739575a8-4c3a-4507-a469-340d22996014", "createdOn": "9/22/2021", "modifiedOn": "12/23/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Cardguard", "description": "Bitchip", "entityReferences": [null, null, null, null], "id": "051f3ca0-0b99-4aa4-b105-145409db7094", "createdBy": "d2d9a00a-fccb-4229-9b33-a1f1c1fa1b84", "modifiedBy": "6a55dfb4-f862-4675-b8be-cd00ca0ee224", "createdOn": "11/13/2021", "modifiedOn": "12/20/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Aerified", "description": "Zoolab", "entityReferences": [null, null, null], "id": "12beccfa-51b7-4642-aa69-d5ff51f1c640", "createdBy": "755f14f4-59c5-4f51-8a74-ba4aad67af4b", "modifiedBy": "2e3a503d-6b55-456b-bef6-8f7e9e45a7f6", "createdOn": "1/10/2022", "modifiedOn": "9/5/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Bitchip", "description": "Bitchip", "entityReferences": [null, null, null, null], "id": "1b472d1d-6a18-4aa9-8776-77195c68034f", "createdBy": "20ee2b47-605c-4670-88e7-4fa39f152df2", "modifiedBy": "d8957347-4f4e-4a2d-9426-74a68e66462d", "createdOn": "3/21/2021", "modifiedOn": "10/8/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Y-Solowarm", "description": "Konklab", "entityReferences": [null, null], "id": "fe1ebf3f-2b45-40ad-a4bf-a5ada67c299a", "createdBy": "7fa94f94-98e9-4a7e-b954-6a64056fc816", "modifiedBy": "8ddde7f6-1990-4171-b7ee-6809c961052c", "createdOn": "5/17/2021", "modifiedOn": "8/2/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "It", "description": "Stronghold", "entityReferences": [null, null], "id": "0e79a8ef-4b06-4bdf-8278-e8bc46b8baf3", "createdBy": "d614ec6d-cc85-49ba-942a-a2cff43f3df7", "modifiedBy": "984946f3-4d2b-4c89-951d-0441fa9d3369", "createdOn": "12/16/2021", "modifiedOn": "9/17/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Prodder", "description": "Keylex", "entityReferences": [null, null, null], "id": "2745e9d3-4ae3-48fa-bf27-085844a7a9a9", "createdBy": "e34973e7-2a32-42bc-9823-d23ea55b742c", "modifiedBy": "833fb824-ca9a-4762-9c23-913780d456b2", "createdOn": "5/15/2021", "modifiedOn": "4/21/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Quo Lux", "description": "Quo Lux", "entityReferences": [null, null, null, null, null], "id": "5ec2e2fe-6479-4024-bd73-765c5d0b1d94", "createdBy": "ea664858-d604-4011-9759-cf192a8a5a29", "modifiedBy": "cdd6975b-de3c-4240-b3c3-904056ee8fad", "createdOn": "6/7/2021", "modifiedOn": "7/26/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Transcof", "description": "Transcof", "entityReferences": [null], "id": "f46546f1-e6ae-4782-ac45-3e0f8511f228", "createdBy": "4d9a7b62-bcae-488d-9d89-ddf1be838c3e", "modifiedBy": "9f1dd17c-f5da-43d5-bdb0-c2bdd929b8ad", "createdOn": "4/3/2021", "modifiedOn": "7/2/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Overhold", "description": "Sonsing", "entityReferences": [null, null], "id": "3b11e7e1-a5e4-47ba-a88b-39c4f7b78e5f", "createdBy": "a364a908-8500-4cd1-b319-da863debb1c6", "modifiedBy": "b53f6694-27cd-47a8-9c1a-26f0be2f32bb", "createdOn": "2/5/2022", "modifiedOn": "9/6/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Tres-Zap", "description": "Toughjoyfax", "entityReferences": [null, null], "id": "c3dffa85-24bd-4757-8f6e-c7cb648ab373", "createdBy": "149f6957-b9fa-488f-83a4-2e8a3693bfff", "modifiedBy": "56b29d9b-d789-476c-be54-db92ab48818d", "createdOn": "8/2/2021", "modifiedOn": "8/13/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Prodder", "description": "Fix San", "entityReferences": [null, null, null, null], "id": "f872918f-69be-44da-a853-794eb677e419", "createdBy": "76944be0-82da-4f6b-8855-7d19cc3668f8", "modifiedBy": "65e59ea9-2183-4571-a3c2-beb46edaf8d0", "createdOn": "9/11/2021", "modifiedOn": "10/31/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Keylex", "description": "Wrapsafe", "entityReferences": [null, null, null, null, null], "id": "123094ca-5b8f-4fec-b80f-064ff00f6d61", "createdBy": "fa8ccf72-a955-44b6-9921-d9df698191ed", "modifiedBy": "4e30ab7a-3592-4e1d-b46d-27e0b2baf4a9", "createdOn": "4/21/2021", "modifiedOn": "12/26/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Redhold", "description": "Cardguard", "entityReferences": [null, null], "id": "d62afbe6-c6d5-4642-aab5-486d217448dc", "createdBy": "7b0c2f49-c21c-4681-97ee-e5ff571d5d3a", "modifiedBy": "926d236a-51a6-42a7-b60b-5d6435cd0570", "createdOn": "8/12/2021", "modifiedOn": "10/2/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Ventosanzap", "description": "Konklux", "entityReferences": [null, null], "id": "22f99ae4-ca83-4ec8-859b-2a946bedc426", "createdBy": "f000f1ce-000c-480b-8eb2-4fa379d88ba5", "modifiedBy": "060834ff-7d43-4a52-8355-8a7a730b73bd", "createdOn": "10/6/2021", "modifiedOn": "7/23/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Veribet", "description": "Solarbreeze", "entityReferences": [null, null, null, null, null], "id": "022431a5-3e17-4dcc-a716-b9cd546ac0a2", "createdBy": "264b46fe-7292-4810-bf5d-a263388a146d", "modifiedBy": "d8c81533-d13d-490a-8979-5cd039db2f14", "createdOn": "4/19/2021", "modifiedOn": "9/1/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Quo Lux", "description": "Domainer", "entityReferences": [null, null, null, null], "id": "ca8c1825-2205-4c07-bdff-e7f2ed4d291d", "createdBy": "bf1fc033-613a-4f07-b83d-9ba470fabe7c", "modifiedBy": "a95c2400-0a9f-4755-ab2e-6761e18ca8cb", "createdOn": "7/13/2021", "modifiedOn": "5/22/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Tresom", "description": "Veribet", "entityReferences": [null, null, null, null, null], "id": "89f194c7-d548-416d-ba38-08daae81040d", "createdBy": "fbead671-bd70-401a-8a55-4f7aa02689c8", "modifiedBy": "b3419e63-6e0b-4fb6-8f2d-1b34f97afcda", "createdOn": "10/7/2021", "modifiedOn": "9/3/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Stringtough", "description": "Home Ing", "entityReferences": [null], "id": "01386d2b-b08e-4aca-919e-31eb079bbdf2", "createdBy": "1e5eff7e-4676-4b90-98ca-0c17a25fed9d", "modifiedBy": "4db9106c-15af-47ce-8a20-6e1bed7b0902", "createdOn": "12/9/2021", "modifiedOn": "3/29/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Bitchip", "description": "Vagram", "entityReferences": [], "id": "8a509e90-6067-4d6a-a2c9-1e27c82997fb", "createdBy": "53ac6793-7cc9-43e6-bd16-da1993b994cc", "modifiedBy": "aabe0f74-17cd-443e-916d-c9668109dcfe", "createdOn": "10/9/2021", "modifiedOn": "4/24/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Transcof", "description": "Zathin", "entityReferences": [null, null, null, null], "id": "440308c5-9452-4e00-b355-6227f193f40f", "createdBy": "39fe6622-6c13-4c96-acf4-036ebfac5f60", "modifiedBy": "08a6b83a-005e-4f7c-a3f8-768e129ed767", "createdOn": "3/14/2021", "modifiedOn": "2/21/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Tres-Zap", "description": "Cardify", "entityReferences": [null, null, null], "id": "17cfd3e0-cefa-4272-8305-34e3c921e6ce", "createdBy": "0baee986-6c39-429c-a5ad-23ec5048cac3", "modifiedBy": "9110e2cc-8ae6-4f43-b624-f6b19a067647", "createdOn": "12/29/2021", "modifiedOn": "12/31/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Viva", "description": "Konklux", "entityReferences": [], "id": "f81d2994-7ccf-4b33-b895-0a6f6ebc89c3", "createdBy": "741e4525-56db-44ae-95cc-34572f840b63", "modifiedBy": "23f5b0c9-f9ff-4944-9303-25ec4f0fb29b", "createdOn": "11/14/2021", "modifiedOn": "2/6/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Zamit", "description": "It", "entityReferences": [null], "id": "946145f1-d3a5-4dcd-b669-97dfa669f579", "createdBy": "2c8e5ca2-ebcd-49f9-b9e0-a4c1cbfd6722", "modifiedBy": "04c1fb9b-f9bd-4b8d-9060-3961acd6baa5", "createdOn": "12/29/2021", "modifiedOn": "12/11/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Prodder", "description": "Greenlam", "entityReferences": [null, null, null], "id": "fb5fb54f-4df5-4475-96d1-7d19d68ac299", "createdBy": "084cdafe-b6e8-40c3-8f82-b31737490f8c", "modifiedBy": "b3bb4baa-072f-4837-b459-bdcf28401206", "createdOn": "9/17/2021", "modifiedOn": "5/27/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Flowdesk", "description": "Bamity", "entityReferences": [null, null, null, null], "id": "47e6c9a3-f3cc-4ec9-963d-62183ece7fbc", "createdBy": "edeae618-4e89-4958-a338-9aeaaf9ea564", "modifiedBy": "8f370eb1-3df4-471b-b6d9-7619a2679915", "createdOn": "2/6/2022", "modifiedOn": "11/7/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Asoka", "description": "Subin", "entityReferences": [], "id": "e4766663-4b64-447b-b761-1552a6f97cf4", "createdBy": "5460c078-2b2b-468f-98c0-8a82e50f559e", "modifiedBy": "4dce9ab7-c379-4a82-b358-09f9116c14da", "createdOn": "9/29/2021", "modifiedOn": "7/18/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Holdlamis", "description": "Cookley", "entityReferences": [null], "id": "a5be7eff-864c-4652-8617-1c650b2a1908", "createdBy": "33b6d050-989b-46fb-a81b-32fa523e8aa5", "modifiedBy": "7180a5c0-4180-4a9a-aec4-9c122b2eb7f7", "createdOn": "5/27/2021", "modifiedOn": "12/20/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Sonair", "description": "Home Ing", "entityReferences": [], "id": "142f21a0-9b9c-4523-bc86-5601d9fa2724", "createdBy": "99525e71-20c8-4193-a253-041ed8952f23", "modifiedBy": "e9a57e02-7c0c-44a7-9eeb-38afb2dda96a", "createdOn": "7/1/2021", "modifiedOn": "5/15/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Asoka", "description": "Kanlam", "entityReferences": [null, null, null], "id": "357c4db4-fd36-4bbf-897b-c5a48c639400", "createdBy": "f8112f1d-2927-4a7c-846b-3a340805bfe4", "modifiedBy": "17ab90e2-b01c-4189-8e34-88031dae844c", "createdOn": "9/22/2021", "modifiedOn": "7/2/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Tin", "description": "Lotstring", "entityReferences": [null, null, null], "id": "5b03d93b-a238-4676-a32e-88aad3d34804", "createdBy": "6c7ac013-c90f-4559-bc72-5742fb75b905", "modifiedBy": "8ac39be9-5b4b-4703-b1c6-ee24674232fb", "createdOn": "7/7/2021", "modifiedOn": "9/26/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Stronghold", "description": "Veribet", "entityReferences": [null, null, null, null, null], "id": "9f7b99b9-c3da-46db-a674-9e3f0d746462", "createdBy": "91c6fee7-b088-4ca6-9dd6-7f251ac437e1", "modifiedBy": "a47cf153-e102-4307-b4a8-32904d55190f", "createdOn": "7/1/2021", "modifiedOn": "7/5/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "It", "description": "Sonsing", "entityReferences": [null, null, null, null], "id": "19c68d87-5f23-4208-8352-2189d05c12b7", "createdBy": "002d5138-7cc4-4788-989a-0d5b7edf6d82", "modifiedBy": "c03a0102-0356-4254-bee4-05718c3e0180", "createdOn": "2/27/2022", "modifiedOn": "8/21/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Fix San", "description": "Sonsing", "entityReferences": [null, null, null], "id": "dc667e32-85b8-4e9f-9116-cbc07b55921d", "createdBy": "dd2f6aed-0b57-4ca4-899c-918f9a44a639", "modifiedBy": "a41a77b6-1411-47cd-bdbe-bc94aae41c13", "createdOn": "2/4/2022", "modifiedOn": "8/2/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Alphazap", "description": "Greenlam", "entityReferences": [], "id": "b07b2148-ba95-4efc-ace8-c7610980973c", "createdBy": "fc1d5790-0d13-4f4a-80d5-8eb0e9fdec1c", "modifiedBy": "074cae11-6f1b-4de4-8b8a-f669fd2876c2", "createdOn": "4/24/2021", "modifiedOn": "3/20/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Zontrax", "description": "Bigtax", "entityReferences": [null], "id": "d46e0480-d3dc-41a1-8d93-ebe3f2bcc9b7", "createdBy": "14251a35-0e63-48c9-b3ab-821ab8851e47", "modifiedBy": "2059f069-829f-48f8-b37a-1b0f53048f73", "createdOn": "7/31/2021", "modifiedOn": "10/7/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Viva", "description": "Pannier", "entityReferences": [null, null, null], "id": "a49ab670-e7ae-4623-bb18-340912348c4b", "createdBy": "1ff1fc68-5597-459e-be3d-b7714645ad71", "modifiedBy": "c0a19d3f-6e8e-4ed6-bf21-8a0d6292565c", "createdOn": "11/5/2021", "modifiedOn": "1/18/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Bigtax", "description": "Stringtough", "entityReferences": [null, null, null], "id": "5d6ba1be-27b1-493d-8fff-bb71de814f25", "createdBy": "41e6c3a3-7589-4ff9-8d02-926ae315a42b", "modifiedBy": "43210fe2-db15-4580-9da5-6804d95d8cd6", "createdOn": "9/19/2021", "modifiedOn": "5/1/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Hatity", "description": "Tresom", "entityReferences": [null, null, null], "id": "6c1af113-322f-4cb9-88e6-30cb8e2365ed", "createdBy": "ec84e97f-f1fa-4d65-b02c-8d74da73718f", "modifiedBy": "60611103-33f3-417c-b72d-c0c5621661b8", "createdOn": "5/27/2021", "modifiedOn": "9/2/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Y-Solowarm", "description": "It", "entityReferences": [null], "id": "fe81f022-5120-442b-b781-b1420719eb5c", "createdBy": "16b515e9-580e-406e-8c52-8d4580af4929", "modifiedBy": "64d6e207-41f8-4821-b8fa-ab65e0296d8b", "createdOn": "12/28/2021", "modifiedOn": "10/3/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Wrapsafe", "description": "Fix San", "entityReferences": [null, null, null, null], "id": "a894c613-18d1-43e0-ae45-77fdb79c162f", "createdBy": "247396a3-163d-46fb-b0a0-f47d351e9ccc", "modifiedBy": "833b4cd2-0dcc-4744-8034-f60302e89e9a", "createdOn": "10/19/2021", "modifiedOn": "3/8/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Sonair", "description": "Ventosanzap", "entityReferences": [null], "id": "0794a0d9-96a3-4f29-b4f8-4abd1604eb52", "createdBy": "6738efe2-6ae9-43e0-89b1-3a057f9d9513", "modifiedBy": "e042eece-c3b1-40eb-9e0e-76890a7f61db", "createdOn": "3/24/2021", "modifiedOn": "5/3/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Fixflex", "description": "Matsoft", "entityReferences": [null], "id": "b17938ad-0b1b-490c-a560-dd6b1ebd43ff", "createdBy": "6d137d78-1432-430e-b75a-63bf80b1ccd3", "modifiedBy": "b51a91de-4b69-4885-b241-8e2545759206", "createdOn": "5/8/2021", "modifiedOn": "9/25/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Opela", "description": "Gembucket", "entityReferences": [null, null], "id": "188cb289-b9fc-472e-b71b-86e9195411fb", "createdBy": "ea798fe8-24c3-44ec-aa34-08ff0a8a58f0", "modifiedBy": "e710b589-36f4-46be-ade3-ef7f33b09e9c", "createdOn": "12/4/2021", "modifiedOn": "7/12/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Andalax", "description": "Trippledex", "entityReferences": [null, null, null], "id": "d0bd9107-1745-40ff-b14b-1ccc9fd4b9d8", "createdBy": "1e7eb4a2-e1e0-4340-b170-65c9680436b9", "modifiedBy": "912d0648-1d52-4142-b410-029589610116", "createdOn": "2/23/2022", "modifiedOn": "8/22/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Bamity", "description": "Opela", "entityReferences": [null, null, null], "id": "28153a1f-ae06-43f0-b81a-3d9818bb64f8", "createdBy": "d889c40b-852b-464a-8e06-d1fdcf8a4e63", "modifiedBy": "e01ca050-474d-42e8-85d5-e8c226c7de30", "createdOn": "8/16/2021", "modifiedOn": "6/11/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Daltfresh", "description": "It", "entityReferences": [null, null, null], "id": "cf8cc595-9e26-41d8-b556-f43d887dcbfe", "createdBy": "3c8b49b5-44ba-4bd5-a49b-0317ac22b2a4", "modifiedBy": "9d5441e2-6f8d-40ee-8021-21563aafed77", "createdOn": "4/12/2021", "modifiedOn": "1/8/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Vagram", "description": "Stim", "entityReferences": [null, null, null, null, null], "id": "5b641011-63bd-46d8-a06a-230d8304d7b5", "createdBy": "90fed34c-6431-4f11-8073-abcf100288b1", "modifiedBy": "4703f1a8-1f31-4735-8641-23fb1be98aa0", "createdOn": "9/26/2021", "modifiedOn": "9/27/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Job", "description": "Transcof", "entityReferences": [null], "id": "d451c3fe-184c-4ea2-a7f1-126f6db58dc6", "createdBy": "e2d637c8-8813-4808-86d5-ef7b336ee5a7", "modifiedBy": "b2615bf8-d963-41ba-8b04-985c93446f23", "createdOn": "7/3/2021", "modifiedOn": "4/24/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Mat Lam Tam", "description": "Zontrax", "entityReferences": [null, null, null, null, null], "id": "5de2bc8a-11e0-4acd-b39c-930ab4093bdc", "createdBy": "dcda94f3-02fe-402a-98fa-881c67fd5624", "modifiedBy": "bb6db5e4-4401-4846-bafa-36d1ac23e35a", "createdOn": "3/4/2022", "modifiedOn": "7/10/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Subin", "description": "Bitchip", "entityReferences": [null, null, null, null, null], "id": "538b67fc-8314-4467-b631-a5adc5f4985e", "createdBy": "3f84d243-0734-458a-addf-962b1c16c9ec", "modifiedBy": "771a5f64-18e8-4496-b8ee-a27747b824c1", "createdOn": "1/3/2022", "modifiedOn": "9/26/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Stronghold", "description": "Zathin", "entityReferences": [null], "id": "a2360595-e083-4bfa-8358-233d40188d8b", "createdBy": "8f2be990-a9bf-4ba6-a5a9-3d82faf40eab", "modifiedBy": "b534b2f8-a968-46b1-9f07-a3d2989d2c2c", "createdOn": "3/29/2021", "modifiedOn": "11/5/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Overhold", "description": "Bytecard", "entityReferences": [null, null, null], "id": "60bd91bc-b741-4ff9-b920-ed84e30c359f", "createdBy": "55916759-545c-410b-b1ff-5689a880dc59", "modifiedBy": "f057d31e-ad17-4a89-9063-cc9781c8e9e8", "createdOn": "3/1/2022", "modifiedOn": "2/4/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Y-find", "description": "Gembucket", "entityReferences": [null, null, null], "id": "3be1b3d3-4359-490d-9ff1-54b196848e75", "createdBy": "dec8fc17-b268-4f7f-94bb-354bd8689f6f", "modifiedBy": "c44504f2-4757-48d2-82df-f3cab519c382", "createdOn": "1/18/2022", "modifiedOn": "7/24/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Job", "description": "Andalax", "entityReferences": [null, null, null], "id": "d52315fd-b52e-489c-83be-55ee42d6b8ab", "createdBy": "03351ad0-b39a-464d-8721-adfedf79f04e", "modifiedBy": "df91cd18-7f64-4db4-847d-2a7d6f4652fd", "createdOn": "10/15/2021", "modifiedOn": "11/3/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Tin", "description": "Y-find", "entityReferences": [null, null, null, null], "id": "b6aded97-114f-4eea-bb2d-96bedf5abe64", "createdBy": "1312698d-27a7-44f1-a4a4-fefa01e21a0c", "modifiedBy": "48bd8a85-707a-4fe5-b977-c32a02d1f07e", "createdOn": "7/26/2021", "modifiedOn": "4/11/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Zathin", "description": "Cardguard", "entityReferences": [null], "id": "731a916a-15bd-451b-bf04-0e9c902d467d", "createdBy": "029cfa94-2eaa-4afe-b6d4-f069ca675f0c", "modifiedBy": "83b15efd-1e90-4158-8f91-8b6f8b011b7a", "createdOn": "4/15/2021", "modifiedOn": "8/18/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Tres-Zap", "description": "Quo Lux", "entityReferences": [null, null, null, null], "id": "6eed329b-6e12-4a8d-8c22-7f785b3558bb", "createdBy": "224dd3e9-0cfd-42a9-aacb-b49ae70e6b39", "modifiedBy": "a23b9b07-9f07-4bd6-a349-5825661bc774", "createdOn": "1/8/2022", "modifiedOn": "2/18/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Stronghold", "description": "Trippledex", "entityReferences": [null, null, null, null, null], "id": "c705eb96-5ed1-4ce3-ac5a-ab256e690c26", "createdBy": "001047c9-0343-4b3a-91d2-441d2b5fa3ea", "modifiedBy": "723a6221-717d-4295-95ee-7dc5871c9cd7", "createdOn": "8/8/2021", "modifiedOn": "1/4/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Ventosanzap", "description": "Trippledex", "entityReferences": [null, null], "id": "796aaa24-fec0-4d1f-b058-9b17ece01e23", "createdBy": "62dbbfac-1fc4-4aff-bd44-e4927d3fae34", "modifiedBy": "080fb65b-291c-4182-b219-0277307b30a1", "createdOn": "11/10/2021", "modifiedOn": "1/11/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Zoolab", "description": "Cardify", "entityReferences": [], "id": "d8fd5e8a-0fb2-43d9-83ef-98871a7276bd", "createdBy": "7f0a983b-d00a-4ba4-bf57-13117bfa583d", "modifiedBy": "4d7d2c7a-2476-40e4-be33-8d01c5708526", "createdOn": "5/25/2021", "modifiedOn": "8/2/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Sub-Ex", "description": "Flowdesk", "entityReferences": [null, null], "id": "988b9480-7b05-40e4-b3db-ab09f37feb7c", "createdBy": "75d44d48-0941-4da9-a928-a69336be15a2", "modifiedBy": "688b2e13-8ead-4fb7-bf66-10a035260bd1", "createdOn": "1/24/2022", "modifiedOn": "4/10/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Y-find", "description": "Transcof", "entityReferences": [], "id": "df940efb-62ca-4302-9030-8877243b566a", "createdBy": "4f5b83f7-cbfb-4fb5-8bb0-a18755907d70", "modifiedBy": "7531d3e5-acfd-406c-89d5-ce0943af28dc", "createdOn": "2/28/2022", "modifiedOn": "11/10/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Bigtax", "description": "Matsoft", "entityReferences": [null, null, null, null], "id": "52b05bb8-126f-46c9-beae-87b8c79d3059", "createdBy": "518f6acf-4615-457f-9140-e7c22fcc38a6", "modifiedBy": "4041c510-c4e7-44c1-9f85-dc6939b56da7", "createdOn": "5/11/2021", "modifiedOn": "8/9/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Andalax", "description": "Bigtax", "entityReferences": [], "id": "5dc93fd2-975d-46c6-a4e1-1c991e7129de", "createdBy": "42ea0cd7-30ee-4834-8863-09d8dcabc00d", "modifiedBy": "08a9b19e-c522-43c2-83af-383233653383", "createdOn": "5/29/2021", "modifiedOn": "1/30/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Job", "description": "Quo Lux", "entityReferences": [null, null, null, null], "id": "58c85d84-9cbe-4350-ab7d-bfb5bb1925dd", "createdBy": "99acce49-f362-4d7f-992a-99932ff6ae0e", "modifiedBy": "c1e06eb1-28a8-4fa4-af96-adfed631cd51", "createdOn": "10/24/2021", "modifiedOn": "9/29/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Rank", "description": "Ronstring", "entityReferences": [null, null, null, null, null], "id": "e5b41941-3343-48d4-a27b-10cab4fa2544", "createdBy": "8d1d4ff9-72b3-4fc9-9940-8e9ecc30a15e", "modifiedBy": "9593880e-e026-44b9-9a88-8f64674fbc03", "createdOn": "5/13/2021", "modifiedOn": "8/18/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Cardify", "description": "Solarbreeze", "entityReferences": [null, null, null, null], "id": "9463f8e7-7592-4b87-9d3c-d4117a6cff83", "createdBy": "9e018e17-d1ad-460a-96f1-10d0a663a662", "modifiedBy": "1df2afca-cf2c-4e96-8d1b-c66a3a5eec2e", "createdOn": "7/16/2021", "modifiedOn": "2/3/2022", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Sonair", "description": "Lotstring", "entityReferences": [], "id": "3b5b276a-1190-4630-8461-18eaa352aefd", "createdBy": "469de5b0-5405-48cd-8c9d-97e8e8e45b3e", "modifiedBy": "30d597a5-e789-4250-a710-d72dc8954fa9", "createdOn": "12/27/2021", "modifiedOn": "7/1/2021", "deletedOn": null, "tenantId": null },
{ "$id": null, "name": "Stringtough", "description": "Fintone", "entityReferences": [null, null, null], "id": "4f587589-2d05-488a-a09d-b33fa0107cad", "createdBy": "8de83973-42af-47af-854f-1ce3ddf08353", "modifiedBy": "c04503ea-471b-4956-adf3-50f77327cdd9", "createdOn": "4/29/2021", "modifiedOn": "7/31/2021", "deletedOn": null, "tenantId": null },
    { "$id": null, "name": "Tin", "description": "Stronghold", "entityReferences": [], "id": "17cc2b70-43b4-4d32-9a26-7b0ace30ec83", "createdBy": "2ff2348d-48a3-4ddb-ab90-8d9a64311d91", "modifiedBy": "57429a7e-4654-421a-b1f8-de7a34fe54eb", "createdOn": "9/26/2021", "modifiedOn": "10/6/2021", "deletedOn": null, "tenantId": null }];

export { fakeData };