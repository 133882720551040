// A temporary solution for displaying chart placeholder
import * as React from "react";
import { Paper, Fade } from "@mui/material/";
import { styled } from "@mui/material/styles";

const BlankBackground = styled(Paper)({
  backgroundColor: "#B9B9B9",

  transformOrigin: "center",
  transform: "scale(1, 1)",

  position: "absolute",
  display: "flex",

  width: "100%",
});

const Info = styled("span")({
  transform: "scale(1, 1)",
  width: "100%",
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
});

// const Desc = styled("span")({
//     transform: "scale(1, 1)",
//     width: "100%",
//     textAlign: "center",
//     display: "flex",
//     justifyContent: "center",
//     alignItems: "center",
//     fontSize: "15px",
//     color: "#656565"
// })

const TemporaryChartPlaceholder = ({ style, isEnabled }) => {
  return (
    /*TODO: Reason Code */
    <Fade in={isEnabled}>
      <BlankBackground sx={{ ...style }}>
        <Info>No Data to Display</Info>
        {/* <Desc>Please make sure that REASON_CODE</Desc> */}
      </BlankBackground>
    </Fade>
  );
};

export default TemporaryChartPlaceholder;
