import * as React from 'react';
import { Grid, Paper, Typography, Snackbar, Alert } from '@mui/material/';
import DataGrid from '../organisms/DataGrid';
import { styled } from '@mui/material/styles';
import NewRibbonButton from '../molecules/NewRibbonButton';
import RefreshRibbonButton from '../molecules/RefreshRibbonButton';
import DeleteRibbonButton from '../molecules/DeleteRibbonButton';
import ConfirmDialog from '../molecules/ConfirmDialog';
import SidePanel from '../organisms/SidePanel';
import NewProgramTypeForm from '../../forms/programtype/NewProgramTypeForm';
import ViewProgramTypeForm from '../../forms/programtype/ViewProgramTypeForm';
import { Route, Routes, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns'
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams'
import ProfileCard from '../organisms/ProfileCard'
import NotificationSnackBar from '../organisms/NotificationSnackBar';

const GridParent = styled(Grid)(({ theme }) => ({

}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
    padding: 3,
    paddingLeft: 30,
    display: 'inline-flex'
}));

const GridItem = styled(Grid)(({ theme }) => ({
    padding: 16,
    paddingLeft: 30
}));

const columns = [
    { field: 'name', headerName: 'Name', flex: 2, minWidth: 100 },
    {
        field: 'periodType', headerName: 'Period Type', flex: 1, minWidth: 100, type: 'singleSelect',
        valueOptions: ["Calendar", "Rolling"]
    },
    {
        field: 'subscriptionType', headerName: 'Subscription Type', flex: 1, minWidth: 100, type: 'singleSelect',
        valueOptions: [
            { label: "Per Unit Subscription", value: "PerUnitSubscription" },
        ],
        valueFormatter: (params) => {
            if (params.value === "PerUnitSubscription") return "Per Unit Subscription";
            if (params.value === "PerpetualEnhancementPlan") return "Perpetual Enhancement Plan";

            return params.value
        }
    },
    {
        field: 'createdOn', headerName: 'Created On', flex: 1, minWidth: 100, type: "dateTime",
        valueFormatter: (params) => { return params.value ? format(new Date(params.value), "dd/MM/yyyy") : params.value; }
    },
    {
        field: 'modifiedOn', headerName: 'Modified On', flex: 1, minWidth: 100, type: "dateTime",
        valueFormatter: (params) => { return params.value ? format(new Date(params.value), "dd/MM/yyyy") : params.value; }
    },
];

function ProgramTypeIndex({ isLoading, data, onRefresh, displayNotification, setDisplayNotification, handleDeleteRecord }) {

    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [displayDelete, setDisplayDelete] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState({});
    const [selectedRow, setSelectedRow] = React.useState(null);

    let navigate = useNavigateWithParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const isLayoutEmbedded = () => {
        return searchParams.get("layout") === "embedded";
    };

    const onClickRow = (params) => {
        setDisplayEdit(true);
        setDisplayDelete(true);
        setSelectedRow(params.row);
    };

    const onDoubleClickRow = (params) => {
        openRecord();
    };
    const deleteRecord = () => {
                            //setDisplayNotification({ message: "Program Type deleted successfully", type: "success" });
        setConfirmDialog({
            title: "Delete Program Type ?",
            description: `Are you sure you want to delete ${selectedRow.name}?`,
            actionConfirm: () => {
                setConfirmDialog({});
                handleDeleteRecord(selectedRow)
                    .then(() => {
                        setDisplayNotification({ message: "Program Type deleted successfully", type: "success" });
                    })
                    .catch((e) => {
                        setDisplayNotification({ message: e.response.data.Message, type: "error" });
                    })
            },
            actionCancel: () => {
                setConfirmDialog({});
            }
        });

    };

    const createRecord = () => {
        navigate("new");
    };

    const openRecord = () => {
        navigate(`${selectedRow.id}`);
    };

    const refreshGrid = () => {
        setSelectedRow(null);
        setDisplayEdit(false);
        setDisplayDelete(false);
        onRefresh();
    }

    return (<React.Fragment>
        <GridParent
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch">
            {confirmDialog && confirmDialog.title && <ConfirmDialog confirmDialogInfo={confirmDialog} onConfirm={confirmDialog.actionConfirm} onCancel={confirmDialog.actionCancel} />}
            <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
                <NewRibbonButton onClick={createRecord} display={true} title="New Program Type" />
                <RefreshRibbonButton onClick={refreshGrid} display={true} title="Refresh" />
                <DeleteRibbonButton onClick={deleteRecord} display={displayDelete} title="Delete" />
                {isLayoutEmbedded() && (<ProfileCard embeddedMode={true} />)}
            </RibbonGrid>
            <GridItem item xs={12} md={12} lg={12}>
                <Typography>Program Types</Typography>
            </GridItem>
            <GridItem item xs={12} md={12} lg={12}>
                <DataGrid loading={isLoading} onRowClick={onClickRow} onRowDoubleClick={onDoubleClickRow} columns={columns} rows={data} />
            </GridItem>

        </GridParent>
        {displayNotification !== null && (<NotificationSnackBar
            displayNotification={displayNotification}
            onClose={() => setDisplayNotification(null)}
        />)}       
        <Outlet />
    </React.Fragment>);
}

function ProgramTypeNew({ sidePanelOpen, isLoadingSidePanel, isLoadingSearchSidePane, handleCreateRecord, setDisplayNotification, handleListRecords,
    searchSidePanelData}) { 

    let navigate = useNavigateWithParams();

    return (
        <SidePanel open={sidePanelOpen} handleClose={() => navigate("../")} isLoading={isLoadingSidePanel} title="New Program Type">
            <NewProgramTypeForm
                isLoading={isLoadingSidePanel}
                isLoadingSearchSidePane={isLoadingSearchSidePane}
                handleClose={() => navigate("../")}
                handleListRecords={handleListRecords}
                searchSidePanelData={searchSidePanelData}
                handleCreateRecord={(record) => {
                    return handleCreateRecord(record).
                        then((data) => {
                            setDisplayNotification({message: "Program Type created successfully", type: "success"});
                            navigate("../");
                        })
                }} />
        </SidePanel>);
}

function ProgramTypeView({ isLoadingSidePanel, sidePanelOpen, handleFetchRecord, setDisplayNotification }) {
    let navigate = useNavigateWithParams();

    let { programTypeId } = useParams();
    const [recordData, setRecordData] = React.useState(null);

    React.useEffect(() => {
        return handleFetchRecord(programTypeId)
            .then((data) => {
                setRecordData(data);
            })
            .catch((e) => {
                //TODO error handle 404
                setDisplayNotification({message: "Error"})
            })
    }, []);

    return (
        <React.Fragment>
            <SidePanel
                isLoading={isLoadingSidePanel}
                handleClose={() => navigate("../")}
                title={!isLoadingSidePanel && recordData && `${recordData.name}`}
                open={sidePanelOpen}
            >
                {!isLoadingSidePanel && recordData && (<ViewProgramTypeForm
                    isLoading={isLoadingSidePanel}
                    handleClose={() => navigate("../")}
                    recordData={recordData}
                />)}
            </SidePanel>            
        </React.Fragment>);
}

function ProgramTypeTemplate({ data, searchSidePanelData, isLoading, isLoadingSidePanel, isLoadingSearchSidePanel, onRefresh,
    handleDeleteRecord, handleFetchRecord, handleCreateRecord, recordData, handleListRecords }) {

    const [displayNotification, setDisplayNotification] = React.useState(null);


    return (
        <Routes>
            <Route path="/" element={<ProgramTypeIndex
                handleDeleteRecord={handleDeleteRecord}
                isLoading={isLoading}
                displayNotification={displayNotification}
                setDisplayNotification={setDisplayNotification}
                onRefresh={onRefresh}
                handleListRecords={handleListRecords}
                data={data} />}
            >
                <Route path=":programTypeId" element={<ProgramTypeView
                    isLoadingSidePanel={isLoadingSidePanel}
                    recordData={recordData}
                    handleFetchRecord={handleFetchRecord}
                    setDisplayNotification={setDisplayNotification}
                    sidePanelOpen={true} />} />
                <Route path="new" element={<ProgramTypeNew
                    searchSidePanelData={searchSidePanelData}
                    isLoadingSidePanel={isLoadingSidePanel}
                    isLoadingSearchSidePane={isLoadingSearchSidePanel}
                    handleCreateRecord={handleCreateRecord}
                    setDisplayNotification={setDisplayNotification}
                    displayNotification={displayNotification}
                    handleListRecords={handleListRecords}
                    sidePanelOpen={true} />} />
            </Route>
        </Routes>
    );
}

export default ProgramTypeTemplate;