import * as React from "react";
import { Chip } from "@mui/material/";
import { styled } from "@mui/material/styles";

const StyledChip = styled(Chip)(() => ({
  width: "100%",
  "@media screen and (max-width: 950px)": {
    "& span": {
      display: "none",
    },
    "& .MuiChip-icon": {
      padding: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    margin: 0,
  },
}));

const StatusChip = ({ innerRef, ...props }) => {
  return <StyledChip {...props} ref={innerRef} />;
};

export default StatusChip;