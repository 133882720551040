import { styled } from "@mui/material/styles";
import { IconButton } from "@mui/material/";
import { ChevronLeft } from "@mui/icons-material";

const StyledCircle = styled("span", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  position: "inherit",
  zIndex: -1,

  top: "100vh",
  transform:
    "translate(" +
    theme.customerSearchSidepanel.width.open +
    "px, calc(100% - 125px) )",

  height: "50px",
  width: "50px",

  backgroundColor: "#1950d2",
  borderRadius: "50%",
  ...(!open && {
    transform:
      "translate(" +
      theme.customerSearchSidepanel.width.closed +
      "px, calc(100% - 125px) )",
  }),
}));

const SidebarBookmarkButton = ({ open, toggleOpen }) => {
  return (
    <StyledCircle open={open}>
      <span
        style={{
          position: "fixed",
          height: "50px",
          width: "25px",
          backgroundColor: "#1950d2",

          transform: "translate(0%)",
        }}
      />
      <IconButton
        onClick={toggleOpen}
        style={{
          color: "#fff",
          position: "inherit",
          transform:
            "translate(5px, 5px) " + (open ? "rotate(0deg)" : "rotate(180deg)"),
          transitionDuration: "0.4s",
          transitionProperty: "transform",
        }}
      >
        <ChevronLeft />
      </IconButton>
    </StyledCircle>
  );
};

export default SidebarBookmarkButton;
