import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material/";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

const StyledTitle = styled(Typography)({
  color: "#7A7A7A",
  fontWeight: "bold",
  fontSize: "23px",
  fontFamily: "sans-serif",
  textAlign: "center",

  marginTop: "2px",
});

const Subtitle = ({ title, children, style }) => {
  return (
    <StyledTitle style={{ ...style }}>
      {title}
      {children}
    </StyledTitle>
  );
};

export default Subtitle;
