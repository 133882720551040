import * as React from 'react';
import { Grid, Typography } from '@mui/material/';
import DataGrid from '../organisms/DataGrid';
import { styled } from '@mui/material/styles';
import NewRibbonButton from '../molecules/NewRibbonButton';
import EditRibbonButton from '../molecules/EditRibbonButton';
import AddRoleRibbonButton from '../molecules/AddRoleRibbonButton';
import DeactivateUserRibbonButton from '../molecules/DeactivateUserRibbonButton';
import ActivateUserRibbonButton from '../molecules/ActivateUserRibbonButton';

import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

import RefreshRibbonButton from '../molecules/RefreshRibbonButton';
import DeleteRibbonButton from '../molecules/DeleteRibbonButton';
import ConfirmDialog from '../molecules/ConfirmDialog';
import SidePanel from '../organisms/SidePanel';
import NewUserForm from '../../forms/user/NewUserForm';
import EditUserForm from '../../forms/user/EditUserForm';
import UpdateRoleForm from '../../forms/user/UpdateRoleForm';

import { Route, Routes, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns'
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams'
import ProfileCard from '../organisms/ProfileCard'
import NotificationSnackBar from '../organisms/NotificationSnackBar';

const GridParent = styled(Grid)(({ theme }) => ({

}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
    padding: 3,
    paddingLeft: 30,
    display: 'inline-flex'
}));

const GridItem = styled(Grid)(({ theme }) => ({
    padding: 16,
    paddingLeft: 30
}));

const RenderActive = ({value}) => {
    return value ? <CheckIcon sx={{ width: "100%" }} /> : <CloseIcon sx={{ width: "100%" }} />;
}

const columns = [
    { field: 'active', headerName: 'Active', flex: 2, maxWidth: 90, renderCell: RenderActive},
    { field: 'name', headerName: 'Name', flex: 2, minWidth: 100 },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 100 },
    {
        field: 'role', headerName: 'Role', flex: 1, minWidth: 100, type: 'singleSelect',
        valueOptions: ["Administrator", "User"]
    },
    { field: 'timezone', headerName: 'Timezone', flex: 2, minWidth: 100 },
    {
        field: 'createdOn', headerName: 'Created On', flex: 1, minWidth: 100, type: "dateTime",
        valueFormatter: (params) => { return params.value ? format(new Date(params.value), "dd/MM/yyyy") : params.value; }
    },
    {
        field: 'modifiedOn', headerName: 'Modified On', flex: 1, minWidth: 100, type: "dateTime",
        valueFormatter: (params) => { return params.value ? format(new Date(params.value), "dd/MM/yyyy") : params.value; }
    },
];

function UserIndex({ isLoading, data, onRefresh, displayNotification, setDisplayNotification, handleDeleteRecord,
    handleListRecords, handleActivate, handleDeactivate, handleUpdateRole}) {

    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [displayDelete, setDisplayDelete] = React.useState(false);
    const [displayActivateUser, setDisplayActivateUser] = React.useState(false);
    const [displayDeactivateUser, setDisplayDeactivateUser] = React.useState(false);
    const [displayUpdateRole, setDisplayUpdateRole] = React.useState(false);

    const [confirmDialog, setConfirmDialog] = React.useState({});
    const [selectedRow, setSelectedRow] = React.useState(null);

    let navigate = useNavigateWithParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const isLayoutEmbedded = () => {
        return searchParams.get("layout") === "embedded";
    };

    const onClickRow = (params) => {
        if (params.row.active) {
            setDisplayDeactivateUser(true);
            setDisplayActivateUser(false);
        } else {
            setDisplayDeactivateUser(false);
            setDisplayActivateUser(true);
        }

        setDisplayUpdateRole(true);
        setDisplayEdit(true);
        setDisplayDelete(true);
        setSelectedRow(params.row);
    };

    const onDoubleClickRow = (params) => {
        openRecord();
    };

    const deleteRecord = () => {
        setConfirmDialog({
            title: "Delete User ?",
            description: `Are you sure you want to delete ${selectedRow.name}?`,
            actionConfirm: () => {
                setConfirmDialog({});
                handleDeleteRecord(selectedRow)
                    .then(() => {
                        setDisplayNotification({ message: "User deleted successfully", type: "success" });
                    })
                    .catch((e) => {
                        setDisplayNotification({ message: e.response.status === 403 ? "Unauthorized" : e.response.data.Message, type: "error" });
                    })
            },
            actionCancel: () => {
                setConfirmDialog({});
            }
        });
    };    

    const deactivateRecord = () => {
        setConfirmDialog({
            title: "Deactivate User ?",
            description: `Are you sure you want to deactivate ${selectedRow.name}?`,
            actionConfirm: () => {
                setConfirmDialog({});
                handleDeactivate(selectedRow.id)
                    .then(() => {
                        setDisplayNotification({ message: "User deactivated successfully", type: "success" });
                    })
                    .catch((e) => {
                        setDisplayNotification({ message: e.response.status === 403 ? "Unauthorized" : e.response.data.Message, type: "error" });
                    })
            },
            actionCancel: () => {
                setConfirmDialog({});
            }
        });
    };   

    const activateRecord = () => {
        setConfirmDialog({
            title: "Activate User ?",
            description: `Are you sure you want to activate ${selectedRow.name}?`,
            actionConfirm: () => {
                setConfirmDialog({});
                handleActivate(selectedRow.id)
                    .then(() => {
                        setDisplayNotification({ message: "User activated successfully", type: "success" });
                    })
                    .catch((e) => {
                        setDisplayNotification({ message: e.response.status === 403 ? "Unauthorized" : e.response.data.Message, type: "error" });
                    })
            },
            actionCancel: () => {
                setConfirmDialog({});
            }
        });
    };   

    const createRecord = () => {
        navigate("new");
    };

    const editRecord = () => {
        navigate(`${selectedRow.id}/edit`);
    };

    const openRecord = () => {
        navigate(`${selectedRow.id}/edit`);
    };

    const updateRole = () => {
        navigate(`${selectedRow.id}/role`);
    };
    const refreshGrid = () => {
        setSelectedRow(null);
        setDisplayEdit(false);
        setDisplayDelete(false);
        setDisplayUpdateRole(false);
        setDisplayEdit(false);
        setDisplayDelete(false);
        setDisplayDeactivateUser(false);
        setDisplayActivateUser(false);
        onRefresh();
    }

    return (<React.Fragment>
        <GridParent
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch">
            {confirmDialog && confirmDialog.title && <ConfirmDialog confirmDialogInfo={confirmDialog} onConfirm={confirmDialog.actionConfirm} onCancel={confirmDialog.actionCancel} />}
            <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
                <NewRibbonButton onClick={createRecord} display={true} title="Add User" />
                <EditRibbonButton onClick={editRecord} display={displayEdit} title="Edit User" />
                <ActivateUserRibbonButton onClick={activateRecord} display={displayActivateUser} title="Activate User" />
                <DeactivateUserRibbonButton onClick={deactivateRecord} display={displayDeactivateUser} title="Deactivate User" />
                <AddRoleRibbonButton onClick={updateRole} display={displayUpdateRole} title="Update Role" />
                <RefreshRibbonButton onClick={refreshGrid} display={true} title="Refresh" />
                <DeleteRibbonButton onClick={deleteRecord} display={displayDelete} title="Delete User" />
                {isLayoutEmbedded() && (<ProfileCard embeddedMode={true} />)}
            </RibbonGrid>
            <GridItem item xs={12} md={12} lg={12}>
                <Typography>Users</Typography>
            </GridItem>
            <GridItem item xs={12} md={12} lg={12}>
                <DataGrid loading={isLoading} onRowClick={onClickRow} onRowDoubleClick={onDoubleClickRow} columns={columns} rows={data} />
            </GridItem>
        </GridParent>
        {displayNotification !== null && (<NotificationSnackBar
            displayNotification={displayNotification}
            onClose={() => setDisplayNotification(null)}
        />)}       
        <Outlet />
    </React.Fragment>);
}

function UserNew({ sidePanelOpen, isLoadingSidePanel, isLoadingSearchSidePanel, handleCreateRecord, setDisplayNotification, handleListRecords,
    searchSidePanelData, handleListConnectors }) { 

    let navigate = useNavigateWithParams();

    return (
        <SidePanel open={sidePanelOpen}
            handleClose={() => navigate("../")}
            isLoading={isLoadingSidePanel}
            title="New User">
            <NewUserForm
                isLoading={isLoadingSidePanel}
                isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                handleClose={() => navigate("../")}
                handleListRecords={handleListRecords}
                searchSidePanelData={searchSidePanelData}
                handleListConnectors={handleListConnectors }
                handleCreateRecord={(record) => {
                    return handleCreateRecord(record).
                        then((data) => {
                            setDisplayNotification({message: "User created successfully", type: "success"});
                            navigate("../");
                        })
                }} />
        </SidePanel>);
}

function UserEdit({ isLoadingSidePanel, sidePanelOpen, handleFetchRecord, setDisplayNotification, handleEditRecord
    }) {
    let navigate = useNavigateWithParams();

    let { userId } = useParams();
    const [recordData, setRecordData] = React.useState(null);

    React.useEffect(() => {
        return handleFetchRecord(userId)
            .then((data) => {
                setRecordData(data);
            })
            .catch((e) => {
                //TODO error handle 404
                setDisplayNotification({ message: e.response.status === 403 ? "Unauthorized" : "Error"})
            })
    }, []);

    return (
        <React.Fragment>
            <SidePanel
                isLoading={isLoadingSidePanel}
                handleClose={() => navigate("../")}
                title={!isLoadingSidePanel && recordData && `${recordData.name}`}
                open={sidePanelOpen}
            >
                {!isLoadingSidePanel && recordData && (<EditUserForm
                    isLoading={isLoadingSidePanel}
                    handleClose={() => navigate("../")}
                    handleEditRecord={(id, recordData) => {
                        return handleEditRecord(id, recordData)
                            .then(() => navigate("../"))
                            .catch((e) => {
                                setDisplayNotification({ message: e.response.status === 403 ? "Unauthorized" : e.response.data.Message, type: "error" });
                            })
                    }}
                    recordData={recordData}
                />)}
            </SidePanel>            
        </React.Fragment>);
}

function UpdateUserRole({ sidePanelOpen, isLoadingSidePanel, isLoadingSearchSidePanel, handleUpdateRole, setDisplayNotification, handleFetchRecord }) {

    let navigate = useNavigateWithParams();
    let { userId } = useParams();
    const [recordData, setRecordData] = React.useState(null);

    React.useEffect(() => {
        return handleFetchRecord(userId)
            .then((data) => {
                setRecordData(data);
            })
            .catch((e) => {
                //TODO error handle 404
                setDisplayNotification({ message: e.response.status === 403 ? "Unauthorized" : "Error" })
            })
    }, []);

    return (
        <SidePanel open={sidePanelOpen}
            handleClose={() => navigate("../")}
            isLoading={isLoadingSidePanel}
            title="Update user role">
            <UpdateRoleForm
                isLoading={isLoadingSidePanel}
                isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                recordData={recordData}
                handleClose={() => navigate("../")}
                 handleUpdateRole={(id, record) => {
                     return handleUpdateRole(id, record).
                        then((data) => {
                            setDisplayNotification({ message: "Role updated successfully", type: "success" });
                            navigate("../");
                        })
                         .catch((e) => {
                             setDisplayNotification({ message: e.response.status === 403 ? "Unauthorized" : e.response.data.Message, type: "error" });
                         })
                }} />
        </SidePanel>);
}

function UserTemplate({ data, searchSidePanelData, isLoading, isLoadingSidePanel, isLoadingSearchSidePanel, onRefresh,
    handleDeleteRecord, handleFetchRecord, handleCreateRecord, recordData, handleListRecords,
    handleActivate, handleDeactivate, handleUpdateRole, handleUpdateDetails, 
    handleListConnectors
}) {
    const [displayNotification, setDisplayNotification] = React.useState(null);

    return (
        <Routes>
            <Route path="/" element={<UserIndex
                handleDeleteRecord={handleDeleteRecord}
                isLoading={isLoading}
                displayNotification={displayNotification}
                setDisplayNotification={setDisplayNotification}
                onRefresh={onRefresh}
                handleListRecords={handleListRecords}
                handleActivate={handleActivate}
                handleDeactivate={handleDeactivate}
                handleUpdateRole={handleUpdateRole}
                handleUpdateDetails={handleUpdateDetails}
                handleListConnectors={handleListConnectors}
                data={data}
            />}
            >
                <Route path=":userId/edit" element={<UserEdit
                    isLoadingSidePanel={isLoadingSidePanel}
                    recordData={recordData}
                    handleFetchRecord={handleFetchRecord}
                    setDisplayNotification={setDisplayNotification}
                    isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                    handleListConnectors={handleListConnectors}
                    handleEditRecord={handleUpdateDetails}
                    sidePanelOpen={true} />} />
                <Route path=":userId/role" element={<UpdateUserRole
                    isLoadingSidePanel={isLoadingSidePanel}
                    recordData={recordData}
                    handleFetchRecord={handleFetchRecord}
                    setDisplayNotification={setDisplayNotification}
                    isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                    handleUpdateRole={handleUpdateRole}
                    sidePanelOpen={true} />} />
                <Route path="new" element={<UserNew
                    searchSidePanelData={searchSidePanelData}
                    isLoadingSidePanel={isLoadingSidePanel}
                    isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                    handleCreateRecord={handleCreateRecord}
                    setDisplayNotification={setDisplayNotification}
                    displayNotification={displayNotification}
                    handleListRecords={handleListRecords}
                    handleListConnectors={handleListConnectors}
                    sidePanelOpen={true}
                />} />
            </Route>
        </Routes>
    );
}

export default UserTemplate;