import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import RibbonButton from './RibbonButton';
import { Typography } from '@mui/material/';

export default function NewRibbonButton(props) {
    return (
        <RibbonButton {...props} startIcon={<AddIcon sx={{color: "black"}} />}>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonButton>
    );
}