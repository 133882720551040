import * as React from "react";
import { Grid, Paper, Stack, Box } from "@mui/material/";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";

import ConfirmDialog from "../molecules/ConfirmDialog";
import SidebarBookmarkButton from "../molecules/SidebarBookmarkButton";

import NotificationSnackBar from "../organisms/NotificationSnackBar";
import CustomerListSidebar from "../organisms/CustomerListSidebar";

import SubscriptionActionTemplate from "./SubscriptionActionTemplate";
import SubscriptionPlanTemplate from "./SubscriptionPlanTemplate";
import SubscriptionPlanLineTemplate from "./SubscriptionPlanLineTemplate";

import {
  Route,
  Routes,
  Outlet,
  useParams,
  useSearchParams,
  Link,
} from "react-router-dom";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";

const BodyContainer = styled("div")({
  color: "white",
  display: "flex",
  flexDirection: "column",
  position: "relative",
});

const Container = styled(Paper)({
  p: 2,
  display: "flex",
  flexDirection: "column",
  height: "auto",
  paddingBottom: "16px",
  margin: "10px",
});

const GridParent = styled(Grid)(({ theme }) => ({}));

const CustomerList = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 2,
  position: "-webkit-fixed;",
  position: "fixed",

  overflowY: "hidden",
  overflowX: "hidden",

  width: theme.customerSearchSidepanel.width.open,
  height: "100%",
  //height: "calc(100% - " + theme.toolbar.height + "px )",

  flexShrink: 0,

  boxShadow: "5px 0 5px -2px #bbb",

  ...(!open && {
    width: theme.customerSearchSidepanel.width.closed,
  }),
}));

const PageWrapper = styled(Box)(({ theme, open }) => ({
  height: "auto",

  width: "calc(100% - " + theme.customerSearchSidepanel.width.open + "px)",
  marginLeft: theme.customerSearchSidepanel.width.open + "px",
  ...(!open && {
    width: "calc(100% - " + theme.customerSearchSidepanel.width.closed + "px)",
    marginLeft: theme.customerSearchSidepanel.width.closed + "px",
  }),
}));

function SubscriptionAutomationIndex({
  setDisplayNotification,
  displayNotification,
  handleSearchCustomers,
  handleListCustomers,
  setActiveCustomer,
  handleListRecords,
  activeCustomer,
  setActiveConnector,
  activeConnector,
  subscriptionPlans,
  subscriptionPlanLines,
  subscriptionActions,
  isLoadingSidePanel,
  isLoadingSearchSidePanel,
  searchSidePanelData,
  handleListConnectors,
  isLoading,
}) {
  const [customersData, setCustomersData] = React.useState([]);
  const [customerPanelEnabled, setCustomerPanelEnabled] = React.useState(true);

  let [searchParams, setSearchParams] = useSearchParams();

  const getCustomerIdQueryParam = () => {
    return searchParams.get("customer-id");
  };

  const getConnectorIdQueryParam = () => {
    return searchParams.get("connector-id");
  };

  const toggleCustomerPanel = () => {
    setCustomerPanelEnabled(!customerPanelEnabled);
  };

  const [activeSubscriptionPlan, setActiveSubscriptionPlan] =
    React.useState(null);
  const [activeSubscriptionPlanLine, setActiveSubscriptionPlanLine] =
    React.useState(null);

  const [confirmDialog, setConfirmDialog] = React.useState({});

  const refreshCustomerList = () => {
    const timeOutId = setTimeout(
      () =>
        handleListCustomers({activeConnectorId: getCustomerIdQueryParam()}).then((customers) => {
          if (activeConnector)
              customers = customers.filter((x) => x.entity.entityReferences[0].connector === activeConnector.toLowerCase());

          setCustomersData(customers);
        }),
      800
    );
    return () => clearTimeout(timeOutId);
  };

  let navigate = useNavigateWithParams();

  const filterCustomersByConnectorId = (connector, customers) => {
    let filteredCustomers = [];

    customers.forEach(customer => {
      if (customer && customer.entity && customer.entity.entityReferences)
        customer.entity.entityReferences.forEach(reference => {
          if (reference.connector === connector.toLowerCase())
            filteredCustomers.push(customer);
        })
    })

    return filteredCustomers;
  }
  
  React.useEffect(() => {
    const connectorIdQueryParam = getConnectorIdQueryParam();
    const customerIdQueryParam = getCustomerIdQueryParam();

    console.log(connectorIdQueryParam);

    //TODO adding paging
    handleListCustomers().then((customers) => {
      let filteredCustomers = [];
      
      if (connectorIdQueryParam !== null) {
        setActiveConnector(connectorIdQueryParam);
        
        filteredCustomers = filterCustomersByConnectorId(connectorIdQueryParam, customers);
      } else 
        filteredCustomers = customers;

      if (customerIdQueryParam !== null)
        customers.map((customer, index) => {
          if (customer.connector === customerIdQueryParam)
            setActiveCustomer(customer);
        });

      setCustomersData(customers);
    });

    // if (connectorIdQueryParam != null)
    //   console.log(connectorIdQueryParam);

    //   handleListCustomers({activeConnectorId: connectorIdQueryParam}).then((customers) => {
    //     customers.map((customer, index) => {
    //       console.log(customer);
    //       // if (customer.id === getConnectorIdQueryParam())
    //       //   setActiveCustomer(customer);
    //     })
    //     setCustomersData(customers);
    //   });
  }, []);

  console.log(subscriptionPlans.data);

  const filterByConnectorId = (data) => {
    var filteredData = []

    data.forEach(entry => {
      entry.customer.entityReferences.forEach(customer => {
          if (customer.connector === activeConnector.toLowerCase())
          filteredData.push(entry);
      })
    });

    return filteredData;
  }

  return (
    <React.Fragment>
      <Stack direction="row">
        <CustomerList open={customerPanelEnabled}>
          <SidebarBookmarkButton
            open={customerPanelEnabled}
            toggleOpen={toggleCustomerPanel}
          />
          <CustomerListSidebar
            customers={customersData}
            disabled={isLoading}
            isLoading={isLoading}
            activeCustomer={activeCustomer}
            setActiveCustomer={(customer) => {
              setActiveCustomer(customer);
            }}
            handleSearchCustomers={(customerName) => {
              return handleSearchCustomers(customerName)
                .then((data) => {
                  if (data) {
                    if (activeConnector)
                    {
                      setCustomersData(filterCustomersByConnectorId(activeConnector, data));
                    } else 
                      setCustomersData(data);
                  }
                })
                .catch(() => {
                  setCustomersData([]);
                });
            }}
          />
        </CustomerList>

        <PageWrapper open={customerPanelEnabled}>
          <ConfirmDialog
            confirmDialogInfo={confirmDialog}
            onConfirm={confirmDialog.actionConfirm}
            onCancel={confirmDialog.actionCancel}
          />
          <BodyContainer>
            <GridParent
              container
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              marginTop="10px"
            >
              <Container>
                <SubscriptionPlanTemplate
                  mode="cockpit"
                  data={activeConnector ? filterByConnectorId(subscriptionPlans.data) : subscriptionPlans.data}
                  activeCustomer={activeCustomer}
                  activeConnector={activeConnector}
                  setActiveSubscriptionPlan={setActiveSubscriptionPlan}
                  recordData={subscriptionPlans.recordData}
                  isLoading={subscriptionPlans.isLoading}
                  onRefresh={(customerId) => {
                    setActiveSubscriptionPlan(null);
                    setActiveSubscriptionPlanLine(null);
                    subscriptionPlans.onRefresh(customerId);
                  }}
                  handleCreateRecord={(record) => {
                    refreshCustomerList();
                    return subscriptionPlans.handleCreateRecord(record);
                  }}
                  handleFetchRecord={subscriptionPlans.handleFetchRecord}
                  handleEditRecord={subscriptionPlans.handleEditRecord}
                  handleListRecords={handleListRecords}
                  handlePublish={subscriptionPlans.handlePublish}
                  handleUnpublish={subscriptionPlans.handleUnpublish}
                  handleCancel={subscriptionPlans.handleCancel}
                  handleMasterProcess={(id, body) => {
                    return subscriptionPlans
                      .handleMasterProcess(id, body)
                      .then((data) => {
                        subscriptionActions.onRefresh(
                          activeSubscriptionPlan,
                          activeSubscriptionPlanLine,
                          activeCustomer
                        );
                        return data;
                      });
                  }}
                  handleContractDefinition={
                    subscriptionPlans.handleContractDefinition
                  }
                  searchSidePanelData={searchSidePanelData}
                  isLoadingSidePanel={isLoadingSidePanel}
                  isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                  handleListConnectors={handleListConnectors}
                />
              </Container>

              <Container>
                <SubscriptionPlanLineTemplate
                  mode="cockpit"
                  data={subscriptionPlanLines.data}
                  activeSubscriptionPlan={activeSubscriptionPlan}
                  recordData={subscriptionPlanLines.recordData}
                  isLoading={subscriptionPlanLines.isLoading}
                  onRefresh={(id) => {
                    setActiveSubscriptionPlanLine(null);
                    subscriptionPlanLines.onRefresh(id);
                  }}
                  setActiveSubscriptionPlanLine={setActiveSubscriptionPlanLine}
                  setActiveSubscriptionPlan={setActiveSubscriptionPlan}
                  handleCreateRecord={subscriptionPlanLines.handleCreateRecord}
                  handleEditRecord={subscriptionPlanLines.handleEditRecord}
                  handleFetchRecord={subscriptionPlanLines.handleFetchRecord}
                  handleListRecords={handleListRecords}
                  handleEnable={subscriptionPlanLines.handleEnable}
                  handleDisable={subscriptionPlanLines.handleDisable}
                  handleDelete={subscriptionPlanLines.handleDelete}
                  searchSidePanelData={searchSidePanelData}
                  isLoadingSidePanel={isLoadingSidePanel}
                  isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                  handleListConnectors={handleListConnectors}
                  handleAddCreditNote={
                    subscriptionPlanLines.handleAddCreditNote
                  }
                />
              </Container>

              <Container>
                <SubscriptionActionTemplate
                  mode="cockpit"
                  data={subscriptionActions.data}
                  activeCustomer={activeCustomer}
                  recordData={subscriptionActions.recordData}
                  isLoading={subscriptionActions.isLoading}
                  onRefresh={subscriptionActions.onRefresh}
                  handleFetchRecord={subscriptionActions.handleFetchRecord}
                  handleCancel={subscriptionActions.handleCancel}
                  handlePost={subscriptionActions.handlePost}
                  handleFirm={subscriptionActions.handleFirm}
                  handleListRecords={handleListRecords}
                  searchSidePanelData={searchSidePanelData}
                  isLoadingSidePanel={isLoadingSidePanel}
                  activeSubscriptionPlan={activeSubscriptionPlan}
                  activeSubscriptionPlanLine={activeSubscriptionPlanLine}
                  isLoadingSearchSidePanel={isLoadingSearchSidePanel}
                />
              </Container>
            </GridParent>
          </BodyContainer>
        </PageWrapper>
      </Stack>
      {displayNotification && (
        <NotificationSnackBar
          displayNotification={displayNotification}
          onClose={() => {
            setDisplayNotification(null);
          }}
        />
      )}
      <Outlet />
    </React.Fragment>
  );
}

function SubscriptionAutomationTemplate(props) {
  const {
    subscriptionPlans,
    subscriptionActions,
    subscriptionPlanLines,
    handleListCustomers,
    handleSearchCustomers,
    isLoadingSidePanel,
    isLoadingSearchSidePanel,
    searchSidePanelData,
    handleListRecords,
    handleListConnectors,
    isLoading,
  } = props;

  const [displayNotification, setDisplayNotification] = React.useState(null);
  const [activeCustomer, setActiveCustomer] = React.useState(null);
  const [activeConnector, setActiveConnector] = React.useState(null);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SubscriptionAutomationIndex
            subscriptionPlans={subscriptionPlans}
            subscriptionPlanLines={subscriptionPlanLines}
            subscriptionActions={subscriptionActions}
            handleListCustomers={handleListCustomers}
            handleSearchCustomers={handleSearchCustomers}
            setActiveCustomer={setActiveCustomer}
            activeCustomer={activeCustomer}
            setActiveConnector={setActiveConnector}
            activeConnector={activeConnector}
            searchSidePanelData={searchSidePanelData}
            isLoading={isLoading}
            isLoadingSidePanel={isLoadingSidePanel}
            isLoadingSearchSidePanel={isLoadingSearchSidePanel}
            handleListRecords={handleListRecords}
            handleListConnectors={handleListConnectors}
          />
        }
      />
    </Routes>
  );
}

SubscriptionAutomationTemplate.propTypes = {
  subscriptionPlans: PropTypes.shape({
    data: PropTypes.array,
    recordData: PropTypes.object,
    onRefresh: PropTypes.func,
    isLoading: PropTypes.bool,
    handleCreateRecord: PropTypes.func,
    handleFetchRecord: PropTypes.func,
    handleEditRecord: PropTypes.func,
    handlePublish: PropTypes.func,
    handleUnpublish: PropTypes.func,
    handleCancel: PropTypes.func,
    handleMasterProcess: PropTypes.func,
  }),
};
SubscriptionAutomationTemplate.defaultProps = {
  subscriptionPlans: {},
  subscriptionActions: {},
  subscriptionPlanLines: {},
};

export default SubscriptionAutomationTemplate;
