import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Grid, Paper, Typography } from "@mui/material/";
import { styled } from "@mui/material/styles";
import {
  LinearProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material/";

const GridParent = styled(Grid)(({ theme }) => ({}));

const GridItem = styled(Grid)(({ theme }) => ({
  padding: 16,
  paddingLeft: 30,
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function SearchSidePanel({
  open,
  isLoading,
  handleClose,
  title,
  children,
}) {
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          sx: {
            position: "fixed",
            top: 0,
            right: 0,
            m: 0,
            width: "455px",
            height: "100%",
            maxHeight: "100%",
          },
        }}
      >
        {isLoading && <LinearProgress />}
        <GridParent>
          <DialogTitle>
            <Typography>{title}</Typography>
          </DialogTitle>
          <DialogContent>{children}</DialogContent>
        </GridParent>
      </Dialog>
    </div>
  );
}
