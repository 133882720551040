import * as React from "react";
import { Tooltip } from "@mui/material/";
import { styled } from "@mui/material/styles";
import StatusChip from "../atoms/StatusChip";
import PropTypes from "prop-types";

const CustomStatusChip = React.forwardRef((props, ref) => {
  return <StatusChip {...props} innerRef={ref} />;
});

const TooltipStatusChip = ({
  title,
  label,
  variant,
  color,
  icon,
  avatar,
  disabled,
  onDelete,
  size,
  sx,
}) => {
  return (
    <Tooltip title={title}>
      <CustomStatusChip
        label={label}
        variant={variant}
        color={color}
        icon={icon}
        sx={sx}
        avatar={avatar}
        disabled={disabled}
        onDelete={onDelete}
        size={size}
      />
    </Tooltip>
  );
};

TooltipStatusChip.propTypes = {
  title: PropTypes.string,
  label: PropTypes.node,
  variant: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.element,
  avatar: PropTypes.element,
  disabled: PropTypes.bool,
  onDelete: PropTypes.func,
};

TooltipStatusChip.defaultProps = {
  title: "",
  variant: "medium", //mui default
  color: "default",
  onDelete: undefined,
};

export default TooltipStatusChip;
