import SubscriptionPlanTemplate from "../ui/templates/SubscriptionPlanTemplate";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import { useNavigateWithParams } from "../hooks/useNavigateWithParams";
import { fakeData as programTypeFakeData } from "./fakeData/programTypeFakeData";
import { fakeData as reasonCodeFakeData } from "./fakeData/reasonCodeFakeData";
import { fakeData as companyFakeData } from "./fakeData/companyFakeData";
import { fakeData as customerFakeData } from "./fakeData/customerFakeData";
import { useSearchParams} from "react-router-dom";

import {
  cancelSubscriptionPlan,
  masterProcess,
  createSubscriptionPlan,
  draftSubscriptionPlan,
  updateSubscriptionPlan,
  getSubscriptionPlan,
  getSubscriptionPlans,
  publishSubscriptionPlan,
  updateContractDefinition,
} from "../api/subscriptionPlansService";
import { getConnectors } from "../api/connectorsService";

import { getReasonCodes } from "../api/reasonCodesService";
import { getProgramTypes } from "../api/programTypeService";

import { listCompany } from "../api/companyService";

import { listCustomers } from "../api/customersService";

import { fakeData } from "./fakeData/subscriptionPlanFakeData";
import UserContext from "../UserContext";

export default function SubscriptionPlanPage() {
  const appInsights = useAppInsightsContext();
  appInsights.trackPageView({ name: "Subscription Plan Page" });

  const [datagridData, setDatagridData] = React.useState([]);
  const [searchSidePanelData, setSearchSidePanelData] = React.useState([]);
  const [recordData, setRecordData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);
  const [isLoadingSearchSidePanel, setIsLoadingSearchSidePanel] =
    React.useState(false);
  const userContext = React.useContext(UserContext);

  const timerRef = React.useRef(null);
    let navigate = useNavigateWithParams();
    let [searchParams, setSearchParams] = useSearchParams();
    const getConnectorIdFilter = () => {
        return searchParams.get("connector-id");
    };

  const onRefresh = () => {
    setDatagridData([]);
    setIsLoading(true);
    if (process.env.REACT_APP_TEST_DATA === "true") {
      timerRef.current = setTimeout(() => {
        setDatagridData(fakeData);
        setIsLoading(false);
      }, 1500);
    } else {
      return getSubscriptionPlans(
          null,
          getConnectorIdFilter(),
        userContext.activeEnvironment,
        userContext.tenant
      ).then((data) => {
        setIsLoading(false);
        setDatagridData(data);
      });
    }
  };

  const handleCreateRecord = (record) => {
    setIsLoading(true);
    setIsLoadingSidePanel(true);
    if (process.env.REACT_APP_TEST_DATA === "true") {
      return new Promise((resolve) => {
        timerRef.current = setTimeout(() => {
          setDatagridData([{ ...record, id: uuidv4() }, ...datagridData]);
          setIsLoading(false);
          setIsLoadingSidePanel(false);
        }, 1500);
      });
    } else {
      return createSubscriptionPlan(
        record,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);
          return onRefresh();
        })
        .catch((e) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);
          throw e;
        });
    }
  };

  const handleFetchRecord = (id) => {
    setIsLoadingSidePanel(true);

    if (process.env.REACT_APP_TEST_DATA === "true") {
      timerRef.current = setTimeout(() => {
        var recordIndex = datagridData.findIndex((x) => x.id === id);
        setRecordData(datagridData[recordIndex]);
        setIsLoadingSidePanel(false);
      }, 1500);
    } else {
      return getSubscriptionPlan(
        id,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          setRecordData(data);
          setIsLoadingSidePanel(false);
        })
        .catch((e) => {
          setIsLoadingSidePanel(false);
        });
    }
  };

  const handleEditRecord = (subscriptionPlanId, record) => {
    setIsLoading(true);
    setIsLoadingSidePanel(true);
    if (process.env.REACT_APP_TEST_DATA === "true") {
      return new Promise((resolve) => {
        //TODO
      });
    } else {
      return updateSubscriptionPlan(
        subscriptionPlanId,
        record,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);
          return data;
        })
        .catch((e) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);
          throw e;
        });
    }
  };

  const handleMasterProcess = (id, body) => {
    setIsLoading(true);

    if (process.env.REACT_APP_TEST_DATA === "true") {
    } else {
      return masterProcess(
        id,
        body,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          onRefresh();
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          throw e;
        });
    }
  };

  const handlePublish = (id) => {
    setIsLoading(true);

    if (process.env.REACT_APP_TEST_DATA === "true") {
    } else {
      return publishSubscriptionPlan(
        id,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          onRefresh();
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          throw e;
        });
    }
  };

  const handleUnpublish = (id) => {
    setIsLoading(true);
    if (process.env.REACT_APP_TEST_DATA === "true") {
    } else {
      return draftSubscriptionPlan(
        id,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          onRefresh();
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          throw e;
        });
    }
  };

  const handleCancel = (id, body) => {
    setIsLoading(true);
    setIsLoadingSidePanel(true);
    if (process.env.REACT_APP_TEST_DATA === "true") {
    } else {
      return cancelSubscriptionPlan(
        id,
        body,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);
          return onRefresh();
        })
        .catch((e) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);
          throw e;
        });
    }
  };

  const handleContractDefinition = (id, body) => {
    setIsLoading(true);
    setIsLoadingSidePanel(true);

    if (process.env.REACT_APP_TEST_DATA === "true") {
    } else {
      return updateContractDefinition(
        id,
        body,
        userContext.activeEnvironment,
        userContext.tenant
      )
        .then((data) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);

          return onRefresh();
        })
        .catch((e) => {
          setIsLoading(false);
          setIsLoadingSidePanel(false);
          throw e;
        });
    }
  };

  const handleListConnectors = () => {
    return getConnectors(userContext.activeEnvironment, userContext.tenant);
  };

  const handleListRecords = (recordType, query) => {
    setIsLoadingSearchSidePanel(true);
    setSearchSidePanelData([]);

    if (process.env.REACT_APP_TEST_DATA === "true") {
      timerRef.current = setTimeout(() => {
        if (recordType === "reasoncode") {
          if (query && query.name) {
            setSearchSidePanelData(
              reasonCodeFakeData.filter(
                (x) => x.name && x.name.includes(query.name)
              )
            );
          } else setSearchSidePanelData(reasonCodeFakeData);
        } else if (recordType === "programtype") {
          if (query && query.name) {
            const filteredData = programTypeFakeData.filter(
              (x) => x.name && x.name.includes(query.name)
            );
            setSearchSidePanelData(filteredData);
          } else setSearchSidePanelData(programTypeFakeData);
        } else if (recordType === "company") {
          if (query && query.name) {
            const filteredData = companyFakeData.filter(
              (x) => x.name && x.name.includes(query.name)
            );
            setSearchSidePanelData(filteredData);
          } else setSearchSidePanelData(companyFakeData);
        } else if (recordType === "customer") {
          if (query && query.name) {
            const filteredData = customerFakeData.filter(
              (x) => x.name && x.name.includes(query.name)
            );
            setSearchSidePanelData(filteredData);
          } else setSearchSidePanelData(customerFakeData);
        }
        setIsLoadingSearchSidePanel(false);
      }, 1500);
    } else {
      if (recordType === "reasoncode") {
        return getReasonCodes(
          query,
          userContext.activeEnvironment,
          userContext.tenant
        )
          .then((data) => {
            if (data && query && query.name) {
              const filteredData = data.filter(
                (x) =>
                  x.name &&
                  x.name.toLowerCase().includes(query.name.toLowerCase())
              );
              setSearchSidePanelData(filteredData);
            } else {
              setSearchSidePanelData(data);
            }

            setIsLoadingSearchSidePanel(false);

            return data;
          })
          .catch((e) => {
            setIsLoadingSearchSidePanel(false);
          });
      } else if (recordType === "programtype") {
        return getProgramTypes(
          userContext.activeEnvironment,
          userContext.tenant
        )
          .then((data) => {
            if (data && query && query.name) {
              const filteredData = data.filter(
                (x) =>
                  x.name &&
                  x.name.toLowerCase().includes(query.name.toLowerCase())
              );

              setSearchSidePanelData(filteredData);
              setIsLoadingSearchSidePanel(false);

              return data;
            }
            setSearchSidePanelData(data);
            setIsLoadingSearchSidePanel(false);

            return data;
          })
          .catch((e) => {
            setIsLoadingSearchSidePanel(false);
          });
      } else if (recordType === "company") {
        return listCompany(
          query.connectorId,
          userContext.activeEnvironment,
          userContext.tenant
        )
          .then((data) => {
            if (data && query && query.name) {
              const filteredData = data.filter(
                (x) =>
                  x.name &&
                  x.name.toLowerCase().includes(query.name.toLowerCase())
              );
              setSearchSidePanelData(filteredData);
            } else {
              setSearchSidePanelData(data);
            }
            setIsLoadingSearchSidePanel(false);
          })
          .catch((e) => {
            setIsLoadingSearchSidePanel(false);
          });
      } else if (recordType === "customer") {
        return listCustomers(
          query.connectorId,
          userContext.activeEnvironment,
          userContext.tenant
        )
          .then((data) => {
            if (data && query && query.name) {
              const filteredData = data.filter(
                (x) =>
                  x.name &&
                  x.name.toLowerCase().includes(query.name.toLowerCase())
              );
              setSearchSidePanelData(filteredData);
            } else {
              setSearchSidePanelData(data);
            }
            setIsLoadingSearchSidePanel(false);
          })
          .catch((e) => {
            setIsLoadingSearchSidePanel(false);
          });
      } else if (recordType === "connector") {
        debugger;
        return getConnectors(userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            if (data && query && query.name) {
              const filteredData = data.filter(
                (x) =>
                  x.name &&
                  x.name.toLowerCase().includes(query.name.toLowerCase())
              );
              setSearchSidePanelData(filteredData);
            } else {
              setSearchSidePanelData(data);
            }

            setIsLoadingSearchSidePanel(false);
          })
          .catch((e) => {
            setIsLoadingSearchSidePanel(false);
          });
      }
    }
  };

  React.useEffect(() => {
    setIsLoading(true);

    if (process.env.REACT_APP_TEST_DATA === "true") {
      const timer = setTimeout(() => {
        setDatagridData(fakeData);
        setIsLoading(false);
      }, 1500);
      return () => clearTimeout(timer);
    } else {
      getSubscriptionPlans(
          null,
          getConnectorIdFilter(),
        userContext.activeEnvironment,
        userContext.tenant
      ).then((data) => {
        setIsLoading(false);
        setDatagridData(data);
      });
    }
  }, [userContext.activeEnvironment]);

  React.useEffect(() => {
    // Clear the interval when the component unmounts
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <SubscriptionPlanTemplate
      data={datagridData}
      isLoading={isLoading}
      isLoadingSidePanel={isLoadingSidePanel}
      isLoadingSearchSidePanel={isLoadingSearchSidePanel}
      handleCreateRecord={handleCreateRecord}
      handleFetchRecord={handleFetchRecord}
      handleListRecords={handleListRecords}
      recordData={recordData}
      searchSidePanelData={searchSidePanelData}
      handleEditRecord={(subscriptionPlanId, data) => {
        return handleEditRecord(subscriptionPlanId, data).then(() =>
          onRefresh()
        );
      }}
      onRefresh={onRefresh}
      handlePublish={handlePublish}
      handleUnpublish={handleUnpublish}
      handleCancel={handleCancel}
      handleMasterProcess={handleMasterProcess}
      handleListConnectors={handleListConnectors}
      handleContractDefinition={handleContractDefinition}
    />
  );
}
