import * as React from 'react';
import RibbonButton from './RibbonButton';
import { Typography } from '@mui/material/';
import PublishIcon from '@mui/icons-material/Publish';

export default function PublishRibbonButton(props) {
    return (
        <RibbonButton {...props} startIcon={<PublishIcon sx={{ color: "black" }} />}>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonButton>
    );
}