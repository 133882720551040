import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string, number, mixed, bool } from "yup";
import SearchSidePanel from "../../ui/organisms/SearchSidePanel";
import LookupPaper from "../../ui/organisms/LookupPaper";
import LookupField from "../../ui/organisms/LookupField";

import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";

import { DialogActions } from "@mui/material/";

const defaultValues = {
  ReasonCode: "",
  ProgramType: {},
  Customer: "",
  Connector: "",
  PeriodType: "Rolling",
  SubscriptionType: "PerUnitSubscription",
  BillingPeriodUnit: "",
  BillingPeriodValue: 0,
  SalesPerson: "",
  MonthBillingPeriodType: "",
  BillingType: "Upfront",
  IsGoCardlessIntegration: false
};

let entityReferenceSchema = object({
  id: string().required(),
  name: string().required(),
  connector: string(),
  secondaryKey: string(),
  extensionData: mixed(),
  url: string().nullable(),
});

let subscriptionPlanSchema = object({
  Customer: entityReferenceSchema,
  BillingPeriodUnit: string().oneOf(["Days", "Months"]),
  MonthBillingPeriodType: string().oneOf([
    "None",
    "Monthly",
    "Quarterly",
    "HalfYearly",
    "Yearly",
    "Other",
  ]),
  BillingPeriodValue: number().required(),
  BillingType: string().oneOf(["Upfront"]),
  ProgramType: entityReferenceSchema,
  ReasonCode: entityReferenceSchema,
  SalesPerson: string(),
  Connector: string().uuid(),
  PeriodType: string().required().oneOf(["Calendar", "Rolling"]),
  SubscriptionType: string()
    .required()
    .oneOf(["PerpetualEnhancementPlan", "PerUnitSubscription", "Consumption"]),
  IsGoCardlessIntegration: bool()
});

export default function NewSubscriptionPlanForm({
  activeCustomer,
  handleCreateRecord,
  handleClose,
  isLoading,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
  handleListConnectors,
}) {
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState({});
  const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
  const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
  const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
  const [columns, setColumns] = React.useState([]);
  const [searchSidePanelType, setSearchSidePanelType] = React.useState("");
  const [isDisabled, setIsDisabled] = React.useState(false);

  const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] =
    React.useState({});
  const [selectedLookupId, setSelectedLookupId] = React.useState(null);

  const [connectors, setConnectors] = React.useState([]);
  const [defaultConnector, setDefaultConnector] = React.useState(null);
  const [displayConnectorSelect, setDisplayConnectorSelect] =
        React.useState(true);

  

  let navigate = useNavigateWithParams();
  const onSubmit = (data) => {
    data.preventDefault();
    handleCreateRecord(formValues);
  };

  React.useEffect(() => {
    console.log(activeCustomer);
    handleListConnectors()
      .then((connectors) => {
        setConnectors(connectors);
      })
      .catch((e) => setConnectors([]));
  }, []);

  React.useEffect(() => {
    handleListRecords("reasoncode", { type: "new", isDefault: true })
      .then((defaultReasonCode) => {
        if (defaultReasonCode && defaultReasonCode.length > 0) {
          return defaultReasonCode[0];
        }

        throw new Error("No default reason code found");
      })
      .then((reasoncode) => {
        return entityReferenceSchema.validate(reasoncode, {
          stripUnknown: true,
        });
      })
      .then((parsedReasonCode) => {
        setFormValues((prev) => {
          return {
            ...prev,
            ReasonCode: parsedReasonCode,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });

    handleListRecords("programtype")
      .then((data) => {
        if (data && data.length > 0) {
          return data;
        }
        throw new Error("No program type found");
      })
      .then((parsedProgramType) => {
        if (parsedProgramType.length === 1) {
          return entityReferenceSchema.validate(parsedProgramType[0], {
            stripUnknown: true,
          });
        }
      })
      .then((parsedProgramType) => {
        setFormValues((prev) => {
          return {
            ...formValues,
            ProgramType: parsedProgramType,
          };
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  React.useEffect(() => {
    if (!connectors || connectors.length === 0) {
      handleListConnectors()
        .then((connectors) => {
          setConnectors(connectors);
        })
        .catch((e) => setConnectors([]));
    } else if (!formValues.Connector) {
      setDefaultConnector(null);
    } else {
      let foundConnector = connectors.find((connector) => {
        if (connector && connector.company && connector.company.id)
          return connector.id === formValues.Connector;

        return false;
      });

      if (foundConnector) {
        setDefaultConnector(foundConnector);
      } else setDefaultConnector(null);
    }
  }, [formValues.Connector]);

  React.useEffect(() => {
    const {
      ["customerlookup"]: removedKey,
      ...newSearchSidePanelSelectedLookup
    } = searchSidePanelSelectedLookup;
    setSearchSidePanelSelectedLookup(newSearchSidePanelSelectedLookup);
  }, [formValues.Connector]);

  React.useEffect(() => {
    var subscriptionPlanParsed = subscriptionPlanSchema
      .validate(formValues, { abortEarly: false })
      .then(() => setIsValid(true))
      .catch((e) => {
        const errors = e.inner.reduce((acc, value) => {
          acc[value.path] = {
            message: value.message,
            type: value.type,
          };
          return acc;
        }, {});
        setError(errors);
        setIsValid(false);
      });
  }, [formValues]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;
      console.log(name, value);

      if (e.target.type === "checkbox") {
          value = e.target.checked;
      }  

    setFormValues({
      ...formValues,
      [name]: value,
    });
    };

    const showMonthBillingPeriodType = () => {
        if (formValues.BillingPeriodUnit === 'Months') {           
            return false;
        }
        formValues.MonthBillingPeriodType = "Other"
        return true;
    };

    const showBillingPeriodValue = () => {
        if (formValues.BillingPeriodUnit === 'Months') {

            switch (formValues.MonthBillingPeriodType) {
                case "Monthly":
                    formValues.BillingPeriodValue = "1"
                    break;

                case "Quarterly":
                    formValues.BillingPeriodValue = "3"
                    break;

                case "HalfYearly":
                    formValues.BillingPeriodValue = "6"
                    break;

                case "Yearly":
                    formValues.BillingPeriodValue = "12"
                    break;

                default:
                    break;
            }

            if (formValues.MonthBillingPeriodType === "Other") {
                return false;
            }

            return true;
        }

        return false;
    };

  return (
    <form onSubmit={onSubmit}>
      <Grid
        marginTop={2}
        spacing={2}
        container
        alignItems="left"
        justify="center"
        direction="row"
      >
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(false);
              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("connectorlookup");
              setSearchSidePanelTitle("Search Connectors");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("connector");
              setColumns([
                {
                  field: "name",
                  headerName: "Name",
                  flex: 2,
                  minWidth: 100,
                },
                {
                  field: "company",
                  headerName: "Company",
                  flex: 2,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (
                      params &&
                      params.row &&
                      params.row.company &&
                      params.row.company.name
                    ) {
                      try {
                        return params.row.company.name;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={(e) => {
              if (
                e &&
                e.target &&
                e.target.name &&
                e.target.value &&
                e.target.value.id
              )
                handleInputChange({
                  target: {
                    name: e.target.name,
                    value: e.target.value.id,
                  },
                });
            }}
            id="connectorlookup"
            type="connector"
            name="Connector"
            label="Connector"
          />
        </Grid>

        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(true);

              if (defaultConnector)
                query = { ...query, connectorId: defaultConnector.id };

              setSearchSidePanelQuery(query);
              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("customerlookup");
              setSearchSidePanelTitle("Search Customers");
              setSearchSidePanelType("customer");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading || !defaultConnector}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="customerlookup"
            type="customer"
            name="Customer"
            label="Customer"
          />
        </Grid>

        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(false);
              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("programtypelookup");
              setSearchSidePanelTitle("Search Program Types");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("programtype");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="programtypelookup"
            type="programtype"
            name="ProgramType"
            label="Program Type"
            initialValue={formValues && formValues.ProgramType}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            name="BillingPeriodUnit"
            label="Billing Period Unit"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={isLoading}
            value={formValues.BillingPeriodUnit}
            onChange={handleInputChange}
          >
            <MenuItem value="Days">Days</MenuItem>
            <MenuItem value="Months">Months</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
                  <TextField
                      select
                      name="MonthBillingPeriodType"
                      label="Billing Period Type"
                      size="small"
                      sx={{ width: 200 }}
                      required={true}
                      disabled={isDisabled || isLoading || showMonthBillingPeriodType()}
                      value={formValues.MonthBillingPeriodType}
                      onChange={handleInputChange}
                  >
                      <MenuItem value="Monthly">Monthly</MenuItem>
                      <MenuItem value="Quarterly">Quarterly</MenuItem>
                      <MenuItem value="HalfYearly">Half-Yearly</MenuItem>
                      <MenuItem value="Yearly">Yearly</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                  </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="BillingPeriodValue"
            label="Billing Period Value"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={isLoading || showBillingPeriodValue()}
            value={formValues.BillingPeriodValue}
            onChange={handleInputChange}
            inputProps={{
              step: 1,
              min: 1,
              max: 99999,
              type: "number",
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            name="BillingType"
            label="Billing Type"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={isLoading}
            value={formValues.BillingType}
            onChange={handleInputChange}
          >
            <MenuItem value="Upfront">Up front</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            id="periodType-input"
            name="PeriodType"
            label="Period Type"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={isLoading}
            value={formValues.PeriodType}
            onChange={handleInputChange}
          >
            {/*<MenuItem value="Calendar">Calendar</MenuItem>*/}
            <MenuItem value="Rolling">Rolling</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            name="SubscriptionType"
            label="Subscription Type"
            size="small"
            sx={{ width: 200 }}
            required
            disabled={isLoading}
            value={formValues.SubscriptionType}
            onChange={handleInputChange}
          >
            {/*<MenuItem value="PerpetualEnhancementPlan">Perpetual Enhancement Plan</MenuItem>*/}
            {/*<MenuItem value="Consumption">Consumption</MenuItem>*/}
            <MenuItem value="PerUnitSubscription">
              Per Unit Subscription
            </MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              setDisplayConnectorSelect(false);
              handleListRecords(type, { ...query, type: "new" });
              setSearchSidePanelQuery({ ...query, type: "new" });
              setSearchSidePanelOpen(true);
              setSelectedLookupId("reasoncodelookup");
              setSearchSidePanelTitle("Search Reason Codes");
              setSearchSidePanelType("reasoncode");
              setColumns([
                { field: "name", headerName: "Name", flex: 2, minWidth: 100 },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={handleInputChange}
            id="reasoncodelookup"
            type="reasoncode"
            name="ReasonCode"
            label="Reason Code"
            initialValue={formValues && formValues.ReasonCode}
          />
         </Grid>
         <Grid item xs={6}>
           <FormControlLabel
            labelPlacement="start"
            sx={{ width: 200 }}
            control={
            <Switch
              id="IsGoCardlessIntegration-input"
              name="IsGoCardlessIntegration"
              label="Enable for GoCardless Direct Debit"
              size="medium"
              disabled={isDisabled || isLoading}
              checked={formValues.IsGoCardlessIntegration}
              onChange={handleInputChange}
              inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Enable for GoCardless Direct Debit"
          />
         </Grid>
        <Grid item>
          <TextField
            id="salesPerson-input"
            name="salesPerson"
            label="Sales Person"
            type="text"
            size="small"
            disabled={isLoading}
            value={formValues.salesPerson}
            onChange={handleInputChange}
            sx={{ width: 200 }}
          />
        </Grid>
        <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
          <Button
            type="submit"
            disabled={!isValid || isLoading}
            variant="outlined"
          >
            <Typography color="black">Create</Typography>
          </Button>
          <Button onClick={handleClose} disabled={isLoading} variant="outlined">
            <Typography color="black">Cancel</Typography>
          </Button>
        </DialogActions>
      </Grid>
      <SearchSidePanel
        open={searchSidePanelOpen}
        handleClose={() => setSearchSidePanelOpen(false)}
        isLoading={isLoadingSearchSidePanel}
        title={searchSidePanelTitle}
      >
        <LookupPaper
          handleClose={() => setSearchSidePanelOpen(false)}
          selectedLookupId={selectedLookupId}
          handleChangeLookupValue={setSearchSidePanelSelectedLookup}
          isLoading={isLoadingSearchSidePanel}
          query={searchSidePanelQuery}
          data={searchSidePanelData}
          columns={columns}
          searchSidePanelType={searchSidePanelType}
          connectors={connectors}
          defaultConnector={defaultConnector}
          displayConnectorSelect={displayConnectorSelect}
          handleListRecords={handleListRecords}
        />
      </SearchSidePanel>
    </form>
  );
}
