import * as React from "react";
import { styled } from "@mui/material/styles";
import { Typography, Button } from "@mui/material/";

import Subtitle from "../atoms/Subtitle";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

const StyledButton = styled(Button)({
  color: "#7A7A7A",
  fontWeight: "bold",
  fontSize: "23px",
  fontFamily: "sans-serif",

  lineHeight: 1.5,
  padding: 0,
  paddingLeft: "2px",
  paddingRight: "2px",
  marginLeft: "2px",
  marginBottom: "2px",

  textTransform: "none",
  alignItems: "center",

  height: "auto",
});

const SubtitleFilter = ({ title, children, style, filter }) => {
  return (
    <Subtitle title={title} style={{ ...style }}>
      {filter && (
        <StyledButton onClick={filter.toggleFiltering}>
          {"filtered by " +
            filter.format.substring(0, filter.format.length - 2) +
            " from " +
            filter.start.getDate() +
            "/" +
            (filter.start.getMonth() + 1) +
            "/" +
            filter.start.getFullYear() +
            " through " +
            filter.finish.getDate() +
            "/" +
            (filter.finish.getMonth() + 1) +
            "/" +
            filter.finish.getFullYear() +
            " "}
          <FilterAltIcon />
        </StyledButton>
      )}
      {children}
    </Subtitle>
  );
};

export default SubtitleFilter;
