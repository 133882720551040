import * as React from "react";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

import Legend from "./Legend";

const LegendContainer = styled(Grid)({
  justifyContent: "center",
  alignItems: "center",
});

const LineChartLegends = ({ color, data, legends, filterData }) => {
  return (
    <LegendContainer container spacing={1}>
      {data && 
        data.map((key, index) => {
          
          //TODO: Fix this
          console.log( key, index );
          
          return (
            <Legend
              id={key.id}
              index={index}
              key={index}
              value={key.data}
              color={isNaN(key.Id) ? color[0] : color[key.id % color.length]}
              visible={legends[index]}
              filterData={filterData}
            />
          );
        })}
    </LegendContainer>
  );
};

LineChartLegends.propTypes = {
  
}

LineChartLegends.defaultProps = {
  color: ["#000437", "#191d4b", "#32365e", "#4c4f73", "#666887", "#7f819b"],
}

export default LineChartLegends;
