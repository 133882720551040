import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import LookupField from '../../ui/organisms/LookupField';
import InputAdornment from "@mui/material/InputAdornment";
import { format } from 'date-fns'

import { DialogActions } from '@mui/material/';

const defaultValues = {
    customer: "",
    salesPerson: "",
    actionDate: "",
    actionType: "",
    product: "",
    creditNote: "",
    actionStatus: "",
    dateFrom: "",
    dateTo: "",
    order: "",
    invoice: "",
    invoiceDate: "",
    postingDate: "",
    quantity: "",
    unit: "",
    salesPrice: "",
    discountAmount: "",
    discountPercent: "",
    grossAmount: "",
    netAmount: "",
    currency: "",
    reasonCode: "",
    cancelledReasonCode: "",
    cancelledBy: "",
    cancelledDateTime: "",
    currentSalesCycle: "",
    multiLine: false
};

export default function ViewSubscriptionActionForm({ handleClose, isLoading, recordData }) {

    const [formValues, setFormValues] = React.useState(defaultValues);

    React.useEffect(() => {
        setFormValues(recordData);
    }, [recordData]);

    return (
        < form >
        <Grid marginTop={2} spacing={2} container alignItems="center" justify="center" direction="row">
            <Grid item xs={5}>
                <LookupField
                    required
                    disabled={true}
                        initialValue={formValues.customer}
                    id="customerlookup"
                    sx={{ width: 200 }}
                    type="customer"
                    name="Customer"
                    label="Customer"
                />
                </Grid>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        id="salesperson-input"
                        name="salesperson"
                        label="Sales Person"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled
                        value={formValues.salesPerson}
                    />
                </Grid>
                <Grid item xs={5}>
                    <LookupField
                        required
                        disabled={true}
                        initialValue={formValues.product}
                        sx={{ width: 200 }}
                        id="productlookup"
                        type="product"
                        name="product"
                        label="Product"
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        select
                        name="actionType"
                        label="Action Type"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.actionType}
                    >
                        <MenuItem value="GenerateSalesOrder">Generate Sales Order</MenuItem>
                        <MenuItem value="RenewalNotice">Renewal Notice</MenuItem>
                        <MenuItem value="DirectDebitPayment">Direct Debit Payment</MenuItem>


                    </TextField>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        id="salesPrice-input"
                        name="salesPrice"
                        label="Sales Price"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues && formValues.salesPrice}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">&#8364;</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        select
                        name="actionStatus"
                        label="Action Status"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.actionStatus}
                    >
                        <MenuItem value="NotFirmed">Not Firmed</MenuItem>
                        <MenuItem value="Firming">Firming</MenuItem>

                        <MenuItem value="Firmed">Firmed</MenuItem>
                        <MenuItem value="Posting">Posting</MenuItem>
                        <MenuItem value="Posted">Posted</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        id="quantity-input"
                        name="quantity"
                        label="Quantity"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues && formValues.quantity}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        id="actionDate-input"
                        name="actionDate"
                        label="Action Date"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.actionDate ? format(new Date(formValues.actionDate), "dd/MM/yyyy") : ""}
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        id="discountPercent-input"
                        name="discountPercent"
                        label="Discount Percent"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues && formValues.discountPercent}
                    />
                </Grid>
            <Grid item xs={5}>
                <LookupField
                    required
                    disabled={true}
                        initialValue={formValues.reasonCode}
                    sx={{ width: 200 }}
                    id="reasoncodelookup"
                    type="reasoncode"
                    name="ReasonCode"
                    label="Reason Code"
                />
            </Grid>
            <Grid item xs={5}>
                <LookupField
                    required
                    disabled={true}
                    initialValue={formValues.unit}
                    sx={{ width: 200 }}
                    id="unitlookup"
                    type="unit"
                    name="unit"
                    label="Unit"
                />
            </Grid>
            <Grid item xs={5}>
                <FormControlLabel
                    labelPlacement="start"
                    control={<Switch
                        id="creditnote-input"
                        name="creditnote"
                        label="Credit Note"
                        type="text"
                        size="big"
                        required
                        disabled={false}
                        defaultChecked={formValues.creditnote ? true : false}
                    />}
                    label="Credit Note"
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    id="discountAmount-input"
                    name="discountAmount"
                    label="Discount Amount"
                    type="text"
                    size="small"
                    sx={{ width: 200 }}
                    required
                    disabled={true}
                    value={formValues && formValues.discountAmount}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">&#8364;</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    id="dateFrom-input"
                    name="dateFrom"
                    label="Date From"
                    type="text"
                    size="small"
                    sx={{ width: 200 }}
                    required
                    disabled={true}
                        value={formValues.dateFrom ? format(new Date(formValues.dateFrom), "dd/MM/yyyy") : ""}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    id="GrossAmount-input"
                    name="grossAmount"
                    label="Gross Amount"
                    type="text"
                    size="small"
                    sx={{ width: 200 }}
                    required
                    disabled={true}
                    value={formValues && formValues.grossAmount}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">&#8364;</InputAdornment>,
                    }}
                />
            </Grid>
            <Grid item xs={5}>
                <TextField
                    id="dateTo-input"
                    name="dateTo"
                    label="Date To"
                    type="text"
                    size="small"
                    sx={{ width: 200 }}
                    required
                    disabled={true}
                        value={formValues.dateTo ? format(new Date(formValues.dateTo), "dd/MM/yyyy") : ""}
                />
                </Grid>

                <Grid item xs={5}>
                    <TextField
                        id="NetAmount-input"
                        name="netAmount"
                        label="Net Amount"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues && formValues.netAmount}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">&#8364;</InputAdornment>,
                        }}
                    />
                </Grid>
                <Grid item xs={5}>
                    </Grid>

                <Grid item xs={5}>
                    <TextField
                        id="CurrentSalesCycle-input"
                        name="currentSalesCycle  "
                        label="Current Sales Cycle "
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues && formValues.currentSalesCycle}
                    />
                </Grid>
                <Grid item xs={10}>
                    <LookupField
                        required
                        disabled={true}
                        id="Order-input"
                        sx={{ width: 200 }}
                        type="order"
                        name="order"
                        label="Order"
                        initialValue={formValues.order ? formValues.order: ""}
                    />                
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="InvoiceDate-input"
                        name="invoiceDate"
                        label="Invoice Date"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.invoiceDate ? format(new Date(formValues.invoiceDate), "dd/MM/yyyy") : ""}
                    />
                </Grid>
                <Grid item xs={10}>
                    <LookupField
                        required
                        disabled={true}
                        initialValue={formValues.invoice}
                        id="invoice-input"
                        sx={{ width: 200 }}
                        type="invoice"
                        name="invoice"
                        label="Invoice"
                    /> 
            </Grid>
            <Grid item xs={10}>
                <TextField
                    id="postingDate-input"
                    name="postingDate"
                    label="Posting Date"
                    type="text"
                    size="small"
                    sx={{ width: 200 }}
                    required
                    disabled={true}
                        value={formValues.postingDate ? format(new Date(formValues.postingDate), "dd/MM/yyyy") : ""}
                />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="createdOn-input"
                        name="createdOn"
                        label="Created On"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.createdOn ? format(new Date(formValues.createdOn), "dd/MM/yyyy") : ""}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        id="CancelledDateTime-input"
                        name="cancelledDateTime "
                        label="Cancelled Date"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        required
                        disabled={true}
                        value={formValues.cancelledDateTime ? format(new Date(formValues.cancelledDateTime), "dd/MM/yyyy") : ""}
                    />
                </Grid>
            <Grid item xs={10}>
                <LookupField
                    required
                    disabled={true}
                        initialValue={formValues.cancelledBy}
                    sx={{ width: 200 }}
                    id="cancelledByLookup"
                    type="user"
                    name="cancelledBy"
                    label="Cancelled By"
                />
            </Grid>


            <Grid item xs={10}>
                <FormControlLabel
                    labelPlacement="start"
                    control={<Switch
                        id="MultiLine -input"
                        name="multiLine "
                        label="MultiLine"
                        type="text"
                        size="big"
                        required
                        disabled={false}
                        defaultChecked={formValues.multiLine ? true : false}
                    />}
                    label="MultiLine"
                />
            </Grid>

            <Grid item xs={10}>
                <TextField
                    id="modifiedOn-input"
                    name="modifiedOn"
                    label="Modified On"
                    type="text"
                    size="small"
                    sx={{ width: 200 }}
                    required
                    disabled={true}
                        value={formValues.modifiedOn ? format(new Date(formValues.modifiedOn), "dd/MM/yyyy") : ""}
                />
            </Grid>
            <DialogActions sx={{ position: "fixed", bottom: 0, right: 8 }}>
                <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                    <Typography color="black">Close</Typography>
                </Button>
            </DialogActions>
        </Grid>
        </form >
    );
}