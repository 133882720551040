import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import SearchIcon from '@mui/icons-material/Search';
import Chip from "@mui/material/Chip";
import PropTypes from 'prop-types';

export default function LookupField({ label, handleSearchSidePanel, searchSidePanelSelectedLookup, id, type, onChange, name, required, disabled, initialValue }) {
    const [fieldValue, setFieldValue] = React.useState("");

    const [chipValue, setChipValue] = React.useState(null);

    const handleChangeValue = (event) => {
        if (chipValue) {
            return event.preventDefault();
        }
        setFieldValue(event.target.value);
    };

    const updateLookupValue = (Id, Name, url) => {
        if (!Id) {
            if (onChange) {
                onChange({
                    target: {
                        name: name,
                        value: null
                    }
                });
            }
            setChipValue(null);
            setFieldValue("");
            return;            
        }

        setFieldValue("");
        if (onChange) {
            onChange({
                target: {
                    name: name,
                    value: searchSidePanelSelectedLookup[id]
                }
            });
        }       

        setChipValue({ label: Name, url: url });
    };
    const onDelete = (event) => {
        updateLookupValue(null);
    }
    const onKeyUp = (event) => {
        if (chipValue && event.key === "Backspace" || event.key === "Delete") {
            return updateLookupValue(null);
        }
        if (!chipValue && event.key === "Enter") {
            return handleSearchSidePanel(type, { name: fieldValue});
        }
    };

    const handleClickSearch = () => {
        return handleSearchSidePanel(type, { name: fieldValue });
    };

    const onClickChip = () => {
        if (chipValue && chipValue.url) {
            //TODO be able to receive relative URLs
            return window.open(chipValue.url, "_blank").focus();
        }
        else
            console.error(chipValue);
    }

    React.useEffect(() => {
        if (initialValue && (initialValue.Name || initialValue.name)) {
            setChipValue({ label: initialValue.Name || initialValue.name, url: initialValue.url || "" });
        } else {
            updateLookupValue(null);
        } 

    }, [initialValue]);

    React.useEffect(() => {
        if (searchSidePanelSelectedLookup && searchSidePanelSelectedLookup[id])
            updateLookupValue(searchSidePanelSelectedLookup[id].id, searchSidePanelSelectedLookup[id].name,
                searchSidePanelSelectedLookup[id].url);
        else
            updateLookupValue(null);

    }, [searchSidePanelSelectedLookup && searchSidePanelSelectedLookup[id]]);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <FormControl sx={{ width: '200px' }}  >
                    <InputLabel required variant="outlined" htmlFor={`lookup-${label}`} >{label}</InputLabel>
                    <OutlinedInput
                    id={`lookup-${label}`}
                    type='text'
                    size="small"
                    disabled={disabled}
                    value={fieldValue}
                    onChange={handleChangeValue}
                    onKeyUp={onKeyUp}
                    label={label}
                    startAdornment={
                        chipValue && (<Chip
                            size="small"
                            sx={{ maxWidth: "135px" }}
                            label={chipValue.label}
                            onClick={onClickChip}
                            onDelete={!disabled ? onDelete : null}
                        />)
                    }
                    endAdornment={
                        <InputAdornment position="end">
                            {!disabled && <IconButton
                                edge="end"
                                onClick={handleClickSearch}
                            >
                                <SearchIcon />
                            </IconButton>}
                        </InputAdornment>}
                        
                    />
                </FormControl>                
        </Box>
    );
}

LookupField.propTypes = {
    type: PropTypes.string.isRequired
};