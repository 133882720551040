import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { ResponsiveBar } from "@nivo/bar";

import SubtitleFilter from "../molecules/SubtitleFilter";
import TemporaryChartPlaceholder from "../molecules/TemporaryChartPlaceholder";
import BarChartTip from "../molecules/BarChartTip";
import BarChartLegends from "../molecules/BarChartLegends";

import _ from "lodash/";

const BarChartCommon = {
  margin: {
    top: 20,
    right: 60,
    bottom: 60,
    left: 60,
  },
  padding: 0.3,
  valueScale: { type: "linear" },
  indexScale: { type: "band", round: true },
  labelSkipWidth: 36,
  labelSkipHeight: 36,
  labelTextColor: "#fff",
  borderRadius: 1,
  borderWidth: 1,
  borderColor: {
    from: "color",
    modifiers: [["brighter", 1.2]],
  },
  role: "application",
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 25,
    legendOffset: 15,
    legendPosition: "middle",
  },
};

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "height",
})(({ theme, height }) => ({
  width: "auto",
  display: "flex",

  height: height.xl + "px",
  "@media screen and (max-width: 1536px)": {
    height: height.xl + "px",
  },
  "@media screen and (max-width: 1200px)": {
    height: height.lg + "px",
  },
  "@media screen and (max-width:  900px)": {
    height: height.md + "px",
  },
  "@media screen and (max-width: 600px)": {
    height: height.sm + "px",
  },
}));

const BarChart = ({
  id,
  title,
  height,
  data,
  format,
  currency,
  toggleFiltering,
  startDate,
  finishDate,
}) => {
  const BluefortColours = useTheme().colors.bluefort;

  const [chartData, setChartData] = React.useState(null);
  const [chartPoints, setChartPoints] = React.useState(null);
  const [chartFilter, setChartFilter] = React.useState(null);
  const [isEmpty, setEmpty] = React.useState(false);
  const [legends, setLegends] = React.useState(false);

  const setColor = (key) => {
    return BluefortColours[key.id % BluefortColours.length];
  };

  const filterData = (key, index, value) => {
    let l = _.cloneDeep(legends);

    if (chartFilter.includes(key)) {
      setChartFilter(chartFilter.filter((id) => id !== key));
      l[index] = false;
      setLegends(l);
    } else {
      setChartFilter([...chartFilter, key]);
      l[index] = true;
      setLegends(l);
    }
  };

  React.useEffect(() => {
    const d = _.cloneDeep(data.data);
    const k = _.cloneDeep(d.keys);

    if (d.currencies)
    {
      setChartPoints(d.values[d.currencies[currency]]);
    } else {
      setChartPoints(d.values)
    }
    
    setChartData(data.data);
    setChartFilter(k);

    let l = _.cloneDeep(d.keys);
    k.map((value, index) => {
      l[index] = true;
    });
    setLegends(l);
  }, [data]);

  React.useEffect(() => {
    if (chartFilter) setEmpty(chartFilter.length <= 0);
  }, [chartFilter]);
  
  
  const formatCurrency = (value) => {
    return chartData && chartData.currencies ? new Intl.NumberFormat("en-GB", {style: "currency", currency: chartData.currencies[currency]}).format(value) : value;
  };

  return (
    <React.Fragment>
      {chartData && chartFilter && (
        <SubtitleFilter
          title={title}
          filter={{
            toggleFiltering: toggleFiltering,
            format: format,
            start: startDate,
            finish: finishDate,
          }}
        />
      )}
      <Container height={height}>
        <TemporaryChartPlaceholder
          style={{
            height: height.xl - 100 + "px",
            "@media screen and (max-width: 1536px)": {
              height: height.xl - 25 + "px",
            },
            "@media screen and (max-width: 1200px)": {
              height: height.lg - 50 + "px",
            },
            "@media screen and (max-width:  900px)": {
              height: height.md - 75 + "px",
            },
            "@media screen and (max-width: 600px)": {
              height: height.sm - 25 + "px",
            },
          }}
          isEnabled={chartData == null || chartFilter == null || chartPoints == null || isEmpty}
        />
        {chartData && chartFilter && chartPoints && !isEmpty && (
          <ResponsiveBar
            {...BarChartCommon}
            data={chartPoints}
            keys={chartFilter}
            indexBy={chartData.indexBy}
            groupMode={chartData.grouping || "grouping"}
            colors={setColor}
            label={selection => chartData.currencies ? new Intl.NumberFormat("en-GB", {style: "currency", currency: chartData.currencies[currency]}).format(selection.value) : selection.value}
            valueFormat={value => chartData.currencies ? new Intl.NumberFormat("en-GB", {style: "currency", currency: chartData.currencies[currency]}).format(value) : value}
            axisLeft={{
              format: value => chartData.currencies ? new Intl.NumberFormat("en-GB", {style: "currency", currency: chartData.currencies[currency]}).format(value) : value
            }}
            tooltip={BarChartTip}
          />
        )}
      </Container>
      {chartData && chartFilter && (
        <BarChartLegends
          color={BluefortColours}
          data={chartData}
          filterData={filterData}
          legends={legends}
        />
      )}
    </React.Fragment>
  );
};

BarChart.defaultProps = {
  height: { xl: 525, lg: 400, md: 300, sm: 200 },
};
export default BarChart;
