import React from 'react';

import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string, number, mixed } from 'yup';
import SearchSidePanel from '../../ui/organisms/SearchSidePanel';
import LookupPaper from '../../ui/organisms/LookupPaper';
import LookupField from '../../ui/organisms/LookupField';

import { useNavigateWithParams } from '../../hooks/useNavigateWithParams'

import { DialogActions } from '@mui/material/';

const defaultValues = {
    ReasonCode: ""
};

let entityReferenceSchema = object({
    id: string().required(),
    name: string().required(),
    connector: string(),
    secondaryKey: string(),
    extensionData: mixed(),
    url: string().nullable()
});

let PaymentCancelSchema = object({
    ReasonCode: entityReferenceSchema
});

export default function CancelPaymentForm({
    handleClose, isLoading, isLoadingSearchSidePanel,
    handleListRecords, searchSidePanelData, handleListConnectors, handleCancel }) {

    const [formValues, setFormValues] = React.useState(defaultValues);
    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});
    const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
    const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
    const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
    const [columns, setColumns] = React.useState([]);
    const [searchSidePanelType, setSearchSidePanelType] = React.useState("");

    const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] = React.useState({});
    const [selectedLookupId, setSelectedLookupId] = React.useState(null);

    let navigate = useNavigateWithParams();
    const onSubmit = data => {
        data.preventDefault();
        handleCancel(formValues.ReasonCode);
    }

    React.useEffect(() => {
        handleListRecords("reasoncode", { type: "cancel", isDefault: true })
            .then((defaultReasonCode) => {
                if (defaultReasonCode && defaultReasonCode.length > 0) {
                    return defaultReasonCode[0];
                }

                throw new Error("No default reason code found");
            })
            .then((reasoncode) => {
                return entityReferenceSchema.validate(reasoncode, { stripUnknown: true });
            })
            .then((parsedReasonCode) => {
                setFormValues({
                    ...formValues,
                    "ReasonCode": parsedReasonCode,
                });
            })
            .catch((e) => { console.log(e); });
    }, []);

    React.useEffect(() => {
        var paymentCancelParsed = PaymentCancelSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = {
                        message: value.message,
                        type: value.type
                    }
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="row">
                <Grid item xs={6}>
                    <LookupField
                        handleSearchSidePanel={(type, query) => {
                            handleListRecords(type, { ...query, type: "cancel" });
                            setSearchSidePanelOpen(true);
                            setSelectedLookupId("reasoncodelookup");
                            setSearchSidePanelTitle("Select Reason Code");
                            setSearchSidePanelQuery({ ...query, type: "cancel" });
                            setSearchSidePanelType("reasoncode");
                            setColumns([{ field: 'name', headerName: 'Name', flex: 2, minWidth: 100 }]);
                        }}
                        required
                        disabled={isLoading}
                        searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
                        onChange={handleInputChange}
                        id="reasoncodelookup"
                        type="reasoncode"
                        name="ReasonCode"
                        label="Reason Code"
                        initialValue={formValues && formValues.ReasonCode}
                    />
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    <Button type="submit" disabled={!isValid || isLoading} variant="outlined" >
                        <Typography color="black">Confirm</Typography>
                    </Button>
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Back</Typography>
                    </Button>
                </DialogActions>

            </Grid>
            <SearchSidePanel open={searchSidePanelOpen}
                handleClose={() => setSearchSidePanelOpen(false)}
                isLoading={isLoadingSearchSidePanel}
                title={searchSidePanelTitle}>
                <LookupPaper
                    handleClose={() => setSearchSidePanelOpen(false)}
                    selectedLookupId={selectedLookupId}
                    handleChangeLookupValue={setSearchSidePanelSelectedLookup}
                    isLoading={isLoadingSearchSidePanel}
                    query={searchSidePanelQuery}
                    data={searchSidePanelData}
                    columns={columns}
                    searchSidePanelType={searchSidePanelType}
                    connectors={[]}
                    defaultConnector={{}}
                    displayConnectorSelect={false}
                    handleListRecords={handleListRecords} />
            </SearchSidePanel>
        </form>
    );
}