import React, { useState } from "react";

import Grid from "@mui/material/Grid";
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string, number, mixed, bool } from "yup";
import SearchSidePanel from "../../ui/organisms/SearchSidePanel";
import LookupPaper from "../../ui/organisms/LookupPaper";
import LookupField from "../../ui/organisms/LookupField";

import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";

import { DialogActions } from "@mui/material/";

const defaultValues = {
    type: "Sandbox",
    email: "",
    connectorId: "",
    customerOnboardingRedirectUrl: "",
    customerOnboardingExitUrl: ""
};

let merchantSchema = object({
    type: string().required().oneOf(["Sandbox", "Live"]),
    connectorId: string().required().uuid(),
    email: string().required(),
    customerOnboardingRedirectUrl: string().required(),
    customerOnboardingExitUrl: string().required()
});

export default function NewMerchantForm({
  handleCreateRecord,
  handleClose,
  isLoading,
  isLoadingSearchSidePanel,
  handleListRecords,
  searchSidePanelData,
}) {
    const [formValues, setFormValues] = React.useState(defaultValues);
  const [isValid, setIsValid] = React.useState(false);
  const [error, setError] = React.useState({});
  const [searchSidePanelOpen, setSearchSidePanelOpen] = React.useState(false);
  const [searchSidePanelTitle, setSearchSidePanelTitle] = React.useState("");
  const [searchSidePanelQuery, setSearchSidePanelQuery] = React.useState("");
  const [columns, setColumns] = React.useState([]);
  const [searchSidePanelType, setSearchSidePanelType] = React.useState("");

  const [searchSidePanelSelectedLookup, setSearchSidePanelSelectedLookup] =
    React.useState({});
  const [selectedLookupId, setSelectedLookupId] = React.useState(null);

  let navigate = useNavigateWithParams();
  const onSubmit = (data) => {
    data.preventDefault();
    handleCreateRecord(formValues);
  };

    React.useEffect(() => {
        console.log(error);
    }, [error]);

  React.useEffect(() => {
      var merchantParsed = merchantSchema
      .validate(formValues, { abortEarly: false })
      .then(() => setIsValid(true))
      .catch((e) => {
        const errors = e.inner.reduce((acc, value) => {
          acc[value.path] = {
            message: value.message,
            type: value.type,
          };
          return acc;
        }, {});
        setError(errors);
        setIsValid(false);
      });
  }, [formValues]);

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid
        marginTop={2}
        spacing={2}
        container
        alignItems="left"
        justify="center"
        direction="row"
      >
        <Grid item xs={6}>
          <LookupField
            handleSearchSidePanel={(type, query) => {
              handleListRecords(type, query);
              setSearchSidePanelOpen(true);
              setSelectedLookupId("connectorlookup");
              setSearchSidePanelTitle("Search Connectors");
              setSearchSidePanelQuery(query);
              setSearchSidePanelType("connector");
              setColumns([
                {
                  field: "name",
                  headerName: "Name",
                  flex: 2,
                  minWidth: 100,
                },
                {
                  field: "company",
                  headerName: "Company",
                  flex: 2,
                  minWidth: 100,
                  valueGetter: function (params) {
                    if (
                      params &&
                      params.row &&
                      params.row.company &&
                      params.row.company.name
                    ) {
                      try {
                        return params.row.company.name;
                      } catch (e) {}
                      return "";
                    }
                  },
                },
              ]);
            }}
            required
            disabled={isLoading}
            searchSidePanelSelectedLookup={searchSidePanelSelectedLookup}
            onChange={(e) => {
              if (
                e &&
                e.target &&
                e.target.name &&
                e.target.value &&
                e.target.value.id
              )
                handleInputChange({
                  target: {
                    name: e.target.name,
                    value: e.target.value.id,
                  },
                });
            }}
            id="connectorlookup"
            type="connector"
                      name="connectorId"
            label="Connector"
          />
              </Grid> 
              <Grid item xs={6}>
                  <TextField
                      select
                      name="type"
                      label="Type"
                      size="small"
                      sx={{ width: 200 }}
                      required
                      disabled={isLoading}
                      value={formValues.type}
                      onChange={handleInputChange}
                  >
                      <MenuItem value="Sandbox">Sandbox</MenuItem>
                      <MenuItem value="Live">Live</MenuItem>
                  </TextField>
              </Grid>
        <Grid item xs={12}>
                  <TextField
                      required
                id="email-input"
                name="email"
                label="Merchant email"
                type="text"
                size="small"
                disabled={isLoading}
                value={formValues.email}
                onChange={handleInputChange}
                      sx={{ width: "100%" }}
            />
              </Grid>      
              <Grid item xs={12}>
                  <TextField
                      required
                      id="customerOnboardingRedirectUrl-input"
                      name="customerOnboardingRedirectUrl"
                      label="Customer onboarding redirect Url"
                      type="text"
                      size="small"
                      disabled={isLoading}
                      value={formValues.customerOnboardingRedirectUrl}
                      onChange={handleInputChange}
                      sx={{ width: "100%" }}

                  />
              </Grid>   
              <Grid item xs={12}>
                  <TextField
                      required
                      id="customerOnboardingExitUrl-input"
                      name="customerOnboardingExitUrl"
                      label="Customer onboarding redirect exit Url"
                      type="text"
                      size="small"
                      disabled={isLoading}
                      value={formValues.customerOnboardingExitUrl}
                      onChange={handleInputChange}
                      sx={{ width: "100%" }}
                  />
              </Grid>                  
        <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
          <Button
            type="submit"
            disabled={!isValid || isLoading}
            variant="outlined"
          >
            <Typography color="black">Create</Typography>
          </Button>
          <Button onClick={handleClose} disabled={isLoading} variant="outlined">
            <Typography color="black">Cancel</Typography>
          </Button>
        </DialogActions>
      </Grid>
      <SearchSidePanel
        open={searchSidePanelOpen}
        handleClose={() => setSearchSidePanelOpen(false)}
        isLoading={isLoadingSearchSidePanel}
        title={searchSidePanelTitle}
      >
        <LookupPaper
          handleClose={() => setSearchSidePanelOpen(false)}
          selectedLookupId={selectedLookupId}
          handleChangeLookupValue={setSearchSidePanelSelectedLookup}
          isLoading={isLoadingSearchSidePanel}
          query={searchSidePanelQuery}
          data={searchSidePanelData}
          columns={columns}
          searchSidePanelType={searchSidePanelType}
          handleListRecords={handleListRecords}
        />
      </SearchSidePanel>
    </form>
  );
}
