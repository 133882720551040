import * as React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

export default function RibbonButton(props) {    

    return (
        props.display ?
            <Button onClick={props.onClick} startIcon={props.startIcon} variant="text" size="small" sx={{ textTransform: "none" }}>
            {props.children}
            </Button> : null
            );
}

RibbonButton.propTypes = {
    title: PropTypes.string,
    onClick: PropTypes.func,
    display: PropTypes.bool
};