import React from 'react';

import {
    FormControlLabel,
    Switch,
    DialogActions,
    Button,
    Grid,
    Typography,
    TextField
} from "@mui/material/";

import { object, string, number, bool, date } from 'yup';

import { useNavigateWithParams } from '../../hooks/useNavigateWithParams'

import { format, isDate, parse, parseJSON, isValid, addMonths, addDays } from 'date-fns'


const defaultValues = {
    globalStartDate: "",
    globalEndDate: "",
    fixedCycles: 0,
    automaticRenewal: false,
    contractRenewalNotice: false,
    renewalNoticeInDays: 0,
    contractRenewalEmailNotification: false,
};

let ContractDefinitionSchema = object({
    globalStartDate: date().nullable(true).transform(parseDateString),
    globalEndDate: date().nullable(true).transform(parseDateString),
    fixedCycles: number().transform((value) => (isNaN(value) ? undefined : value)).default(0),
    automaticRenewal: bool(),
    contractRenewalNotice: bool(),
    renewalNoticeInDays: number(),
    contractRenewalEmailNotification: bool()
});

function parseDateString(value, originalValue) {
    if (originalValue == null)
        return null;

    if (originalValue === "")
        return null;

    let parsedDate = isDate(originalValue)
        ? new Date(Date.UTC(originalValue.getUTCFullYear(), originalValue.getUTCMonth(), originalValue.getUTCDate(), originalValue.getUTCHours(), originalValue.getUTCMinutes(), originalValue.getUTCSeconds()))
        : parseJSON(originalValue, "yyyy-MM-dd", new Date());

    if (!isValid(parsedDate)) {
        parsedDate = parse(originalValue, "yyyy-MM-dd", new Date());

    }

    return parsedDate;
}

const addBillingPeriodValueToDateTime = (currentDate, billingPeriodUnit, billingPeriodValue) => {
    if (billingPeriodUnit === "Days")
        return addDays(currentDate, billingPeriodValue)
    if (billingPeriodUnit === "Months")
        return addMonths(currentDate, billingPeriodValue);

    return currentDate;
}

const calculateGlobalEndDate = (globalStartDate,fixedCycles, billingPeriodValue, billingPeriodUnit) => {
    if (!globalStartDate || !fixedCycles || fixedCycles === "" || Number(fixedCycles) === 0)
        return null;

    let cycleEndDate = addDays(addBillingPeriodValueToDateTime(globalStartDate, billingPeriodUnit, billingPeriodValue * fixedCycles), -1);

    return cycleEndDate;    
}
export default function ContractDefinitionForm({
    handleClose, isLoading, handleContractDefinition, recordData }) {

    const [formValues, setFormValues] = React.useState(defaultValues);

    const [isValid, setIsValid] = React.useState(false);
    const [error, setError] = React.useState({});    
    const [isDisabled, setIsDisabled] = React.useState(false);

    const showContractRenewalNotice = () => {
        if (formValues.automaticRenewal === true) {
            return true;
        }

        return false;
    };

    const showEditableRenewalNoticeInDays = () => {
        if (formValues.contractRenewalNotice === true) {
            return true;
        }

        return false;
    };

    const showContractRenewalEmailNotification = () => {
        if (formValues.automaticRenewal === true && formValues.contractRenewalNotice === true) {
            return true;
        }

        return false;
    };

    let navigate = useNavigateWithParams();
    const onSubmit = data => {
        data.preventDefault();

        ContractDefinitionSchema
            .validate(formValues, { stripUnknown: true })
            .then((parsedFormValues) => {
                return parsedFormValues;                
            })
            .then((updateRecord) => {
                return handleContractDefinition(updateRecord);
            });

    }

    React.useEffect(() => {
        if (recordData && recordData.contractDefinition) {
            ContractDefinitionSchema
                .validate(recordData.contractDefinition, { stripUnknown: true })
                .then((contractDefinition) => {
                    if (recordData.status === "Draft")
                        setIsDisabled(false);
                    else
                        setIsDisabled(true);

                    setFormValues(contractDefinition);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [recordData]);


    React.useEffect(() => {
        var contractDefinitionParsed = ContractDefinitionSchema.validate(formValues, { abortEarly: false })
            .then(() => setIsValid(true))
            .catch((e) => {
                const errors = e.inner.reduce((acc, value) => {
                    acc[value.path] = {
                        message: value.message,
                        type: value.type
                    }
                    return acc;
                }, {});
                setError(errors);
                setIsValid(false);
            });
    }, [formValues]);

    React.useEffect(() => {
        if (formValues.contractRenewalNotice === false) {
            setFormValues({
                ...formValues,
                "renewalNoticeInDays": 0,
                "contractRenewalEmailNotification": false,
            });
        }

    }, [formValues.contractRenewalNotice]);

    React.useEffect(() => {        
        if (recordData && recordData.billingPeriodValue && recordData.billingPeriodUnit) {
            let globalEndDate = calculateGlobalEndDate(formValues.globalStartDate, formValues.fixedCycles, recordData.billingPeriodValue, recordData.billingPeriodUnit)

            setFormValues({
                ...formValues,
                "globalEndDate": globalEndDate
            });
        }       
    }, [formValues.globalStartDate, formValues.fixedCycles]);

    React.useEffect(() => {
        if (formValues.automaticRenewal === false) {
            setFormValues({
                ...formValues,
                "contractRenewalNotice": false
            });
        }

    }, [formValues.automaticRenewal]);

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "globalStartDate" && value !== "") {
            
            value = parse(value, "yyyy-MM-dd", new Date());
            value.setHours(new Date().getHours())
            value.setMinutes(new Date().getMinutes())
        }

        if (e.target.type === "checkbox") {
            value = e.target.checked;
        }        

        setFormValues({
            ...formValues,
            [name]: value,
        });
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="row">     
                <Grid item xs={6}>
                    <TextField
                        id="GlobalStartDate-input"
                        name="globalStartDate"
                        label="Global Start Date"
                        type="date"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={isDisabled || isLoading}
                        onChange={handleInputChange}
                        value={formValues.globalStartDate ? format(formValues.globalStartDate, "yyyy-MM-dd") : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="FixedCycles-input"
                        name="fixedCycles"
                        label="Fixed Cycles"
                        type="number"
                        size="small"
                        disabled={isDisabled || isLoading}
                        sx={{ width: 200 }}
                        value={formValues && formValues.fixedCycles}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="GlobalEndDate-input"
                        name="globalEndDate"
                        label="Global End Date"
                        type="date"
                        size="small"
                        sx={{ width: 200 }}
                        disabled
                        onChange={handleInputChange}
                        value={formValues.globalEndDate ? format(formValues.globalEndDate, "yyyy-MM-dd") : ""}
                        InputLabelProps={{
                            shrink: true,
                        }}

                    />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        labelPlacement="start"
                        sx={{ width: 200 }}
                        control={
                            <Switch
                                id="automaticRenewal-input"
                                name="automaticRenewal"
                                label="Automatic Renewal?"
                                size="medium"
                                disabled={isDisabled || isLoading}
                                checked={formValues.automaticRenewal}
                                onChange={handleInputChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Automatic Renewal?"
                    />
                </Grid> 
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                    {showContractRenewalNotice() && <FormControlLabel
                        labelPlacement="start"
                        sx={{ width: 200 }}
                        control={
                            <Switch
                                id="ContractRenewalNotice-input"
                                name="contractRenewalNotice"
                                label="Contract Renewal Notice?"
                                size="medium"
                                disabled={isDisabled || isLoading}
                                checked={formValues.contractRenewalNotice}
                                onChange={handleInputChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Contract Renewal Notice?"
                    />}
                </Grid>
                <Grid item xs={6}>
                    {showEditableRenewalNoticeInDays() && <TextField
                        id="RenewalNoticeInDays-input"
                        name="renewalNoticeInDays"
                        label="Renewal Notice In Days"
                        type="number"
                        size="small"
                        required={showEditableRenewalNoticeInDays}
                        disabled={isDisabled || isLoading}
                        sx={{ width: 200 }}
                        value={formValues && formValues.renewalNoticeInDays}
                        onChange={handleInputChange}
                    />}              
                </Grid>
                <Grid item xs={6}>
                    {showContractRenewalEmailNotification() && <FormControlLabel
                        labelPlacement="start"
                        sx={{ width: 200 }}
                        control={
                            <Switch
                                id="ContractRenewalEmailNotification-input"
                                name="contractRenewalEmailNotification"
                                label="Contract Renewal Email Notification?"
                                size="medium"
                                disabled={isDisabled || isLoading}
                                checked={formValues.contractRenewalEmailNotification}
                                onChange={handleInputChange}
                                inputProps={{ "aria-label": "controlled" }}
                            />
                        }
                        label="Contract Renewal Email Notification?"
                    />}
                </Grid>
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>
                    {!isDisabled && <Button type="submit" disabled={!isValid || isLoading || isDisabled} variant="outlined" >
                        <Typography color="black">Update</Typography>
                    </Button>}
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Back</Typography>
                    </Button>
                </DialogActions>

            </Grid>            
        </form>
    );
}