import React from 'react';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { object, string, mixed, number, ref } from 'yup';
import MenuItem from "@mui/material/MenuItem";
import LookupField from '../../ui/organisms/LookupField';

import { DialogActions } from '@mui/material/';

const defaultValues = {
    customer: "",
    status: "",
    externalPaymentStatus: "",
    merchant: "",
    invoice: "",
    currency: "",
    grossAmount: "",
    netAmount: "",
    taxAmount: "",
    billingRequestUrl: "",
    chargedOn: "",
    externalPaymentId: ""
};

let entityReferenceSchema = object({
    id: string().required(),
    name: string().required(),
    connector: string(),
    secondaryKey: string().nullable(),
    extensionData: mixed(),
    url: string().nullable()
}).nullable();

let paymentSchema = object({
    customer: object({
        mandate: entityReferenceSchema.nullable()
            .when("$merchantType", (merchantType, schema) => {
                return schema.transform((value, originalValue) => {
                    if (originalValue !== null) {
                        return {
                            id: originalValue, name: originalValue, connector: "", url: baseUrl(merchantType, originalValue)
                        };
                    }
                    return originalValue;
                });
            })            
        })
});

const baseUrl = (merchantType, mandateId) => {
    return merchantType === "Sandbox" ? "https://manage-sandbox.gocardless.com/mandates/" + mandateId : "https://manage.gocardless.com/mandates/" + mandateId
}; 


export default function ViewPaymentForm({ handleClose, isLoading, recordData }) {
    const [formValues, setFormValues] = React.useState(defaultValues);

    const formatCurrency = React.useCallback((currencyField) => {        
        const options = currencyField && currencyField.extensionData && currencyField.extensionData.Code ? {
            style: 'currency',
            currency: currencyField.extensionData.Code,
        } : {};
        
        return new Intl.NumberFormat(undefined, options);

    }, [recordData]);

    React.useEffect(() => {
        if (recordData) {
            paymentSchema
                .validate(recordData, { context: { merchantType: recordData.merchant.type } })
                .then((payment) => {
                    setFormValues(payment);
                    formatCurrency(recordData.currency);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }, [recordData]);  

    return (
        (!isLoading  && <form>
            <Grid marginTop={2} spacing={2} container alignItems="left" justify="center" direction="row">
                <Grid item xs={6}>
                     <LookupField
                        disabled={true}
                        initialValue={formValues.customer}
                        id="customer-input"
                        sx={{ width: 200 }}
                        type="customer"
                        name="customer"
                        label="Customer"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="customerEmail-input"
                        name="customerEmail"
                        label="Customer email"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues.customer && formValues.customer.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LookupField
                        id="customerMandate-input"
                        name="customerMandate"
                        label="Customer Mandate"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        initialValue={formValues.customer && formValues.customer.mandate}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="externalPaymentId-input"
                        name="externalPaymentId"
                        label="External Payment Id"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues.externalPaymentId}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        id="status-input"
                        name="status"
                        label="Status"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues.status}
                    >
                        <MenuItem value="New">New</MenuItem>
                        <MenuItem value="Active">Active</MenuItem>
                        <MenuItem value="Completed">Completed</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        select
                        id="externalPaymentStatus-input"
                        name="externalPaymentStatus"
                        label="External Payment Status"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues.externalPaymentStatus}
                    >
                        <MenuItem value="PendingCustomerApproval">PendingCustomerApproval</MenuItem>
                        <MenuItem value="PendingSubmission">PendingSubmission</MenuItem>
                        <MenuItem value="Submitted">Submitted</MenuItem>
                        <MenuItem value="Confirmed">Confirmed</MenuItem>
                        <MenuItem value="PaidOut">PaidOut</MenuItem>
                        <MenuItem value="Cancelled">Cancelled</MenuItem>
                        <MenuItem value="CustomerApprovalDenied">CustomerApprovalDenied</MenuItem>
                        <MenuItem value="Failed">Failed</MenuItem>
                        <MenuItem value="ChargedBack">ChargedBack</MenuItem>

                    </TextField>
                </Grid>               
                <Grid item xs={12}>
                    <LookupField
                        disabled={true}
                        initialValue={formValues.invoice}
                        id="invoice-input"
                        sx={{ width: 200 }}
                        type="invoice"
                        name="invoice"
                        label="Invoice"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="grossAmount-input"
                        name="grossAmount"
                        label="Gross Amount"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues && formValues.grossAmount && formatCurrency && formatCurrency(formValues.currency).format && formatCurrency(formValues.currency).format(formValues.grossAmount)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="netAmount-input"
                        name="netAmount"
                        label="Net Amount"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues && formValues.netAmount && formatCurrency && formatCurrency(formValues.currency).format && formatCurrency(formValues.currency).format(formValues.netAmount)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="taxAmount-input"
                        name="taxAmount"
                        label="Tax Amount"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues && formValues.taxAmount && formatCurrency && formatCurrency(formValues.currency).format && formatCurrency(formValues.currency).format(formValues.taxAmount)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        id="billingRequestUrl-input"
                        name="billingRequestUrl"
                        label="Billing request url"
                        type="text"
                        size="small"
                        sx={{ width: "100%" }}
                        disabled={true}
                        value={formValues && formValues.billingRequestUrl}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="createdOn-input"
                        name="createdOn"
                        label="Created On"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues.createdOn}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        id="modifiedOn-input"
                        name="modifiedOn"
                        label="Modified On"
                        type="text"
                        size="small"
                        sx={{ width: 200 }}
                        disabled={true}
                        value={formValues.modifiedOn}
                    />
                </Grid>                
                <DialogActions sx={{ position: "absolute", bottom: 0, right: 0 }}>                    
                    <Button onClick={handleClose} disabled={isLoading} variant="outlined" >
                        <Typography color="black">Close</Typography>
                    </Button>
                </DialogActions>
            </Grid>
        </form>)
    );
}