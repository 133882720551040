import {
  List,
  ListSubheader,
  ListItem,
  Typography,
  Menu,
  Fade,
  Divider,
  Card,
  Paper,
  styled,
} from "@mui/material";
import PropTypes from "prop-types";

const StyledTitle = styled(Typography)({
  fontSize: "16px",
  fontSize: "1rem",
  fontFamily: "Paralucent,Mulish,Muli,sans-serif",
  cursor: "default",
});

function SidebarListPopup({ open, handleClick, anchorEl, title, children }) {
  let anchorElPos = anchorEl.getBoundingClientRect();
  return (
    <Menu
      open={open}
      id="fade-menu"
      anchorEl={anchorEl}
      onClose={handleClick}
      onClick={handleClick}
      anchorOrigin={{ vertical: "center", horizontal: "left" }}
      transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={Fade}
      sx={{
        ul: {
          scrollbarWidth: "thin",
          height: "auto",
          overflow: "auto",
          pt: "2px",
          pb: "2px",
        },
      }}
      PaperProps={{
        sx: {
          ml: "25px",
          position: "absolute",
          filter: "drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32))",
          display: "flex",
          minHeight: "100px",
          scrollbarWidth: "thin",
          overflow: "visible",
          // overflowX: "hidden",

          "&:before": {
            content: `''`,
            overflow: "auto",
            display: "flex",
            position: "absolute",
            width: "10px",
            height: "10px",
            bgcolor: "background.paper",
            WebkitTransform: "translateX(-50%) rotate(45deg)",
            top: anchorElPos.top + "px",
            zIndex: 0,
          },
        },
      }}
    >
      <ListSubheader disabled={true} sx={{ pb: "4px", pt: "4px" }}>
        <StyledTitle noWrap>{title}</StyledTitle>
        <Divider />
      </ListSubheader>
      {children}
    </Menu>
  );
}

SidebarListPopup.propTypes = {
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  anchorEl: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.array,
};

SidebarListPopup.defaultProps = {
  open: false,
};

export default SidebarListPopup;
