import PaymentsTemplate from '../ui/templates/PaymentsTemplate';
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { fakeData as companyFakeData } from './fakeData/companyFakeData';
import { fakeData as paymentsFakeData } from './fakeData/paymentsFakeData';
import { fakeData as reasonCodeFakeData } from "./fakeData/reasonCodeFakeData";
import UserContext from "../UserContext";
import { listPayments, getPayment, cancelPayment, addPayment } from '../api/paymentsService';
import { getReasonCodes } from "../api/reasonCodesService";
import { postSubscriptionAction, postSubscriptionActions } from '../api/subscriptionActionsService';
import { useSearchParams } from "react-router-dom";


function PaymentsPage() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "Payments Page" });

    const [datagridData, setDatagridData] = React.useState([]);
    const [searchSidePanelData, setSearchSidePanelData] = React.useState([]);
    const datagridRef = React.useRef(paymentsFakeData);

    const [recordData, setRecordData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);
    const [isLoadingSearchSidePanel, setIsLoadingSearchSidePanel] = React.useState(false);

    const [paymentDatagridData, setPaymentDatagridData] = React.useState([]);
    const [isLoadingPayment, setIsLoadingPayment] = React.useState(true);

    const timerRef = React.useRef(null);
    const userContext = React.useContext(UserContext);

    let [searchParams, setSearchParams] = useSearchParams();
    const getConnectorIdFilter = () => {
        return (searchParams.get("connector-id") != null ? searchParams.get("connector-id").toLowerCase() : null);
    };

    const onRefresh = () => {
        setDatagridData([]);
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {                  
                    setDatagridData(paymentsFakeData);
                    datagridRef.current = paymentsFakeData;
                    setIsLoading(false);
                    resolve();
                }, 1500);
            });
        }
        
        else {
            return listPayments({ connectorId: getConnectorIdFilter() }, userContext.activeEnvironment, userContext.tenant).then((data) => {
                setDatagridData(data);
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
        
    }
    
    const handleListRecords = (recordType, query) => {
        setIsLoadingSearchSidePanel(true);
        setSearchSidePanelData([]);

        if (recordType === "reasoncode") {
            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    if (query && query.name) {
                        setSearchSidePanelData(
                            reasonCodeFakeData.filter(
                                (x) => x.name && x.name.includes(query.name)
                            )
                        );
                    } else {
                        setSearchSidePanelData(reasonCodeFakeData);
                    }
                    setIsLoadingSearchSidePanel(false);
                }, 1500);
            } else {
                return getReasonCodes(
                    query,
                    userContext.activeEnvironment,
                    userContext.tenant
                )
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter(
                                (x) =>
                                    x.name &&
                                    x.name.toLowerCase().includes(query.name.toLowerCase())
                            );
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }

                        setIsLoadingSearchSidePanel(false);
                        console.log(data);
                        return data;

                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
        } else if (recordType === "company") {
            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    if (query && query.name) {
                        const filteredData = companyFakeData.filter(
                            (x) => x.name && x.name.includes(query.name)
                        );
                        setSearchSidePanelData(filteredData);
                    } else {
                        setSearchSidePanelData(companyFakeData);
                    }
                    setIsLoadingSearchSidePanel(false);
                }, 1500);
            } else {
                if (query && query.name) {
                    const filteredData = companyFakeData.filter(
                        (x) => x.name && x.name.includes(query.name)
                    );
                    setSearchSidePanelData(filteredData);
                    setIsLoadingSearchSidePanel(false);
                } else {
                    setSearchSidePanelData(companyFakeData);
                    setIsLoadingSearchSidePanel(false);
                }
            }
        } else {
            setIsLoadingSearchSidePanel(false);
        }
    };

    const handleFetchRecord = (id) => {
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    var recordIndex = datagridRef.current.findIndex((x => x.id === id));
                    if (recordIndex > -1) {
                        return resolve(datagridRef.current[recordIndex]);
                    }

                    return resolve(null);

                }, 1000);
            }).then((data) => {
                setRecordData(data);
                setIsLoadingSidePanel(false);
                return data;
            });
        } else {
            return getPayment(id, userContext.activeEnvironment, userContext.tenant)
                .then((data) => {
                setRecordData(data);
                setIsLoadingSidePanel(false);                
            })
                .catch((e) => {
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }
    };

    const handlePost = (ids) => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            let promise = ids != null && Array.isArray(ids) ? postSubscriptionActions(ids.map((v) => v.reduce((s) => s)), userContext.activeEnvironment, userContext.tenant) : postSubscriptionAction(ids, userContext.activeEnvironment, userContext.tenant);
            return promise
                .then((data) => {
                    onRefresh();
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    };

    const handleCancel = (id, body) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
        } else {
            return cancelPayment(
                id,
                body,
                userContext.activeEnvironment,
                userContext.tenant
            )
                .then((data) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    return onRefresh();
                })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                });
        }
    };

    const payments = {
        data: paymentDatagridData,
        isLoading: isLoadingPayment,
        onRefresh: (activePayment, activeCustomer) => {
            setPaymentDatagridData([]);
            setIsLoadingPayment(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    //TODO fake data
                    setPaymentDatagridData([]);
                    setIsLoadingPayment(false);
                }, 1500);
            } else {
                let query = {};

                if (activeCustomer)
                    query.customerId = activeCustomer.id;
                if (getConnectorIdFilter() != null) {
                    query.connectorId = getConnectorIdFilter();
                }

                listPayments(query, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingPayment(false);
                    setPaymentDatagridData(data);
                });
            }
        },
        handleFetchRecord: (id) => {
            setIsLoadingSidePanel(true);
            if (process.env.REACT_APP_TEST_DATA === "true") {
                timerRef.current = setTimeout(() => {
                    var recordIndex = datagridData.findIndex((x) => x.id === id);
                    setRecordData(datagridData[recordIndex]);
                    setIsLoadingSidePanel(false);
                }, 1500);
            } else {
                return getPayment(id, userContext.activeEnvironment, userContext.tenant).then((data) => {
                    setIsLoadingSidePanel(false);
                    setRecordData(data);
    
                })
                    .catch((e) => {
                        setIsLoadingSidePanel(false);
                        throw e;
                    })
            }
        },
        handleAdd: (ids) => {
            setIsLoadingPayment(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {
                console.log("Payment action posted !")
                let promise = ids != null && Array.isArray(ids) ? addPayment(ids.map((v) => v.id), userContext.activeEnvironment, userContext.tenant) : addPayment(ids, userContext.activeEnvironment, userContext.tenant);

                return promise
                    .catch((e) => {
                        setIsLoadingPayment(false);
                        throw e;
                    })
            } else {
                let promise = ids != null && Array.isArray(ids) ? addPayment(ids.map((v) => v.id), userContext.activeEnvironment, userContext.tenant) : addPayment(ids, userContext.activeEnvironment, userContext.tenant);

                return promise
                    .catch((e) => {
                        setIsLoadingPayment(false);
                        throw e;
                    })
            }
        },
    
        handleCancel: (ids) => {
            setIsLoadingPayment(true);

            if (process.env.REACT_APP_TEST_DATA === "true") {

            } else {
                let promise = ids != null && Array.isArray(ids) ? addPayment(ids.map((v) => v.id), userContext.activeEnvironment, userContext.tenant) : addPayment(ids, userContext.activeEnvironment, userContext.tenant);

                return promise
                    .catch((e) => {
                        setIsLoadingPayment(false);
                        throw e;
                    })
            }
        },
        handleEditRecord: () => {
            return new Promise((resolve, reject) => {
                resolve(true);
            });
        },
    }


    React.useEffect(() => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            const timer = setTimeout(() => {
                setDatagridData(paymentsFakeData);
                datagridRef.current = paymentsFakeData;
                setIsLoading(false);
         
            }, 1500);
            return () => clearTimeout(timer);
        }
       
       else {
            return listPayments({ connectorId: getConnectorIdFilter() } , userContext.activeEnvironment, userContext.tenant).then((data) => {
                setDatagridData(data);
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        } 
        
    }, [userContext.activeEnvironment]);

    React.useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);


    return (<PaymentsTemplate
        data={datagridData}
        payments={payments}
        isLoading={isLoading}
        isLoadingSidePanel={isLoadingSidePanel}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}     
        recordData={recordData}
        handleListRecords={handleListRecords}
        handleFetchRecord={handleFetchRecord}
        searchSidePanelData={searchSidePanelData}
        handlePost={handlePost}
        handleCancel={handleCancel}
        onRefresh={onRefresh} />);
}

export default PaymentsPage;