import React from "react";
import { autocompleteClasses, Paper, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import MicrosoftLoginButton from "../molecules/MicrosoftLoginButton";

import Image from "../../images/Background.svg";
import Logo from "../../images/HARP-Connect-Logo-Full-Colour.svg";

const StyledLoginPage = styled("div")({
  background: `url(${Image}) no-repeat center center fixed`,
  backgroundSize: "cover",
  width: "100%",
  height: "100%",
});

const StyledPaper = styled(Paper)({
  backgroundColor: "#f4f5f7",

  width: "400px",
  height: "300px",

  margin: "0",
  position: "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",

  boxShadow: 3,

  "@media(minWidth: 700px)": {
    width: "auto",
  },
});

const StyledTitle = styled("span")({
  color: "#6a6a6a",
  textAlign: "center",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  marginTop: "20px",
});

const StyledLogo = styled("img")({
  marginTop: "40px",
  marginRight: "auto",
  marginLeft: "auto",
  display: "flex",
  justifyContent: "center",
  width: "90%",
});

const StyledSection = styled("div")({
  margin: "auto",
  marginTop: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer"
});

const Login = () => {
  const { instance, accounts } = useMsal();
  const [isSignInEnabled, setIsSignInEnabled] = React.useState(true);

  return (
    <StyledLoginPage>
      <StyledPaper>
        <StyledLogo src={Logo} />
        <StyledTitle>Sign in with</StyledTitle>
        <StyledSection>
          <MicrosoftLoginButton
            buttonTheme="light"
            enabled={isSignInEnabled}
            onClick={() => instance.loginPopup({ prompt: 'select_account'})}
          ></MicrosoftLoginButton>
        </StyledSection>
      </StyledPaper>
    </StyledLoginPage>
  );
};

export default Login;
