import * as React from 'react';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import RibbonButton from './RibbonButton';
import { Typography } from '@mui/material/';

export default function GoCardlessPayoutsRibbonButton({ display, merchantType }) {
    const baseUrl = (merchantType) => merchantType === "Sandbox" ? "https://manage-sandbox.gocardless.com/" : "https://manage.gocardless.com/";

    return (
        <RibbonButton display={display} onClick={() => {
            window.open(baseUrl(merchantType) + "payouts", "_blank").focus();
        }} startIcon={<CreditCardIcon sx={{ color: "black" }} />}>
            <Typography color="black">
                View all payouts
            </Typography>
        </RibbonButton>
    );
}