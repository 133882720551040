import HomeTemplate from "../ui/templates/HomeTemplate";
import {
  getChurn,
  getCustomers,
  getActionsCreated,
  getActionsFirmed,
  getActionsPosted,
  getCards,
  getRevenue,
} from "../api/dashboardService";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from "react";
import UserContext from "../UserContext";

function HomePage() {
  const appInsights = useAppInsightsContext();
  appInsights.trackPageView({ name: "Home" });
  const [isLoading, setIsLoading] = React.useState(false);

  const timerRef = React.useRef(null);
    const userContext = React.useContext(UserContext);

  React.useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  const handleFetchData = ({ id, startBy, finishBy, format, type }) => {
    setIsLoading(true);
      if (process.env.REACT_APP_TEST_DATA === "true") {
          return Promise.reject("missing fake data");
    } else {
      if (id === "brief") {
          return getCards(startBy, finishBy, userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            setIsLoading(false);
            return data;
          })
          .catch((e) => {
            setIsLoading(false);
            throw e;
          });
      }

      if (id === "revenue") {
          return getRevenue(startBy, finishBy, format, type, userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            setIsLoading(false);
            return data;
          })
          .catch((e) => {
            setIsLoading(false);
            throw e;
          });
      }

      if (id === "customers") {
          return getCustomers(startBy, finishBy, format, type, userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            setIsLoading(false);
            return data;
          })
          .catch((e) => {
            setIsLoading(false);
            throw e;
          });
      }

      if (id === "churn-rate") {
          return getChurn(startBy, finishBy, format, type, userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            setIsLoading(false);
            return data;
          })
          .catch((e) => {
            setIsLoading(false);
            throw e;
          });
      }

      if (id === "actions-created") {
          return getActionsCreated(startBy, finishBy, format, type, userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            setIsLoading(false);
            return data;
          })
          .catch((e) => {
            setIsLoading(false);
            throw e;
          });
      } else if (id === "actions-firmed") {
          return getActionsFirmed(startBy, finishBy, format, type, userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            setIsLoading(false);
            return data;
          })
          .catch((e) => {
            setIsLoading(false);
            throw e;
          });
      } else if (id === "actions-posted") {
          return getActionsPosted(startBy, finishBy, format, type, userContext.activeEnvironment, userContext.tenant)
          .then((data) => {
            setIsLoading(false);
            return data;
          })
          .catch((e) => {
            setIsLoading(false);
            throw e;
          });
      }

      return Promise.resolve();
    }
  };

  return (
    <HomeTemplate isLoading={isLoading} handleFetchData={handleFetchData} />
  );
}

export default HomePage;
