import * as React from "react";
import PropTypes from "prop-types";

import { TextField, Grid } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const CURRENT_DATE = new Date();
const CURRENT_YEAR = CURRENT_DATE.getFullYear();
const CURRENT_MONTH = CURRENT_DATE.getMonth();

const YearMonthRangeSelector = ({
  minDate,
  maxDate,
  startDate,
  finishDate,
  onStartDateChanged,
  onFinishDateChanged,
}) => {
  const onStart = (val) => {
    onStartDateChanged(new Date(val.getFullYear(), val.getMonth(), 1));
  };

  const onFinish = (val) => {
    onFinishDateChanged(new Date(val.getFullYear(), val.getMonth() + 1, 0, 11, 59, 59));
  };

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <DatePicker
              inputFormat="MMM-yyyy"
              views={["year", "month"]}
              label="Month and Year"
              minDate={minDate}
              maxDate={finishDate}
              value={startDate}
              onChange={onStart}
              disableFuture={false}
              renderInput={(params) => {
                return <TextField {...params} helperText={null} />;
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePicker
              inputFormat="MMM-yyyy"
              views={["year", "month"]}
              label="Month and Year"
              minDate={startDate}
              maxDate={maxDate}
              value={finishDate}
              onChange={onFinish}
              disableFuture={false}
              renderInput={(params) => {
                return <TextField {...params} helperText={null} />;
              }}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </React.Fragment>
  );
};

YearMonthRangeSelector.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),

  startDate: PropTypes.instanceOf(Date),
  finishDate: PropTypes.instanceOf(Date),

  onStartDateChanged: PropTypes.func,
  onFinishDateChanged: PropTypes.func,
};

YearMonthRangeSelector.defaultProps = {
  // Default min/max dates
  minDate: new Date(1990),
  maxDate: new Date(CURRENT_YEAR, 12, 0, 11, 59, 59),

  // Set the start and finish to current year range
  startDate: new Date(CURRENT_YEAR, CURRENT_MONTH, 1),
  finishDate: new Date(CURRENT_YEAR, CURRENT_MONTH + 1, 0, 11, 59, 59),

  onStartDateChanged: (val) => {console.log('start date ', val)},
  onFinishDateChanged: (val) => {console.log('finish date ', val)}
};
export default YearMonthRangeSelector;
