import * as React from 'react';
import { Grid, Paper, Typography, Snackbar, Alert } from '@mui/material/';
import DataGrid from '../organisms/DataGrid';
import { styled } from '@mui/material/styles';
import RibbonButton from '../molecules/RibbonButton';
import NewRibbonButton from '../molecules/NewRibbonButton';
import EditRibbonButton from '../molecules/EditRibbonButton';
import RefreshRibbonButton from '../molecules/RefreshRibbonButton';
import DeleteRibbonButton from '../molecules/DeleteRibbonButton';
import ConfirmDialog from '../molecules/ConfirmDialog';
import SidePanel from '../organisms/SidePanel';
import NewReasonCodeForm from '../../forms/NewReasonCodeForm';
import EditReasonCodeForm from '../../forms/EditReasonCodeForm';
import { Route, Routes, Outlet, useParams, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns'
import { useNavigateWithParams } from '../../hooks/useNavigateWithParams'
import ProfileCard from '../organisms/ProfileCard'
import NotificationSnackBar from '../organisms/NotificationSnackBar';

const GridParent = styled(Grid)(({ theme }) => ({

}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
    padding: 3,
    paddingLeft: 30,
    display: 'inline-flex'
}));

const GridItem = styled(Grid)(({ theme }) => ({
    padding: 16,
    paddingLeft: 30
}));

const columns = [
    { field: 'name', headerName: 'Name', flex: 1, minWidth: 100 },
    { field: 'description', headerName: 'Description', flex: 1, minWidth: 100 },
    {
        field: 'createdOn', headerName: 'Created On', flex: 1, minWidth: 100, type: "dateTime",
        valueFormatter: (params) => { return params.value ? format(new Date(params.value), "dd/MM/yyyy") : params.value; }
    },
    {
        field: 'modifiedOn', headerName: 'Modified On', flex: 1, minWidth: 100, type: "dateTime",
        valueFormatter: (params) => { return params.value ? format(new Date(params.value), "dd/MM/yyyy") : params.value; }
    },
];
function ReasonCodeIndex({ isLoading, data, onRefresh, handleDeleteRecord, displayNotification, setDisplayNotification}) {

    const [displayEdit, setDisplayEdit] = React.useState(false);
    const [displayDelete, setDisplayDelete] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState({});
    const [selectedRow, setSelectedRow] = React.useState(null);

    let navigate = useNavigateWithParams();
    let [searchParams, setSearchParams] = useSearchParams();

    const isLayoutEmbedded = () => {
        return searchParams.get("layout") === "embedded";
    };

    const onClickRow = (params) => {
        setDisplayEdit(true);
        setDisplayDelete(true);
        setSelectedRow(params.row);
    };

    const onDoubleClickRow = (params) => {
        editRecord();
    };
    const deleteRecord = () => {
        setConfirmDialog({
            title: "Delete Reason Code ?",
            description: `Are you sure you want to delete ${selectedRow.name}?`,
            actionConfirm: () => {
                setConfirmDialog({});
                handleDeleteRecord(selectedRow)
                    .then(() => {
                        setDisplayNotification({ message: "Reason Code deleted successfully", type: "success" });
                    })
                    .catch((e) => {
                        setDisplayNotification({ message: "Error occurred", type: "error" });
                    })
            },
            actionCancel: () => {
                setConfirmDialog({});
            }
        });
    };

    const createRecord = () => {
        navigate("new");
    };

    const editRecord = () => {
        navigate(`${selectedRow.id}/edit`);
    };

    const refreshGrid = () => {
        setSelectedRow(null);
        setDisplayEdit(false);
        setDisplayDelete(false);
        onRefresh();
    }

    return (<React.Fragment>
        <GridParent
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch">
            <ConfirmDialog confirmDialogInfo={confirmDialog} onConfirm={confirmDialog.actionConfirm} onCancel={confirmDialog.actionCancel} />
            <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
                <NewRibbonButton onClick={createRecord} display={true} title="New Reason Code" />
                <EditRibbonButton onClick={editRecord} display={displayEdit} title="Edit" />
                <RefreshRibbonButton onClick={refreshGrid} display={true} title="Refresh" />
                <DeleteRibbonButton onClick={deleteRecord} display={displayDelete} title="Delete" />
                {isLayoutEmbedded() && (<ProfileCard embeddedMode={true} />)}
            </RibbonGrid>
            <GridItem item xs={12} md={12} lg={12}>
                <Typography>Reason Codes</Typography>
            </GridItem>
            <GridItem item xs={12} md={12} lg={12}>
                <DataGrid loading={isLoading} onRowClick={onClickRow} onRowDoubleClick={onDoubleClickRow} columns={columns} rows={data} />
            </GridItem>

        </GridParent>
        {displayNotification !== null && (<NotificationSnackBar
            displayNotification={displayNotification}
            onClose={() => setDisplayNotification(null)}
        />)}
        <Outlet />
    </React.Fragment>);
}

function ReasonCodeNew({ sidePanelOpen, isLoadingSidePanel, handleCreateRecord, setDisplayNotification }) {

    let navigate = useNavigateWithParams();

    return (
        <SidePanel open={sidePanelOpen} handleClose={() => navigate("../")} isLoading={isLoadingSidePanel} title="New Reason Code">
            <NewReasonCodeForm
                isLoading={isLoadingSidePanel}
                handleClose={() => navigate("../")}
                handleCreateRecord={(record) => {
                    return handleCreateRecord(record)
                        .then(() => {
                            setDisplayNotification({ message: "Reason Code created successfully", type: "success" });
                            navigate("../");
                        })
                        .catch((e) => {
                            if (e.response && e.response.data && e.response.data.Message) {
                                setDisplayNotification({ message: e.response.data.Message, type: "error" });
                            }
                            else
                                setDisplayNotification({ message: "Error occurred", type: "error" });
                        });
                }}
            />
        </SidePanel>);
}

function ReasonCodeEdit({ isLoadingSidePanel, sidePanelOpen, handleEditRecord, handleFetchRecord, setDisplayNotification }) {
    let navigate = useNavigateWithParams();
    const [recordData, setRecordData] = React.useState(null);

    let { reasonCodeId } = useParams();

    React.useEffect(() => {
        handleFetchRecord(reasonCodeId)
            .then((data) => {
                setRecordData(data);
            })
    }, []);

    return (
        <React.Fragment>
            <SidePanel isLoading={isLoadingSidePanel} handleClose={() => navigate("../")} title={!isLoadingSidePanel && recordData && `${recordData.name}`} open={sidePanelOpen}  >
                {recordData && (<EditReasonCodeForm
                    isLoading={isLoadingSidePanel}
                    handleClose={() => navigate("../")}
                    recordData={recordData}
                    handleEditRecord={(id, record) => {                            
                        return handleEditRecord(id, record)
                            .then(() => {
                                setDisplayNotification({ message: "Reason Code updated successfully", type: "success" });
                                return navigate("../");
                            })
                            .catch((e) => {
                                if (e.response && e.response.data && e.response.data.Message) {
                                    setDisplayNotification({ message: e.response.data.Message, type: "error" });
                                }
                                else
                                    setDisplayNotification({ message: "Error occurred", type: "error" });
                            });

                    }} />)}
            </SidePanel>            
        </React.Fragment>);
}

function ReasonCodeTemplate({ data, isLoading, isLoadingSidePanel, onRefresh, handleDeleteRecord, handleFetchRecord, handleEditRecord, handleCreateRecord }) {

    const [displayNotification, setDisplayNotification] = React.useState(null);


    return (
        <Routes>
            <Route path="/" element={<ReasonCodeIndex
                displayNotification={displayNotification}
                setDisplayNotification={setDisplayNotification}
                handleDeleteRecord={handleDeleteRecord}
                isLoading={isLoading}
                onRefresh={onRefresh}
                data={data}
            />}>
                <Route path=":reasonCodeId/edit" element={<ReasonCodeEdit
                    displayNotification={displayNotification}
                    setDisplayNotification={setDisplayNotification}
                    isLoadingSidePanel={isLoadingSidePanel}
                    handleEditRecord={handleEditRecord}
                    handleFetchRecord={handleFetchRecord}
                    sidePanelOpen={true} />} />

                <Route path="new" element={<ReasonCodeNew
                    displayNotification={displayNotification}
                    setDisplayNotification={setDisplayNotification}
                    isLoadingSidePanel={isLoadingSidePanel}
                    handleCreateRecord={handleCreateRecord}
                    sidePanelOpen={true} />} />
            </Route>
        </Routes>
    );
}

export default ReasonCodeTemplate;