import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import * as React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { fakeData } from './fakeData/programTypeFakeData'
import { getUser, listUsers, updateUserDetails, updateUserRole, createUser, removeUserRole, removeUser, activateUser, deactivateUser } from '../api/userService';
import { fakeData as companyFakeData } from './fakeData/companyFakeData'
import UserTemplate from '../ui/templates/UserTemplate';
import { getConnectors } from '../api/connectorsService';
import {
    listCompany
} from '../api/companyService';
import UserContext from "../UserContext";

function UserPage() {
    const appInsights = useAppInsightsContext();
    appInsights.trackPageView({ name: "User Page" });

    const [datagridData, setDatagridData] = React.useState([]);
    const [searchSidePanelData, setSearchSidePanelData] = React.useState([]);
    const datagridRef = React.useRef(fakeData);

    const [recordData, setRecordData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isLoadingSidePanel, setIsLoadingSidePanel] = React.useState(false);
    const [isLoadingSearchSidePanel, setIsLoadingSearchSidePanel] = React.useState(false);

    const timerRef = React.useRef(null);
    const userContext = React.useContext(UserContext);

    const onRefresh = () => {
        setDatagridData([]);
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData(fakeData);
                    datagridRef.current = fakeData;
                    setIsLoading(false);
                    resolve();
                }, 1500);
            });
        }
        else {
            return listUsers(userContext.tenant)
                .then((data) => {
                    setDatagridData(data);
                    setIsLoading(false);
                })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;    
                })
        }   
    }

    const handleCreateRecord = (record) => {
        setIsLoading(true);
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData([{ ...record, id: uuidv4() }, ...datagridData]);
                    datagridRef.current = [{ ...record, id: uuidv4() }, ...datagridData];
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                }, 1550);
            });
        }
        else {
            return createUser(record, userContext.tenant).then((data) => {
                setIsLoading(false);
                setIsLoadingSidePanel(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    setIsLoadingSidePanel(false);
                    throw e;
                })
        }        
    }

    const handleFetchRecord = (id) => {
        setIsLoadingSidePanel(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    var recordIndex = datagridRef.current.findIndex((x => x.id === id));
                    if (recordIndex > -1) {
                        setRecordData(datagridRef.current[recordIndex])
                        return resolve(setIsLoadingSidePanel(false));
                    }

                    return resolve(null);

                }, 1550);               
            })
        }
        else {
            return getUser(id, userContext.tenant).then((data) => {
                setIsLoadingSidePanel(false);
                return data;
            })
                .catch((e) => {
                    setIsLoadingSidePanel(false);
                    throw e;
                });
        }
       
    }

    const handleDeleteRecord = (record) => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            return new Promise((resolve) => {
                timerRef.current = setTimeout(() => {
                    setDatagridData(datagridData.filter((x) => x.id !== record.id));
                    datagridRef.current = datagridData.filter((x) => x.id !== record.id);
                    setIsLoading(false);
                    resolve(true);
                }, 1550);
            });
        }
        else {
            return removeUser(record.id, userContext.tenant).then((data) => {
                setIsLoading(false);
                return onRefresh();
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                });
        }        
    }

    const handleListRecords = (recordType, query) => {
        setIsLoadingSearchSidePanel(true);
        setSearchSidePanelData([]);

        if (process.env.REACT_APP_TEST_DATA === "true") {
            timerRef.current = setTimeout(() => {               
                if (recordType === "company") {
                    if (query && query.name) {
                        const filteredData = companyFakeData.filter((x) => x.name && x.name.includes(query.name));
                        setSearchSidePanelData(filteredData);
                    }
                    else
                        setSearchSidePanelData(companyFakeData);
                }
                setIsLoadingSearchSidePanel(false);
            }, 1500);
        } else {

            if (recordType === "company") {
                return listCompany(query.connectorId, userContext.activeEnvironment, userContext.tenant)
                    .then((data) => {
                        if (data && query && query.name) {
                            const filteredData = data.filter((x) => x.name && x.name.toLowerCase().includes(query.name.toLowerCase()));
                            setSearchSidePanelData(filteredData);
                        } else {
                            setSearchSidePanelData(data);
                        }
                        setIsLoadingSearchSidePanel(false);

                    })
                    .catch((e) => {
                        setIsLoadingSearchSidePanel(false);
                    });
            }
        }
    }

    const handleActivate = (id) => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            return activateUser(id, userContext.tenant).then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleDeactivate = (id) => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            return deactivateUser(id, userContext.tenant).then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleRemoveRole = (id) => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            return removeUserRole(id, userContext.tenant).then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleUpdateRole = (id, role) => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            return updateUserRole(id, role, userContext.tenant).then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleUpdateDetails = (id, data) => {
        setIsLoading(true);

        if (process.env.REACT_APP_TEST_DATA === "true") {

        } else {
            return updateUserDetails(id, data, userContext.tenant).then((data) => {
                onRefresh();
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }

    const handleListConnectors = () => {
        return getConnectors();
    }

    React.useEffect(() => {
        setIsLoading(true);
        if (process.env.REACT_APP_TEST_DATA === "true") {
            const timer = setTimeout(() => {
                setDatagridData(fakeData);
                datagridRef.current = fakeData;
                setIsLoading(false);
            }, 1500);
            return () => clearTimeout(timer);
        }
        else {
            return listUsers(userContext.tenant).then((data) => {                
                setDatagridData(data);
                setIsLoading(false);
            })
                .catch((e) => {
                    setIsLoading(false);
                    throw e;
                })
        }
    }, [userContext.activeEnvironment]);

    React.useEffect(() => {
        // Clear the interval when the component unmounts
        return () => clearTimeout(timerRef.current);
    }, []);


    return (<UserTemplate
        data={datagridData}
        searchSidePanelData={searchSidePanelData}
        isLoading={isLoading}
        isLoadingSidePanel={isLoadingSidePanel}
        isLoadingSearchSidePanel={isLoadingSearchSidePanel}
        handleCreateRecord={handleCreateRecord}
        handleFetchRecord={handleFetchRecord}
        handleDeleteRecord={handleDeleteRecord}
        recordData={recordData}
        handleListRecords={handleListRecords}
        handleActivate={handleActivate}
        handleDeactivate={handleDeactivate}
        handleRemoveRole={handleRemoveRole}
        handleUpdateRole={handleUpdateRole}
        handleUpdateDetails={handleUpdateDetails}
        handleListConnectors={handleListConnectors }
        onRefresh={onRefresh} />);
}

export default UserPage;