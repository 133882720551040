import {
  ListItemText,
  ListItemIcon,
  ListItem,
  Typography,
  styled,
} from "@mui/material";
import { ExpandMore, ExpandLess, VisibilityRounded } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";
import {
  useRef,
  useState,
  Fragment,
  componentDidUpdate,
  useEffect,
} from "react";
import SidebarListChildren from "./SidebarListChildren";
import PropTypes from "prop-types";

const StyledTypography = styled(Typography)({
  fontSize: "16px",
  fontSize: "1rem",
  fontFamily: "Paralucent,Mulish,Muli,sans-serif",
});

const usePrevSidebarState = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

function SidebarListItem({
  icon,
  title,
  depthLevel,
  sidebarActive,
  children,
  to,
}) {
  const [open, setOpen] = useState(false);
  const prevSidebarState = usePrevSidebarState(sidebarActive);

  if (prevSidebarState && !sidebarActive && open) setOpen(false);

  const handleClick = () => {
    if (children) setOpen(!open);
  };

  const listItem = useRef();
  const anchorEl = listItem.current;

  let linkProps = {};

  if (to) {
    linkProps.to = to;
    linkProps.component = Link;
  }

  const location = useLocation();

  // TODO: Later replace the below with something better (for performance purposes)
  let active = location.pathname === to;

  if (children && !active) {
    children.forEach((child) => {
      if (child.props.to === location.pathname) {
        active = true;
      }
    });
  }

  return (
    <Fragment>
      <ListItem
        ref={listItem}
        button
        {...linkProps}
        onClick={handleClick}
        sx={{
          pl: depthLevel,
          borderRadius: 1,
          borderLeft: active ? 5 : 0,
          borderRight: 0,
          borderColor: "#1976d2",

          "&:hover": {
            borderColor: "#1950d2",
          },
        }}
      >
        {icon && (
          <ListItemIcon sx={{ paddingLeft: "9px", minWidth: "30px" }}>
            {icon}
          </ListItemIcon>
        )}
        <ListItemText
          disableTypography
          inset={icon ? false : true}
          sx={{ pl: 0 }}
        >
          <StyledTypography>{title}</StyledTypography>
        </ListItemText>
        {children && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>

      {children && (
        <SidebarListChildren
          open={open}
          sidebarActive={sidebarActive}
          handleClick={handleClick}
          onClick={handleClick}
          anchorEl={anchorEl}
          title={title}
          children={children}
        ></SidebarListChildren>
      )}
    </Fragment>
  );
}

SidebarListItem.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  depthLevel: PropTypes.number,
  onClick: PropTypes.func,
  to: PropTypes.string,
  sidebarListEnabled: PropTypes.bool,
};

SidebarListItem.defaultProps = {
  depthLevel: 0,
  title: "",
};
export default SidebarListItem;
