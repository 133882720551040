const fakeData = [{ "id": "315436fd-9194-4132-b86f-644828c662d5", "name": "analyzer", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "4/30/2021", "modifiedOn": "5/4/2021", "createdBy": "1d96e32e-0d1c-4f65-8a92-72c54e4fc5a5" },
{ "id": "d7122400-0bf6-4035-ab62-79501dbe1f29", "name": "protocol", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "12/17/2021", "modifiedOn": "10/17/2021", "createdBy": "16221394-bc77-4bbf-a515-c95610301b25" },
{ "id": "998cfc10-2c22-495f-9581-396629373566", "name": "Upgradable", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "7/8/2021", "modifiedOn": "2/26/2022", "createdBy": "db648b47-251b-4582-bc19-e083b35c99e2" },
{ "id": "50257fa8-6867-44e0-9ae4-a5eeb0d758ee", "name": "transitional", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "3/23/2022", "modifiedOn": "2/26/2022", "createdBy": "3dd879b8-3d04-48b9-9617-4481343ae22b" },
{ "id": "050b50be-d2ec-48fe-977f-3502018bca77", "name": "web-enabled", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "11/9/2021", "modifiedOn": "10/15/2021", "createdBy": "e11b3725-fc7e-4fd9-b9dd-afefa06f0e53" },
{ "id": "356e4391-7a33-493d-8519-29b33a77010e", "name": "Switchable", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "7/15/2021", "modifiedOn": "8/9/2021", "createdBy": "59335344-c1b3-4398-a0be-2cd47c552dc1" },
{ "id": "f3d6c964-04f2-4357-896b-db501e706b87", "name": "Integrated", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "7/4/2021", "modifiedOn": "4/12/2021", "createdBy": "f2543d44-3298-46aa-bf0e-2003ba32d9cd" },
{ "id": "a345bf06-a225-464c-8077-f88574275690", "name": "solution", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "6/20/2021", "modifiedOn": "12/16/2021", "createdBy": "25ee980e-29c9-4fe9-8db5-dbed907f7eaf" },
{ "id": "07cf031f-a02d-4ced-8352-657cbbe13442", "name": "Up-sized", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "5/3/2021", "modifiedOn": "3/30/2021", "createdBy": "4c5ad8c8-62e7-4be2-94aa-e46fc4ad8d21" },
{ "id": "6eead47f-468f-4bf8-aba4-f6e6dd8a3691", "name": "secured line", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "10/27/2021", "modifiedOn": "1/29/2022", "createdBy": "31cd3a75-65b4-4c68-814b-8049e8415f68" },
{ "id": "a12bb3d0-8e56-4a6e-a391-d24049500333", "name": "structure", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "6/11/2021", "modifiedOn": "9/4/2021", "createdBy": "9da20df2-956b-4d6f-862d-e81610e3e23a" },
{ "id": "246eefb1-c0a7-4f44-9a2b-e3f8bc326326", "name": "superstructure", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "6/12/2021", "modifiedOn": "8/5/2021", "createdBy": "c33c068f-7e77-4260-9827-7fe9cd6347fa" },
{ "id": "9e77ddd6-2cbe-4aab-9f02-cc14833ac45a", "name": "Multi-lateral", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "12/11/2021", "modifiedOn": "11/13/2021", "createdBy": "f70ea9af-52d3-4252-9977-20acd74e7f8d" },
{ "id": "e5289f68-5b1e-4ea3-949e-b865181b42dd", "name": "interactive", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "1/10/2022", "modifiedOn": "11/19/2021", "createdBy": "87246610-a614-4e28-b3c9-70a758e51a9e" },
{ "id": "cb0a9e34-f1a8-4054-b792-e69ec053854e", "name": "grid-enabled", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "6/26/2021", "modifiedOn": "3/19/2022", "createdBy": "962b2633-9afb-4fa9-a165-f35b2fd5ff1f" },
{ "id": "b2aa6495-71cc-438c-a17a-fffab2124b10", "name": "function", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "5/8/2021", "modifiedOn": "6/25/2021", "createdBy": "532f4724-8463-4eec-9070-8438a5b8d2bd" },
{ "id": "aec12a36-e8e2-4c1a-b6f3-f9ce3e8d888a", "name": "reciprocal", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "6/18/2021", "modifiedOn": "1/8/2022", "createdBy": "3217383c-3a45-4df2-9100-cf89a5adc9e0" },
{ "id": "bb1919f8-9132-4567-981d-a26d53a09e6b", "name": "Seamless", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "12/8/2021", "modifiedOn": "6/10/2021", "createdBy": "297c4df8-caf8-4823-9db0-934ced845e59" },
{ "id": "d8d9945f-7079-41e4-85fb-e722b2284783", "name": "Fully-configurable", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "8/6/2021", "modifiedOn": "10/31/2021", "createdBy": "4e898fbd-adf0-45b3-b1f9-5dfd9f09eb93" },
{ "id": "15a948ab-3255-4c31-9dbe-23cbeedb8520", "name": "Compatible", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "4/19/2021", "modifiedOn": "1/8/2022", "createdBy": "ad85c2a7-0ce4-46af-b45a-936b83a0aa6a" },
{ "id": "d994e39d-a913-436b-9d67-30a1ba6ec1e5", "name": "Networked", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "8/16/2021", "modifiedOn": "1/25/2022", "createdBy": "5983f8de-5325-4b40-ba4a-946a98e3ffd3" },
{ "id": "c409a317-2f9f-4d86-a6aa-17839ae3fe89", "name": "success", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "12/10/2021", "modifiedOn": "8/17/2021", "createdBy": "052adf8b-45d2-43f9-bdb8-2c8bfdbe481f" },
{ "id": "c1bfbf1f-72fb-42ad-afca-157c7a210387", "name": "bandwidth-monitored", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "3/11/2022", "modifiedOn": "11/5/2021", "createdBy": "0dc8f6bb-7776-4ffc-929e-f6fe1d329026" },
{ "id": "0e3e798d-dea2-4255-879e-89efdd8f80e5", "name": "Persistent", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "8/14/2021", "modifiedOn": "9/10/2021", "createdBy": "3e3901fa-434e-4ef2-a776-e39ac9e6b98d" },
{ "id": "58426f59-7efe-4881-ac01-34ea7ad8cbd3", "name": "open architecture", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "8/15/2021", "modifiedOn": "5/15/2021", "createdBy": "9ba4889f-5ffc-4f35-ad3e-275fc3288e8e" },
{ "id": "d4c671ec-d625-4390-adf1-77097a6c181d", "name": "national", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "1/22/2022", "modifiedOn": "12/30/2021", "createdBy": "14c6e6c3-944b-4747-a6f3-7356f5083e56" },
{ "id": "d405dcd4-39e5-4633-9e9a-ed8ad44a946f", "name": "Focused", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "3/22/2022", "modifiedOn": "10/9/2021", "createdBy": "005fc77d-858c-4f15-9ef2-ad947afd4bfe" },
{ "id": "0b234a7a-7c7e-4563-81d5-e6e23eccb42c", "name": "reciprocal", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "1/7/2022", "modifiedOn": "12/30/2021", "createdBy": "f39cef3f-e4e9-4639-b72a-811332286872" },
{ "id": "c2596788-ddc6-4e7d-a51d-3e3ad203a643", "name": "Sharable", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "10/2/2021", "modifiedOn": "7/12/2021", "createdBy": "281642ea-42d3-4e3a-ad4b-e99fa8aaf33a" },
{ "id": "8fad1b61-a6ba-437b-8319-ac238fc2fc75", "name": "infrastructure", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "5/23/2021", "modifiedOn": "3/24/2021", "createdBy": "8ecaf2bd-8c5c-47b3-a72d-a0b49628c0ef" },
{ "id": "204abc19-4048-4911-9870-7e3e58a6dd83", "name": "high-level", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "7/20/2021", "modifiedOn": "12/14/2021", "createdBy": "05a9ea7c-78d7-4737-a13b-ad7f357595ba" },
{ "id": "14d16861-eecc-49c1-abe2-5ce6e4c74793", "name": "discrete", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "10/20/2021", "modifiedOn": "10/25/2021", "createdBy": "0988793a-313d-4c00-85ef-05922140eafd" },
{ "id": "836f68be-60a9-4ca6-ad7a-59e816dc8df1", "name": "grid-enabled", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "3/26/2021", "modifiedOn": "12/27/2021", "createdBy": "130f0dc8-2078-4f49-8ffb-ffc347038e90" },
{ "id": "5a1e73b8-3865-4111-a70c-61aab9b3fc4f", "name": "Balanced", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "6/11/2021", "modifiedOn": "5/25/2021", "createdBy": "d9c93a71-158a-4bde-a1f6-64521439106b" },
{ "id": "db65f09a-98e6-4748-b1ea-00432d6d3379", "name": "Total", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "10/3/2021", "modifiedOn": "7/25/2021", "createdBy": "dea573bb-7608-4908-b2de-3bce3652b068" },
{ "id": "c15d8cd4-8083-4e8f-b244-8fae739bfd0c", "name": "3rd generation", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "5/24/2021", "modifiedOn": "12/26/2021", "createdBy": "3597c405-1d65-48c8-aa1d-e014d7b8abb2" },
{ "id": "abc20052-da73-492f-a6f3-a31f613133db", "name": "maximized", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "9/28/2021", "modifiedOn": "9/6/2021", "createdBy": "7e77f588-a62f-4c03-b4a6-2450bf25c49a" },
{ "id": "9f07a8bc-5a56-4e19-88bd-f0b31b35c896", "name": "ability", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "12/11/2021", "modifiedOn": "12/20/2021", "createdBy": "e53600fc-122f-4f98-8f7f-8b32deb6a15d" },
{ "id": "cf95051c-7d1a-4d91-a237-279121636350", "name": "customer loyalty", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "12/14/2021", "modifiedOn": "12/2/2021", "createdBy": "d8b17913-749b-4c73-832b-845260e470c8" },
{ "id": "59d7cbdd-6cd7-44a4-94e1-cd0308a56f2d", "name": "dynamic", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "9/26/2021", "modifiedOn": "1/24/2022", "createdBy": "a68a590a-e3ae-451d-879d-c1c4317bde33" },
{ "id": "06eb1525-bdb3-42a4-9d1f-09ed4b6054b3", "name": "human-resource", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "1/28/2022", "modifiedOn": "9/1/2021", "createdBy": "3fbaabf5-0cb4-4766-aa58-cd3ed7ccc19e" },
{ "id": "8630667a-39be-49b8-bf9f-6d05a46d4a81", "name": "Open-architected", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "10/18/2021", "modifiedOn": "9/9/2021", "createdBy": "8c9d1e01-7a59-4247-be44-4451fc18ea29" },
{ "id": "ae457576-be60-42a6-9da0-728ad24fcd0d", "name": "Graphical User Interface", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "12/28/2021", "modifiedOn": "3/4/2022", "createdBy": "f0e5c941-816e-4334-9dbb-5ab6b2eacd25" },
{ "id": "107de601-ad97-4d22-9af2-dd23e3b1ead8", "name": "superstructure", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "5/12/2021", "modifiedOn": "1/10/2022", "createdBy": "bf9a5c16-463a-4adc-805c-0ccf7041cd73" },
{ "id": "38c6c165-5f3d-496a-b5f9-2f68ba38961e", "name": "array", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "12/10/2021", "modifiedOn": "10/1/2021", "createdBy": "980f87d7-aef1-465c-ac3c-2d04426faff8" },
{ "id": "e8fd2f6a-0470-4f9f-9e97-40bc9f97e65c", "name": "optimal", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "6/13/2021", "modifiedOn": "9/19/2021", "createdBy": "339f89e5-08a4-4186-9d53-6639e51b9c0f" },
{ "id": "219e5657-2fc9-4cb6-a7d9-5407cb8e1de9", "name": "homogeneous", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "4/22/2021", "modifiedOn": "8/28/2021", "createdBy": "ed4870f1-0e24-4fc2-ac58-18404ed8bdfe" },
{ "id": "af53ddfa-620b-4c51-a720-cf6bf72997f9", "name": "knowledge base", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "3/24/2021", "modifiedOn": "7/22/2021", "createdBy": "defa6918-c69c-4b47-ba4e-d5da0d1e52dd" },
{ "id": "190fd62c-7011-42ad-ae6f-75a080eaf54e", "name": "model", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "9/15/2021", "modifiedOn": "7/2/2021", "createdBy": "6b2d27f3-f891-4962-8b9e-6e38a71d1fee" },
{ "id": "bbe3c5ee-25ab-4e0d-b6af-437dd380722a", "name": "Down-sized", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "4/25/2021", "modifiedOn": "6/26/2021", "createdBy": "fa20652c-d338-4539-8c0c-c2f727a6f0e8" },
{ "id": "1991ea27-75e9-4a77-8a01-1c1faa6d1879", "name": "Balanced", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "6/16/2021", "modifiedOn": "10/21/2021", "createdBy": "116b5561-bacd-4db7-9197-afd686f0c9d1" },
{ "id": "e1464bce-44f7-42d4-93f8-0164c471fdb9", "name": "Phased", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "12/4/2021", "modifiedOn": "11/8/2021", "createdBy": "43257b56-8e6c-4e51-8d00-6aea5f518594" },
{ "id": "00cdb120-d799-45bc-8fe9-c8f9fd18ff4a", "name": "zero tolerance", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "11/4/2021", "modifiedOn": "8/30/2021", "createdBy": "6acefb5a-f589-4593-a45a-d14aa3dd1cc6" },
{ "id": "b2cd3493-ed9d-4921-a31f-3c7c60e4a880", "name": "Versatile", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "5/30/2021", "modifiedOn": "4/25/2021", "createdBy": "f15126a2-e825-422b-9612-474d6fa2bfbf" },
{ "id": "1149871e-b14d-417e-b0b9-2e285f45fab8", "name": "groupware", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "10/23/2021", "modifiedOn": "4/3/2021", "createdBy": "57d1a5ed-0928-42ac-b69c-f3599b0cf772" },
{ "id": "deac0074-63a4-4314-a619-ee7dea206963", "name": "methodology", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "7/29/2021", "modifiedOn": "7/6/2021", "createdBy": "f3da40c8-4791-4314-9a20-f6fb892bff5d" },
{ "id": "5824c47c-16fd-414b-a44f-3208595fcacc", "name": "empowering", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "3/28/2021", "modifiedOn": "11/4/2021", "createdBy": "0e2173b0-f24e-45f8-82dd-b8cd15fb479f" },
{ "id": "d615f240-816a-4498-adca-a32ec9d1e1f8", "name": "scalable", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "6/26/2021", "modifiedOn": "6/30/2021", "createdBy": "dcda0fae-c8a8-48ff-9387-491f137d5246" },
{ "id": "c86d1679-3888-4858-90e9-cb2f3ee43c0b", "name": "Synergized", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "5/21/2021", "modifiedOn": "9/16/2021", "createdBy": "41ddc2ff-2059-46ba-ac61-b768523f36c1" },
{ "id": "046fda5f-765b-4444-9c4c-3cc35b00b65d", "name": "solution-oriented", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "8/18/2021", "modifiedOn": "11/29/2021", "createdBy": "c68e1924-4472-438e-aff9-81a4014aeeb5" },
{ "id": "16e6d314-0e0c-4d6a-8b5d-4e88c53b5a1c", "name": "throughput", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "9/27/2021", "modifiedOn": "9/20/2021", "createdBy": "c2154cfe-5c7d-40cf-abb9-2753a115d6dd" },
{ "id": "56ec92a6-1c4a-476e-b870-48fcd2a9c715", "name": "data-warehouse", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "5/21/2021", "modifiedOn": "2/8/2022", "createdBy": "d29cb65c-6e1a-4ca5-bbb8-0d5a23ad1399" },
{ "id": "68b5e80d-f3cf-45e9-8447-7accbb0df54b", "name": "ability", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "9/9/2021", "modifiedOn": "11/22/2021", "createdBy": "067ec362-193d-459c-b9a1-68945dc5e439" },
{ "id": "6e2ff729-4fd3-4a7a-9dcd-187fd1741b55", "name": "Synergized", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "8/22/2021", "modifiedOn": "10/11/2021", "createdBy": "9b4ca58c-5175-4b8a-97f0-e904931b01c0" },
{ "id": "4731f65b-298c-426b-af88-caee89b25637", "name": "bifurcated", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "10/25/2021", "modifiedOn": "3/16/2022", "createdBy": "2ce06d5c-18d8-48ff-9c42-ffffa729a5f9" },
{ "id": "651b50f5-cb6b-4342-ac01-147d4da3a0b2", "name": "concept", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "5/29/2021", "modifiedOn": "6/28/2021", "createdBy": "8d646152-83c2-4deb-a0d0-9e9109e73084" },
{ "id": "3e143b4d-b3c1-428e-bd0f-9c8d4ba06dd7", "name": "Profound", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "9/17/2021", "modifiedOn": "7/27/2021", "createdBy": "74996646-fd68-44a9-a5b2-96e84fb57e49" },
{ "id": "30a226f0-9a97-43a5-b6a1-60368376813e", "name": "website", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "8/19/2021", "modifiedOn": "3/26/2021", "createdBy": "e7a318d8-b928-4b67-8377-d2cbda99c750" },
{ "id": "a471b5d8-cd06-4c5b-85cb-5c40b082dcc5", "name": "Versatile", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "11/11/2021", "modifiedOn": "4/7/2021", "createdBy": "32d4ded0-7612-438c-b63f-16b51eb5d154" },
{ "id": "4612281b-b7b9-42a0-934d-25ae815dbfb3", "name": "Secured", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "7/17/2021", "modifiedOn": "8/1/2021", "createdBy": "419264c6-68d7-4371-a80e-190707d9f841" },
{ "id": "26366d8f-f8da-442f-9c8c-0131103eb572", "name": "Ameliorated", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "4/19/2021", "modifiedOn": "6/16/2021", "createdBy": "348c2af1-f407-4eba-88f1-d31b8cb2d760" },
{ "id": "773d24b7-7c08-4e39-b3e9-e1d638d12822", "name": "Pre-emptive", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "12/4/2021", "modifiedOn": "1/23/2022", "createdBy": "17fdea18-d280-4188-b0a0-80c4e21919af" },
{ "id": "3b820594-932c-4200-acb1-8b3a38e4f53d", "name": "upward-trending", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "9/25/2021", "modifiedOn": "12/23/2021", "createdBy": "51815c8c-50f2-4ef6-a00e-2e6fb993d3e7" },
{ "id": "2bc4476a-4290-4720-98ab-f33ed60adb7a", "name": "grid-enabled", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "5/18/2021", "modifiedOn": "2/14/2022", "createdBy": "8d063dda-306b-49fd-9fd7-f7efd444ebbb" },
{ "id": "bcd0f045-17f4-4cd5-948c-b32e3fba794f", "name": "next generation", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "5/20/2021", "modifiedOn": "5/22/2021", "createdBy": "6b0a5d75-109d-4e89-bf33-351c189f03f3" },
{ "id": "b792c949-4c29-45c2-b87d-ac694cd882e8", "name": "Realigned", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "5/17/2021", "modifiedOn": "2/18/2022", "createdBy": "1d26a885-83ff-473e-984d-95f28e2d5b2e" },
{ "id": "cab88197-2089-4ffd-ac21-b4dc9ef3ea01", "name": "matrix", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "7/14/2021", "modifiedOn": "9/16/2021", "createdBy": "276f4b91-7576-4af2-b245-06011e207f3f" },
{ "id": "e7cfb704-d584-4eb6-ae11-b93d5038bcf2", "name": "maximized", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "1/23/2022", "modifiedOn": "9/20/2021", "createdBy": "edd74342-a45d-43e9-8948-438a426348c7" },
{ "id": "a8dce1e8-7530-49ba-b228-2f7103b4a810", "name": "array", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "3/29/2021", "modifiedOn": "5/8/2021", "createdBy": "f9ee3ee8-b7f9-4924-b80b-26838c4453dc" },
{ "id": "7ca44c4c-ee72-4088-b7fa-a6890d717e4f", "name": "collaboration", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "4/13/2021", "modifiedOn": "12/16/2021", "createdBy": "0577a350-799e-4f02-b26a-73c00138c59b" },
{ "id": "be97468b-5444-42c6-8c1b-31d932a199cd", "name": "systematic", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "11/7/2021", "modifiedOn": "11/16/2021", "createdBy": "af873f49-5dd0-4d77-afe0-4a470d3ede20" },
{ "id": "4f8bf7b8-576d-44fc-8faf-8878ca3ae5bb", "name": "installation", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "9/25/2021", "modifiedOn": "3/27/2021", "createdBy": "4a46565d-3f1a-4986-babd-b658906e6338" },
{ "id": "b28bb064-35b4-4bfc-89d4-c9842f941d0b", "name": "Mandatory", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "7/11/2021", "modifiedOn": "5/29/2021", "createdBy": "aa7b9a52-46b6-4259-a7b2-ccd6e86cf45f" },
{ "id": "e8d5b6e3-dd68-45c5-9d96-6d72fe080f23", "name": "framework", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "8/28/2021", "modifiedOn": "8/12/2021", "createdBy": "5f1cdb43-de56-47c8-99ce-c465aeec1df5" },
{ "id": "5c67849e-ad72-497d-8b16-3f8942602243", "name": "Persevering", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "3/27/2021", "modifiedOn": "1/28/2022", "createdBy": "977dcb16-eb64-4411-ac81-c16f5bd95c81" },
{ "id": "66312847-a13c-4f14-abce-e4b093bbb4b1", "name": "system-worthy", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "6/11/2021", "modifiedOn": "6/15/2021", "createdBy": "526455ef-8289-4497-bbcd-c4e84343fadf" },
{ "id": "fe28138c-7eb2-4f09-9c2f-3f82029a08e8", "name": "real-time", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "2/24/2022", "modifiedOn": "2/9/2022", "createdBy": "78e3b5a2-780b-4b01-9b6b-7a41be2d84a0" },
{ "id": "e958c86c-cc7d-4437-8b90-26711e9419db", "name": "secondary", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "1/7/2022", "modifiedOn": "11/11/2021", "createdBy": "84301a1b-b8af-48f1-ae3d-c1db3eb2fe2d" },
{ "id": "b445f030-8b89-4edf-8fbd-d2f7fc5210fc", "name": "optimizing", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "11/22/2021", "modifiedOn": "4/21/2021", "createdBy": "afe729b4-66ee-4785-bf1d-6570abd76615" },
{ "id": "b32e2bf0-3675-4d06-935f-e069e0716127", "name": "initiative", "periodType": "Calendar", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "1/3/2022", "modifiedOn": "10/3/2021", "createdBy": "5aa6dd9e-0122-46d4-a32a-cb544c5fa3f0" },
{ "id": "b5974601-d34c-457e-9d21-c072124ee3c9", "name": "hub", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "11/20/2021", "modifiedOn": "4/19/2021", "createdBy": "2578a02c-8540-4c3c-bc68-7398aba27e85" },
{ "id": "be902f30-a9a3-453d-bb17-bf88650df0df", "name": "Synergized", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "6/27/2021", "modifiedOn": "6/18/2021", "createdBy": "875093e2-8226-4c53-a693-faee968e6e14" },
{ "id": "2176bfd1-9e10-443d-9210-efd74b710c8f", "name": "Multi-channelled", "periodType": "Rolling", "subscriptionType": "PerUnitSubscription", "createdOn": "2/12/2022", "modifiedOn": "6/18/2021", "createdBy": "cde63b9f-d2e6-4889-9263-edc665f64ec2" },
{ "id": "29ad81bd-de86-43eb-84b0-52c2235d750c", "name": "asynchronous", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "8/14/2021", "modifiedOn": "10/17/2021", "createdBy": "8a61e0d2-7ed1-4651-998b-39dc043174cc" },
{ "id": "667a177b-6651-493c-a200-c9057192f4ca", "name": "internet solution", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "9/8/2021", "modifiedOn": "2/8/2022", "createdBy": "68723295-7f27-4ee1-9da3-e19517b79000" },
{ "id": "a1046ede-7751-4f60-8a63-b468592fa6bd", "name": "reciprocal", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "4/29/2021", "modifiedOn": "9/11/2021", "createdBy": "24e2201c-3155-4688-8fea-d2bd506be3c3" },
{ "id": "e0fa9a45-b8eb-49c7-b946-955dc8c0c2b1", "name": "Open-source", "periodType": "Rolling", "subscriptionType": "PerpetualEnhancementPlan", "createdOn": "2/1/2022", "modifiedOn": "9/19/2021", "createdBy": "98899173-39ef-4e25-96e0-57a334ecc354" },
{ "id": "360bc8bf-ac9b-4afb-8849-e3873ded0390", "name": "non-volatile", "periodType": "Calendar", "subscriptionType": "Consumption", "createdOn": "12/25/2021", "modifiedOn": "12/23/2021", "createdBy": "555b686a-98c7-40c1-bf62-d4c0dd0b5c35" },
{ "id": "931086a3-0954-4e60-9891-c37a38b4dbae", "name": "orchestration", "periodType": "Rolling", "subscriptionType": "Consumption", "createdOn": "8/14/2021", "modifiedOn": "8/10/2021", "createdBy": "b289274a-0a72-4607-a5f4-9c7699ce24f8" },
    { "id": "ee4e8fa1-8235-4584-8a50-0a7f6b84218a", "name": "fresh-thinking", "periodType": "Calendar", "subscriptionType": "PerUnitSubscription", "createdOn": "9/18/2021", "modifiedOn": "1/13/2022", "createdBy": "73cbe962-d2c8-4afe-88d9-f0764b0f5195" }];

export { fakeData };