const fakeData = [{
    "id": "5ba2a815-151f-45bb-d246-08db6b40eab0",
    "status": "New",
    "externalPaymentStatus": "Confirmed",
    "merchant": {
        "id": "2",
        "connector": "Tory",
        "type": "Sandbox",
        "status": "Successful",
        "email": "tfidilis0@nymag.com",
        "organisationId": "01H25M3CW9N5FGFJ1D0MB96M4C",
        "onboardingUrl": "116.181.26.141",
        "customerOnboardingRedirectUrl": "190.145.22.135",
        "customerOnboardingExitUrl": "91.55.110.37"
    },
    "customer": {
        "name": "Somadis",
        "email": null,
        "entityReferences": [
            {
                "$id": "8",
                "id": "DDD7A6E0-2590-EB11-BB66-000D3ABCDDD1",
                "name": "Somadis",
                "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
                "secondaryKey": "21233572",
                "url": null,
                "extensionData": {
                    "$id": "9",
                    "email": ""
                }
            }],
        "createdBy": null,
        "modifiedBy": null,
        "id": "9e1dc94e-3156-4645-046a-08db65d0c0cb",
        "createdOn": "2023-06-05T15:35:51.7701176",
        "modifiedOn": "2023-06-05T15:35:51.7701179",
        "deletedOn": null, 
        "mandate": "MD000TR07RDFXP"
    },
    "invoice": {
        "id": "103251",
        "name": "103251",
        "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
        "secondaryKey": null,
        "url": null,
        "extensionData": {
            "$id": "16"
        }
    },
    "currency": {
        "id": "2052A4DA-2590-EB11-BB66-000D3ABCDDD1",
        "name": "Euro",
        "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
        "secondaryKey": "EUR",
        "url": null,
        "extensionData": {
            "$id": "18",
            "Symbol": "€",
            "Code": "EUR"
        }
},
    "grossAmount": 13,
    "netAmount": 36,
    "taxAmount": 62,
    "billingRequestUrl": "https://house.gov/ridiculus/mus/etiam/vel/",
    "chargedOn": "3/26/2023",
    "externalPaymentId": 123
},
{
    "id": "9e993484-93e8-4186-d244-08db6b40eab0",
    "status": "Active",
    "externalPaymentStatus": "Confirmed",
    "merchant": {
        "id": "4",
        "connector": "Oliy",
        "type": "Live",
        "status": "Successful",
        "email": "omccoveney1@alibaba.com",
        "organisationId": "01H25M3CWBX0T8A8PXS8QD0GVF",
        "onboardingUrl": "217.55.207.81",
        "customerOnboardingRedirectUrl": "3.51.223.185",
        "customerOnboardingExitUrl": "171.103.69.241"

    },
    "customer": {
        "name": "Somadis2",
        "email": null,
        "entityReferences": [
            {
                "$id": "8",
                "id": "DDD7A6E0-2590-EB11-BB66-000D3ABCDDD1",
                "name": "Somadis",
                "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
                "secondaryKey": "21233572",
                "url": null,
                "extensionData": {
                    "$id": "9",
                    "email": ""
                }
            }],
        "createdBy": null,
        "modifiedBy": null,
        "id": "9e1dc94e-3156-4645-046a-08db65d0c0cb",
        "createdOn": "2023-06-05T15:35:51.7701176",
        "modifiedOn": "2023-06-05T15:35:51.7701179",
        "deletedOn": null,
        "mandate": "12345"
    },
    "invoice": {
        "id": "103251",
        "name": "103251",
        "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
        "secondaryKey": null,
        "url": null,
        "extensionData": {
            "$id": "16"
        }
    },
    "currency": {
        "id": "2052A4DA-2590-EB11-BB66-000D3ABCDDD1",
        "name": "Euro",
        "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
        "secondaryKey": "EUR",
        "url": null,
        "extensionData": {
            "$id": "18",
            "Symbol": "€",
            "Code": "EUR"
        }
    },
    "grossAmount": 72,
    "netAmount": 77,
    "taxAmount": 62,
    "billingRequestUrl": "https://house.gov/ridiculus/mus/etiam/vel/",
    "chargedOn": "12/28/2022",
    "externalPaymentId": 456
    
},
    {
        "id": "8ed39f95-6fe6-4a02-d243-08db6b40eab0",
        "status": "Active",
        "externalPaymentStatus": "Confirmed",
    "merchant": {
        "id": "6",
        "connector": "De witt",
        "type": "Live",
        "status": "Failed",
        "email": "dbarradell2@ft.com",
        "organisationId": "01H25M3CWDSZZHJHM8H6SH0F64",
        "onboardingUrl": "114.213.148.213",
        "customerOnboardingRedirectUrl": "153.236.196.244",
        "customerOnboardingExitUrl": "185.86.55.229"
        },
        "customer": {
            "name": "Somadis3",
            "email": null,
            "entityReferences": [
                {
                    "$id": "8",
                    "id": "DDD7A6E0-2590-EB11-BB66-000D3ABCDDD1",
                    "name": "Somadis",
                    "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
                    "secondaryKey": "21233572",
                    "url": null,
                    "extensionData": {
                        "$id": "9",
                        "email": ""
                    }
                }],
            "createdBy": null,
            "modifiedBy": null,
            "id": "9e1dc94e-3156-4645-046a-08db65d0c0cb",
            "createdOn": "2023-06-05T15:35:51.7701176",
            "modifiedOn": "2023-06-05T15:35:51.7701179",
            "deletedOn": null,
            "mandate": null
    },
        "invoice": {
            "$id": "15",
            "id": "103251",
            "name": "103251",
            "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
            "secondaryKey": null,
            "url": null,
            "extensionData": {
                "$id": "16"
            }
        },
        "currency": {
            "$id": "17",
            "id": "2052A4DA-2590-EB11-BB66-000D3ABCDDD1",
            "name": "Euro",
            "connector": "b3920746-c31f-4e92-8ff4-916c684567e0",
            "secondaryKey": "EUR",
            "url": null,
            "extensionData": {
                "$id": "18",
                "Symbol": "€",
                "Code": "EUR"
            }
        },
    "grossAmount": 28,
    "netAmount": 38,
    "taxAmount": 49,
    "billingRequestUrl": "https://house.gov/ridiculus/mus/etiam/vel/",
    "chargedOn": "8/25/2022",
    "externalPaymentId": 789
    }];

export { fakeData };