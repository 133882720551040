import * as React from "react";
import PropTypes from "prop-types";

import { Chip, Grid, Typography, Tooltip } from "@mui/material/";
import DataGrid from "../organisms/DataGrid";
import { styled } from "@mui/material/styles";

import Subtitle from "../atoms/Subtitle";

import RefreshRibbonButton from "../molecules/RefreshRibbonButton";

import FirmRibbonButton from "../molecules/FirmRibbonButton";
import PostRibbonButton from "../molecules/PostRibbonButton";
import CancelRibbonButton from "../molecules/CancelRibbonButton";

import ConfirmDialog from "../molecules/ConfirmDialog";
import SidePanel from "../organisms/SidePanel";

import NotificationSnackBar from "../organisms/NotificationSnackBar";

import ViewSubscriptionActionForm from "../../forms/subscriptionaction/ViewSubscriptionActionForm";
import CancelSubscriptionActionForm from "../../forms/subscriptionaction/CancelSubscriptionActionForm";

import {
  Route,
  Routes,
  Outlet,
  useParams,
  useSearchParams,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import { format } from "date-fns";
import { useNavigateWithParams } from "../../hooks/useNavigateWithParams";
import ProfileCard from "../organisms/ProfileCard";
import TooltipStatusChip from "../molecules/TooltipStatusChip";

import { useFirstRender } from "../../hooks/useFirstRender";

import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import ApprovalIcon from "@mui/icons-material/Approval";
import PostAddIcon from "@mui/icons-material/PostAdd";
import BlockIcon from "@mui/icons-material/Block";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

const GridParent = styled(Grid)(({ theme }) => ({}));

const RibbonGrid = styled(Grid)(({ theme }) => ({
  padding: 3,
  paddingLeft: 30,
  display: "inline-flex",
}));

const GridItem = styled(Grid)(({ theme }) => ({
  padding: 16,
  paddingLeft: 30,
}));

const SetActionColor = (params) => {
  switch (params.value) {
    case "NotFirmed":
      return "default";
    case "Firmed":
      return "primary";
    case "Posted":
      return "success";
    case "Cancelled":
      return "error";
    default:
      return "warning";
  }
};

const SetActionIcon = (params) => {
  switch (params.value) {
    case "NotFirmed":
      return <DriveFileRenameOutlineIcon />;
    case "Firmed":
      return <ApprovalIcon />;
    case "Posted":
      return <PostAddIcon />;
    case "Cancelled":
      return <BlockIcon />;
    default:
      return <HourglassEmptyIcon />;
  }
};

const columns = [
  {
    field: "actionStatus",
    headerName: "Action Status",
    flex: 1,
    minWidth: 145,
    type: "singleSelect",
    valueOptions: [
      { label: "Not Firmed", value: "NotFirmed" },
      { label: "Firmed", value: "Firmed" },
      { label: "Posted", value: "Posted" },
      { label: "Cancelled", value: "Cancelled" },
    ],
    valueFormatter: (params) => {
      if (params.value === "NotFirmed") return "Not Firmed";

      return params.value;
    },
    renderCell: (params) => {
      if (params && params.row && params.row.errorMessage != null) {
        return (
          <TooltipStatusChip
            title={params.row.errorMessage}
            label={params.formattedValue}
            variant="outlined"
            sx={{ width: "100%" }}
            color={"error"}
            icon={SetActionIcon(params)}
          />
        );
      } else {
          return (
          <TooltipStatusChip
            title={params.row.actionStatus}
            label={params.formattedValue}
            variant="outlined"
            color={SetActionColor(params)}
            icon={SetActionIcon(params)}
            sx={{ width: "100%" }}
          />
        );
      }
    },
  },
  {
    field: "actionType",
    headerName: "Action Type",
    flex: 1,
    minWidth: 100,
    type: "singleSelect",
    valueOptions: [
      { label: "Generate Sales Order", value: "GenerateSalesOrder" },
        { label: "Renewal Notice", value: "RenewalNotice" },
        { label: "Direct Debit Payment", value: "DirectDebitPayment" },
    ],
    valueFormatter: (params) => {
      if (params.value === "GenerateSalesOrder") return "Generate Sales Order";
      if (params.value === "RenewalNotice") return "Renewal Notice";
      if (params.value === "DirectDebitPayment") return "Direct Debit Payment";

      return params.value;
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => {
      if (params.value === undefined || params.value.name === undefined)
        return "";

      return params.value.name;
    },
  },
  {
    field: "product",
    headerName: "Product",
    flex: 2,
    minWidth: 100,
    valueGetter: (params) => {
        if (params.value == null) {
            return "";
        } else if (params.value.name == null) {
            return "";
        } else {
            return params.value.name;
        }     
    },
  },
    {
        field: "quantity",
        headerName: "Quantity",
        flex: 1,
        minWidth: 90
    },
  {
    field: "reasonCode",
    headerName: "Reason Code",
    flex: 2,
    minWidth: 105,
    valueGetter: (params) => {
      if (
        params &&
        params.row &&
        params.row.cancelledReasonCode &&
        params.row.cancelledReasonCode.name
      )
        return params.row.cancelledReasonCode.name;

        if (!params.value || params.value === undefined || params.value.name === undefined)
        return "";

      return params.value.name;
    },
  },
  {
    field: "unit",
    headerName: "Unit of Price",
    flex: 1,
    minWidth: 100,
    valueGetter: (params) => {
        if (params.value == null) {
            return "";
        } else if (params.value.name == null) {
            return "";
        } else {
            return params.value.name;
        }  
      },
  },
  {
    field: "salesPrice",
    headerName: "Sales Price",
    flex: 1,
    minWidth: 100,

  },
  {
    field: "discountAmount",
    headerName: "Discount Amount",
    flex: 1,
    minWidth: 136,
  },

  {
    field: "grossAmount",
    headerName: "Gross Amount",
    flex: 1,
    minWidth: 125,

  },

  {
    field: "netAmount",
    headerName: "Net Amount",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "dateFrom",
    headerName: "Date From",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "dateTo",
    headerName: "Date To",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "postingDate",
    headerName: "Posting Date",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "createdOn",
    headerName: "Created On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  {
    field: "modifiedOn",
    headerName: "Modified On",
    flex: 1,
    minWidth: 100,
    type: "dateTime",
    valueFormatter: (params) => {
      return params.value
        ? format(new Date(params.value), "dd/MM/yyyy")
        : params.value;
    },
  },
  
];

function SubscriptionActionIndex({
  mode,
  isLoading,
  data,
  onRefresh,
  setDisplayNotification,
  displayNotification,
  handlePost,
  handleFirm,
  activeSubscriptionPlanLine,
  activeSubscriptionPlan,
  activeCustomer,
}) {
  const [confirmDialog, setConfirmDialog] = React.useState({});
  const [selectedRow, setSelectedRow] = React.useState(null);
  //   const [displayCheckBoxSelection, setDisplayCheckBoxSelection] =
  //   React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);

  let navigate = useNavigateWithParams();
  let [searchParams, setSearchParams] = useSearchParams();

  const [displayFirm, setDisplayFirm] = React.useState(false);
  const [displayPost, setDisplayPost] = React.useState(false);
  const [displayCancel, setDisplayCancel] = React.useState(false);

  const isFirstRender = useFirstRender();

  const isLayoutEmbedded = () => {
    return searchParams.get("layout") === "embedded";
  };

  const isCockpitMode = () => {
    return mode === "cockpit";
  };

  const isDefaultMode = () => {
    return mode === "default";
  };
  const onClickRow = (params) => {
    setSelectedRow(params.row);

    if (selectedRows.includes(params.row)) {
      setSelectedRows(selectedRows.filter((row) => row && row !== params.row));
    } else {
      setSelectedRows([params.row, ...selectedRows]);
    }

    // if (displayCheckBoxSelection === false) {
    //   setDisplayCheckBoxSelection(true);

    if (params.row.actionStatus === "NotFirmed") {
      setDisplayFirm(true);
      setDisplayPost(false);
      setDisplayCancel(true);
    }
    if (params.row.actionStatus === "Firmed") {
      setDisplayFirm(false);
      setDisplayPost(true);
      setDisplayCancel(false);
    }
    if (params.row.actionStatus === "Posted") {
      setDisplayFirm(false);
      setDisplayPost(false);
      setDisplayCancel(false);
    }
    if (params.row.actionStatus === "Cancelled") {
      setDisplayFirm(false);
      setDisplayPost(false);
      setDisplayCancel(false);
    }
    if (
      params.row.actionStatus === "Firming" ||
      params.row.actionStatus === "Posting"
    ) {
      setDisplayFirm(false);
      setDisplayPost(false);
      setDisplayCancel(false);
    }
    // }
  };

  const ProcessSumOfRibbons = (rows) => {
    let displayedFirm = false;
    let displayedPost = false;
    let displayedCancel = false;

    for (var i = 0; i < rows.length; i++) {
      const row = rows[i];

      if (!row || !row.actionStatus) continue;

      if (
        displayedFirm === false &&
        displayedCancel === false &&
        row.actionStatus === "NotFirmed"
      ) {
        displayedFirm = true;
        displayedCancel = true;
      } else if (displayedPost === false && row.actionStatus === "Firmed") {
        displayedPost = true;
      }

      if (displayedFirm && displayedPost && displayedCancel) break;
    }

    setDisplayFirm(displayedFirm);
    setDisplayCancel(displayedCancel);
    setDisplayPost(displayedPost);
  };

  const onDoubleClickRow = (params) => {
    setSelectedRow(params.row.id);
    openSpecificRecord(params.row.id);
  };

  const openRecord = () => {
    navigate(`${isCockpitMode() ? "actions/" : ""}${selectedRow.id}`);
  };

  const openSpecificRecord = (id) => {
    navigate(`${isCockpitMode() ? "actions/" : ""}${id}`);
  };

  const postRecord = () => {
    let title = "Post Subscription Action ?";
    let params = [];

    if (selectedRows.length > 0) {
      params = selectedRows.filter(
        (row) => row && row.actionStatus === "Firmed"
      );
      title = `Post ${params.length} Subscription Actions ?`;
    }

    setConfirmDialog({
      title: title,
      description: `Are you sure you want to Post ?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handlePost(params)
          .then(() => {
            refreshGrid();
            setDisplayNotification({
              message: "Posting Subscription Action.",
              type: "success",
            });
          })
          .catch((e) => {
            if (e.response.data.Message)
              setDisplayNotification({
                message: e.response.data.Message,
                type: "error",
              });
            else {
              setDisplayNotification({
                message: "Unknown Error",
                type: "error",
              });
            }
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const firmRecord = () => {
    let title = "Firm Subscription Action ?";
    let params = [];

    if (selectedRows.length > 0) {
      params = selectedRows.filter(
        (row) => row && row.actionStatus === "NotFirmed"
      );
      title = `Firm ${params.length} Subscription Actions ?`;
    }

    setConfirmDialog({
      title: title,
      description: `Are you sure you want to firm ?`,
      actionConfirm: () => {
        setConfirmDialog({});
        handleFirm(params)
          .then(() => {
            refreshGrid();
            setDisplayNotification({
              message: "Firming Subscription Action",
              type: "success",
            });
          })
          .catch((e) => {
            if (e.response.data.Message)
              setDisplayNotification({
                message: e.response.data.Message,
                type: "error",
              });
            else {
              setDisplayNotification({
                message: "Unknown Error",
                type: "error",
              });
            }
          });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };

  const cancelRecord = () => {
    let title = "Cancel Subscription Action ?";
    let params = [];

    if (selectedRows.length > 0) {
      params = selectedRows.filter(
        (row) => row && row.actionStatus === "NotFirmed"
      );
      title = `Cancel ${params.length} Subscription Actions ?`;
    }

    setConfirmDialog({
      title: title,
      description: `Are you sure you want to cancel ?`,
      actionConfirm: () => {
        setConfirmDialog({});
        navigate((isCockpitMode() ? "actions/" : "") + "cancel", {
          state: { subscriptionActionIds: params.map((row) => row.id) },
        });
      },
      actionCancel: () => {
        setConfirmDialog({});
      },
    });
  };
  const refreshGrid = () => {
    setSelectedRow(null);
    setDisplayFirm(false);
    setDisplayPost(false);
    setDisplayCancel(false);
    onRefresh(
      activeSubscriptionPlan,
      activeSubscriptionPlanLine,
      activeCustomer
    );
  };

  const displaySubscriptionActionRibbons = () => {
    return (
      <RibbonGrid backgroundColor="#f4f4f4" item xs={12} md={12} lg={12}>
        <RefreshRibbonButton
          onClick={refreshGrid}
          display={true}
          title="Refresh"
        />
        <FirmRibbonButton
          onClick={firmRecord}
          display={displayFirm}
          title="Firm Action"
        />
        <PostRibbonButton
          onClick={postRecord}
          display={displayPost}
          title="Post Action"
        />
        <CancelRibbonButton
          onClick={cancelRecord}
          display={displayCancel}
          title="Cancel Action"
        />

        {isLayoutEmbedded() && isDefaultMode() && (
          <ProfileCard embeddedMode={true} />
        )}
      </RibbonGrid>
    );
  };

  React.useEffect(() => {
    if (!isFirstRender) refreshGrid();
  }, [activeSubscriptionPlan, activeSubscriptionPlanLine, activeCustomer]);

  return (
    <React.Fragment>
      <GridParent
        container
        direction="column"
        justifyContent="center"
        alignItems="stretch"
      >
        <ConfirmDialog
          confirmDialogInfo={confirmDialog}
          onConfirm={confirmDialog.actionConfirm}
          onCancel={confirmDialog.actionCancel}
        />
        {isDefaultMode() && displaySubscriptionActionRibbons()}
        <GridItem item xs={12} md={12} lg={12}>
          <Subtitle
            title="Subscription Actions"
            style={{ textAlign: "left" }}
          />
        </GridItem>
        {isCockpitMode() && displaySubscriptionActionRibbons()}

        <GridItem item xs={12} md={12} lg={12}>
          <DataGrid
            loading={isLoading}
            onRowClick={onClickRow}
            onRowDoubleClick={onDoubleClickRow}
            columns={columns}
            rows={data}
            checkboxSelection //={displayCheckBoxSelection}
            onSelectionModelChange={(selection) => {
              if (selection && selection.length === 0) {
                // setDisplayCheckBoxSelection(false);
                setDisplayFirm(false);
                setDisplayPost(false);
                setDisplayCancel(false);
                setSelectedRows([]);
              }

              if (selection && selection.length > 0) {
                const selectedIDs = new Set(selection);

                const selectedRowData = data.filter((row) =>
                  selectedIDs.has(row.id)
                );

                setSelectedRows(selectedRowData);
                ProcessSumOfRibbons(selectedRowData);
              }
            }}
          />
        </GridItem>
      </GridParent>
      {displayNotification !== null && (
        <NotificationSnackBar
          displayNotification={displayNotification}
          onClose={() => {
            setDisplayNotification(null);
          }}
        />
      )}
      <Outlet context={{ refreshGrid }} />
    </React.Fragment>
  );
}

function SubscriptionActionView({
  isLoadingSidePanel,
  sidePanelOpen,
  handleFetchRecord,
  recordData,
}) {
  let navigate = useNavigateWithParams();

  let { subscriptionActionId } = useParams();

  React.useEffect(() => {
    handleFetchRecord(subscriptionActionId);
  }, []);

  return (
    <React.Fragment>
      <SidePanel
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../")}
        title={!isLoadingSidePanel && recordData && `Subscription Action`}
        open={sidePanelOpen}
      >
        {!isLoadingSidePanel && recordData && (
          <ViewSubscriptionActionForm
            isLoading={isLoadingSidePanel}
            handleClose={() => navigate("../")}
            recordData={recordData}
          />
        )}
      </SidePanel>
    </React.Fragment>
  );
}

function SubscriptionActionCancel({
  isLoadingSidePanel,
  sidePanelOpen,
  handleCancel,
  handleListRecords,
  isLoadingSearchSidePanel,
  searchSidePanelData,
}) {
  let navigate = useNavigateWithParams();

  const { refreshGrid } = useOutletContext();
  let { subscriptionActionId } = useParams();
  const { state } = useLocation();

  let subscriptionActionIds;

  if (state && state.subscriptionActionIds)
    subscriptionActionIds = state.subscriptionActionIds;

  return (
    <React.Fragment>
      <SidePanel
        isLoading={isLoadingSidePanel}
        handleClose={() => navigate("../../")}
        title={!isLoadingSidePanel && `Cancel Subscription Action`}
        open={sidePanelOpen}
      >
        {!isLoadingSidePanel && (
          <CancelSubscriptionActionForm
            isLoading={isLoadingSidePanel}
            handleClose={() => {
              navigate("../");
            }}
            handleCancel={(reasonCode) => {
              return handleCancel(
                subscriptionActionIds || subscriptionActionId,
                reasonCode
              ).then(() => {
                navigate("../");
                return refreshGrid();
              });
            }}
            isLoadingSearchSidePanel={isLoadingSearchSidePanel}
            handleListRecords={handleListRecords}
            searchSidePanelData={searchSidePanelData}
          />
        )}
      </SidePanel>
    </React.Fragment>
  );
}

function SubscriptionActionTemplate(props) {
  const {
    data,
    isLoading,
    isLoadingSidePanel,
    isLoadingSearchSidePanel,
    searchSidePanelData,
    onRefresh,
    handleFetchRecord,
    handleListRecords,
    handleEditRecord,
    recordData,
    handlePost,
    handleFirm,
    handleCancel,
    mode,
    activeSubscriptionPlanLine,
    activeSubscriptionPlan,
    activeCustomer,
  } = props;

  const [displayNotification, setDisplayNotification] = React.useState(null);

  const isCockpitMode = () => {
    return mode === "cockpit";
  };

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <SubscriptionActionIndex
            isLoading={isLoading}
            displayNotification={displayNotification}
            onRefresh={onRefresh}
            setDisplayNotification={setDisplayNotification}
            handlePost={handlePost}
            handleFirm={handleFirm}
            data={data}
            mode={mode}
            activeSubscriptionPlanLine={activeSubscriptionPlanLine}
            activeSubscriptionPlan={activeSubscriptionPlan}
            activeCustomer={activeCustomer}
          />
        }
      >
        <Route
          path={(isCockpitMode() ? "actions/" : "") + ":subscriptionActionId"}
          element={
            <SubscriptionActionView
              isLoadingSidePanel={isLoadingSidePanel}
              recordData={recordData}
              handleEditRecord={handleEditRecord}
              handleFetchRecord={handleFetchRecord}
              sidePanelOpen={true}
            />
          }
        />
        <Route
          path={(isCockpitMode() ? "actions/" : "") + "cancel"}
          element={
            <SubscriptionActionCancel
              isLoadingSidePanel={isLoadingSidePanel}
              recordData={recordData}
              handleEditRecord={handleEditRecord}
              handleFetchRecord={handleFetchRecord}
              handleListRecords={handleListRecords}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              searchSidePanelData={searchSidePanelData}
              handleCancel={(ids, reasonCode) => {
                return handleCancel(ids, reasonCode)
                  .then(() => {
                    setDisplayNotification({
                      message: "Actions cancelled!",
                      type: "success",
                    });
                  })
                  .catch((e) => {
                    if (e.response.data.Message)
                      setDisplayNotification({
                        message: e.response.data.Message,
                        type: "error",
                      });
                    else {
                      setDisplayNotification({
                        message: "Unknown Error",
                        type: "error",
                      });
                    }
                    throw e;
                  });
              }}
              sidePanelOpen={true}
            />
          }
        />
        <Route
          path={
            (isCockpitMode() ? "actions/" : "") + ":subscriptionActionId/cancel"
          }
          element={
            <SubscriptionActionCancel
              isLoadingSidePanel={isLoadingSidePanel}
              recordData={recordData}
              handleEditRecord={handleEditRecord}
              handleFetchRecord={handleFetchRecord}
              handleListRecords={handleListRecords}
              isLoadingSearchSidePanel={isLoadingSearchSidePanel}
              searchSidePanelData={searchSidePanelData}
              handleCancel={(id, reasonCode) => {
                return handleCancel(id, reasonCode)
                  .then(() => {
                    setDisplayNotification({
                      message: "Action cancelled!",
                      type: "success",
                    });
                  })
                  .catch((e) => {
                    if (e.response.data.Message)
                      setDisplayNotification({
                        message: e.response.data.Message,
                        type: "error",
                      });
                    else {
                      setDisplayNotification({
                        message: "Unknown Error",
                        type: "error",
                      });
                    }
                    throw e;
                  });
              }}
              sidePanelOpen={true}
            />
          }
        />
      </Route>
    </Routes>
  );
}

SubscriptionActionTemplate.propTypes = {
  mode: PropTypes.oneOf(["default", "cockpit"]),
};
SubscriptionActionTemplate.defaultProps = {
  mode: "default",
};

export default SubscriptionActionTemplate;
