import * as React from 'react';
import GroupIcon from '@mui/icons-material/Group';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material/';
import RibbonButton from './RibbonButton';

export default function GoCardlessCustomersRibbonButton({ merchantType, display }) {


    const baseUrl = (merchantType) => merchantType === "Sandbox" ? "https://manage-sandbox.gocardless.com/" : "https://manage.gocardless.com/";

    return (
        <RibbonButton display={display} onClick={() => {
            window.open(baseUrl(merchantType) + "customers", "_blank").focus();
        }} startIcon={<GroupIcon sx={{ color: "black" }} />}>
            <Typography color="black">
                View all customers
            </Typography>
        </RibbonButton>
    );
}