const fakeData = [{
    "id": "5920be91-0eaf-471c-8909-306a8bcd9f65",
    "name": "Skiles, Hermann and Renner",
    "code": "PH"
}, {
    "id": "74af2198-f8ec-4ee3-861d-2bd94ead117c",
    "name": "King, Swift and Heaney",
    "code": "ID"
}, {
    "id": "76ed95d1-f79f-4325-8df7-8956d00dcfe9",
    "name": "Hudson, Christiansen and Kuvalis",
    "code": "BR"
}, {
    "id": "73cbdd4b-5eb7-4dcd-b9c9-e2a811c91b79",
    "name": "Wilkinson-Kris",
    "code": "CN"
}, {
    "id": "3071e4d4-9a25-440b-a61f-cbf05249aba1",
    "name": "Bogan, Kassulke and Yundt",
    "code": "CN"
}, {
    "id": "19ba18fd-bbe0-4d24-8d7e-b3bd17b283c4",
    "name": "Wehner Inc",
    "code": "TH"
}, {
    "id": "42edb950-5c75-4396-afa1-12bb8dc3e2a6",
    "name": "Vandervort Inc",
    "code": "BR"
}, {
    "id": "7a1047b0-6445-47e7-870b-84ff316bc478",
    "name": "Macejkovic-Torp",
    "code": "US"
}, {
    "id": "cc13213d-1e9b-4f69-bbab-19222d1b75f7",
    "name": "Little, Gleason and Corkery",
    "code": "CZ"
}, {
    "id": "e2fd2536-fff5-43be-9bc6-d770810dff4e",
    "name": "Toy-Schulist",
    "code": "PH"
}, {
    "id": "cb74e31c-e95a-48ed-9653-8c4b46a911c2",
    "name": "Beer and Sons",
    "code": "PK"
}, {
    "id": "d9cfac4a-6e1d-43e4-a0db-0d61afb4b5f5",
    "name": "Maggio, Rempel and Purdy",
    "code": "PL"
}, {
    "id": "26ac6c24-a271-4222-ab41-35cd5cfc8f18",
    "name": "Macejkovic-Moen",
    "code": "CN"
}, {
    "id": "479b102f-28a6-44a5-a3a3-9935268d24b5",
    "name": "Waters-Nader",
    "code": "PH"
}, {
    "id": "99999ca6-7b54-4e65-8308-65b7d36952fc",
    "name": "Kuhlman LLC",
    "code": "RU"
}, {
    "id": "f4dfec61-7c1c-45f0-a913-8633cf3a32d6",
    "name": "Hirthe Inc",
    "code": "PH"
}, {
    "id": "66511b4d-ba47-4c4f-8a05-e5cfdafc485e",
    "name": "Dooley-VonRueden",
    "code": "CN"
}, {
    "id": "65e6a46a-7c2b-4bca-a56d-c0b3da6a836a",
    "name": "Purdy-Predovic",
    "code": "NG"
}, {
    "id": "4dd6a20d-1ded-4763-910a-d05318aebac6",
    "name": "Hoeger, Hills and Heller",
    "code": "ID"
}, {
    "id": "4c7d4126-4b45-4bb3-8994-8cadb0c81148",
    "name": "Turner, Steuber and King",
    "code": "RU"
}, {
    "id": "6bf71000-e3ac-4f9e-b6c0-da6e52b824cb",
    "name": "Kris, Kiehn and Berge",
    "code": "KZ"
}, {
    "id": "c75acaa2-e1ad-4f1f-99dd-d265d832d15b",
    "name": "Kling Group",
    "code": "PE"
}, {
    "id": "d247a47e-d45d-4369-a911-506b2073cec6",
    "name": "Terry, Hilll and Shields",
    "code": "CN"
}, {
    "id": "5cc0f61a-9826-4fb4-8215-b52150578135",
    "name": "Bailey and Sons",
    "code": "RU"
}, {
    "id": "b2644a9c-8d27-4006-831f-bc151ce0a509",
    "name": "Barton, Hermann and Legros",
    "code": "TH"
}, {
    "id": "36c527cf-4964-4ad7-9f6e-5c1f4f862924",
    "name": "Dare-Corkery",
    "code": "VE"
}, {
    "id": "79f358e2-62d0-4f05-8b65-5ab3eb9755e8",
    "name": "Dicki Inc",
    "code": "AR"
}, {
    "id": "7d27fba0-400b-437a-b96d-b01681895ace",
    "name": "Larkin LLC",
    "code": "US"
}, {
    "id": "8964bad7-0256-46dc-a7f6-436aedf29dad",
    "name": "Heaney-Hackett",
    "code": "PT"
}, {
    "id": "fce76b83-b5e1-48d8-855c-c905dab624bd",
    "name": "Abshire LLC",
    "code": "CN"
}, {
    "id": "124b6c1b-c01a-48e0-a5b6-662611ffc233",
    "name": "McGlynn, Quigley and Heidenreich",
    "code": "ZM"
}, {
    "id": "c3170df4-7dc2-4b09-96ce-d8649e611ef2",
    "name": "Wilderman-Roob",
    "code": "PH"
}, {
    "id": "645b3339-fc0b-42f4-a9c1-804e7f764b07",
    "name": "Reilly Group",
    "code": "BR"
}, {
    "id": "37cb9300-5f8d-457f-ae2f-ba6374e98dac",
    "name": "Turcotte, Tremblay and Thiel",
    "code": "CN"
}, {
    "id": "f0706b0d-7293-4f9f-abe7-997f0a127079",
    "name": "Muller and Sons",
    "code": "GR"
}, {
    "id": "53b60f85-5a4f-40f1-94a5-a438273eaaf1",
    "name": "Streich, Weimann and Carter",
    "code": "RU"
}, {
    "id": "245a3fc8-944a-4fb7-8682-5da94a95de92",
    "name": "Kulas-Botsford",
    "code": "CN"
}, {
    "id": "f10886c7-d9f9-4f68-8024-afe4ca9f4ae3",
    "name": "Goodwin Group",
    "code": "ZA"
}, {
    "id": "7141055a-7dfd-4d70-bab7-759c4d6de356",
    "name": "Altenwerth and Sons",
    "code": "UA"
}, {
    "id": "41574781-20ed-4773-abca-8ee18b7faa84",
    "name": "Parisian, Schoen and Gerhold",
    "code": "PL"
}, {
    "id": "909d77a4-06a6-4b85-bebb-dac7dfc3eef6",
    "name": "Hane-Graham",
    "code": "BA"
}, {
    "id": "7ef68027-2fd5-41fe-a375-d361a00d1a58",
    "name": "Wolff Inc",
    "code": "SI"
}, {
    "id": "f58301f1-4f66-4f77-9448-a2dc8088034d",
    "name": "Simonis Group",
    "code": "CN"
}, {
    "id": "3d903018-0656-43f6-b8e7-46ee515a6961",
    "name": "Green, Douglas and Zemlak",
    "code": "TH"
}, {
    "id": "e896f7c7-e9d1-4e70-84e5-2cc050175d9b",
    "name": "Renner, Kutch and Farrell",
    "code": "MG"
}, {
    "id": "ad5d429c-63d1-4e7c-a97a-cb2ef4d51c4d",
    "name": "DuBuque-Kuhn",
    "code": "PH"
}, {
    "id": "9c8fe73b-dff5-4360-bd4d-fae21c601b3d",
    "name": "Russel, Zemlak and Hackett",
    "code": "PL"
}, {
    "id": "967e7d92-6abb-48a6-a382-9537f4cfb7de",
    "name": "Crooks, Beahan and Feil",
    "code": "BB"
}, {
    "id": "1d2b1b2d-c8ea-4dea-863d-acd1111191c4",
    "name": "Bosco-Osinski",
    "code": "CL"
}, {
    "id": "9c03681a-99a4-421d-873c-82176d142596",
    "name": "Friesen, Veum and Corwin",
    "code": "PH"
}, {
    "id": "67891f86-cf03-4ed7-9b96-cabc453d2b06",
    "name": "Wilkinson-Bechtelar",
    "code": "RU"
}, {
    "id": "cf08231c-8b67-49de-a227-1744dc12edd7",
    "name": "Abbott Group",
    "code": "PT"
}, {
    "id": "fb46f56a-7a55-4893-bc23-6c87e82b8071",
    "name": "Hauck Inc",
    "code": "CN"
}, {
    "id": "96ba7505-915f-4c2c-aef4-4daa411299bc",
    "name": "Cummings, Heathcote and Rutherford",
    "code": "FR"
}, {
    "id": "0db59385-5947-4eb7-8659-ca950515af5f",
    "name": "Zulauf LLC",
    "code": "CN"
}, {
    "id": "708f297a-7032-4b25-a916-b6f6723a690f",
    "name": "Hand Group",
    "code": "ID"
}, {
    "id": "f6a55141-3cbf-441b-8a93-7ac0da2a988d",
    "name": "Schroeder LLC",
    "code": "CN"
}, {
    "id": "03459c57-3366-4501-9311-ba070e3a1b3d",
    "name": "Mohr, Rodriguez and Rodriguez",
    "code": "CM"
}, {
    "id": "d523541c-18a4-4165-b9b3-0feb6be5ef2b",
    "name": "Mante, Nader and Renner",
    "code": "SE"
}, {
    "id": "a50c2f9b-7835-4a5b-a49c-51e3c81adb3e",
    "name": "Weimann LLC",
    "code": "AR"
}, {
    "id": "32978432-c465-434f-a408-e55556afb60b",
    "name": "Kessler, Kutch and Macejkovic",
    "code": "SL"
}, {
    "id": "4c00ce18-24de-4161-ab55-9f2ab1b71431",
    "name": "Hartmann, Halvorson and Streich",
    "code": "PK"
}, {
    "id": "3a6794e7-c752-4a6c-ba7b-7e34fc5af1ad",
    "name": "Ferry Group",
    "code": "PT"
}, {
    "id": "f6d5c01b-bbe3-4178-b61a-32c2632466b7",
    "name": "Carter and Sons",
    "code": "SE"
}, {
    "id": "9e541ffa-e780-409d-8d41-6fc0f764b434",
    "name": "Kreiger-Stoltenberg",
    "code": "VN"
}, {
    "id": "9142043b-9ca4-4f93-bb82-4dc73ee77b0e",
    "name": "Davis-Bednar",
    "code": "RU"
}, {
    "id": "18a992ed-f4d1-48ee-ae36-2bc7d6719bcf",
    "name": "Sawayn-Effertz",
    "code": "CO"
}, {
    "id": "30da44cf-57a6-4fa1-818b-94537ffbc3ef",
    "name": "Grimes-O'Conner",
    "code": "VN"
}, {
    "id": "dd109f91-b5a4-4fcc-8420-058556565a73",
    "name": "Mosciski, Mertz and Krajcik",
    "code": "SE"
}, {
    "id": "14a1b41f-06a4-4eb9-82e9-0624a2a8b5ef",
    "name": "Farrell LLC",
    "code": "CN"
}, {
    "id": "71ddd1e2-daf8-4704-98a5-942641b7cffa",
    "name": "Bosco-Steuber",
    "code": "PL"
}, {
    "id": "0cf1e529-a3d1-4117-b275-bc1c3604a0f7",
    "name": "Daugherty, Veum and Zemlak",
    "code": "ZA"
}, {
    "id": "a5d1766d-f97c-4e2f-a63a-5150806f4827",
    "name": "Jaskolski, Runte and Gerhold",
    "code": "ID"
}, {
    "id": "70b5a63c-061a-4421-bc2f-c25d422b45f5",
    "name": "Bogisich, Lockman and Hills",
    "code": "CN"
}, {
    "id": "cd6eb640-4441-4f1e-b17d-0205292e95f3",
    "name": "Hand and Sons",
    "code": "BR"
}, {
    "id": "3711df8c-3e3d-4da2-9bce-62b27abbbe41",
    "name": "Pouros-King",
    "code": "RU"
}, {
    "id": "f26ed9e9-2341-4c45-b170-e5c2650b63a4",
    "name": "Hayes-Okuneva",
    "code": "PA"
}, {
    "id": "88d78c58-efd7-45b5-b1f6-a12ae35e5dcd",
    "name": "Feeney Inc",
    "code": "FI"
}, {
    "id": "84b47dca-ae67-4f46-bd97-51772173ff40",
    "name": "Macejkovic, Sporer and Corwin",
    "code": "IR"
}, {
    "id": "009ba645-ab49-4adb-b7f6-3ad919ea47ad",
    "name": "Cormier-Hane",
    "code": "CN"
}, {
    "id": "6f74b4f3-d7e2-4a59-96d5-bedcaa004a9c",
    "name": "Metz-Okuneva",
    "code": "CN"
}, {
    "id": "331d6d02-f90e-4834-a8af-5eb1bb50af0e",
    "name": "Wilkinson-Lesch",
    "code": "TH"
}, {
    "id": "45569088-f09a-406d-9fc7-4dc93f43edfe",
    "name": "Brown, Cormier and Casper",
    "code": "RU"
}, {
    "id": "27218e9c-1165-4c9d-a80f-b845e88fa634",
    "name": "Moen-Carter",
    "code": "PL"
}, {
    "id": "6bab48bc-45fe-4478-99c5-639010770655",
    "name": "Erdman-Rogahn",
    "code": "RU"
}, {
    "id": "e933cf8e-10d2-4577-8a4c-acd1e2f0f106",
    "name": "Bechtelar LLC",
    "code": "ID"
}, {
    "id": "a5000f04-9be1-47e5-8cc4-dc5dd3e3ae07",
    "name": "Gutmann-Jacobs",
    "code": "AR"
}, {
    "id": "9f3d6fb8-3baa-47f1-a37c-d16966e6f63d",
    "name": "Stoltenberg, Hyatt and Douglas",
    "code": "ID"
}, {
    "id": "bbbb638f-1bef-45b3-abb2-7da01030e93a",
    "name": "Abbott-Brekke",
    "code": "ID"
}, {
    "id": "ab5b0c20-a2a1-4aed-a08b-f05f3733693c",
    "name": "Bahringer and Sons",
    "code": "LK"
}, {
    "id": "eb92cad1-d1a9-4dc0-948f-d40aa6e97c24",
    "name": "Bashirian-Buckridge",
    "code": "ID"
}, {
    "id": "bca2f906-1752-4d4f-b657-9a88889a951c",
    "name": "Okuneva, Larkin and Howell",
    "code": "IT"
}, {
    "id": "111e6deb-4f39-404b-bb7b-b8b4fd50c168",
    "name": "Kulas Group",
    "code": "DO"
}, {
    "id": "5a8a901e-a897-443a-8df9-980967a64f48",
    "name": "Effertz-Nienow",
    "code": "BR"
}, {
    "id": "dc703b43-6352-4aef-adc2-8a6df95f1e34",
    "name": "Adams Inc",
    "code": "RU"
}, {
    "id": "1a13adf3-3948-4a08-b288-37df1e7947af",
    "name": "Hauck, Brekke and Rogahn",
    "code": "PH"
}, {
    "id": "5d443622-4c94-4092-bc9a-0780909300cd",
    "name": "Carroll-Herman",
    "code": "CN"
}, {
    "id": "d3eb6eaa-e1ac-4801-9409-635438bb72cd",
    "name": "Auer LLC",
    "code": "CA"
}, {
    "id": "f02597ff-909c-4859-acca-a6e1945eaee2",
    "name": "Spinka Inc",
    "code": "RU"
}, {
    "id": "5be68075-9e8b-4e3a-904c-b574102c6506",
    "name": "Corkery, Harber and West",
    "code": "CN"
}];
export { fakeData };