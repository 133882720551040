import * as React from 'react';
import { Typography } from '@mui/material/';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import RibbonLoadingButton from './RibbonLoadingButton';

export default function NotificationRibbonButton(props) {
    return (
        <RibbonLoadingButton {...props}
            startIcon={
                <ForwardToInboxIcon sx={{ color: "black" }} />
            }>
            <Typography color="black">
                {props.title}
            </Typography>
        </RibbonLoadingButton>
    );
}