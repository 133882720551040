import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Paper, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const LegendContainer = styled(Button)({
  cursor: "pointer",

  backgroundColor: "rgba(0, 0, 0, 0.05)",
  dropShadow: "none",
  boxShadow: "none",
  borderRadius: "20px",

  padding: "10px",
  paddingTop: "3px",
  paddingBottom: "3px",
  textAlign: "center",

  // Prevent user selection
  userSelect: "none",
  msUserSelect: "none",
  MozUserSelect: "none",
  WebkitUserSelect: "none",
});

const StyledTypography = styled("span")({
  fontSize: "10px",
  fontSize: "0.9rem",
  fontFamily: "Paralucent,Mulish,Muli,sans-serif",
});

const Legend = ({ id, index, value, color, filterData, visible }) => {

  const clickLegend = () => {
    filterData(id, index, value);
  };

  return (
    <Grid item xs="auto">
      <LegendContainer onClick={clickLegend}>
        <svg height="14px" width="14px" style={{ opacity: visible ? 1 : 0.5 }}>
          <circle cx="7" cy="7" r="5" fill={color} />
        </svg>
        <StyledTypography sx={{ color: visible ? "#5C5C5C" : "#A1A1A1" }}>
          {id}
        </StyledTypography>
      </LegendContainer>
    </Grid>
  );
};

Legend.propTypes = {
  id:         PropTypes.any,
  index:      PropTypes.number,
  value:      PropTypes.any,
  color:      PropTypes.string,
  filterData: PropTypes.func,
  visible:    PropTypes.bool
};

Legend.defaultProps = {
  id:     0,
  index:  0,
  value:  0,
  color:  '#fff',
  filterData: () => { },
  visible: false
};

export default Legend;
