// Sidebar List Children handles the different dropdown methods

import { Collapse } from "@mui/material";
import SidebarListPopup from "./SidebarListPopup";

const SidebarListChildren = ({
  open,
  sidebarActive,
  handleClick,
  anchorEl,
  title,
  children,
}) => {
  if (open && sidebarActive)
    return (
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    );

  if (open && anchorEl)
    return (
      <SidebarListPopup
        open={open}
        title={title}
        children={children}
        anchorEl={anchorEl}
        handleClick={handleClick}
      ></SidebarListPopup>
    );

  return null;
};

export default SidebarListChildren;
